<div>
    <div class="modal-header d-flex align-items-end flex-column">
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
        <div style="text-align: center;">
            <i style="font-size: 3em;" class="far fa-times-circle mb-4"></i>
            <h5>Oops... seu plano atual não possui acesso a esse recurso.</h5>
            <p><strong>RECURSO DISPONIVEL EM:</strong></p>
        </div>
        <div class="d-flex flex-column align-items-center">
            <div style="width: fit-content;">
                <p>
                    <i class="far fa-times-circle text-danger mr-1"></i>
                    <span>Gratuito</span>
                </p>
                <p>
                    <i class="far fa-check-circle text-success mr-1"></i>
                    <span>Manual Basic e Manual Full</span>
                </p>
                <p>
                    <i class="far fa-check-circle text-success mr-1"></i>
                    <span>Digital Basic e Digital Full</span>
                </p>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex flex-column align-items-center">
        <button type="button" class="btn btn-primary" (click)="redirectToPricing()">Explorar planos premium</button>
    </div>
</div>