<button class="btn btn-outline-danger btn-sm" (click)="openModal()">Recusar Réplica</button>


<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Recusar Réplica</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
	    Por favor, preencha o motivo da réplica do item {{index}} estar sendo recusada <span class="text-muted"> - máx. {{contestation.value.length || 0}}/2000 caracteres</span>
        <form [formGroup]="contestationForm">
            <div class="row">
                <div class="form-group col">
                    <textarea type="text" formControlName="contestation" class="form-control" style="resize: none;" rows="5"
                        [ngClass]="{ 'is-invalid': (contestation.dirty || contestation.touched) && contestation.errors }"></textarea>
                    <div *ngIf="(contestation.dirty || contestation.touched) && contestation.errors"
                        class="invalid-feedback">
                        <div *ngIf="contestation.errors.required">Motivo é obrigatório</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading || !contestationForm.valid" class="btn btn-primary"
            (click)="saveContestation('REPLICA_DECLINED', 'Réplica recusada com sucesso.'); modal.close();">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Confirmar
        </button>
    </div>
</ng-template>
