import { AfterContentInit, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'evo-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  providers: [{       
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => RangeSliderComponent),
    multi: true     
  }]
})
export class RangeSliderComponent implements AfterContentInit, ControlValueAccessor {
  @Input() floor: number = 0;
  @Input() ceil: number = 100;
  @Input() placeholder = 'Selecione'

  onChange: any = () => {}
  onTouch: any = () => {}

  @ViewChild('select', {static: true}) select: NgSelectComponent;

  selectValue: any;
  lowValue;
  highValue;

  _value = new FormControl([this.floor, this.ceil]);
  set value(val: any) {
    if(val && val?.length === 2) { 
      this._value.setValue(val)
      this.lowValue = val[0];
      this.highValue = val[1];

      this.selectValue = {label: `${val[0]} - ${val[1]}`, value: val};
      this.select?.select(this.selectValue);

      this.onChange(val);
      this.onTouch(val);
    } else if(val === null) {
     this.reset();
    }
  }
  get value() {
    return this._value;
  }

  constructor() { }

  reset() {
    this.value = [0, 100];
  }

  changeValueOnInput(value) {
  }

  ngAfterContentInit() {
    this.selectValue = {label: `${!!this._value.value[0] ? this._value.value[0] : this.floor} - ${!!this._value.value[1] ? this._value.value[1] : this.ceil}`, value: !!this._value.value ? this._value.value : [this.floor, this.ceil]};
    this.select.select(this.selectValue);
  }

  writeValue(value: number[]) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  closeDropdown() {
    this.select.close();
  }

}
