import { MonitorInteractionItem } from '@app/models';
import { InteractionSentiment, InteractionSentimentLabels } from './interaction-status.enum';
import { Interaction } from '@app/models';

export function formatStatus(status:string){
    switch (status) {
        case "Finalizada":
            return "bg-success";
        default:
            return "bg-info";
    }
}
export function checkIfGradeShouldCount(item:MonitorInteractionItem): boolean{
    if(item.answer.id == "NONCONFORMING"){
        let positiveStatus = ["CONTESTATION_ACCEPTED", "REPLICA_ACCEPTED"]
        let positive = false;
        positiveStatus.forEach(element => {
            if(item.status.id == element){
                positive = true;
                return;
            }
        });
        return positive;
    }
    return true;
}

export function getSentimentImage(interaction: Interaction): string {
	if(interaction.dissatisfaction === InteractionSentiment[InteractionSentiment.CRITICAL]) {
		return InteractionSentiment.CRITICAL as unknown as string;
	}

	return interaction.sentiment ? InteractionSentiment[interaction.sentiment] : InteractionSentiment.NEUTRAL;
}

export function getSentimentLabel(interaction: Interaction): string {
	if(interaction.dissatisfaction == InteractionSentimentLabels[InteractionSentimentLabels.CRITICAL]) {
		return InteractionSentimentLabels.CRITICAL as unknown as string;
	}

	return interaction.sentiment ? InteractionSentimentLabels[interaction.sentiment] : InteractionSentimentLabels.NEUTRAL;
}
