import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { environment } from '../../../environments/environment';
import { TrainingService } from '@app/services';
import { OrganizationService } from '@app/services';
import { OnlyPremiumDialogComponent } from '../only-premium-dialog/only-premium-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsService } from 'ngx-permissions';
import { QualityPermissions } from '@app/models';
import { Subscription } from 'rxjs';
import { UserService } from '@app/services';
import { TrainingErrorDialogComponent } from '../training-error-dialog/training-error-dialog.component';
import { SystemMessages, ToastService } from '@app/services';
import { Organization } from '@app/models';
import {CacheInterceptorService} from "../../_services/cache-interceptor.service";

@Component({
	selector: 'evo-switch',
	templateUrl: './evo-switch.component.html',
	styleUrls: ['./evo-switch.component.scss']
})
export class EvoSwitchComponent implements OnInit {

	@Input() isInternal: boolean = false;

	public showOrganizationButton: boolean = false;
	public products: SwitchProducts[] = [];

	public trainingUrl: string = '';

	@Output() switchOrganizations: EventEmitter<any> = new EventEmitter<any>();
	@Input() public showAdmin: boolean = true;

	public showTraining: boolean = false;
	public qualityPermissions = QualityPermissions;

	public subs = new Subscription();

	@Input()
	public set switchProductsOrganizations(value: SwitchProductsOrganizations) {
		value.allProducts.forEach(product => {
			let switchProducts = new SwitchProducts();
			switchProducts.alias = product.alias;
			switchProducts.name = product.name;
			switchProducts.accessUrl = product.accessUrl;
			switchProducts.organizations = [];

			this.showOrganizationButton = value.allOrganizations.length > 1;

			value.allOrganizations.forEach((organization: any) => {
				organization.products.forEach((organizationProduct: { alias: string; }) => {
					if(organizationProduct.alias == product.alias) {
						switchProducts.organizations = [ ...switchProducts.organizations, organization ];
					}
				});
			});

			this.products.push(switchProducts);
		});
	}

	constructor(private router: Router,
	            public trainingService: TrainingService,
	            public organizationService: OrganizationService,
				public ngxPermissionService: NgxPermissionsService,
				private modalService: NgbModal,
				public userService: UserService,
				private toastService: ToastService,
				private cacheInterceptorService: CacheInterceptorService,
	            @Inject(DOCUMENT) private document: Document) {
	}

	ngOnInit(): void {
		this.ngxPermissionService.hasPermission([QualityPermissions.ALL_PERMISSIONS, QualityPermissions.TRAINING_ACCESS]).then(
			data => {
				this.showTraining = data && environment.features.trainingUrl !== null;
				this.trainingUrl = environment.features.trainingUrl;
			});
	}

	public onSwitchOrganizations(): void {
		this.cacheInterceptorService.clearCache();
		this.switchOrganizations.emit();
	}

	public get idUrl(): string {
		return environment.apps['id'];
	}

	public get adminUrl(): string {
		return environment.apps['admin'];
	}

	public get currentOrganization(): Organization {
		return this.organizationService.currentOrganization;
	}

	public getAdminUrl(): string {
		let url = this.adminUrl;

		if(this.currentOrganization.id) {
			url += '/organizations?organization=' + this.currentOrganization.alias;
		}

		return url;
	}
}

export class SwitchProducts {
	public alias: string;
	public name: string;
	public organizations: any[];
	public accessUrl: string;

	public isOpen: boolean = false;
}

export class SwitchProductsOrganizations {
	public allProducts: any[];
	public allOrganizations: any[];
}


