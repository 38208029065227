import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Group } from '@app/models';
import { BaseServiceClass } from './baseService.service';

@Injectable({ providedIn: 'root' })
export class GroupService extends BaseServiceClass<Group>{

  constructor(protected httpService: HttpClient) {
    super(httpService, '/groups');
}
}
