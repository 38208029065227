import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IntervalDays } from '@app/models';
import { BaseServiceClass } from "./baseService.service";

@Injectable({ providedIn: 'root' })
export class IntervalDaysService extends BaseServiceClass<IntervalDays> {
    constructor(protected httpService: HttpClient) {
        super(httpService, '/api/v1/intervalDays');
    }

    getIntervalDays() {
        return this.httpService.get<IntervalDays[]>(`${this.entity_url}`);
    }

    createIntervalDays(days: any) {
        return this.httpService.post<IntervalDays>(`${this.entity_url}`, {days});
    }
}
