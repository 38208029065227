import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-checklist-modal',
  templateUrl: './new-checklist-modal.component.html',
  styleUrls: ['./new-checklist-modal.component.scss']
})
export class NewChecklistModalComponent implements OnInit {

	public title: string = 'Novo checklist';
	public subtitle: string = 'Seu checklist foi criado.';
	public description: string = 'O que gostaria de fazer agora?';

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
