import { FormGroup } from '@angular/forms';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { InteractionStatus } from './interaction-status';
import { ChecklistItem } from './checklist-item';
import { Checklist } from './checklist';
import { User } from './user';
import { MonitorInteractionItem } from './monitor-interaction-item';
import { Transcription } from './transcription';
import { FileInfo } from './fileInfo';
import { Contestation } from './contestation';
import { Segment } from './segment';
import { AnalysisHistory } from "./analysis-history";
import { InteractionStatusEnum } from '../_helpers/interaction-status.enum';
import { InteractionAttachment } from './interaction-attachment';
import { InteractionCustomer } from './interaction-customer';
import { InteractionDebt } from './interaction-debt';
import { Agent } from './agent';
import { Justification } from './justification';

export class Interaction implements Deserializable {
    id: number;
    agentName?: string;
    status?: InteractionStatus;
    contactDate?: Date;
    monitorDate?: Date;
    grade?: number;
    deadLine?: Date;
    operation?: Operation;
    customerId?: number;
    feedback?: string;
    monitorDuration?: string;
    userMonitor?: User;
    userSupervisor?: User;
	userOperator: any;
    customer?: Customer;
    evaluation?: MonitorInteractionItem[];
    silenceTime?: string;
    silencePercent?: any;
    fileInfo: FileInfo;
    transcription: Transcription;
    duration?: any;
    audioUrl: string;
    audioStem?: string;
    originalFileName?: string;
    monthlyCycleName: string;
	dateFeedback: Date;
	dateFeedbackAccepted: Date;
	userFeedback: User;
	userFeedbackAccepted: User;
	metadata: any;

	inAnalysis: boolean = false;

	analysisHistories: AnalysisHistory[];

    metadataId: any;

    itensToUpdate?:MonitorInteractionItem[] = [];
    segment:Segment;
    contestations: Contestation[];
	checklist?: Checklist;

	interactionType: string;

	dissatisfaction: string;
	sentiment: string;

    interactionCustomer: InteractionCustomer;
    interactionDebt: InteractionDebt;
	// lastStatus: InteractionStatus;

	attachments: InteractionAttachment[];

    agent?: Agent;

	justification?: Justification;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

	public get lastStatusHistory(): InteractionStatus {
		if(this.status.id !== InteractionStatusEnum.IN_ANALYSIS) {
			return this.status;
		}

		if(this.analysisHistories.length == 0) {
			return {} as InteractionStatus;
		}

		return this.lastAnalysisHistory.interactionPreviousStatus;
	}

	public get lastAnalysisHistory(): AnalysisHistory {
		if(!this.analysisHistories || this.analysisHistories.length == 0) {
			return {} as AnalysisHistory;
		}

		return this.analysisHistories.find(item => item.active);
	}


}
