<script src="../create-checklist.component.ts"></script>
<div>
    <div class="body">
        <p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Segmento: {{checklist?.segment?.name}}</p>
        <evo-stepper-title title="Regras de Efetividade" subtitle="Selecione as regras para marcação de efetividade e não efetividade"></evo-stepper-title>

	    <div class="alert alert-warning">
			<p>Caso a mesma regra seja selecionada para efetiva e não efetiva, a prioridade será para a efetiva.</p>
		</div>

		<form [formGroup]="form">
			<div class="form-group">
				<label>Selecione a regra de efetividade</label>
				<ng-select formControlName="effective" bindLabel="name" bindValue="_id" [items]="rules" placeholder="Não selecionado"></ng-select>
			</div>

			<div class="form-group">
				<label>Selecione a regra de não efetividade</label>
				<ng-select formControlName="nonEffective" bindLabel="name" bindValue="_id" [items]="rules" placeholder="Não selecionado"></ng-select>
			</div>
		</form>

    </div>

	<evo-stepper-footer [isFormInvalid]="false" (onPrev)="prevStep()" (onNext)="nextStep()"></evo-stepper-footer>
</div>
