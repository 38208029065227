import { FormGroup } from '@angular/forms';
import { Customer } from './customer';
import { Deserializable } from './deserializable';

export class Group implements Deserializable {
    id?: number;
    name: string;
    customer?: Customer;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}