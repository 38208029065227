import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { JobService } from '@app/services';
import { UserService } from '@app/services';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { UserFilter } from '@app/models';
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { JobMetadataUser, MetadataUser } from '@app/models';
import { ConfirmDialogComponent } from '../../../../_components/confirm-dialog/confirm-dialog.component';
import { ImportDataService, ImportTypes } from '@app/services';
import { ToastService } from '@app/services';

@Component({
	selector: 'app-import-data-step-agents',
	templateUrl: './import-data-step-agents.component.html',
	styleUrls: ['./import-data-step-agents.component.scss']
})
export class ImportDataStepAgentsComponent implements OnInit, OnDestroy {

	subs = new Subscription();
	uploadSub: Subscription = new Subscription();

	public operators = [];
	public pagedUsers = [];

	search$: BehaviorSubject<string> = new BehaviorSubject(null);
	users: any[] = [];
	curAgentOffset = 0;
	isAgentLastPage = true;

	public jobMetadataUsers: JobMetadataUser[];
	public tempMetadataUser: MetadataUser;

	importTypes = ImportTypes;

	public isLoading = false;

	public limit: number = 8;
	public offset: number = 0;

	public isOperatorsDuplicated: boolean = false;

	constructor(public importDataService: ImportDataService,
	            private userService: UserService,
	            private modalService: NgbModal,
	            private activeModal: NgbActiveModal,
	            private toastService: ToastService,
				private router: Router,
	            public jobService: JobService) {
	}

	ngOnInit(): void {
		this.onAgentSearch();
		this.getJobsMetadataUsers();
		this.loadUsers();
	}

	onPrev() {
		this.importDataService.prevStep();
	}

	onNext() {
		this.isLoading = true;

		this.importDataService.operatorsMap = Object.assign({}, ...this.operators.map(s => ({[s.operatorName]: s.user.id})));

		if(this.importDataService.importType !== this.importTypes.METADATA) {
			this.importDataService.nextStep();
		} else {
			const body = {
				segmentId: this.importDataService.segmentId,
				operationId: this.importDataService.operationId,
				columns: this.importDataService.columnIndexes,
				metadata: this.importDataService.metadata,
				metadataId: this.jobService.importJobId,
				type: 'IMPORT_METADATA'
			}
			return this.postImportJob(body);
		}
	}

	private postImportJob(body): void {
		this.jobService.post(body).subscribe(
			data => {
				this.toastService.showSuccess('Chamadas importadas com sucesso.');
				this.activeModal.close();
				this.router.navigate(['/interactions']);
			}, error => {
				console.error(error);
				this.toastService.showDanger('Não foi possível fazer a importação das chamadas, entre em contato.');
			});
	}

	public getJobsMetadataUsers(): void {
		this.jobService.getJobsMetadataUsers().subscribe(
			data => {
				this.jobMetadataUsers = data;

				this.importDataService.uniqueOperators.map(item => {
					let operator = this.jobMetadataUsers.find( operator => operator.metadataUserValue === item);
					this.operators = [...this.operators, { operatorName: item, user: (operator ? operator.user : null), autoFill: !!operator }];
				});
			}
		)
	}

	onAgentSearch() {
		this.subs.add(this.search$.asObservable().pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			switchMap((searchTerm: string) => {
				let userFilter: UserFilter = {
					name: searchTerm,
					email: searchTerm,
					active: true,
					linesPerPage: 10,
					page: 0,
					orderBy: 'name',
					direction: 'ASC'
				};
				return this.userService.filter(userFilter)
			})
		).subscribe((users) => {
			this.users = [];
			this.completeUsers(users);

			this.curAgentOffset = 1;
			this.isAgentLastPage = users.last;
		}));
	}

	nextAgentBatch() {
		this.userService.filter({
			page: this.curAgentOffset,
			name: this.search$.value,
			email: this.search$.value,
			active: true,
			linesPerPage: 10,
			orderBy: 'name',
			direction: 'ASC'
		}).subscribe((users) => {
			this.completeUsers(users);
			this.curAgentOffset = this.curAgentOffset + 1;
			this.isAgentLastPage = users.last;
		});
	}

	private completeUsers(users: any): void {
		users.content.forEach(user => {
			this.users = [ ...this.users, { id: user.id, name: user.name, email: user.email} ];
		});
	}

	public pageChanged(event): void {
		this.pagedUsers = [];

		this.operators.forEach(operator => {
			if(operator.user && operator.user.id) {
				if(!this.pagedUsers.find(item => item.name == operator.user.name)) {
					this.pagedUsers = [...this.pagedUsers, { id: operator.user.id, name: operator.user.name, email: operator.user.email}];
				}
			}
		});

		this.users = this.pagedUsers;
	}

	public get adminUrl(): string {
		return environment.apps.admin;
	}

	isOperatorsValid(): boolean {
		let isValid = true;
		this.isOperatorsDuplicated = false;

		this.operators.forEach(operator => {
			if(!operator.user || !operator.user.id) { isValid = false; }

			this.operators.forEach(dup => {
				if(dup.user && dup.user.id && operator.user && operator.user.id) {
					if (dup.user.id == operator.user.id && dup.operatorName != operator.operatorName) {
						this.isOperatorsDuplicated = true;
					}
				}
			});
		});

		return isValid;
	}

	public isDuplicated(row: any): boolean {
		if(!row.user || !row.user.id) return false;

		let isDup = false;

		this.operators.forEach(operator => {
			if(operator.user && operator.user.id) {
				if(operator.user.id == row.user.id && row.operatorName != operator.operatorName) {
					isDup = true;
				}
			}
		});

		return isDup;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	public loadUsers() {
		let userFilter: UserFilter = {
			name: '',
			email: '',
			active: true,
			linesPerPage: 10,
			page: 0,
			orderBy: 'name',
			direction: 'ASC'
		};

		this.userService.filter(userFilter).subscribe(users => {
			this.users = [];
			this.completeUsers(users);
			this.curAgentOffset = 1;
			this.isAgentLastPage = users.last;
		});
	}

	public saveTempMetadataUser(user): void {
		this.tempMetadataUser = user;
	}

	public validateMetadataUserChange(user, row): void {
		if(!row.autoFill) return;

		const modalRef = this.modalService.open(ConfirmDialogComponent, {});
		modalRef.componentInstance.title = 'Opções de relacionamento';
		modalRef.componentInstance.text = 'O usuário <b>' + this.tempMetadataUser.name + '(' + this.tempMetadataUser.email + ')</b>' + ' já possui vínculo. Você quer substituir pelo usuário <b>' + user.name + '(' + user.email + ')</b>?';
		modalRef.componentInstance.confirmText = 'Substituir';
		modalRef.componentInstance.cancelText = 'Cancelar';

		modalRef.result.then( data => {
			if(!data) {
				row.user = this.tempMetadataUser;
			} else {
				row.autoFill = false;
			}
		})
	}
}

