import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class CustomerService extends BaseServiceClass<Customer>{

    constructor(protected httpService: HttpClient, private userService: UserService) {
        super(httpService, '/customers');
    }

    findByCurrentUser() {
        let userId = this.userService.currentUser.id;
        return this.httpService.get<Customer[]>(`${this.entity_url}/users/${userId}`);
    }

    patch(customerId: number, customer: Customer) {
        return this.httpService.patch<any>(`${this.entity_url}/${customerId}`, customer);
    }
}
