import { Deserializable } from '../deserializable';

export class JobMetadataUser implements Deserializable {

	user: MetadataUser;
	metadataUserValue: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}

export interface MetadataUser {
	id: number;
	name: string;
	email: string;
	username: string;
}
