import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-grade',
  templateUrl: './grade.component.html',
  styleUrls: ['./grade.component.scss']
})
export class GradeComponent implements OnInit, OnChanges {

  grade: FormControl;
  @Output() onFilterGrade: EventEmitter<object> = new EventEmitter<object>();
  @Input() resetFilterEvent;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetFilterEvent.currentValue && changes.resetFilterEvent.currentValue.grade === null) {
      this.grade = new FormControl([0, 100]);
    }
  }

  ngOnInit(): void {
    this.grade = new FormControl([0, 100]);
    this.grade.valueChanges.subscribe(() => this.filter());
  }

  filter() {
    this.onFilterGrade.emit({ gradeInicial: this.grade.value[0], gradeFinal: this.grade.value[1] });
  }

}
