<div>
    <div class="body">
        <p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Segmento: {{checklist?.segment?.name}}</p>
        <evo-stepper-title title="Atribuir peso aos itens" subtitle="Selecione um peso para cada item do checklist"></evo-stepper-title>

	    <div class="d-flex justify-content-center">
		    <button class="btn btn-link" (click)="showHelper()">Saiba mais</button>
	    </div>

        <div class="items" *ngFor="let group of categoriesSelected; let i = index">
	        <ng-container *ngIf="group.categories.length > 0">
	            <ngb-accordion #a="ngbAccordion" activeIds="group0" style="overflow: visible !important;">
	                <ngb-panel [id]="'group'+i">
	                    <ng-template ngbPanelHeader let-opened="opened">
	                        <div class="group-header d-flex justify-content-between align-items-center w-100">
	                            <h6 style="color: #1F1F1F;">{{group.group}} ({{group.categories.length}})</h6>
	                            <button class="btn ml-auto" ngbPanelToggle>
	                                <i class="fas text-muted" style="font-size: 1.5em"
	                                    [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
	                            </button>
	                        </div>
	                    </ng-template>
	                    <ng-template ngbPanelContent *ngIf="this.categoriesPesos">
		                    <div class="d-flex align-items-center mb-3 px-4">
			                    <div class="d-flex flex-grow-1 align-items-center">
				                    <span style="font-size: 14px;">Itens</span>
				                    <i class="fas fa-info-circle ml-1"></i>
			                    </div>

			                    <div class="d-flex align-items-center" style="color: #404040; width: 70px; margin-right: 10px;">
				                    <span style="font-size: 14px;">Peso</span>
				                    <i class="fas fa-info-circle ml-1" ngbTooltip="Itens com peso 100 não consomem pontos e são classificados como Não conformidades graves (NCGs)."></i>
			                    </div>

			                    <div class="d-flex align-items-center justify-content-center" style="color: #404040;  width: 120px;">
				                    <span style="font-size: 14px;">NCG</span>
				                    <i class="fas fa-info-circle ml-1" ngbTooltip="Não conformidade grave (NCG)."></i>
			                    </div>
		                    </div>

		                    <div class="checklist-item-group">
			                    <div class="checklist-item" *ngFor="let category of group.categories; let j = index">
				                    <div class="item-row">
					                    <div class="item-name">
						                    <div class="">
							                    <span style="font-size: 14px; font-weight: bold">{{category.name}}</span>
							                    <p class="mb-0 text-muted" style="max-width: 520px;">{{ category.description }}</p>
						                    </div>
					                    </div>

					                    <div class="d-flex" style="width: 70px; margin-right: 10px;">
						                    <input class="form-control" mask="separator.2" separatorLimit="100" #peso [formControl]="getCategoryControl(i, j, 'peso')" (focus)="onCategoryFocus(category)" (blur)="onCategoryBlur(category, +peso.value)"
						                           [attr.disabled]="+peso.value == 100 ? true : null">
					                    </div>

					                    <div class="d-flex justify-content-center" style="width: 120px;">
						                    <input type="checkbox" [checked]="+peso.value == 100" (change)="resetNcgValue(category, +peso.value, i, j)">
					                    </div>
				                    </div>
			                    </div>
		                    </div>

	                    </ng-template>
	                </ngb-panel>
	            </ngb-accordion>
	        </ng-container>
        </div>
    </div>

    <evo-stepper-footer [isLoading]="isLoading" [isFormInvalid]="roundNumber(pesoTotal, 2) != 100" (onPrev)="prevStep()" (onNext)="nextStep()">
        <div progress class="progress-wrapper">
            <p *ngIf="pesoTotal <= 100" class="text-muted">Você possui {{roundNumber(100 - pesoTotal, 2) | number: '1.0-2'}} pontos restantes <i class="fas fa-info-circle ml-1"></i></p>
            <p *ngIf="pesoTotal > 100" class="text-danger">Você excedeu a pontuação em {{roundNumber(100 - pesoTotal, 2) | number: '1.0-2'}} pontos <i class="fas fa-info-circle ml-1"></i></p>
            <p class="progress-bar"><ngb-progressbar type="primary" [value]="pesoTotal <= 100 ? pesoTotal : 0"></ngb-progressbar></p>
        </div>
    </evo-stepper-footer>
</div>
