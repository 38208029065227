import { InteractionStatusEnum } from '../_helpers/interaction-status.enum';
import { Deserializable } from './deserializable';

export class InteractionType implements Deserializable {
    value: string;
	name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
