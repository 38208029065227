import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
import { LoginComponent } from './_views/login/login.component';
import { OrganizationsComponent } from './_views/organizations/organizations.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { QualityPermissions } from '@app/models';
import { NoAccessComponent } from './_views/no-access/no-access.component';
import { environment } from '../environments/environment';

const GUARDS = [AuthGuard, NgxPermissionsGuard];

const NO_ACCESS_ROUTE = 'no-access'

const routes: Routes = [
	{path: '', redirectTo: environment.features.homepage, pathMatch: 'full'},
	{
		path: 'home',
		loadChildren: () => import('./_views/home/home.module').then(m => m.HomeModule),
		canActivate: [ AuthGuard ]
	},
	{
		path: 'dashboards',
		loadChildren: () => import('./_views/dashboard/dashboard.module').then(m => m.DashboardModule),
		canActivate: [ AuthGuard ],
		data: {
			permissions: {
				only: [QualityPermissions.ALL_PERMISSIONS]
			}
		}
	},
	{
		path: 'flows',
		loadChildren: () => import('./_views/flows/flows.module').then(m => m.FlowsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'segments',
		loadChildren: () => import('./_views/segments/segments.module').then(m => m.SegmentsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'integrations',
		loadChildren: () => import('./_views/integrations/integrations.module').then(m => m.IntegrationsModule),
		canActivate: GUARDS,
		data: {
			permissions: {
				only: [
					QualityPermissions.INTERNAL
				],
				redirectTo: NO_ACCESS_ROUTE
			}
		}
	},
	{
		path: 'imports',
		loadChildren: () => import('./_views/import-jobs/import-jobs.module').then(m => m.ImportJobsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'interactions',
		loadChildren: () => import('./_views/interactions/interactions.module').then(m => m.InteractionsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'interactions/:id',
		loadChildren: () => import('./_views/interactions/interactions.module').then(m => m.InteractionsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'justifications',
		loadChildren: () => import('./_views/justification/justification.module').then(m => m.JustificationModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'report',
		loadChildren: () => import('./_views/reports/reports.module').then(m => m.ReportsModule),
		canActivate: GUARDS,
		data: { permissions: {} }
	},
	{
		path: 'first-steps',
		loadChildren: () => import('./_views/first-steps/first-steps.module').then(m => m.FirstStepsModule),
		canActivate: GUARDS,
		data: {
			permissions: {
				only: ['ALL_PERMISSIONS'],
				redirectTo: NO_ACCESS_ROUTE
			}
		}
	},
	{
		path: 'pbi-reports',
		loadChildren: () => import('./_views/pbi-reports/pbi-reports.module').then(m => m.PbiReportsModule),
		canActivate: GUARDS,
		data: {
			permissions: {
				only: ['ALL_PERMISSIONS'],
				redirectTo: NO_ACCESS_ROUTE
			}
		}
	},
	{
		path: 'gen-ai-configuration',
		loadChildren: () => import('./_views/organization-ai-model/organization-ai-model.module').then(m => m.OrganizationAiModelModule),
		canActivate: GUARDS,
		data: {
			permissions: {
				only: ['ALL_PERMISSIONS'],
				redirectTo: NO_ACCESS_ROUTE
			}
		}
	},
	{
		path: 'chat',
		loadChildren: () => import('./_views/gen-ai-chat/gen-ai-chat.module').then(m => m.GenAiChatModule),
		canActivate: GUARDS,
		data: {
			permissions: {
				only: [ QualityPermissions.ALL_PERMISSIONS, QualityPermissions.GEN_AI_CHAT ],
				redirectTo: NO_ACCESS_ROUTE
			}
		}
	},
	{path: 'organizations', component: OrganizationsComponent, canActivate: [AuthGuard]},
	{path: 'login', component: LoginComponent},
	{path: 'no-access', component: NoAccessComponent, canActivate: [AuthGuard]},
	// otherwise redirect to home
	{path: '**', redirectTo: ''}

];

@NgModule({
	imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
