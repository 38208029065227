import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInTop } from '../../_helpers/animations/fade.animations';
import { SpeedService } from '../../_services/speed.service';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { fromEvent, merge, Observable, Observer, Subscription } from 'rxjs';
import { formatDistance, subMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { UserService } from '../../_services/user.service';

@Component({
    selector: 'app-speed-test',
    templateUrl: './speed-test.component.html',
    styleUrls: ['./speed-test.component.scss'],
	animations: [ fadeInTop ]
})
export class SpeedTestComponent implements OnInit, OnDestroy {

	public pingDates: any[] = [];
	public numPings: number = 10;
	public count: number = 0;

	public finalPing: number = 0;
	public finalDownload: number = 0;
	public finalUpload: number = 0;

	public maxPing = 300;
	public maxDownload = 300;
	public maxUpload = 50;

	public pingLoading = false;
	public downloadLoading = false;
	public uploadLoading = false;

	public networkStatus: boolean = false;
	public networkStatus$: Subscription = Subscription.EMPTY;

	public lastTest: Date;

    constructor(public userService: UserService, public speedService: SpeedService) {
    }

	get lastTestLabel(): string {
		if(!this.lastTest) {
			return '';
		}

		return formatDistance(this.lastTest, new Date(), { locale: ptBR });
	}

	ngOnInit() {
		this.checkNetworkStatus().subscribe(isOnline => this.networkStatus = isOnline);

		if(localStorage.getItem('ping')) {
			this.finalPing = Number(localStorage.getItem('ping'));
			this.finalDownload = Number(localStorage.getItem('download'));
			this.finalUpload = Number(localStorage.getItem('upload'));
		}

		let lastTest = localStorage.getItem('connectionLog');
		if(lastTest) {
			this.lastTest = new Date(lastTest);

			let now = new Date();
			now = subMinutes(now, 5);

			if(now.getTime() < this.lastTest.getTime()) {
				return;
			}
		}

		this.startLogs();
	}

	public startLogs(): void {
		this.startPingMeasure();
		this.getDownloadUrl();
		this.getUploadInfo();
	}

	public checkNetworkStatus() {
		return merge<boolean>(
			fromEvent(window, 'offline').pipe(map(() => false)),
			fromEvent(window, 'online').pipe(map(() => true)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine);
				sub.complete();
			}));
	}

	public getDownloadUrl(): void {
		this.downloadLoading = true;

		this.speedService.getDownloadUrl().pipe(
			switchMap(result => {
				return this.speedService.download(result)
			})
		).subscribe(data => {
			if(data.state == 'DONE') {
				this.finalDownload = this.getDownloadSpeed();
				this.validateStatus();
			}
		}).add(() => this.downloadLoading = false);
	}

	public getUploadInfo(): void {
		let r = new ArrayBuffer(1048576);
		let maxInt = Math.pow(2, 32) - 1;
		try {
			r = new Uint32Array(r);
			// @ts-ignore
			for (var i = 0; i < r.length; i++) r[i] = Math.random() * maxInt;
		} catch (e) {}
		let req: any = [];
		let reqsmall: any = [];
		for (let i = 0; i < 20; i++) req.push(r);
		req = new Blob(req);
		r = new ArrayBuffer(2621440);
		try {
			r = new Uint32Array(r);
			// @ts-ignore
			for (let i = 0; i < r.length; i++) r[i] = Math.random() * maxInt;
		} catch (e) {}
		reqsmall.push(r);
		reqsmall = new Blob(reqsmall);

		let start = new Date().getTime();
		let size = reqsmall.size / 1000 / 1000;

		this.uploadLoading = true;

		this.speedService.upload(reqsmall).subscribe(
			data => {
				let end = new Date().getTime();
				let time = (end - start) / 1000;

				this.finalUpload = (size / time) * 100;
				this.validateStatus();
			}, error => {
				console.error(error);
			}).add(() => this.uploadLoading = false);
	}

	public startPingMeasure(): void {
		this.pingDates = [];
		this.count = 0;
		this.pingLoading = true;
		this.getPingInfo();
	}

	private getPingInfo(): void {
		let start = new Date().getTime();
		let end = new Date().getTime();

		let requests = this.recursiveGetPingWithMerge();
		start = new Date().getTime();

		requests.subscribe(
			(data: any) => {
				end = new Date().getTime();
				this.pingDates = [ ...this.pingDates, { start, end }];
				this.pingDates.shift();
				this.pingDates.pop();

				this.pingDates.forEach(item => {
					this.finalPing += item.end - item.start;
				});

				this.finalPing = this.finalPing / this.pingDates.length;

				this.validateStatus();
			}
		).add(() => this.pingLoading = false);
	}

	private recursiveGetPingWithMerge(): any {
		if(this.count < this.numPings) {
			this.count++;
			let start = new Date().getTime();

			return this.speedService.ping().pipe(
				mergeMap( item => {
					let end = new Date().getTime();

					this.pingDates = [ ...this.pingDates, {start, end}];
					return this.recursiveGetPingWithMerge();
				})
			);
		} else {
			return this.speedService.ping();
		}
	}

	public getDownloadSpeed(): number {
		if(this.speedService.downloadStatus.length == 0) {
			return 0;
		}

		let speed = 0;

		this.speedService.downloadStatus.forEach(item => {
			speed += item.speed;
		});

		return Math.round(speed / this.speedService.downloadStatus.length);
	}

	public getPingColor(): string {
		if(this.finalPing < 100) { return 'success'; }
		if(this.finalPing >= 100 && this.finalPing < 200) { return 'warning'; }
		if(this.finalPing >= 200) { return 'danger'; }

		return 'primary';
	}

	public getDownloadColor(): string {
		if(this.finalDownload < 100) { return 'danger'; }
		if(this.finalDownload >= 100 && this.finalDownload < 200) { return 'warning'; }
		if(this.finalDownload >= 200) { return 'success'; }

		return 'primary';
	}

	public getUploadColor(): string {
		if(this.finalUpload < 10) { return 'danger'; }
		if(this.finalUpload >= 10 && this.finalUpload < 20) { return 'warning'; }
		if(this.finalUpload >= 20) { return 'success'; }

		return 'primary';
	}

	ngOnDestroy(): void {
		this.networkStatus$.unsubscribe();
	}

	private validateStatus(): void {
		if(this.finalUpload != 0 && this.finalDownload != 0 && this.finalPing != 0) {
			this.speedService.postConnectionLog(this.finalPing, this.finalDownload, this.finalUpload).subscribe(
				data => {
					this.lastTest = new Date(localStorage.getItem('connectionLog'));
				});
		}
	}

}
