import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Group } from '@app/models';
import { GroupService } from '@app/services';
import { ToastService } from '@app/services';
import { GroupFilter } from '@app/models';
import { Checklist } from '@app/models';

@Component({
	selector: 'app-checklist-item-group',
	templateUrl: './checklist-item-group.component.html',
	styleUrls: ['./checklist-item-group.component.scss']
})
export class ChecklistItemGroupComponent implements OnInit {

	@Input() public showBody: boolean = true;
	@Input() public showRemove: boolean = true;
	@Input() public checklist: Checklist;
	@Input() public group: any = {};

	@Input() public disabled: boolean = true;

	@Output() public onRemoveGroup: EventEmitter<any> = new EventEmitter();

	public groups: any[];

	public isLoading: boolean = false;

	constructor(public groupService: GroupService,
	            private toastService: ToastService) {
	}

	ngOnInit(): void {
		this.getGroups();
	}

	public getGroups(): void {
		let groupFilter: GroupFilter = {
			name: ''
		};
		this.groupService.filter(groupFilter).subscribe(response =>{
			this.groups =  response.content
		});
	}

	public addTagPromise = (name:string) => {
		return new Promise((resolve) => {
			this.isLoading = true;
			let group = new Group();
			group.name = name;

			this.groupService.post(group).subscribe(response => {
				this.isLoading = false;
				resolve(response);
			});
		})
	}

	public groupSelected(event): void {
		this.group.id = event.id;
		this.group.name = event.name;
	}

	public removeGroup(): void {
		this.onRemoveGroup.emit();
	}

}
