import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  @Input()
  title: string = "Confirmar";
  @Input()
  confirmText: string = "Confirmar";
  @Input()
  cancelText: string = 'Fechar';
  @Input()
  text: string;


  constructor(public modal: NgbActiveModal) {
  }
}
