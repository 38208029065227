<div class="">
	<div class="body">
		<evo-stepper-title title="Filtros do checklist" subtitle="Adicione filtros para classificar o checklist"></evo-stepper-title>

		<form [formGroup]="metadataForm">
			<div formArrayName="blocks">
				<div class="my-3" *ngFor="let block of blocks.controls; let i = index" [formArrayName]="i">
					<div class="d-flex justify-content-between align-items-center">
						<h6>Desfecho {{ i + 1}}</h6>

						<div class="btn btn-delete" (click)="removeBlock(i)">
							<i class="fas fa-trash"></i>
						</div>
					</div>

					<div class="checklist-item-group">
						<div class="checklist-item" *ngFor="let field of block.controls; let j = index">
							<div class="d-flex align-items-center" [formGroupName]="j">
								<div class="w-100 mr-2">
									<ng-select formControlName="property"
									           [items]="metadataOptionsKey"
									           placeholder="Selecione um item"
									           [addTag]="true"
									           [addTagText]="'Adicionar propriedade'"
									           (add)="valueSelect.handleClearClick()"
									           (change)="valueSelect.handleClearClick()">
										<ng-template ng-option-tmp ng-label-tmp let-item="item">
											{{ item }}
										</ng-template>
									</ng-select>
								</div>
								<div class="w-100 ml-2">
									<ng-select formControlName="value" #valueSelect
									           [items]="metadataOptions[field.get('property').value]"
									           placeholder="Selecione um item"
									           [addTag]="true"
									           [addTagText]="'Adicionar valor'">
										<ng-template ng-option-tmp ng-label-tmp let-item="item">
											{{ item }}
										</ng-template>
									</ng-select>
								</div>
								<div class="btn btn-delete" (click)="removeField(i, j)">
									<i class="fas fa-trash"></i>
								</div>
							</div>
						</div>

						<div class="text-danger my-3" *ngIf="block.hasError('duplicateCombination')">
							<span>Verifique os filtros, não é possível ter combinações duplicadas.</span>
						</div>

						<div class="d-flex justify-content-end mt-3">
							<button class="btn btn-sm btn-link" (click)="addField(i)" [disabled]="metadataForm.invalid">
								<i class="fas fa-plus"></i>
								Novo filtro
							</button>
						</div>
					</div>


				</div>
			</div>

			<div class="d-flex justify-content-center mt-3">
				<button class="btn btn-sm btn-outline-primary" (click)="addBlock()" [disabled]="metadataForm.invalid">
					<i class="fas fa-plus"></i>
					Novo desfecho
				</button>
			</div>

		</form>


	</div>

	<evo-stepper-footer [isLoading]="isLoading" [isFormInvalid]="metadataForm.invalid" (onNext)="onNext()" (onPrev)="onPrev()"></evo-stepper-footer>
</div>
