import { Injectable, Pipe, PipeTransform } from '@angular/core';
/*
 * Transform seconds (number) yo hh:mm:ss string
*/

@Pipe({name: 'secondsToHhmmss'})
export class SecondsToHhmmssPipe implements PipeTransform {
	/*transform(totalSeconds: number): string {

		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor((totalSeconds % 3600) / 60);
		const seconds = (totalSeconds % 60);
		let result = `${minutes
			.toString()
			.padStart(1, '0')}:${seconds.toString().padStart(2, '0')}`;
		if (!!hours) {
			result = `${hours.toString()}:${minutes
				.toString()
				.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
		}
		return result;
	}*/

	transform(value: any, placeholder: string = '--'): string {
		if (!value) { return '00:00'; }

		const minutes = Math.floor(value / 60);
		const seconds = Math.floor(value) % 60;
		return (
			(minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
		);
	}
}
