import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { BucketService, InteractionService } from '@app/services';
import { MonitorInteractionItem } from '@app/models';
import { ToastService } from '@app/services';
import { ContestationStatus } from '../../../../_helpers/interaction-status.enum';
import { Justification } from '@app/models';
import { Contestation } from '@app/models';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-evaluation-decline-button',
  templateUrl: './evaluation-decline-button.component.html',
  styleUrls: ['./evaluation-decline-button.component.scss']
})
export class EvaluationDeclineButtonComponent extends InteractionContestationButton implements OnInit {
  @Input() isConfirming: boolean;
  @Input() items: MonitorInteractionItem[] = [];

  reasonContestationDeclined: FormGroup;

  constructor(modalService: NgbModal, protected activeModal: NgbActiveModal, toastService: ToastService, protected formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
    this.reasonContestationDeclined = this.formBuilder.group({
      reason: [null, Validators.required]
    });
  }

  openDeclinedEvaluationModal() {
    this.item.contestationAcceptJustification = new Justification();
    this.modalService.open(this.modal, {ariaLabelledBy: 'modal-basic-title'});
  }

  saveContestationDeclinedEvaluation(status: string, text: string) {
    const contestation = new Contestation();
    contestation.explanation = this.reasonContestationDeclined.get('reason').value;
    contestation.status = {id: status, name: ContestationStatus[status]};
    contestation.user = this.currentUser;
    contestation.monitorInteractionItem = new MonitorInteractionItem();
    contestation.monitorInteractionItem.id = this.item.id;
    contestation.registerDate = new Date();

    this.save(contestation, text);
  }

}
