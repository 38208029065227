<div>
    <div class="body">
        <evo-stepper-title title="Relacionar operadores" subtitle="Relacione os nomes dos operadores que você importou, com os usuários existentes do Quality."></evo-stepper-title>

	    <div class="alert alert-warning" role="alert">
		    <div class="d-flex flex-start">
			    <i class="fas fa-exclamation-triangle font-warning mr-2 mt-2"></i>
			    <div>
				    <p class="m-0">É necessário vincular todos os operadores para avançar.</p>
				    <p class="m-0">Não é possível vincular o mesmo usuário em vários operadores.</p>
			    </div>
		    </div>
	    </div>

	    <div class="d-flex justify-content-between align-items-center">
		    <b>
			   Exibindo {{ limit > operators.length ? operators.length : limit }} de {{ operators.length }} operadores importados.
		    </b>

		    <a class="btn btn-link text-primary" href="{{ adminUrl + '/users' }}" target="_blank">
			    Cadastrar usuário
			    <i class="fas fa-external-link-alt"></i>
		    </a>
	    </div>

	    <div>
		    <ngx-datatable
				    [rows]="operators"
				    [count]="operators.length"
				    [headerHeight]="50"
				    [footerHeight]="50"
				    [rowHeight]="'auto'"
				    [offset]="offset"
				    [limit]="limit"
				    class="material"
				    (page)="pageChanged($event)"
				    [columnMode]="'force'"
				    [messages]="{emptyMessage:'Nenhum operador encontrado.'}">
			    <ngx-datatable-column name="Operador" prop="operatorName" [sortable]="false">
				    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
					    <span>{{value}}</span>
				    </ng-template>
			    </ngx-datatable-column>
			    <ngx-datatable-column name="Usuário" prop="id" [sortable]="false">
				    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
					    <evo-select iconUnicode="f007">
						    <ng-select  [(ngModel)]="row.user"
						                (search)="search$.next($event.term.length > 0 ? $event.term : null)"
						                bindLabel="name"
						                [items]="users"
						                [clearable]="false"
						                [appendTo]="'.card-custom'"
						                [ngClass]="{'is-invalid': isDuplicated(row)}"
						                [notFoundText]="search$.value ? 'Nenhum operador localizado' : 'Pesquise por um operador'"
						                placeholder="Pesquise por um operador"
										(click)="loadUsers()"
						                (ngModelChange)="validateMetadataUserChange($event, row)"
						                (open)="saveTempMetadataUser(row.user)">
							    <ng-template ng-option-tmp let-item="item" let-index="index">
								    <div class="d-flex flex-column">
									    <span style="font-size: 14px;">{{ item.name }}</span>
									    <span style="font-size: 12px;">{{ item.email }}</span>
								    </div>
							    </ng-template>
							    <ng-template ng-footer-tmp>
								    <button class="btn btn-link" [disabled]="isAgentLastPage" (click)="nextAgentBatch()">Listar mais operadores</button>
							    </ng-template>
						    </ng-select>
					    </evo-select>
				    </ng-template>
			    </ngx-datatable-column>
			    <ngx-datatable-column  name="Preenchimento" prop="autoFill">
				    <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
					    <span class="badge badge-light" *ngIf="value"><i class="fas fa-clipboard-check"></i> Preenchimento Automático</span>
					    <span class="badge badge-light" *ngIf="!value"><i class="fas fa-user-check"></i> Preenchimento Manual</span>
					</ng-template>
			    </ngx-datatable-column>
		    </ngx-datatable>
	    </div>
    </div>
    <evo-stepper-footer [isLoading]="importDataService.isLoading" [isFormInvalid]="isLoading || !isOperatorsValid() || isOperatorsDuplicated" (onPrev)="onPrev()" (onNext)="onNext()"></evo-stepper-footer>
</div>
