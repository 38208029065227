import { Pipe, PipeTransform } from '@angular/core';


@Pipe({name: 'timeFormat'})
export class TimeFormatPipe implements PipeTransform {
  transform(value: any, placeholder: string = '--'): string {
    if (!value) { return '00:00'; }

    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value) % 60;
    return (
      (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
    );
  }
}
