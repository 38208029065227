<div>
    <div class="row">
        <div class="col menu-section">
            <div class="clickable-link" (click)="clearFilters()">
                <i class="far fa-times-circle"></i> Limpar filtros
            </div>
            <button class="btn btn-outline-primary" (click)="showFilterBtn = !showFilterBtn">
                <i class="fas fa-filter icon-small"></i>
                Filtros
                <i class="fas fa-chevron-down icon-small" *ngIf="!showFilterBtn"></i>
                <i class="fas fa-chevron-up icon-small" *ngIf="showFilterBtn"></i>
            </button>
            <ng-template [ngxPermissionsOnly]="downloadPermissions">
                <div uib-dropdown="dropdown" class="btn-group dropdown ml-2" dropdown [isDisabled]="!downloadEnabled">
                    <button uib-dropdown-toggle class="btn btn-outline-primary" aria-haspopup="true" aria-expanded="false"
                        dropdownToggle [disabled]="!downloadEnabled"  *ngIf="showDownloadBtn">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        <i *ngIf="!loading" class="fas fa-download icon-small"></i>
                        <span> Download </span>
                        <i class="fas fa-chevron-down icon-small"></i>
                    </button>
                    <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
                        <a class="dropdown-item" style="cursor: pointer;" (click)="onDownloadFile('XLSX')">
                            <i class="fas fa-file-excel mr-2"></i>
                            <span>Excel</span>
                        </a>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="onDownloadFile('CSV')">
                            <i class="fas fa-file-csv"></i>
                            <span>CSV</span>
                        </a>
	                    <ng-content select="[download-slot]"></ng-content>
                    </ul>
                </div>
            </ng-template>
        </div>
    </div>
    <div *ngIf="showFilterBtn && filters">
        <evo-filter [filters]="filters" [isDeadlineDisabled]="true" [limitDate]="limitDate"
					[isCicloFechamentoDisabled]="!organizationService.currentOrganization.plan.interactionConfiguration?.hasMonthlyCycle"
                    [showItemsFilter]="showItemsFilter" [metadataColumns]="4" [checklistItems]="checklistItems"
                    [showInteractionType]="showInteractionType"
        ></evo-filter>
        <div class="row">
            <div class="col-3 ml-auto">
                <button type="button" [tooltip]="filters.invalid ? 'Selecione uma Data de Contato e/ou uma Data de Monitoria.' : null" class="btn btn-primary btn-block" [disabled]="filters.invalid" (click)="onFilter()">
                    Aplicar filtros
                </button>
            </div>
        </div>
    </div>
</div>
