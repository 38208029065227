import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OnboardingStepStatus } from '@app/models';
import { OnboardingStepService } from './onboarding-step.service';
import { Segment } from '@app/models';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirstStepsService {
	public savedSegments: Segment[] = [];

	public onboardingStatus: { segment: boolean, operation: boolean, checklist: boolean, importJob: boolean };

  public steps: {
    title: string,
    isStepDone: boolean,
    isDisabled: boolean
  }[] = [
    {
      title: 'Introdução',
      isStepDone: false,
      isDisabled: false
    },
    {
      title: 'Configurar operações',
      isStepDone: false,
      isDisabled: false
    },
    {
      title: 'Configurar checklist',
      isStepDone: false,
      isDisabled: true
    },
    {
      title: 'Importar interações',
      isStepDone: false,
      isDisabled: true
    }
  ];

  _currentStep: BehaviorSubject<number> = new BehaviorSubject(0);
  get currentStep() {return this._currentStep.value;}
  set currentStep(step: number) {this._currentStep.next(step);}

  constructor(private onboardingStepService: OnboardingStepService, private router: Router) {
    /* const steps = JSON.parse(localStorage.getItem('steps'));
    if (steps) this.steps = steps;

    const currentStep = localStorage.getItem('currentStep');
    if (currentStep) this.setCurrentStep(Number(currentStep)); */
  }

  public setStepAsDone(stepIndex: number) {
    this.steps[stepIndex].isStepDone = true;
  }

  public setStepAsUndone(stepIndex: number) {
	  this.steps[stepIndex].isStepDone = false;
  }

  public setStepAsEnabled(stepIndex: number) {
    this.steps[stepIndex].isDisabled = false;
  }

  public setStepAsDisabled(stepIndex: number) {
	  this.steps[stepIndex].isDisabled = true;
  }

  public setCurrentStep(stepIndex: number) {
	  if(stepIndex > 0) {
		  this.setStepAsDone(stepIndex - 1);
	  }

    this.currentStep = stepIndex;
    this.saveStepsState(stepIndex);

	if(this.steps[stepIndex].isStepDone) {
		this.steps[stepIndex + 1].isDisabled = false;
	}
  }

  public completePrevSteps(): void {
	  for(let i = this.currentStep - 1; i >= 0; i--) {
		  this.setStepAsDone(i);
		  this.setStepAsEnabled(i);
	  }
  }

  private saveStepsState(step: number) {
	this.onboardingStepService.putNextOnboardingStep(step, this.steps.length).subscribe(
		data => {
		}, error => {
			console.error(error);
		}
	);
  }

	public skipFirstSteps(): void {
		this.onboardingStepService.currentOnboardingStep.status = OnboardingStepStatus.COMPLETED;
		this.onboardingStepService.put(this.onboardingStepService.currentOnboardingStep).subscribe(
			data => {
				this.router.navigate(['/interactions']);
			}, error => {
				console.error(error);
			});
	}

	public updateOnboardingStatus(): void {
	  this.onboardingStepService.getStatus().subscribe(
		  data => {
			  this.onboardingStatus = data;
		  }, error => {
			  console.error(error);
		  });
	}

	public goToFirstSteps(): void {
		if(!this.onboardingStatus.segment || !this.onboardingStatus.operation) {
			this.goToFirstStepOperation();
			return;
		}

		if(!this.onboardingStatus.checklist) {
			this.goToFirstStepChecklist();
			return;
		}

		if(!this.onboardingStatus.importJob) {
			this.goToFirstStepImportJob();
			return;
		}
	}

	private goToFirstStepOperation(): void {
		this.setCurrentStep(1);
		this.completePrevSteps();

		this.setStepAsUndone(2);
		this.setStepAsUndone(3);

		this.router.navigate(['/first-steps']);
	}

	private goToFirstStepChecklist(): void {
		this.setCurrentStep(2);
		this.completePrevSteps();

		this.setStepAsUndone(3);
		this.router.navigate(['/first-steps']);
	}

	private goToFirstStepImportJob(): void {
		this.setCurrentStep(3);
		this.completePrevSteps();

		this.router.navigate(['/first-steps']);
	}

	public get missingSegment(): boolean {
		return !this.onboardingStatus.segment;
	}

	public get missingOperation(): boolean {
		return this.onboardingStatus.segment && !this.onboardingStatus.operation;
	}

	public get missingChecklist(): boolean {
		return this.onboardingStatus.operation && !this.onboardingStatus.checklist;
	}

	public get missingImportJob(): boolean {
		return this.onboardingStatus.checklist && !this.onboardingStatus.importJob;
	}
}
