<div class="w-100">
    <input  ngbDatepicker
            #datepicker="ngbDatepicker"
            [autoClose]="'outside'"
            (dateSelect)="onDateSelection($event)"
            [displayMonths]="2"
            [dayTemplate]="t"
            [maxDate]="maxDate"
            outsideDays="hidden"
            [startDate]="fromDate!"
            [value]="format(fromDate, toDate)"
            [markDisabled]="isDisabled"
            positionTarget=".range-select"
            [hidden]="true"
            tabindex="-1"
            readonly>
    <evo-select iconUnicode="f133" class="range-select">
        <ng-select (change)="onRangeSelection($event)"
                   [searchable]="false"
                   [closeOnSelect]="false"
                   [items]="items"
                   [placeholder]="placeholder"
                   (clear)="value = null; maxDate = null; onDateClear.emit(true)"
                   #select>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span *ngIf="index !== items.length - 1" style="width: 100%; display: block;">{{item.label}}</span>
                <span *ngIf="index === items.length - 1" style="width: 100%; display: block;" (click)="$event.stopPropagation(); datepicker.toggle();">Período personalizado</span>
            </ng-template>
        </ng-select>
    </evo-select>
    <ng-template #t let-date let-focused="focused">
        <span class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
            [class.text-muted]="isDisabled(date, null)">
        {{ date.day }}
        </span>
    </ng-template>
</div>
