
import { Deserializable } from "./deserializable";
import { PageFilter } from "./page-filter";

export class ReportByFilter implements Deserializable, PageFilter {
	reportType: string;
    startDate: Date;
    endDate: Date;
    page?: number;
    linesPerPage?: number;
    orderBy?: string;
    direction?: string;
    interactionType?: string;
	status?: string;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
