<div class="container" *ngIf="showOrganizationList">

	<div class="row">
		<div class="col-12 d-flex justify-content-center">
			<div class="user-info" *ngIf="currentUser">

				<avatar-initials [large]="true" [name]="currentUser.name"></avatar-initials>

				<div class="d-flex flex-column ml-3">
					<h4>{{ currentUser?.name }}</h4>
					<span>{{ currentUser?.email ? currentUser.email : '-' }}</span>
					<button class="btn btn-outline-primary">Visualizar Perfil</button>
				</div>

			</div>
		</div>
	</div>

	<div class="row mt-4">
		<div class="col-12">
			<div class="card">
				<div class="header mb-4">
					<div class="d-flex align-items-center">
						<h5>Organizações</h5>

						<!-- <div class="search-input input-group">
							<input type="text" class="form-control" placeholder="Pesquisar">
						</div> -->
					</div>
				</div>
				<div class="content">
					<div class="row">
						<div class="col-md-3 col-sm-6 mb-3" *ngFor="let organization of organizations">
							<div class="organization">

								<div class="organization-info">
									<div class="organization-logo" *ngIf="organization.logoUrl" [ngStyle]="{'background-image' : 'url(' + organization.logoUrl + ')'}"></div>
									<div class="organization-logo no-logo" *ngIf="!organization.logoUrl">
										<h1>{{ organization.name[0].toUpperCase() }}</h1>
									</div>
									<span style="width: 200px; word-wrap: break-word;" class="text-center">
                                        {{ organization.name }}
                                    </span>
								</div>

								<div class="profiles" *ngIf="organization.profiles">
									<span>{{ organization.profiles[0].name }}</span>
									<span *ngIf="organization.profiles.length > 1">+ {{ organization.profiles.length - 1 }}</span>
								</div>

								<button class="btn btn-sm btn-outline-primary my-3" (click)="selectOrganization(organization)">Acessar</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
