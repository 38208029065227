<div class="onboard-overlay" [ngClass]="{'open' : isOpen }">

	<div class="d-flex justify-content-between pointer" (click)="isOpen = !isOpen">
		<div class="overlay-title">Configurações iniciais</div>

		<div class="d-flex align-items-center">
			<div class="d-flex align-items-center text-muted mr-3">
				<span class="mr-2">{{ stepCompleted }}/3 Completo</span>
				<i class="far fa-check-circle"></i>
			</div>

			<i class="fas" [ngClass]="{ 'fa-chevron-down' : isOpen, 'fa-chevron-up' : !isOpen }"></i>
		</div>
	</div>

	<div class="d-flex flex-column mt-3" *ngIf="isOpen" @fadeInTop>
		<div class="onboard-item" [ngClass]="{ 'disabled': !firstStepsService.missingSegment && !firstStepsService.missingOperation }">
			<div class="d-flex align-items-center" style="font-size: 14px;">
				<span class="onboard-step">1</span> Adicionar operações
			</div>
			<button class="btn btn-link btn-sm" (click)="firstStepsService.goToFirstSteps()">Adicionar</button>
		</div>

		<div class="onboard-item" [ngClass]="{ 'disabled': !firstStepsService.missingChecklist }">
			<div class="d-flex align-items-center" style="font-size: 14px;">
				<span class="onboard-step">2</span> Adicionar checklists
			</div>
			<button class="btn btn-link btn-sm" (click)="firstStepsService.goToFirstSteps()">Adicionar</button>
		</div>

		<div class="onboard-item" [ngClass]="{ 'disabled': !firstStepsService.missingImportJob }">
			<div class="d-flex align-items-center" style="font-size: 14px;">
				<span class="onboard-step">3</span> Adicionar interações
			</div>
			<button class="btn btn-link btn-sm" (click)="firstStepsService.goToFirstSteps()">Adicionar</button>
		</div>

	</div>

</div>
