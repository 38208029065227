import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Operation } from './operation';

export class SpeechEnvironments implements Deserializable {
    customer: number;
    externalId: string;
    id: number;
    name: string;
    speechClientId: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}