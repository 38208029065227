import { Component, OnInit } from "@angular/core";
import {  NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxPermissionsService } from "ngx-permissions";
import { AddTrainingComponent } from "./add-training/add-training.component";
import { QualityPermissions } from '@app/models';
import { HelpDialogComponent } from '../../_components/help-dialog/help-dialog.component';

@Component({
    selector: 'training-list',
    templateUrl: './training-list.component.html',
    styleUrls: ['./training-list.component.scss']
})
export class TrainingListComponent implements OnInit {

    loading: boolean = false;
    qualityPermissions = QualityPermissions;

    constructor(private permissionService: NgxPermissionsService,
        private modalService: NgbModal) {}

    ngOnInit(): void {
    }

    addTraining() : void {
        const modalRef = this.modalService.open(AddTrainingComponent, {windowClass: 'stepper-card'});

        modalRef.closed.subscribe(data => {
            if (data) {

            }
        })
    }

	public openHelpDialog(): void {
		const modalRef = this.modalService.open(HelpDialogComponent, {});

		modalRef.result.then( data => {
			if(data) {
			}
		});
	}
}
