import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmMonitor } from '@app/models';

@Component({
  selector: 'app-confirm-monitor',
  templateUrl: './confirm-monitor.component.html',
  styleUrls: ['./confirm-monitor.component.scss']
})
export class ConfirmMonitorComponent {
  @Input()
  title: string = "Confirmar";
  @Input()
  confirmText: string = "Confirmar";
  @Input()
  text: string;

  feedbackText: string;

  public monitor: ConfirmMonitor = {};

  constructor(public modal: NgbActiveModal) {

  }

  confirmMonitor(feedback: string, closeModal: boolean) {
    if (closeModal) {
      this.monitor.feedback = feedback;
      this.monitor.closeModal = closeModal;
      this.modal.close(this.monitor);
    } else {
      this.monitor.closeModal = closeModal;
      this.modal.close(this.monitor);
    }
  }
}
