import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JobValidate } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { JobMetadataUser } from '@app/models';
import * as _ from 'lodash';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class JobService extends BaseServiceClass<any> {

	public importJobId: number;

	public validateErrors: JobValidate[];

	constructor(protected httpService: HttpClient) {
		super(httpService, '/jobs');
	}

	validarMetadado(body) {
		return this.httpService.post(`${this.entity_url}/metadata/validate`, body);
	}

	public getJobsMetadataUsers(): Observable<JobMetadataUser[]> {
		return this.httpService.get<any>(`${this.entity_url}/metadata/users`);
	}

	public getImportJobs(filters: any): Observable<any> {
		let options = {params: <any> _.omitBy(filters, _.isNil)};
		return this.httpService.get(this.entity_url, options);
	}

	public getImportJob(id: number): Observable<any> {
		return this.httpService.get(`${this.entity_url}/${id}`);
	}
}

