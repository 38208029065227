<div @fadeInOut>
	<div id="body" class="body" style="width: 100%">
		<evo-stepper-title title="Importar interações" subtitle="Selecione um dos tipos de importação disponíveis para realizar as monitorias"></evo-stepper-title>
		<div class="d-flex mt-4">

			<app-simple-checkable-card class="pointer" (click)="importType = importTypes.MULTI" [isSelected]="importType == importTypes.MULTI" iconClass="fa-layer-group" style="width: 100%"
			                           title="Importar várias chamadas" description="Selecione os arquivos de áudio e seu CSV de metadados e importe todos de uma vez.">
			</app-simple-checkable-card>

			<app-simple-checkable-card  class="pointer" (click)="importType = importTypes.SINGLE" [isSelected]="importType == importTypes.SINGLE" iconClass="fa-phone-alt" style="width: 100%"
			                           title="Importar uma chamada" description='Selecione um arquivo de áudio para importação. <br/><br/>Obs.: Formatos aceitos: "WAV", "GSM", "MP3"'>
			</app-simple-checkable-card>

			<app-simple-checkable-card  class="pointer" (click)="importType = importTypes.METADATA" [isSelected]="importType == importTypes.METADATA" iconClass="fa-calendar-alt" style="width: 100%"
			                           title="Importar metadados" description="Selecione apenas o CSV de metadados com a lista de chamadas e ouça os áudios externamente.">
			</app-simple-checkable-card>

		</div>

		<div class="d-flex text-muted mt-4 mb-5">
			<i class="fa fa-info-circle mt-1 mr-2"></i>

			<p>
				Importação por metadados é feita através de um arquivo geralmente Excel (.CSV ou .XLS). Nele, deve possuir as informações referentes às chamadas que deverão ser monitoradas, como: Data do contato, hora do contato, nome do operador, nome da chamada.
			</p>
		</div>
	</div>
	<evo-stepper-footer [isFormInvalid]="!importType" (onPrev)="onPrev()" (onNext)="onNext()"></evo-stepper-footer>
</div>
