<div>
    <div class="body">
        <div class="main-content">
            <p style="font-size: 14px; font-weight: 600; color: #5E5E5E;">Segmento: {{checklist?.segment?.name}}</p>
            <evo-stepper-title title="Itens do checklist" subtitle="Selecione os itens de cada grupo para o seu checklist"></evo-stepper-title>

	        <div class="d-flex justify-content-center">
		        <button class="btn btn-link" (click)="showHelper()">Saiba mais</button>
	        </div>

            <div class="items" *ngFor="let group of categoriesGrouped; let i = index">
                <ngb-accordion #a="ngbAccordion" activeIds="group0">
                    <ngb-panel [id]="'group'+i">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="group-header d-flex justify-content-between align-items-center w-100">
                                <div>
                                    <h6 style="color: #1F1F1F;">{{group.group}} ({{group.categories.length}})</h6>
                                    <p style="padding: 0; color: #727272; font-size: 14px; font-weight: 600;">Informações sobre o grupo de {{group.group | lowercase}}</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <div class="custom-control custom-checkbox mr-2">
                                        <input [(ngModel)]="groupSelected[i]" (change)="changeAllGroup(i, !isGroupAllSelected(i))" class="custom-control-input" type="checkbox" [id]="group.group">
                                        <label class="custom-control-label" [for]="group.group">{{isGroupAllSelected(i) ? 'Desselecionar tudo' : 'Selecionar tudo'}}</label>
                                    </div>
                                    <button class="btn ml-auto" ngbPanelToggle>
                                        <i class="fas text-muted" style="font-size: 1.5em"
                                            [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                                    </button>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="item" *ngFor="let category of group.categories; let j = index" style="border-bottom: 1px solid #CCC; margin-bottom: 10px">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input [formControl]="getFormControl(i, j)" class="custom-control-input" type="checkbox" [id]="category._id">
                                    <label class="custom-control-label" [for]="category._id" style="font-size: 14px; font-weight: bold">{{category.name}}</label><br/>
	                                <label class="text-muted" style="font-size: 12px;">{{ category.description }}</label>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
        <div class="side-bar">
            <h5><strong>Itens selecionados ({{categoriesSelectedLength}})</strong></h5>
            <div *ngIf="categoriesSelected.length === 0" class="no-item-selected">
                <img src="/assets/images/checklist-automatic-step2.png" />
                <h6>Os itens selecionados aparecerão aqui</h6>
            </div>
            <div *ngIf="categoriesSelected.length > 0"></div>
            <div class="items" *ngFor="let group of categoriesSelected; let i = index">
                <ngb-accordion #a="ngbAccordion" *ngIf="group.categories.length > 0" [activeIds]="['group0']">
                    <ngb-panel [id]="'group'+i">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="group-header d-flex align-items-center w-100">
                                <h6 style="color: #1F1F1F;">{{group.group}} ({{group.categories.length}} itens)</h6>
                                <button class="btn ml-auto" ngbPanelToggle>
                                    <i class="fas text-muted" style="font-size: 1.5em"
                                        [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                                </button>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="item d-flex flex-column mb-3" *ngFor="let category of group.categories; let j = index">
	                            <span style="font-size: 13px; font-weight: bold">{{category?.name}}</span>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
    <evo-stepper-footer [isFormInvalid]="categoriesSelectedLength === 0" (onPrev)="prevStep()" (onNext)="nextStep()"></evo-stepper-footer>
</div>
