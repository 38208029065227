<div>
    <div class="body">
        <evo-stepper-title title="Importar arquivo de metadados" subtitle="Importe o arquivo de metadados no Quality"></evo-stepper-title>
        <div class="alert alert-primary" role="alert">
            <div class="d-flex flex-start">
                <i class="fas fa-exclamation-triangle font-primary mr-2 mt-2"></i>
                <div>
                    <p class="m-0">
                        O sistema irá considerar a primeira linha do arquivo como a descrição da coluna.
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="file" class="required">Upload de metadados</label>
            <p class="text-muted">500kb é o tamanho limite por arquivo. Somente arquivos .xls, .xlsx e .csv são permitidos.</p>
            <p class="text-muted">O arquivo de metadados deve incluir as seguintes informações: Nome do áudio, ID do agente, data de contato e hora do contato (a nomenclatura pode variar). É fundamental observar que o nome do áudio no arquivo de metadados deve incluir a extensão, como por exemplo, audio1.wav.</p>
            <div class="d-flex">
                <evo-upload-file-input name="file" style="width: 100%"
                    [(ngModel)]="file"
                    accept="text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    [violatedRules]="violatedRulesFileUpload"
                    [isProgressBarEnable]="true"
                    [uploadProgress]="[uploadProgress]"
                    [isLoading]="importDataService.isLoading"
                    [isMultiple]="false"
                    [showItemLegend]="true"
                    fileLegend="Somente arquivos .XLS e .CSV (max. 500KB por arquivo)"
                    (cancelUpload)="cancelUpload()"
                    (invalidType)="isInvalidType($event)">
                </evo-upload-file-input>
            </div>
        </div>

	    <div class="" *ngIf="jobService.validateErrors?.length > 0" style="margin-bottom: 80px;">
		    <h6>Erros encontrados no arquivo de Metadado</h6>
		    <span>Corrija os seguintes erros para fazer a importação:</span>

		    <div class="mt-3 alert alert-danger" *ngFor="let jobError of jobService.validateErrors">
			    <i class="fas fa-exclamation-triangle mr-2"></i>
			    <span>{{ jobError.message }}</span>
		    </div>
	    </div>
    </div>
    <evo-stepper-footer [isLoading]="importDataService.isLoading" [isFormInvalid]="file.length === 0 || violatedRulesFileUpload?.length > 0" (onPrev)="onPrev()" (onNext)="onNext()"></evo-stepper-footer>
</div>
