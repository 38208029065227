<div class="form-group">
    <label for="input-agent" *ngIf="showLabel">Operador</label>
    <evo-select iconUnicode="f007">
        <ng-select  id="input-agent"
                    [formControl]="value"
                    #select
                    [items]="users"
                    bindValue="name"
                    bindLabel="name"
                    [multiple]="true"
                    dropdownPosition="bottom"
                    placeholder="{{placeholder}}"
                    [notFoundText]="search$.value ? 'Nenhum operador localizado' : 'Pesquise por um operador'"
                    (clear)="users = []; search$.next(null); isLastPage = true;"
                    (search)="search$.next($event.term.length > 0 ? $event.term : null)"
                    (change)="value = $event">
            <ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
                <div class="ng-value d-flex" style="max-width: 32%" container="body"
                    placement="top" [ngbTooltip]="getItemLabelByValue(item)"
                    *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
                    <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                    <span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(item)}}</span>
                    </div>
                    <div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
                        <span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
                    </div>
            </ng-template>
            <ng-template ng-footer-tmp>
                <button class="btn btn-link" [disabled]="isLastPage" (click)="nextBatch()">Listar mais operadores</button>
            </ng-template>
        </ng-select>
    </evo-select>
</div>
