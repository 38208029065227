import { FormGroup } from '@angular/forms';
import { Deserializable } from '../deserializable';
import { InteractionStatus } from '../interaction-status';

export class InteractionListModel implements Deserializable {
    id: number;
    agentName?: string;
    status?: InteractionStatus;
    contactDate?: Date;
    monitorDate?: Date;
    grade?: number;
    deadLine?: Date;
    operationName: string;
    segmentName: string;
    fileName: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}