import { InteractionStatusEnum } from '../_helpers/interaction-status.enum';
import { Deserializable } from './deserializable';

export class InteractionStatus implements Deserializable {
    id: string;
    name: string;

    bgClass: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        this.setStatusColor();
        return this;
    }

    setStatusColor(){
        switch (this.id) {
            case InteractionStatusEnum.FINISHED.toString():
                this.bgClass = "bg-success";
                break;
            default:
                this.bgClass = "bg-info";
        }
    }
    
}