<div>
    <div class="body">
        <evo-stepper-title title="Relacionar campos" subtitle="Atribua o significado das colunas do arquivo de metadados no Quality"></evo-stepper-title>
        <div class="alert alert-warning" role="alert">
            <div class="d-flex flex-start">
                <i class="fas fa-exclamation-triangle font-warning mr-2 mt-2"></i>
                <div>
                    <p class="m-0">A primeira linha do arquivo está sendo considerada como cabeçalho.</p>
                    <p class="m-0">Se você não mapear esses cabeçalhos para um campo disponível, os dados nelas não serão importados.</p>
                </div>
            </div>
        </div>
        <div class="card" style="margin-bottom: 80px;">
            <div class="row card-row">
                <div class="col-3"><span class="card-head-text">Cabeçalho</span></div>
                <div class="col-4"><span class="card-head-text">Campos disponíveis</span></div>
                <div class="col-5 text-center"><span class="card-head-text">Pré-visualização</span></div>
            </div>
            <div *ngFor="let column of columns; index as i" class="row card-row">
                <div class="col-3 d-flex align-items-center"><span class="card-body-text">{{column.header}}</span></div>
                <div class="col-4 d-flex align-items-center flex-column">
                    <ng-select [(ngModel)]="column.fieldSelected"
                               [items]="availableFields"
                               [disabled]="availableFields.length === 0 && !column.fieldSelected"
                               (change)="updateAvailableFields()"
                               [dropdownPosition]="i > 5 ? 'top' : 'auto'"
                               placeholder="Selecione" bindLabel="label" bindValue="value"
                               class="w-100">
                    </ng-select>
                    <span *ngIf="column.fieldSelected == 'DATE'" class="text-muted">{{getFieldByValue('DATE')?.formatMessage}}</span>
                </div>
                <div class="col-5 d-flex flex-column align-items-center">
                    <span style="font-size: 14px; color: #5E5E5E;">
	                    {{column.preview.length > 26 ? (column.preview | slice:0:23)+'...'+(column.preview | slice:column.preview.length - 3:column.preview.length ): validateDateValue(column.preview) }}
                    </span>
                    <div *ngIf="column.fieldSelected == 'DATE' && !isContactDateValid(column.preview) ||
                                column.fieldSelected == 'FILE_NAME' && !isFilenameValid(column.preview) ||
                                column.fieldSelected == 'METADATA_ID' && !isUniqueValid(column) ||
                                column.fieldSelected == 'TIME' && !isContactHourValid(column.preview)" class="d-flex align-items-center ml-2">
                        <i class="fas fa-exclamation-circle text-danger mr-2"></i>
                        <span *ngIf="column.fieldSelected == 'DATE' && !isContactDateValid(column.preview)" class="text-danger">Você possui datas não formatadas. Utilize o formato de data DD/MM/YYYY.</span>
                        <span *ngIf="column.fieldSelected == 'FILE_NAME' && !isFilenameValid(column.preview)" class="text-danger">Você possui arquivos em formatos não
	                        aceitos. Apenas .mp3, .gsm e .wav serão aceitos na importação.</span>
	                    <span *ngIf="column.fieldSelected == 'METADATA_ID' && !isUniqueValid(column)" class="text-danger">Você não possui identificadores únicos para cada linha.</span>
                        <span *ngIf="column.fieldSelected == 'TIME' && !isContactHourValid(column.preview)" class="text-danger">Você possui horários com formato errado. Utilize um dos formatos de horário: HHMM, HMM, HHMMSS, HMMSS, HH:MM, H:MM, HH:MM:SS, H:MM:SS.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <evo-stepper-footer [isFormInvalid]="!validateAvailableFields() || invalidFields.length > 0"
                        prevMessage="Ao voltar, os dados da importação serão perdidas. Tem certeza que deseja voltar ?"
                        prevTitle="Importação da chamada"
                        [isLoading]="isLoading"
                        (onPrev)="onPrev()"
                        (onNext)="onNext()">
    </evo-stepper-footer>
</div>
