import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {CacheService} from "./cache.service";

@Injectable({providedIn: 'root'})
export class CacheInterceptorService implements HttpInterceptor {

	private cachedUrls: string[] = [
		'/segments/filter',
		'/interactions/status',
		'/interactionsMetadata',
		'users/filter?name=&email=&linesPerPage=10&page=0&orderBy=name&direction=ASC&hideUserType=INTERNAL&hideUserType=STANDARD',
		'/api/v2/segments',
		'/api/v2/operations'
	];
	private cacheExpiryLimit: number = 10;

	private cache: Map<string, { response: HttpResponse<any>; expiry: number }> = new Map<string, { response: HttpResponse<any>; expiry: number }>();

	constructor(private cacheService: CacheService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if(req.method !== 'GET') {
			return next.handle(req);
		}

		let foundUrl = this.cachedUrls.find(url => req.urlWithParams.indexOf(url) > -1);

		if(foundUrl) {
			const cachedItem = this.cacheService.get(req.urlWithParams);
			if (cachedItem) {
				const isExpired = cachedItem.expiry < Date.now();
				if (!isExpired) {
					return of(cachedItem.response);
				}

				this.clearCache(req.urlWithParams);
			}
		}

		return next.handle(req).pipe(
			tap(event => {
				if (event instanceof HttpResponse) {
					if(foundUrl) {
						const expiry = Date.now() + this.cacheExpiryLimit * 60 * 1000;
						this.cacheService.set(req.urlWithParams, event, expiry);
					}
				}
			})
		);
	}

	clearCache(url?: string) {
		if(url) {
			this.cacheService.clear(url);
		} else {
			this.cacheService.clear();
		}
	}
}
