import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TagCloudModule } from 'angular-tag-cloud-module';
import { RangeDatepickerComponent } from '../_components/range-datepicker/range-datepicker.component';
import { StylePaginatorDirective } from '../_helpers/directives/style-paginator/style-paginator.directive';
import { InteractionModalComponent } from '../_components/integration-modal/interaction-modal.component';
import { FilterComponent } from '../_components/filter/filter.component';
import {
	ChangeAnswerButtonComponent
} from '../_views/interactions/interaction-contestation/change-answer-button/change-answer-button.component';
import {
	ContestationAcceptedButtonComponent
} from '../_views/interactions/interaction-contestation/contestation-accepted-button/contestation-accepted-button.component';
import {
	ContestationButtonComponent
} from '../_views/interactions/interaction-contestation/contestation-button/contestation-button.component';
import {
	ContestationDeclinedButtonComponent
} from '../_views/interactions/interaction-contestation/contestation-declined-button/contestation-declined-button.component';
import {
	EvaluationDeclineButtonComponent
} from '../_views/interactions/interaction-contestation/evaluation-decline-button/evaluation-decline-button.component';
import {
	FeedbackButtonComponent
} from '../_views/interactions/interaction-contestation/feedback-button/feedback-button.component';
import {
	ReplicaAcceptedButtonComponent
} from '../_views/interactions/interaction-contestation/replica-accepted-button/replica-accepted-button.component';
import {
	ReplicaButtonComponent
} from '../_views/interactions/interaction-contestation/replica-button/replica-button.component';
import {
	ReplicaDeclinedButtonComponent
} from '../_views/interactions/interaction-contestation/replica-declined-button/replica-declined-button.component';
import { SelectComponent } from '../_components/select/select.component';
import { CheckIFDate } from '../_helpers/pipes/checkIFDate.pipe';
import { CheckIFDateHour } from '../_helpers/pipes/checkIFDateHour.pipe';
import { RangeSliderComponent } from '../_components/range-slider/range-slider/range-slider.component';
import { AgentFilterComponent } from '../_components/filter/agent/agent-filter.component';
import {
	ChecklistStepOneComponent
} from '../_views/first-steps/create-checklist/checklist-step-one/checklist-step-one.component';
import { CreateChecklistComponent } from '../_views/first-steps/create-checklist/create-checklist.component';
import { StepperFooterComponent } from '../_components/stepper-footer/stepper-footer.component';
import {
	ChecklistAutomaticStepTwoComponent
} from '../_views/first-steps/create-checklist/checklist-automatic-step-two/checklist-automatic-step-two.component';
import {
	ChecklistAutomaticStepThreeComponent
} from '../_views/first-steps/create-checklist/checklist-automatic-step-three/checklist-automatic-step-three.component';
import {
	ChecklistManualStepTwoComponent
} from '../_views/first-steps/create-checklist/checklist-manual-step-two/checklist-manual-step-two.component';
import { InlineEditInputComponent } from '../_components/inline-edit-input/inline-edit-input.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CheckableCardComponent } from '../_components/checkable-card/checkable-card.component';
import { SimpleCheckableCardComponent } from '../_components/simple-checkable-card/simple-checkable-card.component';
import { StepperTitleComponent } from '../_components/stepper-title/stepper-title.component';
import { StepperHeaderComponent } from '../_components/stepper-header/stepper-header.component';
import { EvoCardExpandComponent } from '../_components/evo-card-expand/evo-card-expand.component';
import { DiasUteisComponent } from '../_components/dias-uteis/dias-uteis.component';
import { ReportFiltersComponent } from '../_views/reports/report-filters/report-filters.component';
import {
	InteractionContestationComponent
} from '../_views/interactions/interaction-contestation/interaction-contestation.component';
import { EvoStepperComponent } from '../_components/evo-stepper/evo-stepper.component';
import { InteractionAddComponent } from '../_views/interactions/interaction-add/interaction-add.component';
import { MonitorInteractionComponent } from '../_views/interactions/monitor-interaction/monitor-interaction.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ChecklistItemGroupComponent } from '../_components/checklist-item-group/checklist-item-group.component';
import { SearchInputComponent } from '../_components/search-input/search-input.component';
import { UploadFileComponent } from '../_components/upload-file/upload-file.component';
import { NgxTimerModule } from 'ngx-timer';
import { StatusBadgeComponent } from '../_components/status-badge/evo-status-badge.component';
import { TimelineListComponent } from '../_components/timeline-list/timeline-list.component';
import { ClpAudioPlayerComponent } from '../_components/clp-audio-player/clp-audio-player.component';
import { OnboardOverlayComponent } from '../_components/onboard-overlay/onboard-overlay.component';
import { TimeFormatPipe } from '../_components/clp-audio-player/time-format.pipe';
import { ConfirmActionDirective } from '../_helpers/directives/confirm-action/confirm-action.directive';
import { CurrentStepDirective, StepperModalComponent } from '../_components/stepper-modal/stepper-modal.component';
import { ImportDataComponent } from '../_views/first-steps/import-data/import-data.component';
import {
	ImportDataStepZeroComponent
} from '../_views/first-steps/import-data/import-data-step-zero/import-data-step-zero.component';
import {
	ImportDataStepOneComponent
} from '../_views/first-steps/import-data/import-data-step-one/import-data-step-one.component';
import {
	ImportDataStepTwoComponent
} from '../_views/first-steps/import-data/import-data-step-two/import-data-step-two.component';
import {
	ImportDataStepThreeComponent
} from '../_views/first-steps/import-data/import-data-step-three/import-data-step-three.component';
import {
	ImportDataStepFourComponent
} from '../_views/first-steps/import-data/import-data-step-four/import-data-step-four.component';
import {
	ImportDataStepAgentsComponent
} from '../_views/first-steps/import-data/import-data-step-agents/import-data-step-agents.component';
import { DataTableComponent } from '../_components/data-table/data-table.component';
import { DataTablesModule } from 'angular-datatables';
import { OperationAddDialogComponent } from '../_components/operation-add-dialog/operation-add-dialog.component';
import {
	BarChartWithPaginationComponent
} from '../_components/bar-chart-with-pagination/bar-chart-with-pagination.component';
import { BarAndLineChartComponent } from '../_components/bar-and-line-chart/bar-and-line-chart.component';
import { BarSeriesVerticalComponent } from '../_components/bar-and-line-chart/bar-series-vertical.component';
import { CardComponent } from '../_components/card/card.component';
import { ToastComponent } from '../_components/toast/toast.component';
import { ModalComponent } from '../_components/modal/custom-modal.component';
import { ConfirmDialogComponent } from '../_components/confirm-dialog/confirm-dialog.component';
import { ConfirmDangerDialogComponent } from '../_components/confirm-danger-dialog/confirm-danger-dialog.component';
import { HelpDialogComponent } from '../_components/help-dialog/help-dialog.component';
import { GradeComponent } from '../_components/filter/grade/grade.component';
import { TruncatePipe } from '../_helpers/pipes/truncate.pipe';
import { LegendIndicatorComponent } from '../_components/legend-indicator/legend-indicator.component';
import { IndicatorsComponent } from '../_components/indicators/indicators.component';
import { AvatarInitialsComponent } from '../_components/avatar-initials/avatar-initials.component';
import { ConfirmMonitorComponent } from '../_components/confirm-monitor/confirm-monitor.component';
import { FormErrorsDirective } from '../_helpers/directives/form-errors/form-errors.directive';
import { ItemCheckComponent } from '../_components/item-check/item-check.component';
import {
	ConfirmDangerActionDirective
} from '../_helpers/directives/confirm-danger-action/confirm-danger-action.directive';
import { BlockCopyDirective } from '../_helpers/directives/block-copy/block-copy.directive';
import { UserCardComponent } from '../_components/user-card/user-card.component';
import {
	NewChecklistModalComponent
} from '../_views/first-steps/create-checklist/new-checklist-modal/new-checklist-modal.component';
import { OnlyPremiumDialogComponent } from '../_components/only-premium-dialog/only-premium-dialog.component';
import { UserSearchDialogComponent } from '../_components/user-search/user-search.component';
import {
	InteractionContestationButton
} from '../_views/interactions/interaction-contestation/interaction-contestation-button';
import { TrainingErrorDialogComponent } from '../_components/training-error-dialog/training-error-dialog.component';
import { TrainingListComponent } from '../_views/training/training-list.component';
import { AddTrainingComponent } from '../_views/training/add-training/add-training.component';
import { EvoDndDirective } from '../_components/dragndrop/drag-drop.directive';
import { SpeedTestComponent } from '../_components/speed-test/speed-test.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
	ChecklistAutomaticEffectiveRulesComponent
} from "../_views/first-steps/create-checklist/checklist-automatic-effective-rules/checklist-automatic-effective-rules.component";
import {
	ChecklistMetadataComponent
} from '../_views/first-steps/create-checklist/checklist-metadata/checklist-metadata.component';
import {
	InteractionAttachmentDialogComponent
} from '../_components/interaction-attachment-dialog/interaction-attachment-dialog.component';
import { ShortenNamePipe } from '../_helpers/pipes/shortenName';
import { InteractionSimplifiedComponent } from 'app/_views/interactions/interaction-simplified/interaction-simplified.component';
import {
	InteractionsReprocessDialogComponent
} from '../_views/interactions/interaction-management/interactions-reprocess-dialog/interactions-reprocess-dialog.component';
import {
	ConfirmJustificationTypeComponent
} from '../_views/interactions/interaction-management/confirm-justification-type.component/confirm-justification-type.component';
import { SecondsToDateTime } from 'app/_helpers/seconds-to-time.pipe';
import {
	EditConformingButtonComponent
} from "../_views/interactions/interaction-contestation/edit-conforming-button/edit-conforming-button.component";
import { MatTabsModule } from "@angular/material/tabs";
import { SecondsToHhmmssPipe } from "../_helpers/pipes/seconds-to-hhmmss.pipe";
import { MatSliderModule } from "@angular/material/slider";
import { AiChatComponent } from "../_components/ai-chat/ai-chat.component";
import { AiChatFiltersComponent } from "../_components/ai-chat/ai-chat-filters/ai-chat-filters.component";
import { MarkdownModule } from "ngx-markdown";

@NgModule({
	declarations: [
		RangeDatepickerComponent,
		StylePaginatorDirective,
		InteractionModalComponent,
		FilterComponent,
		SelectComponent,
		RangeSliderComponent,
		AgentFilterComponent,
		CheckIFDate,
		CheckIFDateHour,
		ChecklistStepOneComponent,
		CreateChecklistComponent,
		StepperFooterComponent,
		ChecklistAutomaticStepTwoComponent,
		ChecklistAutomaticStepThreeComponent,
		ChecklistAutomaticEffectiveRulesComponent,
		ChecklistManualStepTwoComponent,
		ChecklistMetadataComponent,
		InlineEditInputComponent,
		CheckableCardComponent,
		SimpleCheckableCardComponent,
		StepperTitleComponent,
		StepperHeaderComponent,
		EvoCardExpandComponent,
		DiasUteisComponent,
		ReportFiltersComponent,
		InteractionAddComponent,
		InteractionContestationComponent,
		MonitorInteractionComponent,
		EvoStepperComponent,
		ChecklistItemGroupComponent,
		SearchInputComponent,
		UploadFileComponent,
		StatusBadgeComponent,
		TimelineListComponent,
		ClpAudioPlayerComponent,
		OnboardOverlayComponent,
		StepperModalComponent,
		BarChartWithPaginationComponent,
		BarAndLineChartComponent,
		BarSeriesVerticalComponent,
		CardComponent,
		DataTableComponent,
		InteractionAttachmentDialogComponent,
		InteractionSimplifiedComponent,
		SecondsToDateTime,
		ToastComponent,
		ModalComponent,
		ConfirmDialogComponent,
		ConfirmDangerDialogComponent,
		HelpDialogComponent,
		GradeComponent,
		TruncatePipe,
		ShortenNamePipe,
		LegendIndicatorComponent,
		IndicatorsComponent,
		AvatarInitialsComponent,
		ConfirmMonitorComponent,
		FormErrorsDirective,
		ItemCheckComponent,
		ConfirmDangerActionDirective,
		BlockCopyDirective,
		UserCardComponent,
		NewChecklistModalComponent,
		OnlyPremiumDialogComponent,
		UserSearchDialogComponent,
		InteractionContestationButton,
		CurrentStepDirective,
		TrainingErrorDialogComponent,
		TrainingListComponent,
		AddTrainingComponent,
		EvoDndDirective,
		SpeedTestComponent,
		AiChatComponent,
		AiChatFiltersComponent,

		/** Import Data **/
		ImportDataComponent,
		ImportDataStepZeroComponent,
		ImportDataStepOneComponent,
		ImportDataStepTwoComponent,
		ImportDataStepThreeComponent,
		ImportDataStepFourComponent,
		ImportDataStepAgentsComponent,

		/** Interaction Components */
		ChangeAnswerButtonComponent,
		ContestationAcceptedButtonComponent,
		ContestationButtonComponent,
		ContestationDeclinedButtonComponent,
		EvaluationDeclineButtonComponent,
		FeedbackButtonComponent,
		ReplicaAcceptedButtonComponent,
		ReplicaButtonComponent,
		ReplicaDeclinedButtonComponent,
		ConfirmJustificationTypeComponent,
		EditConformingButtonComponent,

		/** Pipes **/
		TimeFormatPipe,
		ConfirmActionDirective,
		SecondsToHhmmssPipe,

		/** Dialogs **/
		OperationAddDialogComponent,
		InteractionsReprocessDialogComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgxChartsModule,
		NgSelectModule,
		NgbModule,
		NgxSliderModule,
		NgxTimerModule,
		NgxChartsModule,

		BsDropdownModule.forRoot(),
		CollapseModule.forRoot(),
		TooltipModule.forRoot(),
		NgxMaskModule.forRoot(),
		NgxDatatableModule,
		DataTablesModule,

		TagCloudModule,
		NgxPermissionsModule.forChild(),
		MarkdownModule.forChild(),

		/** Angular Material **/
		MatSortModule,
		MatCheckboxModule,
		MatTableModule,
		MatPaginatorModule,
		DragDropModule,
		MatTabsModule,
		MatSliderModule

	],
	exports: [
		RangeDatepickerComponent,
		StylePaginatorDirective,
		InteractionModalComponent,
		FilterComponent,
		SelectComponent,
		RangeSliderComponent,
		AgentFilterComponent,
		CheckIFDate,
		CheckIFDateHour,
		CreateChecklistComponent,
		StepperFooterComponent,
		ChecklistStepOneComponent,
		ChecklistAutomaticStepTwoComponent,
		ChecklistAutomaticStepThreeComponent,
		ChecklistAutomaticEffectiveRulesComponent,
		ChecklistManualStepTwoComponent,
		ChecklistMetadataComponent,
		InlineEditInputComponent,
		CheckableCardComponent,
		SimpleCheckableCardComponent,
		StepperTitleComponent,
		StepperHeaderComponent,
		EvoCardExpandComponent,
		DiasUteisComponent,
		ReportFiltersComponent,
		InteractionAddComponent,
		InteractionContestationComponent,
		MonitorInteractionComponent,
		EvoStepperComponent,
		ChecklistItemGroupComponent,
		SearchInputComponent,
		UploadFileComponent,
		StatusBadgeComponent,
		TimelineListComponent,
		ClpAudioPlayerComponent,
		OnboardOverlayComponent,
		StepperModalComponent,
		BarChartWithPaginationComponent,
		BarAndLineChartComponent,
		BarSeriesVerticalComponent,
		CardComponent,
		DataTableComponent,
		InteractionAttachmentDialogComponent,
		InteractionsReprocessDialogComponent,
		SecondsToDateTime,
		ToastComponent,
		ModalComponent,
		ConfirmDialogComponent,
		ConfirmDangerDialogComponent,
		HelpDialogComponent,
		GradeComponent,
		LegendIndicatorComponent,
		IndicatorsComponent,
		AvatarInitialsComponent,
		ConfirmMonitorComponent,
		FormErrorsDirective,
		ItemCheckComponent,
		ConfirmDangerActionDirective,
		BlockCopyDirective,
		UserCardComponent,
		NewChecklistModalComponent,
		OnlyPremiumDialogComponent,
		UserSearchDialogComponent,
		InteractionContestationButton,
		CurrentStepDirective,
		TrainingErrorDialogComponent,
		TrainingListComponent,
		AddTrainingComponent,
		EvoDndDirective,
		SpeedTestComponent,
		AiChatComponent,
		AiChatFiltersComponent,

		/** Interaction Components */
		ChangeAnswerButtonComponent,
		ContestationAcceptedButtonComponent,
		ContestationButtonComponent,
		ContestationDeclinedButtonComponent,
		EvaluationDeclineButtonComponent,
		FeedbackButtonComponent,
		ReplicaAcceptedButtonComponent,
		ReplicaButtonComponent,
		ReplicaDeclinedButtonComponent,

		/** Pipes **/
		TruncatePipe,
		TimeFormatPipe,
		TitleCasePipe,
		ConfirmActionDirective,
		ShortenNamePipe,
		SecondsToHhmmssPipe,

		/** Dialogs **/
		OperationAddDialogComponent,
		InteractionsReprocessDialogComponent,
		InteractionsReprocessDialogComponent,

		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		NgxChartsModule,
		NgSelectModule,
		NgbModule,
		NgxSliderModule,
		NgxTimerModule,
		NgxChartsModule,

		BsDropdownModule,
		CollapseModule,
		TooltipModule,
		NgxMaskModule,
		NgxDatatableModule,
		DataTablesModule,

		TagCloudModule,
		NgxPermissionsModule,
		MarkdownModule,

		/** Angular Material **/
		MatSortModule,
		MatCheckboxModule,
		MatTableModule,
		MatPaginatorModule,
		DragDropModule,
		ConfirmJustificationTypeComponent,
		MatTabsModule,
		MatSliderModule
	]
})
export class SharedModule {
}
