import { PageFilter } from '../page-filter';

export interface InteractionFilter extends PageFilter {
	operationsIds?: number[];
	segmentsIds?: number[];
	contactDate?: Date;
	contactDateFinal?: Date;

	monitorDate?: Date;
	monitorDateFinal?: Date;

	deadLine?: Date;
	deadLineFinal?: Date;
	newDeadLine?: Date;

	status?: string[];

	grade?: number;
	gradeFinal?: number;

	agentName?: string;

	fileName?: string;
	originalFileName?: string;

	id?: any;

	interactionType?: string;
	transcription?: string;

	ids?: any[];
	idsToExclude?: any[];
	reprocessInteractions?: { id: number, applyRules: boolean }[];

	applyRules?: boolean;

	monitorInteractionItemStatus?: any;

	all?: string;

	metadata?: any[];

	justificationId?: number;
}
