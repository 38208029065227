<button class="btn btn-outline-danger btn-sm" (click)="openModal()">Contestar Item</button>

<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Contestação</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
        Por favor, preencha sua contestação para o item {{index}}
        <br>
        <form [formGroup]="contestationForm">
	        <div class="row">
		        <div class="form-group col pb-0">
			        <label for="description">Momento no Áudio</label>
			        <input type="text" formControlName="minute" class="form-control"
			               mask="00:00:00"
			               ngbTooltip="Momento no áudio referente à contestação (hh:mm:ss)"
			               [ngClass]="{ 'is-invalid': (minute.dirty || minute.touched) && minute.errors }" />
			        <div *ngIf="(minute.dirty || minute.touched) && minute.errors"
			             class="invalid-feedback">
				        <div *ngIf="minute.errors.required">Momento no Áudio é obrigatório</div>
			        </div>
		        </div>
	        </div>
            <div class="row">
                <div class="form-group col">
	                <label for="description">Explicação <span class="text-muted"> - máx. {{contestation.value.length || 0}}/2000 caracteres</span></label>
                    <textarea type="text" formControlName="contestation" class="form-control pb-0" (keydown)="disableCtrlV($event)" style="resize: none;" rows="5"
                        [ngClass]="{ 'is-invalid': (contestation.dirty || contestation.touched) && contestation.errors }"></textarea>
                    <div *ngIf="(contestation.dirty || contestation.touched) && contestation.errors"
                        class="invalid-feedback">
                        <div *ngIf="contestation.errors.required">Contestação é obrigatória</div>
                    </div>
                </div>
            </div>
	        <div class="row" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.CONTEST_EVALUATION_ATTACHMENT]">
		        <div class="col">
			        <evo-upload-file-input name="file" style="width: 100%" [ngModelOptions]="{standalone: true}"
			                               [(ngModel)]="file"
			                               [disabled]="file.length > 0"
			                               [violatedRules]="violatedRulesFileUpload"
			                               [isProgressBarEnable]="true"
			                               [uploadProgress]="[uploadProgress]"
			                               fileLegend="Tamanho máximo permitido é de 500MB"
			                               [isLoading]="isLoading"
			                               [isMultiple]="false">
			        </evo-upload-file-input>
		        </div>
	        </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading || (!contestationForm.valid || minute.value == '')" class="btn btn-primary"
            (click)="confirmContestation(); modal.close()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Confirmar
        </button>
    </div>
</ng-template>
