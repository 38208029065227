import { Segment } from "./segment";

export class SpeechSegmentIds {
    segment:Segment;
    speechSegmentId: any;

    constructor(segment:Segment, speechSegmentId: any) {
        this.segment = segment;
        this.speechSegmentId = speechSegmentId;
    }
}
