import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SpeechSegmentService {
    basePath: string = `${environment.apiUrl}/external/speech/segments`;

    constructor(private http: HttpClient) { }

    findBySpeechEnvironmentId(speechEnvironmentId: string) {
        return this.http.get<any[]>(`${environment.apiUrl}/speech/segments/environments/${speechEnvironmentId}`);
    }
}

