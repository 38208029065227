<div class="mb-3" style="position: relative">
  <div class="d-flex justify-content-center align-items-center mb-3 overlay" *ngIf="!audioLoaded && !playbackError">
      <button class="btn btn-light" (click)="loadAudio()" [disabled]="isLoading">
          <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
          <span *ngIf="isLoading">Carregando áudio </span>
          <span *ngIf="!isLoading">Carregar áudio </span>
           <i class="fas fa-volume-up"></i>
      </button>
  </div>
  <div id="waveform" [hidden]="playbackError || !audioLoaded"></div>
  <div class="playback-error-placeholder" [hidden]="!playbackError">
    <i class="material-icons">broken_image</i> Não foi possível executar o áudio
  </div>
  <div class="player-menu">
    <!-- Buttons -->
    <div class="player-controls pl-2">
      <button class="btn play-pause-btn" (click)="!audioIsPlaying ? onPlayPressed() : onPausePressed()" [disabled]="!wave || playbackError || !audioLoaded">
        <i class="fas" [ngClass]="!audioIsPlaying ? 'fa-play' : 'fa-pause'"></i>
      </button>
      <button class="btn stop-btn ml-2 mr-2" (click)="onStopPressed()" [disabled]="!wave || playbackError || !audioLoaded">
        <i class="fas fa-stop"></i>
      </button>
      <button class="btn stop-btn" [disabled]="!wave || playbackError || !audioLoaded" (click)="openedVolumeSlider = !openedVolumeSlider">
        <i class="fas fa-volume-up"></i>
      </button>
      <div class="volume-floating-card" [hidden]="!openedVolumeSlider">
        <div class="volume-slider">
          <input id="volume" orient="vertical" type="range" min="0" max="1" value="1" step="0.1">
          <span>{{volumePercentage}}%</span>
        </div>
      </div>
    </div>
    <!-- Speed -->
    <button class="btn speed-btn" name="speed" ngDefaultControl [(ngModel)]="playbackSpeed" (click)="togglePlaybackSpeed()"><span>{{playbackSpeed}}x</span></button>
     <!-- Time -->
     <div class="audio-time">
      <span>{{timeInterval | timeFormat}} / {{totalAudioDuration | timeFormat}}</span>
    </div>
  </div>
</div>
