<button class="btn btn-sm" [ngClass]="isConfirming ? 'btn-outline-success' : 'btn-outline-danger'" (click)="openDeclinedEvaluationModal()">
    {{isConfirming ? 'Confirmar' : 'Recusar'}} Contestação Improcedente
</button>

<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">{{isConfirming ? 'Confirmar' : 'Recusar'}} Contestação Improcedente</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
        Selecione a justificativa para a contestação {{!isConfirming ? 'ser' : ''}} procedente
        <form [formGroup]="reasonContestationDeclined" class="row mt-2">
            <div class="form-group col">
                <label for="description">Justificativa</label>
                <input type="text" formControlName="reason" class="form-control"
                    [ngClass]="{ 'is-invalid': (reasonContestationDeclined.dirty || reasonContestationDeclined.touched) && reasonContestationDeclined.errors }" />
                <div *ngIf="(reasonContestationDeclined.dirty || reasonContestationDeclined.touched) && reasonContestationDeclined.errors"
                    class="invalid-feedback">
                    <div *ngIf="reasonContestationDeclined.errors.required">Justificativa é obrigatório</div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading || !reasonContestationDeclined.get('reason').valid" class="btn btn-primary"
            (click)="saveContestationDeclinedEvaluation(isConfirming ? 'CONTESTATION_DECLINED' : 'CONTESTATION_ACCEPTED', isConfirming ? 'Contestação improcedente confirmada.' : 'Contestação improcedente recusada.')" >
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Confirmar
        </button>
    </div>
</ng-template>
