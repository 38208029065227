import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { OnboardingStepStatus } from '@app/models';
import { OnboardingStepService,
	    FirstStepsService,
		ImportDataService,
		ToastService,
		BucketService,
		JobService } from '@app/services';
import {
	ConfirmDangerDialogComponent
} from '../../../../_components/confirm-danger-dialog/confirm-danger-dialog.component';

@Component({
	selector: 'app-import-data-step-four',
	templateUrl: './import-data-step-four.component.html',
	styleUrls: ['./import-data-step-four.component.scss']
})
export class ImportDataStepFourComponent implements OnInit, OnDestroy {
	file: File[] = [];

	get violatedRulesFileUpload() {
		return this.file.length > 0 && this.file[0].size > 2024000 * 1000 ? ['Arquivo excede o limite permitido. Envie arquivos com até 2GB.'] : [];
	  }

	subs = new Subscription();

	uploadProgress: number = 0;
	toggleUpload$ = new Subject<boolean>();

	constructor(public importDataService: ImportDataService,
				private activeModal: NgbActiveModal,
				private toastService: ToastService,
				private modalService: NgbModal,
				private router: Router,
				private httpClient: HttpClient,
				private bucketService: BucketService,
				private onboardingStepService: OnboardingStepService,
				private firstStepsService: FirstStepsService,
				private jobService: JobService) { }

	ngOnInit(): void {
		this.importDataService.isLoading = false;

		this.subs.add(this.toggleUpload$.pipe(
			switchMap((toggleUpload) => {
				return toggleUpload ? this.bucketService.getUploadUrl(this.file[0].name) : of(null);
			}),
			switchMap(uploadInfo => {
				if (!uploadInfo) return EMPTY;
				this.importDataService.zip = uploadInfo.file;
				return this.httpClient.put(uploadInfo.url, (this.file[0] as File), {reportProgress: true, observe: 'events'});
			}),
			tap((event) => {
				if (event.type === 1) this.uploadProgress = ((event as HttpProgressEvent)?.loaded / (event as HttpProgressEvent)?.total) * 100;
			}),
			filter((event) => event.type === 4),
			switchMap(() => {
				const body = {
					segmentId: this.importDataService.segmentId,
					operationId: this.importDataService.operationId,
					columns: this.importDataService.columnIndexes,
					metadata: this.importDataService.metadata,
					zip: this.importDataService.zip,
					metadataId: this.jobService.importJobId,
					checklistId: this.importDataService.checklistId
				}
				return this.jobService.post(body);
			})
		).subscribe(() => {
			this.toastService.showSuccess('Chamadas em processamento');
			this.firstStepsService.onboardingStatus.importJob = true;
			this.activeModal.close(true);
			this.router.navigate(['/imports']);
		}, (err) => this.toastService.showDanger(err.message || 'Ocorreu um problema no upload do seu arquivo.')).add(() => this.importDataService.isLoading = false));
	}

	cancelUpload() {
		this.toggleUpload$.next(false);
		this.resetUploadFile();
	}

	private resetUploadFile() {
		this.uploadProgress = 0;
		this.importDataService.isLoading = false;
	}

	onPrev() {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = "Ao voltar, os dados da importação serão perdidas. Tem certeza que deseja voltar?";
		modalRef.componentInstance.title = "Importação da chamada";

		this.subs.add(modalRef.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => this.importDataService.prevStep()));
	}

	onNext() {
		if(this.onboardingStepService.currentOnboardingStep.status !== OnboardingStepStatus.COMPLETED) {
			this.onboardingStepService.currentOnboardingStep.status = OnboardingStepStatus.COMPLETED;
			this.onboardingStepService.put(this.onboardingStepService.currentOnboardingStep).subscribe(
				data => {
				}, error => {
					console.error(error);
				}
			);
		}

		this.importDataService.isLoading = true;
		this.toggleUpload$.next(true);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}

