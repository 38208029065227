import { saveAs } from 'file-saver';

export function saveReportFile(data:any, fileName:string, type: string){
    fileName = fileName + new Date().toISOString();
    const blob = new Blob([data], { type: 'application/octet-stream' });
    let typeFile = { type: 'application/vnd.ms.excel' };
    if(type == 'CSV'){
        typeFile = { type: 'text/csv' };
    }
    
    const file = new File([blob], fileName + '.' + type, { type: 'application/vnd.ms.excel' });
    saveAs(file);
}

export function saveFile(data:any, fileName:string, type: string){
    const blob = new Blob([data], { type: 'application/octet-stream' });

    const file = new File([blob], fileName, { type: 'application/vnd.ms.excel' });
    saveAs(file);
}

export function saveAudioFile(data: any, fileName: string) {
    const blob = new Blob([data], {type: 'audio/*'});
    const file = new File([blob], fileName, {type: 'audio/*'});
    saveAs(file);
}