import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'evo-dias-uteis',
  templateUrl: './dias-uteis.component.html',
  styleUrls: ['./dias-uteis.component.scss']
})
export class DiasUteisComponent implements OnInit {
  weekDays = [
    {label: 'Domingo', formControl: 'sunday'}, 
    {label: 'Segunda-feira', formControl: 'monday'},
    {label: 'Terça-feira', formControl: 'tuesday'}, 
    {label: 'Quarta-feira', formControl: 'wednesday'}, 
    {label: 'Quinta-feira', formControl: 'thursday'},
    {label: 'Sexta-feira', formControl: 'friday'}, 
    {label: 'Sábado', formControl: 'saturday'}
  ];

  @Input() days: FormArray;
  @Input() holidays: FormArray;

  _days: FormArray;

  currDateSelected: NgbDate = null;
  datesSelected: Set<any> = new Set();

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (!this.holidays) {
      this.holidays = this.formBuilder.array([]);
    }
    if (!this.days) {
      this.days = this.formBuilder.array([
        false,
        true,
        true,
        true,
        true,
        true,
        false
      ]);
    } else {
      const selectedDays = new Set(this.days.value.map(day => day.toLowerCase()));
      const days = this.weekDays.reduce((acc, day) => {
        return acc = [...acc, selectedDays.has(day.formControl)];
      }, []);
      this._days = this.formBuilder.array(days);
    }
  }

  onDayChanged(ev) {
    this.days.patchValue(this._days.value.map((day, idx) => {
	  return {isEnabled: day, value: this.weekDays[idx].formControl.toUpperCase()}
	}).filter(day => day.isEnabled).map(day => day.value));
  }

  onDateSelection(date: NgbDate) {
    const _date = {year: date.year, month: date.month, day: date.day};
    (this.holidays as FormArray).push(this.formBuilder.control(_date));
    this.currDateSelected = undefined;
    this.datesSelected.add(_date);
  }

  removeDiaNaoUtil(idx) {
    this.datesSelected.delete((this.holidays as FormArray).at(idx).value);
    (this.holidays as FormArray).removeAt(idx);
  }

  isDisabled = (date: NgbDate, current: {month: number}) => {
    return this.datesSelected.has(date);
  }

}
