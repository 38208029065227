import { Injectable, Pipe, PipeTransform } from '@angular/core';
/*
 * Transform seconds (number) yo hh:mm:ss string
*/

@Pipe({name: 'secondsToDateTime'})
export class SecondsToDateTime implements PipeTransform {
  transform(totalSeconds: number) {
    return new Date(1970, 0, 1).setSeconds(totalSeconds);
  }
}
