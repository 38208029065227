import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { ImportDataService, ImportTypes } from '../../../../_services/import-data.service';
import { SegmentService } from '../../../../_services/segment.service';
import { FlowService } from '../../../../_services/flow.service';

@Component({
	selector: 'app-import-data-step-zero',
	templateUrl: './import-data-step-zero.component.html',
	styleUrls: ['./import-data-step-zero.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0}),
				animate('200ms 260ms ease-in-out', style({ opacity: 1}))
			])
		])
	]
})
export class ImportDataStepZeroComponent implements OnInit, OnDestroy {

	public importType;
	public importTypes = ImportTypes;

	private multiImportSteps = [
		{label: 'Tipo de importação', componentPromise: import('../import-data-step-zero/import-data-step-zero.component')},
		{label: 'Detalhes da chamada', componentPromise: import('../import-data-step-one/import-data-step-one.component')},
		{label: 'Importar metadados', componentPromise: import('../import-data-step-two/import-data-step-two.component')},
		{label: 'Relacionar campos', componentPromise: import('../import-data-step-three/import-data-step-three.component')},
		// {label: 'Relacionar operadores', componentPromise: import('../import-data-step-agents/import-data-step-agents.component')},
		{label: 'Importar áudio', componentPromise: import('../import-data-step-four/import-data-step-four.component')}
	];

	private singleImportSteps = [
		{label: 'Tipo de importação', componentPromise: import('../import-data-step-zero/import-data-step-zero.component')},
		{label: 'Importar interação', componentPromise: import('../../../interactions/interaction-add/interaction-add.component')}
	];

	private metadataImportSteps = [
		{label: 'Tipo de importação', componentPromise: import('../import-data-step-zero/import-data-step-zero.component')},
		{label: 'Detalhes da chamada', componentPromise: import('../import-data-step-one/import-data-step-one.component')},
		{label: 'Importar metadados', componentPromise: import('../import-data-step-two/import-data-step-two.component')},
		{label: 'Relacionar campos', componentPromise: import('../import-data-step-three/import-data-step-three.component')},
		// {label: 'Relacionar operadores', componentPromise: import('../import-data-step-agents/import-data-step-agents.component')},
	];

	private subs = new Subscription();

	constructor(public importDataService: ImportDataService,
	            private formBuilder: FormBuilder,
	            private segmentService: SegmentService,
	            private flowService: FlowService,
	            private modalService: NgbModal) {
	}

	ngOnInit(): void {
		this.importDataService.segmentId = null;
		this.importDataService.operationId = null;
	}

	onPrev() {
		const modal = this.importDataService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.modalService.dismissAll();
		}));
	}

	onNext() {
		this.importDataService.importType = this.importType;

		if(this.importType == this.importTypes.MULTI) this.importDataService.steps = this.multiImportSteps;
		if(this.importType == this.importTypes.SINGLE) this.importDataService.steps = this.singleImportSteps;
		if(this.importType == this.importTypes.METADATA) this.importDataService.steps = this.metadataImportSteps;

		this.importDataService.nextStep();
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
