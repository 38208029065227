<div class="d-flex align-items-center">
    <div class="circle" [ngStyle]="{'background-color':  '#1839B4' }">
	    <div *ngIf="showInitials" class="initials initials-default-color">
	        {{ initials }}
	    </div>
    </div>

	<div class="d-flex flex-column">
		<span class="user-name">
			{{ user.name }}
		</span>

		<span class="user-email" *ngIf="showEmail">
			{{ user.email ? user.email : '-' }}
		</span>
	</div>
</div>
