export class UpdatePbiReportRequest {
	public groupId: string;
	public reportId: string;
	public groupName: string;
	public reportName: string;
	public description: string;
	public imageUrl: string;
	public active: boolean;
	public permissionCode: any;
}
