import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Justification } from '@app/models';
import { BaseServiceClass } from './baseService.service';

@Injectable({ providedIn: 'root' })
export class JustificationService extends BaseServiceClass<Justification>{

  constructor(protected httpService: HttpClient) {
    super(httpService, '/justifications');
  }

}
