import { Component, Input, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'evo-select',
  template: `<div #container><ng-content></ng-content></div>`,
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements AfterViewInit {
  @Input() iconUnicode: string;

  @ViewChild('container') container: ElementRef<any>;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit() {
    if(this.iconUnicode) {
      const unicode = '\\' + this.iconUnicode;
      this.renderer.setStyle(this.container.nativeElement, '--icon', `"${unicode}"`, 2);
    }
  }


}
