<div class="modal-header d-flex align-items-center">
    <h5 class="modal-title" id="modal-title">{{ title }}</h5>
</div>
<div class="modal-body" style="padding-top: 0px;">
    <div class="mt-2" style="text-align: center;">
        <i class="far fa-check-circle text-success mb-3" style="font-size: 6em;"></i>
        <p class="m-0" style="color: #404040; font-size: 14px; font-weight: 600;">{{ subtitle }}</p>
        <p class="m-0" style="color: #404040; font-size: 14px; font-weight: 600;">{{ description }}</p>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn text-primary" (click)="modal.close('NEW_CHECKLIST')">Criar novo checklist</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('IMPORT')">Importar interações</button>
</div>
