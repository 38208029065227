import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionFilter } from '@app/models';

@Component({
	selector: 'app-interactions-reprocess-dialog',
	templateUrl: './interactions-reprocess-dialog.component.html',
	styleUrls: ['./interactions-reprocess-dialog.component.scss']
})
export class InteractionsReprocessDialogComponent implements OnInit {

	@Input() public interactionFilter: InteractionFilter;
	@Input() public allInteractionSelected: boolean;
	@Input() public selectedIds: any[] = [];
	@Input() public totalResults: number;
	@Input() public reprocessDashboard: boolean = false;

	public processRules: boolean = true;

	public reprocessInteractions: { id: string, applyRules: boolean }[] = [];

	public displayedColumns: string[] = [ 'id', 'applyRules' ];

	constructor(public modal: NgbActiveModal) {
	}

	ngOnInit(): void {
		if(!this.allInteractionSelected && this.selectedIds.length > 0) {
			this.selectedIds.forEach(interaction => {
				this.reprocessInteractions.push({
					id: interaction,
					applyRules: true
				});
			});
		}
	}

	public sendReprocessResult(): void {
		this.modal.close({ reprocessInteractions: this.reprocessInteractions, applyRules: this.processRules });
	}

}
