<div class="modal-header">
	<h5 class="modal-title">Gerenciar usuários | <span class="text-muted" style="font-size: 75%">{{ segment.name }} - <span class="text-primary">{{ operation.name }}</span></span></h5>
	<button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">

	<div class="d-flex">

		<div class="" style="flex: 1">
			<div class="search-div">
				<input class="search-input" [(ngModel)]="searchTerm" placeholder="Procurar"/>
				<i class="fa fa-search"></i>
			</div>

			<div class="users-list">
				<div class="list">
					<div class="user-item mb-2" *ngFor="let user of users; index as i">
						<div class="d-flex align-items-center">
							<div class="circle" [ngStyle]="{'background-color':  '#1839B4' }">
								<div class="initials initials-default-color">{{ getInitials(user) | uppercase }}</div>
							</div>
							<div class="d-flex flex-column">
								<span class="user-name">{{ user?.name ? user.name : user?.email }} <span class="badge badge-warning" *ngIf="user.userType == 'INTERNAL'">Interno</span></span>
								<span class="user-name text-muted" *ngIf="user?.name && user?.email">{{ user?.email }}</span>
							</div>

						</div>

						<div class="p-2" *ngIf="isLoading">
							<span class="spinner-border spinner-border-sm mr-1"></span>
						</div>

						<div class="btn btn-delete" (click)="saveUser(user)" *ngIf="!isLoading">
							<i class="fas fa-arrow-right"></i>
						</div>
					</div>

				</div>
				<div *ngIf="!currentPage?.last" class="d-flex align-items-center flex-column">
					<button class="btn text-primary" (click)="pageChange$.next(currentPage?.number + 1)">Carregar mais</button>
				</div>

				<!-- <div class="user-list-result">
					<p class="text-primary">
						{{ usersToSave.length }} usuário<span *ngIf="usersToSave.length != 1">s</span> selecionado<span *ngIf="usersToSave.length != 1">s</span>
					</p>
					<p class="text-primary">
						{{ usersToDelete.length }} usuário<span *ngIf="usersToDelete.length != 1">s</span> desselecionado<span *ngIf="usersToDelete.length != 1">s</span>
					</p>
				</div> -->
			</div>
		</div>

		<div class="ml-3 pl-3 border-left" style="flex: 1">

			<div class="user-list-status mb-3">
				Usuários vinculados
			</div>

			<div class="list">
				<div class="d-flex justify-content-between mb-2" *ngFor="let user of soUsers; index as i">
					<div class="d-flex align-items-center">
						<div class="circle" [ngStyle]="{'background-color':  '#1839B4' }">
							<div class="initials initials-default-color">{{ getInitials(user) | uppercase }}</div>
						</div>
						<div class="d-flex flex-column">
							<span class="user-name">{{ user?.name ? user.name : user?.email }}</span>
							<span class="user-name text-muted" *ngIf="user?.name && user?.email">{{ user?.email }}</span>
						</div>
					</div>

					<div class="btn btn-delete" (click)="confRemoveSoUser(user)">
						<i class="fas fa-trash"></i>
					</div>
				</div>
			</div>

		</div>

	</div>

</div>

<div class="modal-footer justify-content-end">
	<!-- <button type="button"
			class="btn btn-link text-primary"
			evolloConfirmDangerAction
			title="Gerenciar usuários"
			text="Deseja sair do gerenciamento de usuários? Todas suas alterações serão perdidas."
			(confirm)="modal.close()">
		Cancelar
	</button> -->
	<button type="button" class="btn btn-primary" [disabled]="isLoading" (click)="modal.close()">
		<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
		Fechar
	</button>
</div>
