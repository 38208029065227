<div class="w-100">
    <evo-select iconUnicode="f005">
        <ng-select [searchable]="false" [closeOnSelect]="false" [clearable]="false" [placeholder]="placeholder" #select>
            <ng-template ng-header-tmp>
                <div class="d-flex flex-column ml-auto mr-auto" style="width: 90%">
                    <ngx-slider (userChange)="value = [$event.value, $event.highValue]"
                            [formControl]="_value"
                            [options]="{floor: floor, ceil: ceil, hideLimitLabels: true}"></ngx-slider>
                    <div class="d-flex justify-content-between">
                        <input [(ngModel)]="lowValue" (input)="value = [+lowValue, +highValue]" class="form-control disable-border-color-change-on-touch" style="width: 20%"/>
                        <input [(ngModel)]="highValue" (input)="value = [+lowValue, +highValue]" class="form-control disable-border-color-change-on-touch" style="width: 20%"/>
                    </div>
                </div>
            </ng-template>
            <ng-option [value]="selectValue"></ng-option>
        </ng-select>
    </evo-select>
</div>