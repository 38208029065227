import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shortenName'
})
export class ShortenNamePipe implements PipeTransform {

	transform(value: string, limit: number = 10): string {
		if (!value) {
			return '';
		}

		const extension = value.split('.').pop();
		const name = value.substring(0, value.lastIndexOf('.'));
		const shortenedName = name.length > limit ? `${name.substring(0, limit)}...` : name;

		return `${shortenedName}.${extension}`;
	}
}
