import { City } from './city';
import { Deserializable } from './deserializable';
import { State } from './state';


export class UserPatchRequest implements Deserializable {
    id: number;
    document: string;
    email: string;
    name: string;
    personType: number;
    
	city: number;
	neighborhood: string;
    addressLine: string;
	postalCode: string;
    streetName: string;
	streetNumber: string;
    state: number;

    type?:number;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
