import { QualityPermissions } from '@app/models';

export const MENU_PATHS = {
    LOGIN: '/login',
    DASHBOARD: '/dashboards',
    USERS: '/users',
    USER: '/user',
    NEW_USER: '/newuser',
    CUSTOMERS: '/customers',
    NEW_CUSTOMER: '/new_costumer',
    SEGMENTS: '/segments',
    SEGMENT: '/segment',
    NEW_SEGMENT: '/new_segment',
    OPERATIONS: '/operations',
    OPERATION: '/operation',
    NEW_OPERATION: '/new_operation',
    JUSTIFICATIONS: '/justifications',
    NEW_JUSTIFICATION: '/new_justification',
    CHECKLISTS: '/checklists',
    CHECKLIST: '/checklist',
    INTERACTIONS: '/interactions',
    CONTESTATION: '/report/constestation',
    INTERACTION: '/report/interaction',
    ITEMS: '/report/items',
	MY_REPORTS: 'report/my-reports',
	FLOWS: '/flows',
	FLOW: '/flow',
	NEW_FLOW: '/newflow',
    TRAINING: '/training',
	INTERACTIONS_MANAGEMENT: '/interactions/management',
	IMPORT_JOBS: '/imports',
    GEN_AI_CONFIGURATION: '/gen-ai-configuration',
	CHAT: '/chat'
};

export const MENU = [
    {
        id: 'dashboards',
        description: 'Dashboards',
        path: MENU_PATHS.DASHBOARD,
        icon: 'fas fa-chart-pie',
        permissions: [QualityPermissions.ALL_PERMISSIONS,
            QualityPermissions.DASHBOARD_QUALITY,
            QualityPermissions.DASHBOARD_PERFORMANCE,
            QualityPermissions.DASHBOARD_SENTIMENT,
            QualityPermissions.DASHBOARD_PBI_QUALITY,
            QualityPermissions.DASHBOARD_PBI_VOLUME,
            QualityPermissions.DASHBOARD_PBI_CONTROL,
            QualityPermissions.DASHBOARD_PBI_PERFORMANCE,
            QualityPermissions.DASHBOARD_PBI_SENTIMENT,
            QualityPermissions.DASHBOARD_PBI_CALLBACK,
	        QualityPermissions.DASHBOARD_PBI_SCORE,
	        QualityPermissions.DASHBOARD_PBI_BUSINESS,
	        QualityPermissions.DASHBOARD_PBI_OUTCOME,
            QualityPermissions.DASHBOARD_TRAINING],
        closed: true
    },
	{
		id: 'chat',
		description: 'IA Generativa',
		path: MENU_PATHS.CHAT,
		icon: 'fas fa-comment',
		permissions: [QualityPermissions.ALL_PERMISSIONS, QualityPermissions.GEN_AI_CHAT],
	},
	{
		id: 'interaction',
		description: 'Interações',
		path: '/interaction',
		icon: 'fas fa-phone',
		permissions: [QualityPermissions.ALL_PERMISSIONS,
			QualityPermissions.VIEW_ALL_INTERACTIONS,
			QualityPermissions.VIEW_OWNED_INTERACTION,
			QualityPermissions.VIEW_INTERACTIONS_DETAILS,
			QualityPermissions.REVIEW_INTERACTIONS,
			QualityPermissions.EVALUATE_INTERACTIONS,
			QualityPermissions.RELEASE_INTERACTIONS,
			QualityPermissions.ACCEPT_EVALUATION,
			QualityPermissions.CONTEST_EVALUATION,
			QualityPermissions.REPLY_CONTESTATION,
			QualityPermissions.REPLICA_REPLY_CONTESTATION,
			QualityPermissions.REPLY_REPLICA,
			QualityPermissions.CREATE_INTERACTIONS,
			QualityPermissions.IMPORT_INTERACTIONS,
		],
		closed: false,
		submenu: [
			{
				id: 'monitoring',
				description: 'Monitorias',
				path: MENU_PATHS.INTERACTIONS,
				icon: 'fas fa-phone',
				permissions: [QualityPermissions.ALL_PERMISSIONS,
					QualityPermissions.VIEW_ALL_INTERACTIONS,
					QualityPermissions.VIEW_OWNED_INTERACTION,
					QualityPermissions.VIEW_INTERACTIONS_DETAILS,
					QualityPermissions.REVIEW_INTERACTIONS,
					QualityPermissions.EVALUATE_INTERACTIONS,
					QualityPermissions.RELEASE_INTERACTIONS,
					QualityPermissions.ACCEPT_EVALUATION,
					QualityPermissions.CONTEST_EVALUATION,
					QualityPermissions.REPLY_CONTESTATION,
					QualityPermissions.REPLICA_REPLY_CONTESTATION,
					QualityPermissions.REPLY_REPLICA,
					QualityPermissions.CREATE_INTERACTIONS],
				closed: true,
			},
			{
				id: 'imports',
				description: 'Importações',
				path: MENU_PATHS.IMPORT_JOBS,
				icon: 'fas fa-upload',
				permissions: [ QualityPermissions.ALL_PERMISSIONS, QualityPermissions.IMPORT_INTERACTIONS ],
				closed: true
			},
			{
				id: 'integrations',
				description: 'Gerenciamento',
				path: MENU_PATHS.INTERACTIONS_MANAGEMENT,
				icon: '',
				permissions: [QualityPermissions.ALL_PERMISSIONS ]
			}
		]
	},
    /*{
        id: 'interactions',
        description: 'Interações',
        path: MENU_PATHS.INTERACTIONS,
        icon: 'fas fa-phone',
        permissions: [QualityPermissions.ALL_PERMISSIONS,
            QualityPermissions.VIEW_ALL_INTERACTIONS,
            QualityPermissions.VIEW_OWNED_INTERACTION,
            QualityPermissions.VIEW_INTERACTIONS_DETAILS,
            QualityPermissions.REVIEW_INTERACTIONS,
            QualityPermissions.EVALUATE_INTERACTIONS,
            QualityPermissions.RELEASE_INTERACTIONS,
            QualityPermissions.ACCEPT_EVALUATION,
            QualityPermissions.CONTEST_EVALUATION,
            QualityPermissions.REPLY_CONTESTATION,
            QualityPermissions.REPLICA_REPLY_CONTESTATION,
            QualityPermissions.REPLY_REPLICA,
            QualityPermissions.CREATE_INTERACTIONS],
        closed: true,
    },*/
	/*{
		id: 'imports',
		description: 'Importações',
		path: MENU_PATHS.IMPORT_JOBS,
		icon: 'fas fa-upload',
		permissions: [ QualityPermissions.ALL_PERMISSIONS ],
		closed: true
	},*/
    {
        id: 'reports',
        description: 'Relatórios',
        path: '/report',
        icon: 'fas fa-stream',
        permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_CONTESTATION_REPORTS,
                    QualityPermissions.DOWNLOAD_CONTESTATION_REPORTS,
                    QualityPermissions.VIEW_INTERACTION_REPORTS,
                    QualityPermissions.DOWNLOAD_INTERACTION_REPORTS,
                    QualityPermissions.VIEW_ITEM_REPORTS,
                    QualityPermissions.DOWNLOAD_ITEM_REPORTS,
					QualityPermissions.VIEW_REPORTS,
				],
        closed: false,
        submenu: [
            {
                description: 'Fluxo de Contestação',
                path: MENU_PATHS.CONTESTATION,
                icon: 'fas fa-exclamation',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_CONTESTATION_REPORTS,
                    QualityPermissions.DOWNLOAD_CONTESTATION_REPORTS],
            },
            {
                description: 'Interações',
                path: MENU_PATHS.INTERACTION,
                icon: 'fas fa-headset',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_INTERACTION_REPORTS,
                    QualityPermissions.DOWNLOAD_INTERACTION_REPORTS],
            },
            {
                description: 'Itens',
                path: MENU_PATHS.ITEMS,
                icon: 'fas fa-puzzle-piece',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_ITEM_REPORTS,
                    QualityPermissions.DOWNLOAD_ITEM_REPORTS],
            },
			{
                description: 'Meus Relatórios',
                path: MENU_PATHS.MY_REPORTS,
                icon: 'fas fa-puzzle-piece',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_REPORTS],
            },
        ]
    },
    {
        id: 'administrative',
        description: 'Configurações',
        path: '/administrative',
        icon: 'fas fa-cog',
        permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_SEGMENTS,
                    QualityPermissions.CREATE_SEGMENTS,
                    QualityPermissions.EDIT_SEGMENTS,
                    QualityPermissions.CREATE_OPERATIONS,
                    QualityPermissions.EDIT_OPERATIONS,
                    QualityPermissions.VIEW_OPERATIONS,
                    QualityPermissions.CREATE_CHECKLISTS,
                    QualityPermissions.EDIT_CHECKLISTS,
                    QualityPermissions.VIEW_CHECKLISTS,
                    QualityPermissions.CREATE_TRAINING,
                    QualityPermissions.EDIT_JUSTIFICATIONS,
                    QualityPermissions.VIEW_JUSTIFICATIONS,
                    QualityPermissions.CREATE_JUSTIFICATIONS],
        closed: false,
        submenu: [
            {
                description: 'Segmentos e Checklists',
                path: MENU_PATHS.SEGMENTS,
                icon: 'fas fa-industry',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_SEGMENTS,
                    QualityPermissions.CREATE_SEGMENTS,
                    QualityPermissions.EDIT_SEGMENTS,
                    QualityPermissions.CREATE_OPERATIONS,
                    QualityPermissions.EDIT_OPERATIONS,
                    QualityPermissions.VIEW_OPERATIONS,
                    QualityPermissions.CREATE_CHECKLISTS,
                    QualityPermissions.EDIT_CHECKLISTS,
                    QualityPermissions.VIEW_CHECKLISTS]
            },
            {
                description: 'Justificativas',
                path: MENU_PATHS.JUSTIFICATIONS,
                icon: 'fas fa-clipboard-list',
                permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_JUSTIFICATIONS,
                    QualityPermissions.CREATE_JUSTIFICATIONS,
                    QualityPermissions.EDIT_JUSTIFICATIONS]
            },
	        {
		        description: 'Fluxos',
		        path: MENU_PATHS.FLOWS,
		        icon: 'fas fa-clipboard-list',
		        permissions: [QualityPermissions.ALL_PERMISSIONS,
                    QualityPermissions.VIEW_FLOWS,
                    QualityPermissions.CREATE_FLOWS,
                    QualityPermissions.EDIT_FLOWS]
	        },
	        {
		        description: 'Relatórios PowerBI',
		        path: '/pbi-reports',
		        icon: 'fas fa-chart-pie',
		        permissions: [QualityPermissions.ALL_PERMISSIONS]
	        },
            {
                description: 'Treinamentos',
                path: null,
                icon: 'fas fa-clipboard-list',
	            action: 'trainingAction',
                permissions: [QualityPermissions.ALL_PERMISSIONS, QualityPermissions.CREATE_TRAINING]
            },
            {
                description: 'Gen AI',
		        path: MENU_PATHS.GEN_AI_CONFIGURATION,
		        icon: 'fas fa-clipboard-list',
		        permissions: [QualityPermissions.ALL_PERMISSIONS]
            }
	        /*{
				description: 'Integrações',
		        path: MENU_PATHS.INTEGRATIONS,
		        icon: '',
		        permissions: [QualityPermissions.ALL_PERMISSIONS]
	        }*/
        ]
    },
];
