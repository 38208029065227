import { Directive, ElementRef, OnDestroy, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Optional()
@Directive({
  selector: '.form-control[formControlName]'
})
export class FormErrorsDirective implements OnInit, OnDestroy {
  private subscription = new Subscription();

  constructor(private el: ElementRef<any>, private control: NgControl) {
  }

  ngOnInit() {
    this.subscription.add(this.control.statusChanges.subscribe((valid: string )=> {
      if (valid === 'VALID') {
        this.el.nativeElement.previousElementSibling?.classList.remove('ng-invalid');
      } else {
        this.el.nativeElement.previousElementSibling?.classList.add('ng-invalid');
      }
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}