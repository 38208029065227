import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreateChecklistService } from '@app/services';
import { QualityPermissions } from "@app/models";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: 'app-create-checklist',
  templateUrl: './create-checklist.component.html',
  styleUrls: ['./create-checklist.component.scss']
})
export class CreateChecklistComponent implements OnInit, OnDestroy {
  currentComponent$: Observable<any>;
  subs = new Subscription();

  steps: Step[] = [];

  automaticSteps: Step[] = [
	  {label: 'Detalhes do checklist', componentPromise: import(`./checklist-step-one/checklist-step-one.component`)},
	  {label: 'Selecionar itens', componentPromise: import(`./checklist-automatic-step-two/checklist-automatic-step-two.component`)},
	  {label: 'Atribuir peso', componentPromise: import(`./checklist-automatic-step-three/checklist-automatic-step-three.component`)},
  ];

  automaticInternalSteps: Step[] = [
	  {label: 'Detalhes do checklist', componentPromise: import(`./checklist-step-one/checklist-step-one.component`)},
	  {label: 'Metadados do checklist', componentPromise: import(`./checklist-metadata/checklist-metadata.component`)},
	  {label: 'Regras de efetividade', componentPromise: import(`./checklist-automatic-effective-rules/checklist-automatic-effective-rules.component`)},
	  {label: 'Selecionar itens', componentPromise: import(`./checklist-automatic-step-two/checklist-automatic-step-two.component`)},
	  {label: 'Atribuir peso', componentPromise: import(`./checklist-automatic-step-three/checklist-automatic-step-three.component`)},
  ];

  manualSteps: Step[] = [
    {label: 'Detalhes do checklist', componentPromise: import(`./checklist-step-one/checklist-step-one.component`)},
    {label: 'Metadados do checklist', componentPromise: import(`./checklist-metadata/checklist-metadata.component`)},
    {label: 'Selecionar itens', componentPromise: import(`./checklist-manual-step-two/checklist-manual-step-two.component`)}
  ];

  constructor(public createChecklistService: CreateChecklistService,
			  public ngxPermissionsService: NgxPermissionsService,
			  public modal: NgbModal) { }

  ngOnInit() {

	  this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
		  data => {
			  if(data) {
				  this.steps = this.automaticInternalSteps;
			  } else {
				  this.steps = this.automaticSteps;
			  }

			  this.subs.add(this.createChecklistService._currentStep.asObservable().subscribe((step) => {
				  this.currentComponent$ = this.getComponent(this.steps[step].componentPromise);
			  }));

			  this.subs.add(this.createChecklistService.changeCreationMode$.subscribe((stepMode: "AUTOMATIC" | "MANUAL") => {
				  if(stepMode == 'AUTOMATIC') {
					  this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
						  data => {
							  if(data) {
								  this.steps = this.automaticInternalSteps;
							  } else {
								  this.steps = this.automaticSteps;
							  }
						  });
				  } else {
					  this.steps = this.manualSteps;
				  }
			  }));
		  });
  }

  getComponent(componentPromise: Promise<any>) {
    return from(componentPromise).pipe(map(component => Object.values(component)[0]));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.createChecklistService.currentStep = 0;
  }
}

interface Step {
  label: string;
  componentPromise: Promise<any>;
}
