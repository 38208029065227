<div class="filter" [ngClass]="{'hide': !showFilter}">
	<div class="filter-header">
		<h5>Configurações</h5>
		<button class="btn btn-link" (click)="close()">
			<i class="fa fa-times"></i>
		</button>
	</div>

	<form [formGroup]="filters" class="filter-body">
		<ng-container *ngIf="hasFilter('aiModelCode')">
			<div class="filter-group pt-2" *ngxPermissionsOnly="[ qualityPermissions.ALL_PERMISSIONS, qualityPermissions.GEN_AI_CHAT_CONFIGURATION ]">
				<h6>Modelo de IA</h6>
				<evo-select>
					<ng-select formControlName="aiModelCode"
					           bindLabel="label"
					           bindValue="value"
					           [items]="aiModels"
					           placeholder="Selecione um Modelo">
						<ng-template ng-option-tmp ng-label-tmp let-item="item">
							{{ item.label }}
						</ng-template>
					</ng-select>
				</evo-select>
			</div>
		</ng-container>
		<ng-container *ngIf="hasFilter('temperature')">
			<div class="filter-group pt-2" *ngxPermissionsOnly="[ qualityPermissions.ALL_PERMISSIONS, qualityPermissions.GEN_AI_CHAT_CONFIGURATION ]">
				<h6>Temperatura</h6>
				<div class="d-flex justify-content-between" style="margin-bottom: -15px">
					<span class="text-muted">Pouca criatividade</span>
					<span class="text-muted">Muita criatividade</span>
				</div>
				<mat-slider formControlName="temperature" class="w-100" [max]="1" [min]="0" [step]="0.1" color="primary">
					<input matSliderThumb #slider>
				</mat-slider>
			</div>
		</ng-container>

		<ng-container *ngIf="hasFilter('topP')">
			<div class="filter-group" *ngxPermissionsOnly="[ qualityPermissions.INTERNAL ]">
				<h6>Top P - {{ topP.value || 0 }}</h6>
				<mat-slider formControlName="topP" value="1" class="w-100" [max]="1" [min]="0" [step]="0.1" color="primary" #topP style="margin-top: -15px;">
					<input matSliderThumb #slider>
				</mat-slider>
			</div>
		</ng-container>

		<h5 *ngIf="hasFilter('segments') || hasFilter('contactDate') || hasFilter('metadata')">Filtros</h5>

		<div class="filter-group" *ngIf="hasFilter('segments')">
			<h6>Segmento</h6>
			<evo-select iconUnicode="f03a">
				<ng-select  formControlName="segments"
				            [multiple]="true"
				            [items]="segments"
				            bindValue="id"
				            bindLabel="name"
				            (clear)="canSearchMetadata.next(false)"
				            placeholder="Selecione um segmento">
					<ng-template ng-header-tmp>
						<div><span class="btn btn-link" (click)="onSelectSegmentsAll()">Selecionar Todos</span></div>
					</ng-template>
					<ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
						<div class="ng-value d-flex" style="max-width: 32%" container="body"
						     placement="top" [ngbTooltip]="getItemLabelByValue(segments, item)"
						     *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
							<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
							<span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(segments, item)}}</span>
						</div>
						<div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
							<span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
						</div>
					</ng-template>
				</ng-select>
			</evo-select>
		</div>

		<div class="filter-group" *ngIf="hasFilter('operations')">
			<div class="d-flex align-items-center">
				<h6>Operação</h6>
				<div style="margin-bottom: 7px; margin-left: 5px">
					<span *ngIf="operationIsLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
				</div>
			</div>
			<evo-select iconUnicode="f095">
				<ng-select  formControlName="operations"
				            [multiple]="true"
				            placeholder="Selecione uma operação"
				            [items]="operations"
				            (clear)="canSearchMetadata.next(false)"
				            bindValue="id"
				            bindLabel="name">
					<ng-template ng-header-tmp>
						<div><span class="btn btn-link" (click)="onSelectOperationsAll()">Selecionar Todos</span></div>
					</ng-template>
					<ng-template ng-multi-label-tmp let-selecteditems="items" let-clear="clear">
						<div class="ng-value d-flex" style="max-width: 32%" container="body"
						     placement="top" [ngbTooltip]="getItemLabelByValue(operations, item)"
						     *ngFor="let item of selecteditems | slice:0:selectMultipleMaxLabels">
							<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
							<span class="ng-value-label item-label" style="font-size: 0.9em">{{getItemLabelByValue(operations, item)}}</span>
						</div>
						<div class="ng-value" *ngIf="selecteditems.length > selectMultipleMaxLabels">
							<span class="ng-value-label">+{{selecteditems.length - selectMultipleMaxLabels}}</span>
						</div>
					</ng-template>
				</ng-select>
			</evo-select>
		</div>

		<div class="filter-group" *ngIf="hasFilter('contactDate')">
			<h6>Data de Contato</h6>
			<datepicker-range-popup formControlName="contactDate"></datepicker-range-popup>
		</div>

		<!-- Metadata -->
		<ng-container *ngIf="hasFilter('metadata')">
			<ng-container *ngFor="let innerTag of getTags(); let i = index">
				<div class="row metadata-filter" formGroupName="metadata">
					<div class="col filter-group">
						<h6>{{ innerTag }}</h6>
						<evo-select>
							<ng-select [formControlName]="innerTag"
							           [items]="parsedMetadata[innerTag]"
							           placeholder="Selecione um item"
							           [notFoundText]="searchMetadata$.value ? 'Nenhum metadado encontrado' : 'Pesquise um metadado'"
							           (clear)="parsedMetadata[innerTag] = []; searchMetadataKey$.next(innerTag); searchMetadata$.next(null); metadataLastPage[searchMetadataKey$.value] = true;"
							           (search)="searchMetadata(innerTag, $event.term)"
							           [tooltip]="!canSearchMetadata.value ? 'Selecione segmentos e operações para filtrar metadados.' : ''">
								<ng-template ng-option-tmp ng-label-tmp let-item="item">
									{{ item }}
								</ng-template>
								<ng-template ng-footer-tmp *ngIf="!metadataLastPage[searchMetadataKey$.value]">
									<button class="btn btn-link" (click)="nextMetadataBatch()">Listar mais itens</button>
								</ng-template>
							</ng-select>
						</evo-select>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</form>
</div>
