import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { BucketService, InteractionService, ToastService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-replica-accepted-button',
  templateUrl: './replica-accepted-button.component.html',
  styleUrls: ['./replica-accepted-button.component.scss']
})
export class ReplicaAcceptedButtonComponent extends InteractionContestationButton implements OnInit {

  constructor(modalService: NgbModal, protected activeModal: NgbActiveModal, toastService: ToastService, formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
	  this.contestationForm = this.formBuilder.group({
		  contestation: [null],
		  replicaAcceptComment: ["Réplica procedente.", Validators.required]
	  });
  }

  acceptReplica() {
    this.item.status = {id: "REPLICA_ACCEPTED"};
    this.item.answer = {id: "CONFORMING", name: "Conforme"};

    this.contestationForm.get('contestation').patchValue(this.contestationForm.get('replicaAcceptComment').value);
    this.saveContestation('REPLICA_ACCEPTED', 'Réplica aceita com sucesso.');
  }

}
