import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrganizationService } from './organization.service';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private userService: UserService, private organizationService: OrganizationService, private router: Router) { }

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.url.includes(environment.apiUrl) &&
			!req.url.includes(environment.accountUrl) &&
			!req.url.includes(environment.integrationUrl) &&
			!req.url.includes(environment.genAiUrl)) return next.handle(req);

		if(this.userService.jwtToken) {
			req = req.clone({
				headers: req.headers.append('Authorization',`Bearer ${this.userService.jwtToken}`)
			});
		}

		if(this.organizationService.currentOrganization.id) {
			if(!req.url.includes(environment.genAiUrl)) {
				req = req.clone({
					headers: req.headers.append('organization', this.organizationService.currentOrganization.alias)
				});
			} else {
				req = req.clone({
					headers: req.headers.append('organizationAlias', this.organizationService.currentOrganization.alias)
				});
			}
		}

		return next.handle(req);
	}

}
