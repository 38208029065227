import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Contestation } from '@app/models';
import { ReportByFilter } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import * as _ from 'lodash';
@Injectable({ providedIn: 'root' })
export class ContestationService extends BaseServiceClass<Contestation>{

  constructor(protected httpService: HttpClient) {
    super(httpService, '/contestations');
  }

  getInteractionReport(reportByFilter: ReportByFilter) {
    // let options = { params: <any> _.omitBy(reportByFilter, _.isNil)};
    return this.httpService.post<any>(`${this.entity_url}/report`, reportByFilter);
  }

  contestationReportDownload(reportByFilter: ReportByFilter, type: string) {
    let options = { responseType: 'blob' as 'json'};
    return this.httpService.post<any>(`${this.entity_url}/report/download/${type}`, reportByFilter, options);
  }
}
