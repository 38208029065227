import { Customer } from "./customer";
import { Deserializable } from "./deserializable";

export class IntervalDays implements Deserializable {
    id?: number;
    customer?: Customer;
    days?: number;
    description?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}
