<div @fadeInOut>
	<div id="body" class="body" style="position: relative;">
		<evo-stepper-title title="Detalhe da interação" subtitle="Adicione informações sobre a interação a ser importada"></evo-stepper-title>

		<form [formGroup]="interactionForm">
			<div class="content">
				<div class="row">
					<div class="col-6 form-group">
						<label for="segment">Segmento</label>
						<evo-select>
							<ng-select  formControlName="segmentId"
							            [ngClass]="{ 'is-invalid': (segmentId.dirty || segmentId.touched) && segmentId.errors }"
							            placeholder="Selecione"
							            [items]="segments"
							            (change)="segmentSelected($event)"
							            bindValue="id"
							            bindLabel="name">
							</ng-select>
						</evo-select>
						<div *ngIf="(segmentId.dirty || segmentId.touched) && segmentId.errors"
						     class="invalid-feedback">
							<div *ngIf="segmentId.errors">Segmento é obrigatório</div>
						</div>
					</div>
					<div class="col-6 form-group">
						<label for="operationId">Operação</label>
						<evo-select>
							<ng-select  formControlName="operationId"
							            [ngClass]="{ 'is-invalid': (operationId.dirty || operationId.touched) && operationId.errors }"
							            [items]="operations"
							            bindValue="id"
							            bindLabel="name"
							            placeholder="Selecione">
							</ng-select>
						</evo-select>
						<div *ngIf="(operationId.dirty || operationId.touched) && operationId.errors"
						     class="invalid-feedback">
							<div *ngIf="operationId.errors">Operação é obrigatório</div>
						</div>
					</div>
					<div class="col-12 form-group">
						<label for="checklist">Checklist</label>
						<evo-select>
							<ng-select  formControlName="checklistId"
							            [ngClass]="{ 'is-invalid': (checklistId.dirty || checklistId.touched) && checklistId.errors }"
							            [items]="segmentChecklists"
							            bindValue="id"
							            bindLabel="name"
							            placeholder="Selecione">
								<ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index">
									<span>{{ item.name }}</span>
									<span class="ml-2 badge" [ngClass]="item.checkListType == 'AUTOMATIC' ? 'badge-automatic' : 'badge-manual'">
										{{ item.checkListType == 'AUTOMATIC' ? 'Automático' : 'Manual' }}
									</span>
								</ng-template>
							</ng-select>
						</evo-select>
					</div>
				</div>

				<div class="row">
					<div class="col-12 form-group">
						<div class="d-flex justify-content-between">
							<label for="user">Operador</label>
						</div>
						<evo-select>
							<input class="form-control" formControlName="agentName" placeholder="Informe o operador"
							       [ngClass]="{ 'is-invalid': (agentName.dirty || agentName.touched) && agentName.errors }"/>
						</evo-select>
						<div *ngIf="(agentName.dirty || agentName.touched) && agentName.errors" class="invalid-feedback">
							<div *ngIf="agentName.errors">Operador é obrigatório</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-6 form-group">
						<label for="segment">Data de Contato*</label>
						<div class="input-group">
							<input class="form-control" name="d2" formControlName="contactedAt" ngbDatepicker
							       #datepicker="ngbDatepicker" placeholder="Escolher nova data">
							<div class="input-group-append">
								<button class="btn btn-outline-primary btn-sm" (click)="datepicker.toggle()" type="button">
									<i class="far fa-calendar-alt"></i></button>
							</div>
						</div>
						<div *ngIf="(contactedAt.dirty || contactedAt.touched) && contactedAt.errors"
						     class="invalid-feedback">
							<div *ngIf="contactedAt.errors">Data de Contato é obrigatório</div>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="row">
			<div class="col-12 form-group">
				<label for="file">Arquivo*
					<i class="fas fa-question" container="body" placement="bottom" ngbTooltip="Tamanho máximo permitido é de 1GB"></i>
				</label>
				<evo-upload-file-input name="file" style="width: 100%"
				                       [(ngModel)]="file"
				                       [disabled]="file.length > 0"
				                       [accept]="isChecklistAutomatic ? automaticMimetypes : manualMimetypes"
				                       [violatedRules]="violatedRulesFileUpload"
				                       [isProgressBarEnable]="true"
				                       [uploadProgress]="[uploadProgress]"
				                       fileLegend="Tamanho máximo permitido é de 1GB"
				                       [isLoading]="importDataService.isLoading"
				                       [isMultiple]="false"
				                       (cancelUpload)="cancelUpload()"
									   (existentFile)="isFileExistent($event)">
				</evo-upload-file-input>
			</div>
		</div>

	</div>
	<evo-stepper-footer [isLoading]="isLoading" [isFormInvalid]="(interactionForm.invalid || file.length == 0 || existentFile)" (onPrev)="onPrev()" (onNext)="onNext()"></evo-stepper-footer>
</div>
