import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceClass } from './baseService.service';
import { Observable } from "rxjs";
import { GenAiFlag } from "../_models/gen-ai-flag";

@Injectable({
	providedIn: 'root'
})
export class ExternalSpeechService extends BaseServiceClass<any> {

	constructor(public httpClient: HttpClient) {
		super(httpClient, '/external/speech/');
	}

	public getSpeechInteractionById(id: number): Observable<any> {
		return this.httpClient.get(`${this.entity_url}interactions/${id}`);
	}
}
