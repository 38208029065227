import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { InteractionFilter } from '@app/models';
import { Interaction } from '@app/models';
import { InteractionStatus } from '@app/models';
import { PageResponse } from '@app/models';
import { ReportByFilter } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;
@Injectable({ providedIn: 'root' })
export class InteractionMetadataService extends BaseServiceClass<Interaction> {

  constructor(protected httpService: HttpClient) {
    super(httpService, '/interactionsMetadata');
  }

  public getInteractionMetadata(interactionsMetadataFilter?: { segmentId: number[], operationId: number[] }): Observable<any> {

    let options = { params: <any> _.omitBy(interactionsMetadataFilter, _.isNil)};
    return this.httpService.get<any>(`${this.entity_url}`, options);
  }
  
  public getInteractionMetadataInitialValues(interactionMetadataFilter?: { segmentId: number[], operationId: number[] }): Observable<any> {
    let options = { params: <any> _.omitBy(interactionMetadataFilter, _.isNil)};
    return this.httpService.get<any>(`${this.entity_url}/initialValues`, options);
  }

  public getInteractionMetadataValues(interactionMetadataFilter?: { segmentId?: number[], operationId?: number[], metadataKey: string, metadataValue: string, page: number, linesPerPage: number }): Observable<any> {
    let options = { params: <any> _.omitBy(interactionMetadataFilter, _.isNil)};
    return this.httpService.get<any>(`${this.entity_url}/values`, options);
  }
}
