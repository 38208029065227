import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { AgentCacheService } from './agent-cache.service';
import {InteractionServiceV2, UserService} from "@app/services";
import { AgentService } from 'app/_services/agent.service';

@Component({
	selector: 'app-agent-filter',
	templateUrl: './agent-filter.component.html',
	styleUrls: ['./agent-filter.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => AgentFilterComponent),
		multi: true
	}]
})
export class AgentFilterComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input()
	users: any[] = [];

	search$ = new BehaviorSubject('');
	currOffset = 0;
	isLastPage = true;

	selectMultipleMaxLabels = 2;

	onChange: any = () => {
	}
	onTouch: any = () => {
	}

	@ViewChild('select', {static: true}) select: NgSelectComponent;

	@Input()
	public showLabel: boolean = true;

	@Input()
	public placeholder: string = 'Pesquise por um operador';

	@Input()
	public useSegmentsOperations: boolean = false;

	@Input()
	public segmentIds: number[] = [];

	@Input()
	public operationIds: number[] = [];

	constructor(private userService: UserService,
		private agentCache: AgentCacheService,
		private interactionService: InteractionServiceV2,
		private agentService: AgentService) {
	}

	ngOnInit() {
		this.search$.asObservable().pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			tap(() => this.currOffset = 0),
			switchMap((searchTerm: string) => {
				let filter: any = {
					name: searchTerm,
					number: searchTerm,
					linesPerPage: 10,
					page: 0,
					orderBy: 'name',
					direction: 'ASC'
				}

				if(this.useSegmentsOperations) {
					filter.segmentIds = this.segmentIds;
					filter.operationIds = this.operationIds;
				}

				return this.agentService.filter(filter);
			})
		).subscribe((users) => {
			const currUsers = new Set(this.users.map(user => user.name))
			this.users = [...this.users, ...users.content.filter(user => !currUsers.has(user.name))];
			this.currOffset = this.currOffset + 1;
			this.isLastPage = users.last;

			this.users = this.users.sort((a, b) => {
				if(a.name && b.name) {
					return a.name.localeCompare(b.name);
				}
			});
		});

		this.initiateFilter();
	}

	private initiateFilter(): void {
		this.agentService.filter({
			name: '',
			number: '',
			linesPerPage: 10,
			segmentIds: this.useSegmentsOperations ? this.segmentIds : [],
			operationIds: this.useSegmentsOperations ? this.operationIds : [],
			page: 0,
			orderBy: 'name',
			direction: 'ASC'
		}).subscribe(data => {
			this.users = data.content;
		});
	}

	_value = new FormControl(null);
	set value(val: any) {
		if (val) {
			const names = val.map(agent => agent.name);
			this.agentCache.agents = names;
			this.agentCache.addAgentOptions(this.users);
			this._value.setValue(names);
			this.onChange(names);
			this.onTouch(names);
		} else if (val === null) {
			this._value.setValue(null);
			this.onChange(null);
			this.onTouch(null);
		}
	}

	get value() {
		return this._value;
	}

	writeValue(value) {
		if (value) {
			this.users = this.agentCache.agentOptions;
			const agents = new Set(this.agentCache.agents);
			this.value = this.agentCache.agentOptions.filter(agent => {
				return agents.has(agent.name);
			})
		} else {
			this.value = value;
		}
	}

	registerOnChange(fn: any) {
		this.onChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouch = fn;
	}

	nextBatch() {
		this.agentService.filter({
			name: this.search$.value,
			number: this.search$.value,
			linesPerPage: 10,
			page: this.currOffset,
			segmentIds: this.useSegmentsOperations ? this.segmentIds : [],
			operationIds: this.useSegmentsOperations ? this.operationIds : [],
			orderBy: 'name',
			direction: 'ASC'
		}).subscribe((data) => {
			this.users = [...this.users, ...data.content];
			this.currOffset = this.currOffset + 1;
			this.isLastPage = data.last;
		});

		/*this.userService.filter({
		  page: this.currOffset,
		  name: this.search$.value,
		  email: this.search$.value,
		  active: true,
		  linesPerPage: 10,
		  orderBy: 'name',
		  direction: 'ASC'
		}).subscribe((users) => {
		  this.users = [...this.users, ...users.content];
		  this.currOffset = this.currOffset + 1;
		  this.isLastPage = users.last;
		}); */
	}

	getItemLabelByValue(value) {
		const item = this.users.find(item => item.name == value.name);
		return item ? item.name : '';
	}

	ngOnDestroy() {
		if (!this.value || this.value.length === 0) {
			this.agentCache.agents = null;
			this.agentCache.agentOptions = [];
		}
	}

}
