<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <ng-content select="[body]"></ng-content>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="modal.dismiss()">Fechar</button>
    <button type="button" class="btn btn-primary" (click)="emitAction()">Finalizar</button>
</div>