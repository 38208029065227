import { Address } from './address';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { Organization } from './organization';
import { Segment } from './segment';

export class User implements Deserializable {
  id: number;
  name: string;
  email: string;
  username: string;
  cpfCnpj: string;
  type: any;
  token: string;
  refreshToken: string;
  tokenExpiry: number;

  changePassword: boolean;

  operations: Operation[];

  profiles: string[];
  customer: Customer;

  //new user dto
  operationsId: number[];
  customerId: number;
  address: Address;

  segmentOperations?: any[];

  userType: string;

	organizations: Organization[];

  segmentOperationIds: number[];

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }
}

export enum Profiles {
  ADMIN = 'Administrador',
  GERENTE_ATENDIMENTO = 'Gerente de Atendimento',
  GERENTE_QUALIDADE = 'Gerente de Qualidade',
  GERENTE_OPERACAO = 'Gerente de Operação',
  SUPERVISOR_QUALIDADE = 'Supervisor de Qualidade',
  SUPERVISOR_OPERACAO = 'Supervisor de Operação',
  OPERADOR = 'Operador',
  INSTRUTOR = 'Instrutor',
  MONITOR = 'Monitor',
  ANALISTA_MIS = 'Analista Mis',
  ANALISTA_ATENDIMENTO = 'Analista de Atendimento'
}
