<div class="checklist-item-group">
	<div class="checklist-item-header">
		<div class="info">
			<label>Grupo*</label>
			<!-- <input type="text" (focusout)="saveGroup()"
			       [(ngModel)]="group.name"
			       placeholder="Digite o nome do grupo"
			       [ngClass]="{ 'is-invalid': group.invalid }"
			       class="form-control"/> -->

			<evo-select>
				<ng-select  [ngClass]="{ 'is-invalid': group?.invalid }" (ngModelChange)="groupSelected($event)"
				            [items]="groups"
				            [(ngModel)]="group"
				            [addTag]="addTagPromise"
				            bindLabel="name"
				            [clearable]="false"
				            [addTagText]="'Criar grupo'"
				            [loading]="isLoading"
				            [disabled]="checklist.checkListType == 'AUTOMATIC' && disabled"
							[placeholder]="'Selecione ou crie um grupo'">
				</ng-select>
			</evo-select>
		</div>

		<div class="d-flex">
			<div class="header-action" (click)="showBody = !showBody">
				<i class="fas fa-chevron-up" [ngClass]="{'fa-chevron-up': showBody, 'fa-chevron-down' : !showBody}"></i>
			</div>

			<div class="header-action" evolloConfirmAction title="Remover Grupo" text="Deseja remover o grupo?" *ngIf="showRemove && group.id && checklist.checkListType == 'MANUAL'"
			     (confirm)="removeGroup()">
				<i class="fas fa-times"></i>
			</div>

			<div class="header-action" *ngIf="showRemove && !group.id" (click)="removeGroup()">
				<i class="fas fa-trash"></i>
			</div>
		</div>
	</div>

	<div class="mb-3" *ngIf="showBody">
		<span>Itens</span>
	</div>

	<ng-content select="[slot=item-body]" *ngIf="showBody"></ng-content>
</div>
