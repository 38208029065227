import { EvoModel } from '@app/models';
import { Organization } from '@app/models';
import { Permission } from '@app/models';


export class Role extends EvoModel {

	public id: string;
	public code: number;
	public name: string;
	public organization: Organization;
	public permissions: Permission[];
	public createdAt: any;
	public updatedAt: any;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}
