import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

const API_URL = environment.apiUrl;

@Injectable({ providedIn: 'root' })
export class SpeedService extends BaseServiceClass<any> {

	public downloadStatus: any[] = [];
	public periodicStatus: { start: number , end: number , time: number , speed: number , size: number }[] = [];

    constructor(protected httpService: HttpClient) {
        super(httpService, '/api/v1/connectionLogs');
    }

	public getDownloadUrl(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/download`, { responseType: 'text' });
	}

	public download(url: string): Observable<any> {
		return this.httpService.get(url, { reportProgress: true, observe: 'events', responseType: 'blob' }).pipe(this.doDownload(blob => null ));
	}

	public upload(value: any): Observable<any> {
		let  formData: FormData = new FormData();
		formData.append('file', value);
		return this.httpService.post(`${this.entity_url}/upload`, formData);
	}

	public ping(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/ping`);
	}

	private doDownload(saver?: (b: Blob | null) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
		this.periodicStatus = [];
		let start = new Date();

		return (source: Observable<HttpEvent<Blob>>) => source.pipe(
			scan((download: Download, event): Download => {
					if (this.isHttpProgressEvent(event)) {
						let loadMoment = new Date();

						let tempDiff = (loadMoment.getTime() - start.getTime()) / 1000; // segundos
						let tempSize = (event.loaded / 1000) / 1000;
						let tempSpeed = ((tempSize / tempDiff) * 10);

						this.periodicStatus = [ ...this.periodicStatus,
							{
								start: start.getTime(),
								end: loadMoment.getTime(),
								time: tempDiff,
								speed: tempSpeed,
								size: tempSize
							}
						];

						return {
							progress: event.total ? Math.round((100 * event.loaded) / event.total) : download.progress,
							state: "IN_PROGRESS",
							content: null
						};
					}
					if (this.isHttpResponse(event)) {
						let end = new Date();
						let diff = (end.getTime() - start.getTime()) / 1000; // segundos
						let speed: any = 0;
						let size = 0;

						if(event.body) {
							size = (event.body.size / 1000) / 1000;
							speed = Number(((size / diff) * 100).toFixed(2));
						}

						this.downloadStatus = [ ...this.downloadStatus, {start: start.getTime(), end: end.getTime(), time: diff.toFixed(2) + 's', speed, size: size.toFixed(2) + 'mb' } ];
						return { progress: 100, state: "DONE", content: event.body };
					}
					return download;
				},
				{ state: "PENDING", progress: 0, content: null }),
			distinctUntilChanged((a, b) => a.state === b.state
				&& a.progress === b.progress
				&& a.content === b.content
			)
		);
	}

	private isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
		return (
			event.type === HttpEventType.DownloadProgress ||
			event.type === HttpEventType.UploadProgress
		);
	}

	private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
		return event.type === HttpEventType.Response;
	}

	public postConnectionLog(ping: number, download: number, upload: number): Observable<any> {
		localStorage.setItem('connectionLog', new Date().toISOString());
		localStorage.setItem('ping', ping.toString());
		localStorage.setItem('download', download.toString());
		localStorage.setItem('upload', upload.toString());


		let obj = {
			verifiedAt: new Date().toISOString(),
			ping,
			jitter: 0,
			download,
			upload
		}

		return this.httpService.post(`${this.entity_url}`, obj);
	}

}

export interface Download {
	content: Blob | null;
	progress: number;
	state: "PENDING" | "IN_PROGRESS" | "DONE";
}
