import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { BucketService, InteractionService } from '@app/services';
import { Justification } from '@app/models';
import { Interaction } from '@app/models';
import { MonitorInteractionItem } from '@app/models';
import { MonitorInteractionItemService } from '@app/services';
import { SystemMessages, ToastService } from '@app/services';
import { UserService } from '@app/services';
import { User } from '@app/models';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-change-answer-button',
  templateUrl: './change-answer-button.component.html',
  styleUrls: ['./change-answer-button.component.scss']
})
export class ChangeAnswerButtonComponent extends InteractionContestationButton implements OnInit {
  @Input() justifications: Justification[];
  @Input() interaction: Interaction;
  currentItem: MonitorInteractionItem;
  user: User;
  answerOptions = [
    {
      id: "CONFORMING",
      name: "Conforme"
    },
    {
      id: "NONCONFORMING",
      name: "Não Conforme"
    },
    {
      id: "NOT_APPLICABLE",
      name: "Não se aplica"
    }
  ]
  constructor(modalService: NgbModal,
			  protected activeModal: NgbActiveModal,
              private monitorInteractionItemService: MonitorInteractionItemService,
              toastService: ToastService,
              formBuilder: FormBuilder,
              public interactionService: InteractionService,
              private userService: UserService) {
    super(modalService, activeModal, toastService, formBuilder);
    this.currentItem = Object.assign({}, this.item);
  }

  ngOnInit(): void {
    this.userService.getUserByAccountId(this.currentUser.id).subscribe(response => {
      this.user = response;
    }, error => {
      console.error(error);
      this.toastService.showWarning(SystemMessages.UNEXPECTED_ERROR);
    });
  }

  openChangeAnswerModal() {
    if(!this.currentItem.justification) this.currentItem.justification = new Justification();
    this.openModal();
  }

  saveAnswer(){
    if (!this.currentItem.answer) return null;

    this.isLoading = true;
    this.item.userMonitor = this.user;
    this.item.answer = this.currentItem.answer;
    this.monitorInteractionItemService.patch(this.item).subscribe(item =>{
		this.modalRef.close();
        // this.activeModal.dismiss();
        this.toastService.showSuccess("Conformidade salva com sucesso.");
        this.actionCompleted.emit({index: this.index, item});
    },
    error => {console.debug(error); this.toastService.showDanger("Erro ao salvar nova resposta")}).add(() => {this.isLoading = false;});
}

}
