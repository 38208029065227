import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BaseServiceClass } from '../baseService.service';
import { Observable } from 'rxjs';
import { MonitorInteractionUpdateDTO } from '@app/models';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class InteractionServiceV3 extends BaseServiceClass<any> {

	public onPageReset: EventEmitter<void> = new EventEmitter();

	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v3/interactions');
	}


	public interactionsDiscard(params: any): Observable<any> {
		let objClone = Object.assign({}, params);
		let filteredParams = _.omitBy(objClone, _.isNil);
		return this.httpService.post(`${this.entity_url}/discard`, filteredParams);
	}

	public updateMonitorInteractionItem(monitorInteractionUpdateDTO: MonitorInteractionUpdateDTO): Observable<any> {
    return this.httpService.patch(`${this.entity_url}/${monitorInteractionUpdateDTO.interactionId}/interactionItens`, monitorInteractionUpdateDTO);
}
}
