<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.className"
  [autohide]="true"
  [delay]="toast.delayTime"
  (hidden)="toastService.removeToast(toast)">
  <div class="toast-template">
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
      <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>
    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
  </div>
  <div class="toast-actions">
    <div class="undo-button"
         *ngIf="toast.allowUndoAction"
         (click)="toastService.cancelToastRequest(toast)">
      Desfazer
    </div>
    <div class="close-button"
         *ngIf="!toast.hideCloseButton"
         (click)="toastService.removeToast(toast)">
      <i class="fas fa-times"></i>
    </div>
  </div>
</ngb-toast>
