<div *ngIf="interaction && checklist" id="view">
    <div id="main">
		<div class="d-flex justify-content-between align-items-center alert-danger p-3" style="width: 100%;" *ngIf="interaction.inAnalysis && !sameUserAnalysis">
			<span style="font-size: 14px;">Interação está sendo analisada pelo usuário <b>{{ interaction.lastAnalysisHistory?.startedBy?.name }}</b>. Não é possível fazer alterações.</span>

			<ng-container *ngIf="isAdmin && isInternal">
				<button class="btn btn-light" [disabled]="isLoading" (click)="unblockInteraction()">
					<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
					Desbloquear
				</button>
			</ng-container>
		</div>

		<div class="d-flex justify-content-between align-items-center alert-warning p-3" style="width: 100%;" *ngIf="interaction.inAnalysis && sameUserAnalysis">
			<span style="font-size: 14px;">Interação está em análise, outros usuários não podem fazer alterações.</span>

			<button class="btn btn-light" (click)="unblockInteraction()" [disabled]="isLoading">
				<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
				Desbloquear
			</button>
		</div>

        <div class="header" style="height: 8vh">
            <div class="row align-items-center">
                <div class="col-sm">
                    <h4 class="page-header-title">Interações | <strong>Nº {{interaction.id}}</strong></h4>
                </div>
                <div class="col-sm-auto" *ngIf="!hasNoPermissions">
                    <div class="d-flex align-items-center">
                        <span class="mr-1">Tempo de monitoria</span>
                        <countup-timer [countUpTimerConfig]="timerValue"></countup-timer>
                    </div>
                </div>
            </div>
	        <div class="row" *ngIf="hasNoPermissions">
		        <div class="col">
			        <span class="badge badge-soft-info p-2">Apenas visualização</span>
		        </div>
	        </div>
        </div>
        <div class="items" *ngFor="let group of checklistGrouped; let i = index">
            <ngb-accordion #a="ngbAccordion" [activeIds]="getGroupElementsIds()" class="no-padding">
                <ngb-panel [id]="'group'+i">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div class="group-header d-flex align-items-center w-100">
                            <h5>{{group[0].group.name}}</h5>
                            <button class="btn ml-auto" ngbPanelToggle>
                                <i class="fas text-muted" style="font-size: 1.5em"
                                    [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="item" *ngFor="let item of group; let j = index">
                            <ngb-accordion #acc="ngbAccordion" [class]="j === group.length - 1 && 'last-accordion'" activeIds="">
                                <ngb-panel [id]="'group'+i+'item'+j">
                                    <ng-template ngbPanelHeader let-itemopened="opened">
                                        <div class="d-flex align-items-start">
                                            <span class="badge badge-light br-50 mr-1">{{j+1}}</span>
                                            <div class="">
	                                            <span style="font-size: 14px;" class="ml-2 mr-2">{{item.name}}</span>
	                                            <p class="mx-2">{{ item.descricao }}</p>
                                            </div>
                                            <span>
                                                <i class="fas fa-star"
                                                    [ngClass]="item.answer.id == 'NONCONFORMING'? 'default':'warning'"
                                                    placement="bottom" ngbTooltip="Peso"></i> {{item.peso}}
                                            </span>
                                            <evo-select class="ml-auto" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.EVALUATE_INTERACTIONS]">
                                                <ng-select  class="ml-auto"
                                                            style="width: 100%"
                                                            placeholder="Resposta"
                                                            [clearable]="false"
                                                            appendTo="body"
                                                            bindLabel="label"
                                                            bindValue="value"
                                                            [ngClass]="{ 'is-invalid': (!item.answer || item.answer == '') && item.answerTouched }"
                                                            [(ngModel)]="item.answer"
                                                            [items]="[{value: 'CONFORMING', label: 'Conforme'}, {value: 'NONCONFORMING', label: 'Não Conforme'}, {value: 'NOT_APPLICABLE', label: 'Não se aplica'}]"
                                                            (blur)="item.answerTouched = true" (change)="validateConforming($event, acc,'group'+i+'item'+j)">
                                                </ng-select>
                                            </evo-select>
                                            <button class="btn" ngbPanelToggle *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.EVALUATE_INTERACTIONS]">
                                                <span class="fa-stack" style="font-size: 0.6em; display: block;">
                                                    <i class="far fa-circle fa-stack-2x"></i>
                                                    <i class="fas fa-stack-1x"
                                                        [ngClass]="itemopened ? 'fa-minus' : 'fa-plus'"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="form-group">
	                                        <div class="d-flex justify-content-between">
		                                        <label for="explanation">Explicação</label>
		                                        <span >{{ item.explanation?.length || 0 }}/2000</span>
	                                        </div>
	                                        <!-- <input type="text" class="form-control" [(ngModel)]="item.explanation" /> -->
	                                        <textarea class="form-control" [(ngModel)]="item.explanation" rows="5" style="resize: none"
	                                                  [ngClass]="{ 'is-invalid': item.explanation?.length > 2000 }"></textarea>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <div class="row mt-3 pb-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.EVALUATE_INTERACTIONS]">
            <div class="col d-flex text-right" *ngIf="(interaction.inAnalysis && sameUserAnalysis) || !interaction.inAnalysis">
                <button [disabled]="loading" class="btn btn-outline-danger mr-3" (click)="discardMonitor()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Descartar Monitoria
                </button>
				<div class="flex-grow-1"></div>
                <button [disabled]="loading || isExplanationInvalid()" class="btn btn-primary" (click)="onSubmit()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Finalizar
                </button>
            </div>
        </div>
    </div>
    <div id="side">
	    <div class="interaction-close-modal">
		    <button class="btn btn-link" (click)="closeHandler()">
			    <i class="fa fa-times"></i>
		    </button>
	    </div>

        <ngb-accordion #a="ngbAccordion" activeIds="detalhes,audio,cliente,contrato,anexos">
            <ngb-panel id="detalhes">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="group-header d-flex align-items-center w-100">
                        <h6>Detalhes</h6>
                        <button class="btn ml-auto" ngbPanelToggle>
                            <i class="fas text-muted" style="font-size: 1.5em"
                                [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                        </button>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
	                <div class="detail-item">
		                <div class="label">Contato</div>
		                <div class="value">{{interaction?.contactDate ? (interaction?.contactDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Operador</div>
		                <div class="value">{{interaction?.agent && interaction?.agent.name ? interaction?.agent.name : (interaction.agentName ? interaction.agentName : '-')}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Monitoria</div>
		                <div class="value">{{interaction?.monitorDate ? (interaction?.monitorDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Segmento</div>
		                <div class="value">{{interaction?.segment?.name ? interaction?.segment?.name : '-'}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Operação</div>
		                <div class="value">{{interaction?.operation?.name ? interaction?.operation?.name : '-'}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Monitor</div>
		                <div class="value">{{interaction?.userMonitor?.name ? interaction?.userMonitor?.name : '-'}}</div>
	                </div>
	                <div class="detail-item">
		                <div class="label">Prazo</div>
		                <div class="value">{{interaction?.deadLine ? (interaction?.deadLine | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
	                </div>
					<div class="detail-item" *ngFor="let metadata of interactionMetadata">
						<div class="label">
							<span [ngbTooltip]="metadata[0]">{{ metadata[0]?.length > 13 ? (metadata[0] | slice:0:13) + '...' : metadata[0] }}</span>
						</div>
						<div class="value">
							<span [ngbTooltip]="metadata[1]">{{ metadata[1]?.length > 13 ? (metadata[1] | slice:0:13) + '...' : metadata[1] }}</span>
						</div>
					</div>
                </ng-template>
            </ngb-panel>
	        <ng-container *ngIf="interaction.interactionCustomer">
		        <ngb-panel id="cliente" class="mt-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
			        <ng-template ngbPanelHeader let-opened="opened">
				        <div class="group-header d-flex align-items-center w-100">
					        <h6>Cliente</h6>
					        <button class="btn ml-auto" ngbPanelToggle>
						        <i class="fas text-muted" style="font-size: 1.5em"
						           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
					        </button>
				        </div>
			        </ng-template>
			        <ng-template ngbPanelContent *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.name" [ngbTooltip]="interaction?.interactionCustomer?.name">
					        <div class="label">Nome</div>
					        <div class="value">{{interaction?.interactionCustomer?.name }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.email" [ngbTooltip]="interaction?.interactionCustomer?.email">
					        <div class="label">E-mail</div>
					        <div class="value">{{interaction?.interactionCustomer?.email }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.taxId" [ngbTooltip]="interaction?.interactionCustomer?.taxId">
					        <div class="label">CPF/CNPJ</div>
					        <div class="value">{{interaction?.interactionCustomer?.taxId }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.state" [ngbTooltip]="interaction?.interactionCustomer?.state">
					        <div class="label">UF</div>
					        <div class="value">{{interaction?.interactionCustomer?.state }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.phoneNumber" [ngbTooltip]="interaction?.interactionCustomer?.phoneNumber">
					        <div class="label">Telefone</div>
					        <div class="value">{{interaction?.interactionCustomer?.phoneNumber }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.birthDate" [ngbTooltip]="interaction?.interactionCustomer?.birthDate">
					        <div class="label">Nascimento</div>
					        <div class="value">{{interaction?.interactionCustomer?.birthDate }}</div>
				        </div>
			        </ng-template>
		        </ngb-panel>
	        </ng-container>
			<ng-container *ngIf="interaction.interactionDebt">
		        <ngb-panel id="contrato" class="mt-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
			        <ng-template ngbPanelHeader let-opened="opened">
				        <div class="group-header d-flex align-items-center w-100">
					        <h6>Contrato</h6>
					        <button class="btn ml-auto" ngbPanelToggle>
						        <i class="fas text-muted" style="font-size: 1.5em"
						           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
					        </button>
				        </div>
			        </ng-template>
			        <ng-template ngbPanelContent *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.contractCode" [ngbTooltip]="interaction?.interactionDebt?.contractCode">
					        <div class="label">Código</div>
					        <div class="value">{{interaction?.interactionDebt?.contractCode }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.contactDateLimit" [ngbTooltip]="interaction?.interactionDebt?.contactDateLimit">
					        <div class="label">Data Inicial</div>
					        <div class="value">{{interaction?.interactionDebt?.contactDateLimit | date: 'dd/MM/yyyy' : '+1000' }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.payedAt" [ngbTooltip]="interaction?.interactionDebt?.payedAt">
					        <div class="label">Data da Baixa</div>
					        <div class="value">{{interaction?.interactionDebt?.payedAt | date: 'dd/MM/yyyy' : '+1000' }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.debtValue" [ngbTooltip]="interaction?.interactionDebt?.debtValue">
					        <div class="label">Valor</div>
					        <div class="value">{{interaction?.interactionDebt?.debtValue }}</div>
				        </div>
			        </ng-template>
		        </ngb-panel>
	        </ng-container>
	        <ngb-panel id="audio" class="mt-4">
		        <ng-template ngbPanelHeader let-opened="opened">
			        <div class="group-header d-flex align-items-center w-100">
				        <h6>{{ isAudio ? 'Áudio' : 'Arquivo'}}</h6>
				        <button class="btn ml-auto" ngbPanelToggle>
					        <i class="fas text-muted" style="font-size: 1.5em"
					           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
				        </button>
			        </div>
		        </ng-template>
		        <ng-template ngbPanelContent>
			        <div class="detail-item" *ngIf="isAudio">
				        <div class="label">Silêncio</div>
				        <div class="value">{{interaction?.silenceTime ? interaction.silenceTime : '-'}}</div>
			        </div>
			        <div class="detail-item" *ngIf="isAudio">
				        <div class="label">% Silêncio</div>
				        <div class="value">{{interaction?.silencePercent ? (interaction.silencePercent | number : '0.0-2') : '-'}}</div>
			        </div>
			        <div class="detail-item">
				        <div class="label">Nome do {{ isAudio ? 'Áudio' : 'Arquivo'}}</div>
				        <div class="value interaction-audio-name">
                      <span class="truncate-text" *ngIf="interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName)">
                        {{interaction.fileInfo.originalFileName || interaction.fileInfo.fileName}}
                      </span>
					        <span class="" *ngIf="!(interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName))">-</span>
					        <button class="btn"
					                (click)="copyToClipboard($event, interaction)"
					                placement="bottom"
					                ngbTooltip="Copiar nome do arquivo" style="padding: 5px 10px;">
						        <i class="far fa-copy"></i>
					        </button>
					        <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.AUDIO_DOWNLOAD]">
						        <button class="btn ml-0"  ngbTooltip="Download do Arquivo" (click)="downloadFile()" placement="bottom">
							        <i class="fas fa-download"></i>
						        </button>
					        </ng-template>

				        </div>
			        </div>
			        <div class="pt-4" *ngIf="interaction.fileInfo && isAudio">
				        <clp-audio-player #clpAudioPlayerComponent [fileInfoId]="interaction.fileInfo.id"></clp-audio-player>
			        </div>
					<div class="pt-4" *ngIf="interaction.transcription">
						<ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_TRANSCRIPTION]">
					        <div appBlockCopy>
						        {{interaction.transcription.transcriptionAll }}
					        </div>
				        </ng-template>
					</div>
		        </ng-template>
	        </ngb-panel>
	        <ngb-panel id="anexos" class="mt-4" *ngIf="interaction.attachments.length > 0">
		        <ng-template ngbPanelHeader let-opened="opened">
			        <div class="group-header d-flex align-items-center w-100">
				        <h6>Anexos</h6>
				        <button class="btn ml-auto" ngbPanelToggle>
					        <i class="fas text-muted" style="font-size: 1.5em"
					           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
				        </button>
			        </div>
		        </ng-template>
		        <ng-template ngbPanelContent>
			        <div class="d-flex align-items-center mb-2" *ngFor="let attachment of interaction.attachments">
				        <i class="fas fa-2x mr-2" [ngClass]="attachmentType(attachment)"></i>

				        <span class="">{{ attachment.fileName | shortenName : 20 }}</span>

				        <div class="text-right flex-grow-1">
					        <button class="btn btn-sm btn-link" (click)="openInteractionAttachmentModal(attachment)">
						        <i class="fas fa-external-link-alt"></i>
						        Abrir
					        </button>
				        </div>

			        </div>
		        </ng-template>
	        </ngb-panel>
        </ngb-accordion>
    </div>
</div>
