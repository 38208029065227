import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { from } from 'rxjs/internal/observable/from';
import { map } from 'rxjs/operators';
import { ImportDataService } from '../../../_services/import-data.service';

@Component({
	selector: 'app-import-data',
	templateUrl: './import-data.component.html',
	styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit, OnDestroy {
	private subs = new Subscription();

	constructor(public importDataService: ImportDataService) {
	}

	ngOnInit(): void {
		this.subs.add(this.importDataService._currentStep.asObservable().subscribe((step) => {
			this.importDataService.currentComponent$ = this.getComponent(this.importDataService.steps[step].componentPromise);
		}));
	}

	getComponent(componentPromise: Promise<any>) {
		return from(componentPromise).pipe(map(component => Object.values(component)[0]));
	}

	ngOnDestroy() {
		this.importDataService.currentStep = 0;
		this.subs.unsubscribe();
	}

}
