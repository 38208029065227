import { Component, Input } from '@angular/core';

@Component({
    selector: 'evo-item-check',
    templateUrl: './item-check.component.html',
    styleUrls: ['./item-check.component.scss']
})
export class ItemCheckComponent {
    @Input() status: 'success' | 'error' = 'success';

    constructor() {}
}
