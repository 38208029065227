<div *ngIf="interaction" id="view">
    <div id="main">
        <div class="header" style="height: 8vh">
            <div class="row align-items-center">
                <div class="col-sm">
                    <h4 class="page-header-title">Interações | <strong>Nº {{interaction.id}}</strong></h4>
                </div>
            </div>
            <div class="interaction-close-modal">
                <button class="btn btn-link" (click)="closeHandler()">
                    <i class="fa fa-times"></i>
                </button>
            </div>
        </div>
        <div class="details">
            <ngb-accordion #a="ngbAccordion" activeIds="detalhes,audio,feedback,cliente,contrato,anexos">
                <ngb-panel id="detalhes">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div class="group-header d-flex align-items-center w-100">
                            <h6>Detalhes</h6>
                            <button class="btn ml-auto" ngbPanelToggle>
                                <i class="fas text-muted" style="font-size: 1.5em"
                                    [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="detail-item">
                        <div class="label">Contato</div>
                        <div class="value">{{interaction?.contactDate ? (interaction?.contactDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                        </div>
                        <div class="detail-item" [ngbTooltip]="interaction?.agent && interaction?.agent.name ? interaction?.agent.name : (interaction.agentName ? interaction.agentName : '-')">
                        <div class="label">Operador</div>
                        <div class="value">{{interaction?.agent && interaction?.agent.name ? interaction?.agent.name : (interaction.agentName ? interaction.agentName : '-')}}</div>
                        </div>
                        <div class="detail-item">
                        <div class="label">Monitoria</div>
                        <div class="value">{{interaction?.monitorDate ? (interaction?.monitorDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                        </div>
                        <div class="detail-item" [ngbTooltip]="interaction?.segment?.name ? interaction?.segment?.name : '-'">
                        <div class="label">Segmento</div>
                        <div class="value">{{interaction?.segment?.name ? interaction?.segment?.name : '-'}}</div>
                        </div>
                        <div class="detail-item" [ngbTooltip]="interaction?.operation?.name ? interaction?.operation?.name : '-'">
                        <div class="label">Operação</div>
                        <div class="value">{{interaction?.operation?.name ? interaction?.operation?.name : '-'}}</div>
                        </div>
                    <div class="detail-item">
                        <div class="label">Monitor</div>
                        <div class="value">{{interaction?.userMonitor?.name ? interaction?.userMonitor?.name : '-'}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Prazo</div>
                        <div class="value">{{interaction?.deadLine ? (interaction?.deadLine | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                    </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="audio" class="mt-4" *ngIf="interaction.fileInfo">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div class="group-header d-flex align-items-center w-100">
                            <h6>{{ isAudio ? 'Áudio' : 'Arquivo'}}</h6>
                            <button class="btn ml-auto" ngbPanelToggle>
                                <i class="fas text-muted" style="font-size: 1.5em"
                                    [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                    <div class="detail-item" *ngIf="isAudio">
                        <div class="label">Silêncio</div>
                        <div class="value">{{interaction?.silenceTime ? interaction.silenceTime : '-'}}</div>
                    </div>
                    <div class="detail-item" *ngIf="isAudio">
                        <div class="label">% Silêncio</div>
                        <div class="value">{{interaction?.silencePercent ? (interaction.silencePercent | number : '0.0-2') : '-'}}</div>
                    </div>
                    <div class="detail-item">
                        <div class="label">Nome do {{ isAudio ? 'Áudio' : 'Arquivo'}}</div>
                        <div class="value interaction-audio-name">
                        <span class="truncate-text" *ngIf="interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName)">
                            {{interaction.fileInfo.originalFileName || interaction.fileInfo.fileName}}
                        </span>
                        <span class="" *ngIf="!(interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName))">-</span>
                            <button class="btn"
                                    (click)="copyToClipboard($event, interaction)"
                                    placement="bottom"
                                    ngbTooltip="Copiar nome do arquivo" style="padding: 5px 10px;">
                                <i class="far fa-copy"></i>
                            </button>
                            <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.AUDIO_DOWNLOAD]">
                                <button class="btn ml-0"  ngbTooltip="Download do Arquivo" (click)="downloadFile()" placement="bottom">
                                    <i class="fas fa-download"></i>
                                </button>
                            </ng-template>

                        </div>
                    </div>
                        <div class="pt-4" *ngIf="interaction.fileInfo && isAudio">
                            <clp-audio-player #clpAudioPlayerComponent [fileInfoId]="interaction.fileInfo.id"></clp-audio-player>
                            <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_TRANSCRIPTION]">
                                <div *ngIf="interaction.transcription" appBlockCopy>
                                    {{interaction.transcription.transcriptionAll }}
                                </div>
                            </ng-template>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
        <div class="row mt-3 pb-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.DISCARD_INTERACTION]">
            <div class="col text-right">
                <button [disabled]="loading" class="btn btn-outline-danger mr-3" (click)="discardMonitor()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Descartar Monitoria
                </button>
            </div>
        </div>
    </div>
</div>