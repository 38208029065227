import { Deserializable } from './deserializable';

export class HelpDeskMessage implements Deserializable {

	public subject: string;
	public message: string;
	public attachment: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}
