import { Address } from './address';
import { Deserializable } from './deserializable';
import { Segment } from './segment';
import { User } from './user';

export class Customer implements Deserializable {

    address: Address;
    businessName: string;
    contestationDeadline: number;
    cpfCnpj: string;
    emailContato: string;
    hasFeedback: boolean;
    id: number;
    logoUrl: string | ArrayBuffer;
    name: string;
    segments: Segment[];
    speechClientId: string;
    telefone: string;
    type: number;
    users: User[];
    website: string;

    simpleInteractionFlow: boolean;
    

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}