import { Injectable } from "@angular/core";
import { BaseServiceClass } from "./baseService.service";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class FileInfoService extends BaseServiceClass<any> {
    constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v1/fileInfo');
	}

    public findByOriginalFileName(originalFileName: String): Observable<any> {
        return this.httpService.get(`${this.entity_url}/name/${originalFileName}`);
    }
}