import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './baseService.service';

@Injectable({
	providedIn: 'root'
})
export class TrainingService extends BaseServiceClass<any> {

	constructor(private http: HttpClient) {
		super(http, '/v1/trainings');
	}

	public getLoginUrl(): Observable<any> {
		return this.http.get(`${this.entity_url}/ead/login/url`);
	}

}
