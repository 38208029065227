import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { City } from '@app/models';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CityService {
    basePath: string = `${environment.apiUrl}/states`;

  constructor(private http: HttpClient) { }

  findByState(stateId: number) {
    return this.http.get<City[]>(`${this.basePath}/${stateId}/citys`);
  }
}
