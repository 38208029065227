<div class="inline-edit" [ngClass]="type">
	<span style="width: 100%" class="" [ngClass]="{'text-center': isCenter}" *ngIf="!showInput" (click)="onShowInput()">
		{{ value }}
		<i class="ml-2 fas fa-pen" style="opacity: 0.5" *ngIf="type == inlineEditTypes.TABLE && showEditIcon"></i>
	</span>

	<div class="d-flex" [ngClass]="{'flex-row': type == inlineEditTypes.TABLE, 'flex-column': type != inlineEditTypes.TABLE}" *ngIf="showInput">
		<input class="form-control" [ngClass]="{'text-center': isCenter}" name="name" [(ngModel)]="tempValue"  (keypress)="blockSpecialCharacter($event)" (paste)="blockOnPaste($event)"/>
		<div class="d-flex justify-content-between">
			<div class="pl-1 pt-1 text-danger" *ngIf="type != inlineEditTypes.TABLE">
				<div *ngIf="lengthError">{{ lengthError }}</div>
			</div>

			<div class="inline-edit-action">
				<button class="btn btn-edit mr-2" (click)="onChangeDone()"><i class="fas fa-check"></i></button>
				<button class="btn btn-edit" (click)="onChangeCancel()"><i class="fas fa-times"></i></button>
			</div>
		</div>
	</div>
	<div class="text-danger" *ngIf="lengthError && type == inlineEditTypes.TABLE" style="font-size: 12px;">
		{{ lengthError }}
	</div>
</div>
