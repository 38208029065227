import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChecklistItem, InteractionFilter } from '@app/models';
import { ChecklistItemFilter } from '@app/models';
import { PageResponse } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChecklistItemService extends BaseServiceClass<ChecklistItem>{

	constructor(protected httpService: HttpClient) {
		super(httpService, '/itenschecklists');
	}

	public updateItemsChecklist(items: ChecklistItem[]): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/batch`, items);
	}

	filterWithChecklist(checklistItemFilter: ChecklistItemFilter, checklistId: number) {
		let options = { params: <any>checklistItemFilter };
		return this.httpService.get<PageResponse<ChecklistItem>>(`${this.entity_url}/checklists/${checklistId}/filter`, options);
	}

	changeActive(checklistItemId: number, active: boolean) {
		return this.httpService.patch(`${this.entity_url}/active/${checklistItemId}/${active}`, null);
	}

	public getChecklistItemsByInteractionFilter(interactionFilter: InteractionFilter): Observable<any[]> {
		interactionFilter.page = null;
		interactionFilter.orderBy = null;
		interactionFilter.linesPerPage = null;
		interactionFilter.direction = null;
		interactionFilter.grade = null;
		interactionFilter.gradeFinal = null;
		let options = { params: this.filterToParams(interactionFilter) };
		return this.httpService.get<any[]>(`${this.entity_url}/interactions`, options);
	}

	findBySegments(segments: any[]) {
		let options = { params: { "segmentsIds" : segments } };
		console.log("Options:", options);
		return this.httpService.get<any>(`${this.entity_url}`, options);
	}

}
