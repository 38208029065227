import { HttpClient } from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import * as _ from 'lodash';
import { BaseServiceClass } from '../baseService.service';
import { Observable } from 'rxjs';
import {InteractionFilter, MonitorInteractionUpdateDTO, PageResponse} from '@app/models';
import { environment } from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class InteractionServiceV2 extends BaseServiceClass<any> {

	public onPageReset: EventEmitter<void> = new EventEmitter();

	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v2/interactions');
	}

	public filter(obj: any) {
		// let options = {params: <any> _.omitBy(obj, _.isNil)};
		return this.httpService.post<PageResponse<any>>(`${this.entity_url}/filter`, obj);
	}

	public getInteractionsFilterPredefined(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/filter/predefined`);
	}

	public filterIndicators(obj: any) {
		let objClone = Object.assign({}, obj);

		if (objClone.originalFileName) {
			objClone.fileInfo = obj.originalFileName;
			objClone.originalFileName = undefined;
		}

		let body = _.omitBy(objClone, _.isNil);

		return this.httpService.post<any>(`${this.entity_url}/filter/indicators`, body);
	}

	public newInteraction(obj: any) {
		return this.httpService.post<any>(`${this.entity_url}`, obj);
	}

	public getTotalImported(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/evaluations`);
	}

	public getEvaluatedMinutes(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/evaluations/duration`);
	}

	public getEvaluatedWords(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/evaluations/words`);
	}

	public getInteractionAgents(params: any): Observable<any> {
		let options = { params };

		return this.httpService.get(`${this.entity_url}/agents`, options);
	}

	public getTotalMonth(): Observable<any> {
		return this.httpService.get(`${this.entity_url}/total/current-month`);
	}

	public getInteractionFilterId(params: any): Observable<any> {
		let options = { params };
		return this.httpService.get(`${this.entity_url}/filter/ids`);
	}

	public interactionsJobsRecalculate(params: any): Observable<any> {
		let objClone = Object.assign({}, params);
		let body = _.omitBy(objClone, _.isNil);
		return this.httpService.post(`${this.entity_url}/jobs/recalculate`, body);
	}

	public interactionsJobsReprocessDashboard(params: any): Observable<any> {
		let objClone = Object.assign({}, params);
		let body = _.omitBy(objClone, _.isNil);
		return this.httpService.post(`${this.entity_url}/jobs/reprocess-dashboard`, body);
	}

	public interactionStatus(interactionId: any, status: string): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${interactionId}/status/${status}`, {});
	}

	public interactionInAnalysis(interactionId: any, inAnalysis: boolean): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${interactionId}/inAnalysis/${inAnalysis}`, {});
	}

	public getInteractionIdsByFilter(filter: any): Observable<any> {
		let objClone = Object.assign({}, filter);
		let body = _.omitBy(objClone, _.isNil);
		body.monitorInteractionItemStatus = {id: filter.monitorInteractionItemStatus};
		return this.httpService.post(`${this.entity_url}/ids`, body);
	}

	public interactionsDiscard(params: any): Observable<any> {
		let objClone = Object.assign({}, params);

		let options = {params: <any> _.omitBy(objClone, _.isNil)};
		return this.httpService.post(`${this.entity_url}/discard`, null, options);
	}

	public updateMonitorInteractionItem(monitorInteractionUpdateDTO: MonitorInteractionUpdateDTO): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${monitorInteractionUpdateDTO.interactionId}/interactionItens`, monitorInteractionUpdateDTO);
	}

	public updateInteractionDeadlineInMass(interactionFilter: InteractionFilter): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/deadlines`, interactionFilter);
	}

	public getLatestContactedAt(): Observable<any> {
		return this.httpService.get<any>(`${this.entity_url}/latest`);
	}

	resetPage() {
		this.onPageReset.emit();
	}
}

