export interface InteractionAttachment {
	id: number;
	path: string;
	fileName: string;
	key: string;
	bucket: string;
	type: string;
	originalFileName: string;
	url: string;
}
