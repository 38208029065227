import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Interaction } from "@app/models";
import {
	FlowService,
	InteractionService,
	JustificationService,
	SystemMessages,
	ToastService
} from '@app/services';
import { InteractionStatusEnum } from '../../_helpers/interaction-status.enum';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'evo-interaction-modal',
    templateUrl: './interaction-modal.component.html',
    styleUrls: ['./interaction-modal.component.scss']
})
export class InteractionModalComponent implements OnInit, OnDestroy {

	@Input()
	public interactionId: number;

	@Input()
	public isMonitor: boolean = false;

	public isReadySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	public isReady: boolean = false;

	public interaction: Interaction;

	public interactionStatusEnum = InteractionStatusEnum;

	public isInTranscription: boolean = false;

    constructor(public activeModal: NgbActiveModal,
                public interactionService: InteractionService,
                public flowService: FlowService,
				public justificationService: JustificationService,
                public toastService: ToastService) {
		this.listenToReady();
    }

	ngOnInit() {
		if(!this.interactionId) {
			throw (new Error('É obrigatório enviar interactionId'));
		}

		this.getInteraction();
	}

	public getInteraction(): void {
		this.interactionService.find(this.interactionId).subscribe(
			data => {
				this.interaction = new Interaction().deserialize(data);
				this.isInTranscription = this.interaction.status.id == this.interactionStatusEnum.IN_TRANSCRIPTION.toString();
			}, error => {
				this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
				console.error(error);
			});
	}

	resetScroll(): void {
		let card = document.getElementById('card');
		card.scrollTop = 0;
	}

	public closeModal(interaction?: Interaction): void {
		this.activeModal.close(interaction);
	}

	public updateIsReady(): void {
		this.isReadySubject.next(true);
	}

	private listenToReady(): void {
		this.isReadySubject.pipe(delay(0)).subscribe(
			isReady => {
				this.isReady = isReady;
			});
	}

	ngOnDestroy() {
		this.isReadySubject.unsubscribe();
	}

}
