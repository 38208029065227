<div class="modal-header">
	<div>
		<h4 class="modal-title" id="modal-title">Anexo</h4>
		<h6>{{ attachment.fileName }}</h6>
	</div>
	<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
	        (click)="dismissModal()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body" *ngIf="attachment.url">
	<div *ngIf="isAudio">
		<clp-audio-player [fileInfoId]="attachment.id"></clp-audio-player>
	</div>

	<div *ngIf="isImage" style="max-width: 100%">
		<img [src]="attachment.url" alt="Image" style="width: 100%"/>
	</div>

	<div *ngIf="!isAudio && downloadEnabled">
		<a class="btn btn-link" (click)="downloadFile(attachment)">Baixar arquivo</a>
		<!-- <button class="btn btn-link btn-sm" (click)="downloadFile(attachment)">Baixar arquivo</button> -->
	</div>
</div>

<div class="modal-body text-center" *ngIf="!attachment.url">
	<span class="spinner-border spinner-border-sm mr-1"></span>
	Carregando...
</div>
