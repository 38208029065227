import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { BaseServiceClass } from './baseService.service';
import {CacheInterceptorService} from "./cache-interceptor.service";

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseServiceClass<any> {

	public jwtToken: string | null = null;

	public currentUser$ = new Subject<any>();
	public _currentUser: any;
	get currentUser() {
		return this._currentUser;
	}
	set currentUser(user: any) {
		this.currentUser$.next(user);
		this._currentUser = user;
	}

	public returnUrl: string = '/users';

	private account_url: string | null = null;

	constructor(private http: HttpClient,
				@Inject(DOCUMENT) private document: Document,
				private cacheInterceptorService: CacheInterceptorService) {
		super(http, '/users');
		this.account_url = environment.accountUrl + '/api/v1/users';
	}

	public logout(): void {
		this.cacheInterceptorService.clearCache();
		localStorage.clear();
		this.currentUser = undefined;
		this.document.location.href = environment.apps['id'] + '/logout?redirectApp=quality';
	}

	public filterAll(filters: any): Observable<any> {
		return this.http.get(`${this.entity_url}`, { params: filters });
	}

	public getCurrentUser(): Observable<any> {
		return this.http.get(`${this.account_url}/current`);
	}

	public getCurrentUserPermissions(): Observable<any> {
		return this.http.get(`${this.account_url}/current/roles`);
	}

	public getUserByAccountId(accountId): Observable<any> {
		return this.http.get(`${this.entity_url}/account/${accountId}`);
	}

	public getQualityCurrentUser(): Observable<any> {
		return this.http.get(`${this.entity_url}/current`);
	}
}
