<div *ngIf="isReady" id="view" #view>
    <div id="main">
		<div class="d-flex justify-content-between align-items-center alert-danger p-3" style="width: 100%;" *ngIf="interaction.inAnalysis && !sameUserAnalysis">
			<span style="font-size: 14px;">Interação está sendo analisada pelo usuário <b>{{ interaction.lastAnalysisHistory?.startedBy?.name }}</b>. Não é possível fazer alterações.</span>

			<ng-container *ngIf="isAdmin && isInternal">
				<button class="btn btn-light" [disabled]="isLoading" (click)="unblockInteraction()">
					<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
					Desbloquear
				</button>
			</ng-container>
		</div>

		<div class="d-flex justify-content-between align-items-center alert-warning p-3" style="width: 100%;" *ngIf="interaction.inAnalysis && sameUserAnalysis">
			<span style="font-size: 14px;">Você está analisando esta Interação, clique em desbloquear para liberar para outros usuários.</span>

			<button class="btn btn-light" (click)="unblockInteraction()" [disabled]="isLoading">
				<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
				Desbloquear
			</button>
		</div>

        <div class="header" style="height: 8vh; border: none;">
            <div class="row align-items-center">
                <div class="col-sm">
                    <h4 class="page-header-title">Interações | <strong>Nº {{interaction.id}}</strong></h4>
                    <countup-timer *ngIf="interaction?.status?.id === 'MANUAL_MONITOR'" class="mr-2" [countUpTimerConfig]="timer"></countup-timer>
                </div>
                <div class="col-sm-auto">
					<evo-status-badge class="header-badge-modal mr-2" [inAnalysis]="interaction.inAnalysis" [statusModel]="interaction.status"></evo-status-badge>
                    <span class="header-badge-modal">
						<span *ngIf="interaction?.status?.id !== 'NO_EFFECTIVE'" class="badge badge-primary header-badge-modal">
							<i class="far fa-bookmark mr-2"></i>{{interaction.grade}}/100 NOTA FINAL
						</span>
					</span>
                </div>
            </div>
			<div class="row" *ngIf="hasNoPermissions">
				<div class="col">
					<span class="badge badge-soft-info p-2">Apenas visualização</span>
				</div>
			</div>
        </div>

	    <mat-tab-group>
		    <mat-tab label="Monitoria">
			    <div style="overflow: auto;">
				    <div class="items" *ngFor="let group of itemsGrouped; let i = index">
					    <ngb-accordion #a="ngbAccordion" [activeIds]="getGroupElementsIds()" class="no-padding">
						    <ngb-panel [id]="'group'+i">
							    <ng-template ngbPanelHeader let-opened="opened">
								    <div class="group-header d-flex align-items-center w-100">
									    <h5>{{group[0].itensChecklist.group.name}}</h5>
									    <span class="group-grade ml-2" *ngIf="group[0].status !== 'NO_EFFECTIVE'">NOTA {{getGroupGrade(group[0].itensChecklist)}}</span>
									    <button class="btn ml-auto" ngbPanelToggle>
										    <i class="fas text-muted" style="font-size: 1.5em"
										       [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
									    </button>
								    </div>
							    </ng-template>
							    <ng-template ngbPanelContent>
								    <div class="item" *ngFor="let item of group; let j = index">
									    <ngb-accordion #acc="ngbAccordion" [class]="j === group.length - 1 ? 'last-accordion' : ''" [activeIds]="getActiveIdsGroup(i)">
										    <ngb-panel [id]="'group'+i+'item'+j">
											    <ng-template ngbPanelHeader let-itemopened="opened">
												    <div class="d-flex align-items-center">
	                                            <span class="fa-stack">
	                                                <i class="fa fa-circle fa-stack-2x"
	                                                   [ngClass]="item.answer.id === 'CONFORMING' ? 'item-success' : 'item-danger'"></i>
	                                                <i class="fas fa-stack-1x"
	                                                   [ngClass]="item.answer.id === 'CONFORMING' ? 'fa-check item-success-color' : 'fa-times item-danger-color'"></i>
	                                            </span>
													    <span style="font-size: 14px;"
													          class="ml-2 mr-2">{{item.itensChecklist.name}}</span>
													    <span class="font-weight-bold"
													          [ngClass]="checkGradeShouldCount(item)? 'item-success-color':'item-danger-color'">{{checkGradeShouldCount(item)
														    ? '+' : '-'}}{{item.peso}}</span>
													    <div class="ml-auto d-flex align-items-center">
														    <evo-status-badge class="mr-2" [statusModel]="item.status"></evo-status-badge>
														    <span class="badge mr-2 h-100"
														          [ngClass]="item.answer.id == 'NONCONFORMING' ? 'badge-danger' : 'badge-success'">{{item.answer.name}}</span>
														    <button class="btn ml-auto" ngbPanelToggle (click)="updateActiveIds(i, 'group'+i+'item'+j)">
	                                                    <span class="fa-stack" style="font-size: 0.6em; display: block;">
	                                                        <i class="far fa-circle fa-stack-2x"></i>
	                                                        <i class="fas fa-stack-1x"
	                                                           [ngClass]="itemopened ? 'fa-minus' : 'fa-plus'"></i>
	                                                    </span>
														    </button>
													    </div>
												    </div>
												    <div class="d-flex">
													    <span>{{ item.itensChecklist.descricao }}</span>
												    </div>
											    </ng-template>
											    <ng-template ngbPanelContent>
												    <div style="margin-left: 15px;" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_FRAGMENTS]">
														<span *ngIf="item.fragments != null && item.fragments.length > 0">
															<i class="fas fa-info-circle ml-2" data-toggle="tooltip" data-placement="top" title="Transcrição aproximada, pode apresentar variações em decorrência da qualidade do áudio."></i>
															<span *ngFor="let fragment of item.fragments.slice(0, 5);" class="mr-2 h-100">
																<span class="fa-stack">
																	<i class="fa fa-circle fa-stack-2x" [ngClass]="item.answer.id === 'CONFORMING' ? 'item-success' : 'item-danger'"></i>
																	<i class="fas fa-stack-1x fa-comments" [ngClass]="item.answer.id === 'CONFORMING' ? 'item-success-color' : 'item-danger-color'"></i>
																</span>
																{{fragment.text}} ({{fragment.start.startInTime | secondsToDateTime | date:'HH:mm:ss'}})
															</span>
														</span>
													</div>
												    <evo-timeline-list [monitorInteraction]="item"></evo-timeline-list>
												    <div class="row pb-4">
													    <div class="col text-right">
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REVIEW_INTERACTIONS]">
															    <evo-change-answer-button
																    *ngIf="currentStatus.id == 'REVISION' && (!analysisInProgress || (analysisInProgress && sameUserAnalysis))"
																    [interaction]="interaction" [index]="getItemIndex(item)"
																    [item]="item" [justifications]="justifications"
																    [currentUser]="currentUser"
																    (actionCompleted)="monitorItems[$event.index] = $event.item">
															    </evo-change-answer-button>
														    </ng-template>
													    </div>
													    <div class="col-12 text-right">
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.CONTEST_EVALUATION]">
															    <evo-contestation-button
																    *ngIf="flow.contestation && ((!analysisInProgress || (analysisInProgress && sameUserAnalysis))) && currentStatus.id == 'EVALUATION' && item.status.id !== 'CONTESTED' && item.status.id !== 'CONFORMING_EDITED' && item.answer.id == 'NONCONFORMING'"
																    [index]="getItemIndex(item)" [item]="item" class="ml-2" [currentUser]="currentUser"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-contestation-button>
														    </ng-template>
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.EDIT_CONFORMING_ANSWER]">
															    <evo-edit-conforming-button *ngIf="currentStatus.id == 'EVALUATION' && item.answer.id == 'CONFORMING'"
															                                [interaction]="interaction" [index]="getItemIndex(item)"
															                                [item]="item" [justifications]="justifications"
															                                [currentUser]="currentUser" (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-edit-conforming-button>
														    </ng-template>
													    </div>
													    <div class="col-12 text-right">
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLY_CONTESTATION]">
															    <evo-contestation-declined-button
																    *ngIf="flow.contestation && (!analysisInProgress || (analysisInProgress && sameUserAnalysis)) && currentStatus.id == 'CONTESTED' && item.status.id == 'CONTESTED'"
																    [index]="getItemIndex(item)" [item]="item"
																    [currentUser]="currentUser" class="mr-2"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-contestation-declined-button>
															    <evo-contestation-accepted-button
																    *ngIf="flow.contestation && (!analysisInProgress || (analysisInProgress && sameUserAnalysis)) && currentStatus.id == 'CONTESTED' && item.status.id == 'CONTESTED'"
																    [index]="getItemIndex(item)" [item]="item"
																    [currentUser]="currentUser" [justifications]="justifications"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-contestation-accepted-button>
														    </ng-template>
													    </div>
													    <div class="col text-right">
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLICA_REPLY_CONTESTATION]">
															    <evo-replica-button
																    *ngIf="flow.replica && (!analysisInProgress || (analysisInProgress && sameUserAnalysis)) && currentStatus.id === 'REPLICA' && item.status.id === 'CONTESTATION_DECLINED'"
																    [index]="getItemIndex(item)" [item]="item"
																    [currentUser]="currentUser"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-replica-button>
														    </ng-template>
													    </div>
													    <div class="col-12 text-right">
														    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLY_REPLICA]">
															    <evo-replica-declined-button
																    *ngIf="flow.replica && (!analysisInProgress || (analysisInProgress && sameUserAnalysis)) && currentStatus.id == 'REPLICA_SENDED' && item.status.id == 'REPLICA'"
																    [index]="getItemIndex(item)" [item]="item" class="mr-2" [currentUser]="currentUser"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-replica-declined-button>
															    <evo-replica-accepted-button
																    *ngIf="flow.replica && (!analysisInProgress || (analysisInProgress && sameUserAnalysis)) && currentStatus.id == 'REPLICA_SENDED' && item.status.id == 'REPLICA'"
																    [index]="getItemIndex(item)" [item]="item" [currentUser]="currentUser"
																    (actionCompleted)="actionItemUpdate($event, i, j)">
															    </evo-replica-accepted-button>
														    </ng-template>
													    </div>
												    </div>
											    </ng-template>
										    </ngb-panel>
									    </ngb-accordion>
								    </div>
							    </ng-template>
						    </ngb-panel>
					    </ngb-accordion>
				    </div>
				    <!-- aqui -->
				    <div class="container-fluid">
					    <div class="row mt-3 pb-4">
						    <div class="col d-flex text-right" *ngIf="(analysisInProgress && sameUserAnalysis) || !analysisInProgress">
							    <ng-container *ngIf="currentStatus.id != 'DISCARDED' && currentStatus.id != 'IN_PROGRESS' && currentStatus.id != 'NOT_EFFECTIVE'">
								    <button class="btn btn-outline-danger" (click)="discardMonitor($event)" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.DISCARD_INTERACTION]">Descartar
									    Monitoria</button>
								    <div class="flex-grow-1"></div>
							    </ng-container>

							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.ACCEPT_EVALUATION]">
								    <button *ngIf="currentStatus.id == 'EVALUATION' || currentStatus.id == 'REPLICA'"
								            [disabled]="isLoading || checkHasStatus('CONTESTED') || checkHasStatus('REPLICA')"
								            class="btn btn-primary btn-sm ml-3" (click)="finishEvaluation()">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    <span *ngIf="checkHasStatus('CONFORMING_EDITED'); else aceitarMonitoria">Enviar Item Editado</span>
									    <ng-template #aceitarMonitoria>Aceitar Monitoria</ng-template>
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.CONTEST_EVALUATION]">
								    <button
									    *ngIf="(flow.contestation && currentStatus.id == 'EVALUATION')"
									    class="btn btn-danger btn-sm ml-3" [disabled]="isLoading || (!checkHasStatus('CONTESTED'))"
									    evolloConfirmAction [title]="getContestationTitle()" [text]="getContestationText()  "
									    (confirm)="finishContestation()">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    <span *ngIf="checkHasStatus('CONTESTED') && checkHasStatus('CONFORMING_EDITED'); else enviarContestacao">Enviar Contestação/Item Editado</span>
									    <ng-template #enviarContestacao>Enviar Contestação</ng-template>
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLY_CONTESTATION]">
								    <button
									    *ngIf="flow.contestation && currentStatus.id === 'CONTESTED'"
									    [disabled]="isLoading || checkHasStatus('CONTESTED')"
									    class="btn btn-success btn-sm ml-3" container="body" placement="top"
									    ngbTooltip="Enviar respostas de contestações" (confirm)="finishContestationResponses()"
									    evolloConfirmAction title="Enviar respostas de contestações" text="Deseja enviar as respostas das contestações?">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    Enviar Respostas
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLICA_REPLY_CONTESTATION]">
								    <button
									    *ngIf="flow.replica && currentStatus.id === 'REPLICA'"
									    [disabled]="isLoading || (!checkHasStatus('REPLICA'))" class="btn btn-outline-primary btn-sm ml-3"
									    container="body" placement="top" ngbTooltip="Enviar réplicas das respostas das contestações" evolloConfirmAction
									    (confirm)="finishContestationReplicas()" title="Enviar réplicas das respostas das contestações" text="Deseja enviar as réplicas das respostas das contestações?">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    Enviar Réplicas
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.REPLY_REPLICA]">
								    <button
									    *ngIf="flow.replica && currentStatus.id === 'REPLICA_SENDED'"
									    [disabled]="isLoading || checkHasStatus('REPLICA')"
									    class="btn btn-success btn-sm ml-3" container="body" placement="top"
									    ngbTooltip="Enviar respostas de réplicas" evolloConfirmAction title="Enviar respostas de réplicas"
									    text="Deseja enviar as respostas de réplicas?" (confirm)="finishContestationReplicasResponses()">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    Enviar Respostas
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.ACCEPT_FEEDBACK]">
								    <button [disabled]="isLoading" *ngIf="currentStatus.id == 'FEEDBACK_ACCEPTED'"
								            class="btn btn-primary mr-3" container="body" placement="bottom"
								            ngbTooltip="Aceitar feedback" (click)="finishInteraction()">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    Aceitar feedback
								    </button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.APPLY_FEEDBACK]">
								    <evo-feedback-button
									    *ngIf="flow.feedback && (currentStatus.id === 'FEEDBACK' || (!flow.contestation && currentStatus.id === 'EVALUATION'))"
									    [interaction]="interaction" class="ml-3"
									    (actionCompleted)="finishFeedback($event)">
								    </evo-feedback-button>
							    </ng-template>
							    <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.INTERNAL]">
								    <button
									    *ngIf="currentStatus.id === 'REVISION'"
									    [disabled]="isLoading" class="btn btn-primary btn-smw ml-3" container="body" placement="top"
									    ngbTooltip="Finalizar revisão" (confirm)="finishRevision()" evolloConfirmAction
									    title="Finalizar revisão" text="Gostaria de finalizar a revisão?">
									    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
									    Finalizar
								    </button>
							    </ng-template>
						    </div>
					    </div>
				    </div>
			    </div>
		    </mat-tab>
		    <ng-container *ngxPermissionsOnly="[ qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTION_INSIGHT ]">
			    <mat-tab label="Insights" *ngIf="aiInsightsEnabled">
				    <ng-container *ngIf="!genAiFlags">
					    <div class="d-flex py-4">
						    <span class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
						    <span>Carregando insights...</span>
					    </div>
				    </ng-container>
				    <ng-container *ngIf="genAiFlags?.length > 0">
					    <div class="items" *ngFor="let genAi of genAiFlags; let i = index">
						    <ngb-accordion #a="ngbAccordion" class="no-padding" activeIds="ai0">
							    <ngb-panel [id]="'ai' + i">
								    <ng-template ngbPanelHeader let-opened="opened">
									    <div class="group-header d-flex align-items-center w-100">
										    <h5>{{ genAi.genAiQuestion.name }}</h5>
										    <button class="btn ml-auto" ngbPanelToggle>
											    <i class="fas text-muted" style="font-size: 1.5em"
											       [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
										    </button>
									    </div>
								    </ng-template>
								    <ng-template ngbPanelContent>
									    <div style="padding: 0 17px;" [innerHTML]="genAi.genAiResponse"></div>
								    </ng-template>
							    </ngb-panel>
						    </ngb-accordion>
					    </div>
				    </ng-container>
				    <ng-container *ngIf="genAiFlags?.length == 0">
					    <div class="alert alert-warning" role="alert">
						    <div class="d-flex flex-start align-items-center">
							    <i class="fas fa-exclamation-triangle font-warning mr-2"></i>
							    <div>
								    <p class="m-0">Não há insights para esta interação.</p>
							    </div>
						    </div>
					    </div>
				    </ng-container>
			    </mat-tab>
		    </ng-container>
		    <ng-container *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.GEN_AI_INTERACTION_CHAT]">
			    <mat-tab label="EVA" *ngIf="aiInteractionEnabled">
				    <div class="" style="height: 65vh;">
					    <app-ai-chat [filters]="aiFilters" [interaction]="interaction" [showFilter]="false" [showTitle]="false" [showGenAiTips]="false"></app-ai-chat>
				    </div>
			    </mat-tab>
		    </ng-container>
	    </mat-tab-group>
    </div>
    <div id="side">
	    <div class="interaction-close-modal">
		    <button class="btn btn-link" (click)="closeHandler()">
			    <i class="fa fa-times"></i>
		    </button>
	    </div>

        <ngb-accordion #a="ngbAccordion" activeIds="detalhes,audio,feedback,cliente,contrato,anexos">
            <ngb-panel id="detalhes">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="group-header d-flex align-items-center w-100">
                        <h6>Detalhes</h6>
                        <button class="btn ml-auto" ngbPanelToggle>
                            <i class="fas text-muted" style="font-size: 1.5em"
                                [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                        </button>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <!-- <div class="detail-item" [ngbTooltip]="interaction?.monthlyCycleName ? interaction?.monthlyCycleName : '-'">
                        <div class="label">Ciclo de monitoria</div>
                        <div class="value">{{interaction?.monthlyCycleName ? interaction?.monthlyCycleName : '-'}}</div>
                      </div> -->
                    <div class="detail-item">
                      <div class="label">Contato</div>
                      <div class="value">{{interaction?.contactDate ? (interaction?.contactDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                    </div>
                    <div class="detail-item" [ngbTooltip]="interaction?.agent && interaction?.agent.name ? interaction?.agent.name : (interaction.agentName ? interaction.agentName : '-')">
                      <div class="label">Operador</div>
                      <div class="value">{{interaction?.agent && interaction?.agent.name ? interaction?.agent.name : (interaction.agentName ? interaction.agentName : '-')}}</div>
                    </div>
                    <div class="detail-item">
                      <div class="label">Monitoria</div>
                      <div class="value">{{interaction?.monitorDate ? (interaction?.monitorDate | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                    </div>
                    <div class="detail-item" [ngbTooltip]="interaction?.segment?.name ? interaction?.segment?.name : '-'">
                      <div class="label">Segmento</div>
                      <div class="value">{{interaction?.segment?.name ? interaction?.segment?.name : '-'}}</div>
                    </div>
                    <div class="detail-item" [ngbTooltip]="interaction?.operation?.name ? interaction?.operation?.name : '-'">
                      <div class="label">Operação</div>
                      <div class="value">{{interaction?.operation?.name ? interaction?.operation?.name : '-'}}</div>
                    </div>
                  <div class="detail-item">
                    <div class="label">Monitor</div>
                    <div class="value">{{interaction?.userMonitor?.name ? interaction?.userMonitor?.name : '-'}}</div>
                  </div>
                  <div class="detail-item">
                    <div class="label">Prazo</div>
                    <div class="value">{{interaction?.deadLine ? (interaction?.deadLine | date: 'dd/MM/yyyy HH:mm') : '-'}}</div>
                  </div>
	                <div class="detail-item">
		                <div class="label">Sentimento</div>
		                <div class="value">
			                <img src="{{ getSentimentImage(interaction) }}" ngbTooltip="{{ getSentimentLabel(interaction) }}"/>
		                </div>
	                </div>

	                <div class="detail-item" *ngFor="let metadata of interactionMetadata">
		                <div class="label">
			                <span [ngbTooltip]="metadata[0]">{{ metadata[0]?.length > 13 ? (metadata[0] | slice:0:13) + '...' : metadata[0] }}</span>
		                </div>
		                <div class="value">
			                <span [ngbTooltip]="metadata[1]">{{ metadata[1]?.length > 13 ? (metadata[1] | slice:0:13) + '...' : metadata[1] }}</span>
		                </div>
	                </div>
                </ng-template>
            </ngb-panel>
	        <ng-container *ngIf="interaction.interactionCustomer">
                <ngb-panel id="cliente" class="mt-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
                    <ng-template ngbPanelHeader let-opened="opened">
                        <div class="group-header d-flex align-items-center w-100">
                            <h6>Cliente</h6>
                            <button class="btn ml-auto" ngbPanelToggle>
                                <i class="fas text-muted" style="font-size: 1.5em"
                                   [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                            </button>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
                        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.name" [ngbTooltip]="interaction?.interactionCustomer?.name">
                          <div class="label">Nome</div>
                          <div class="value">{{interaction?.interactionCustomer?.name }}</div>
                        </div>
                        <div class="detail-item" *ngIf="interaction?.interactionCustomer?.email" [ngbTooltip]="interaction?.interactionCustomer?.email">
                            <div class="label">E-mail</div>
                            <div class="value">{{interaction?.interactionCustomer?.email }}</div>
                          </div>
                          <div class="detail-item" *ngIf="interaction?.interactionCustomer?.taxId" [ngbTooltip]="interaction?.interactionCustomer?.taxId">
                            <div class="label">CPF/CNPJ</div>
                            <div class="value">{{interaction?.interactionCustomer?.taxId }}</div>
                          </div>
                          <div class="detail-item" *ngIf="interaction?.interactionCustomer?.state" [ngbTooltip]="interaction?.interactionCustomer?.state">
                            <div class="label">UF</div>
                            <div class="value">{{interaction?.interactionCustomer?.state }}</div>
                          </div>
                          <div class="detail-item" *ngIf="interaction?.interactionCustomer?.phoneNumber" [ngbTooltip]="interaction?.interactionCustomer?.phoneNumber">
                            <div class="label">Telefone</div>
                            <div class="value">{{interaction?.interactionCustomer?.phoneNumber }}</div>
                          </div>
                          <div class="detail-item" *ngIf="interaction?.interactionCustomer?.birthDate" [ngbTooltip]="interaction?.interactionCustomer?.birthDate">
                            <div class="label">Nascimento</div>
                            <div class="value">{{interaction?.interactionCustomer?.birthDate }}</div>
                          </div>
                    </ng-template>
                </ngb-panel>
	        </ng-container>
            <ng-container *ngIf="interaction.interactionDebt">
		        <ngb-panel id="contrato" class="mt-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
			        <ng-template ngbPanelHeader let-opened="opened">
				        <div class="group-header d-flex align-items-center w-100">
					        <h6>Contrato</h6>
					        <button class="btn ml-auto" ngbPanelToggle>
						        <i class="fas text-muted" style="font-size: 1.5em"
						           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
					        </button>
				        </div>
			        </ng-template>
			        <ng-template ngbPanelContent *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_INTERACTIONS_CUSTOMER_DETAILS]">
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.contractCode" [ngbTooltip]="interaction?.interactionDebt?.contractCode">
					        <div class="label">Código</div>
					        <div class="value">{{interaction?.interactionDebt?.contractCode }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.contactDateLimit" [ngbTooltip]="interaction?.interactionDebt?.contactDateLimit">
					        <div class="label">Data Inicial</div>
					        <div class="value">{{interaction?.interactionDebt?.contactDateLimit  | date: 'dd/MM/yyyy' : '+1000' }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.payedAt" [ngbTooltip]="interaction?.interactionDebt?.payedAt">
					        <div class="label">Data da Baixa</div>
					        <div class="value">{{interaction?.interactionDebt?.payedAt | date: 'dd/MM/yyyy' : '+1000' }}</div>
				        </div>
				        <div class="detail-item" *ngIf="interaction?.interactionDebt?.debtValue" [ngbTooltip]="interaction?.interactionDebt?.debtValue">
					        <div class="label">Valor</div>
					        <div class="value">{{interaction?.interactionDebt?.debtValue }}</div>
				        </div>
			        </ng-template>
		        </ngb-panel>
	        </ng-container>
	        <ngb-panel id="feedback" class="mt-4" *ngIf="interaction?.feedback">
		        <ng-template ngbPanelHeader let-opened="opened">
			        <div class="group-header d-flex align-items-center w-100">
				        <h6>Feedback</h6>
				        <button class="btn ml-auto" ngbPanelToggle>
					        <i class="fas text-muted" style="font-size: 1.5em"
					           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
				        </button>
			        </div>
		        </ng-template>
		        <ng-template ngbPanelContent>
			        <evo-timeline-list [timelineItems]="itemsTimeline"></evo-timeline-list>
		        </ng-template>
	        </ngb-panel>
            <ngb-panel id="audio" class="mt-4">
                <ng-template ngbPanelHeader let-opened="opened">
                    <div class="group-header d-flex align-items-center w-100">
                        <h6>{{ isAudio ? 'Áudio' : 'Arquivo'}}</h6>
                        <button class="btn ml-auto" ngbPanelToggle>
                            <i class="fas text-muted" style="font-size: 1.5em"
                                [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
                        </button>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="detail-item" *ngIf="isAudio">
                    <div class="label">Silêncio</div>
                    <div class="value">{{interaction?.silenceTime ? interaction.silenceTime : '-'}}</div>
                  </div>
                  <div class="detail-item" *ngIf="isAudio">
                    <div class="label">% Silêncio</div>
                    <div class="value">{{interaction?.silencePercent ? (interaction.silencePercent | number : '0.0-2') : '-'}}</div>
                  </div>
                  <div class="detail-item">
                    <div class="label">Nome do {{ isAudio ? 'Áudio' : 'Arquivo'}}</div>
                    <div class="value interaction-audio-name">
                      <span class="truncate-text" *ngIf="interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName)">
                        {{interaction.fileInfo.originalFileName || interaction.fileInfo.fileName}}
                      </span>
                      <span class="" *ngIf="!(interaction?.fileInfo && (interaction?.fileInfo.originalFileName || interaction?.fileInfo.fileName))">-</span>
	                    <button class="btn"
	                            (click)="copyToClipboard($event, interaction)"
	                            placement="bottom"
	                            ngbTooltip="Copiar nome do arquivo" style="padding: 5px 10px;">
		                    <i class="far fa-copy"></i>
	                    </button>
                        <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.AUDIO_DOWNLOAD]">
                            <button class="btn ml-0"  ngbTooltip="Download do Arquivo" (click)="downloadFile()" placement="bottom">
                                <i class="fas fa-download"></i>
                            </button>
                        </ng-template>

                    </div>
                  </div>
                    <div class="pt-4" *ngIf="interaction.fileInfo && isAudio">
                        <clp-audio-player #clpAudioPlayerComponent [fileInfoId]="interaction.fileInfo.id"></clp-audio-player>
                    </div>
                    <div class="pt-4" *ngIf="interaction.transcription">
                        <ng-template [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_TRANSCRIPTION]">
	                        <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="(!transcriptionSegments || transcriptionSegments.length == 0)">
		                        <span class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
		                        <span>Carregando transcrição...</span>
	                        </div>
	                        <div *ngIf="transcriptionSegments && transcriptionSegments.length > 0" style="max-height: 700px; overflow-y: auto">
		                        <div class="d-flex align-items-start" *ngFor="let transcriptionSegment of transcriptionSegments">
			                        <div class="d-flex align-items-center mr-2" style="min-width: 45px;">
				                        <i class="fas fa-user" *ngIf="transcriptionSegment.type == 'CLIENT'"></i>
				                        <i class="fas fa-headset" *ngIf="transcriptionSegment.type == 'AGENT'"></i>
				                        <span class="ml-1 text-muted">{{ transcriptionSegment.start | secondsToHhmmss }}</span>
			                        </div>
			                        <p>{{ transcriptionSegment.text }}</p>
		                        </div>
	                        </div>
                        </ng-template>
                    </div>
                </ng-template>
            </ngb-panel>
	        <ngb-panel id="transcription" class="mt-4" *ngxPermissionsOnly="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.VIEW_TRANSCRIPTION]">
		        <ng-template ngbPanelHeader let-opened="opened" *ngIf="interaction.transcription">
			        <div class="group-header d-flex align-items-center w-100">
				        <h6>Transcrição</h6>
				        <button class="btn ml-auto" ngbPanelToggle>
					        <i class="fas text-muted" style="font-size: 1.5em"
					           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
				        </button>
			        </div>
		        </ng-template>
		        <ng-template ngbPanelContent *ngIf="interaction.transcription">
			        <!--<div *ngIf="interaction.transcription" appBlockCopy>
						{{interaction.transcription.transcriptionAll }}
					</div>-->
			        <div class="d-flex flex-column align-items-center justify-content-center" *ngIf="transcriptionSegments.length == 0">
				        <span class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
				        <span>Carregando transcrição...</span>
			        </div>
			        <div class="" *ngIf="transcriptionSegments.length > 0">
				        <div class="d-flex align-items-start" *ngFor="let transcriptionSegment of transcriptionSegments">
					        <div class="d-flex align-items-center mr-2" style="min-width: 45px;">
						        <i class="fas fa-user" *ngIf="transcriptionSegment.type == 'CLIENT'"></i>
						        <i class="fas fa-headset" *ngIf="transcriptionSegment.type == 'AGENT'"></i>
						        <span class="ml-1 text-muted">{{ transcriptionSegment.start | secondsToHhmmss }}</span>
					        </div>
					        <p>{{ transcriptionSegment.text }}</p>
				        </div>
			        </div>
		        </ng-template>
	        </ngb-panel>

	        <ngb-panel id="anexos" class="mt-4" *ngIf="interaction.attachments.length > 0">
		        <ng-template ngbPanelHeader let-opened="opened">
			        <div class="group-header d-flex align-items-center w-100">
				        <h6>Anexos</h6>
				        <button class="btn ml-auto" ngbPanelToggle>
					        <i class="fas text-muted" style="font-size: 1.5em"
					           [ngClass]="'fa-chevron-' + (opened ? 'up': 'down')"></i>
				        </button>
			        </div>
		        </ng-template>
		        <ng-template ngbPanelContent>
			        <div class="d-flex align-items-center mb-2" *ngFor="let attachment of interaction.attachments">
				        <i class="fas fa-2x mr-2" [ngClass]="attachmentType(attachment)"></i>

				        <span class="">{{ attachment.fileName | shortenName : 20 }}</span>

				        <div class="text-right flex-grow-1">
					        <button class="btn btn-sm btn-link" (click)="openInteractionAttachmentModal(attachment)">
						        <i class="fas fa-external-link-alt"></i>
						        Abrir
					        </button>
				        </div>

			        </div>
		        </ng-template>
	        </ngb-panel>
        </ngb-accordion>
    </div>
</div>
