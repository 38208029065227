<div class="">
	<div class="body">
		<div class="checklist-segment-header">
			<span>Segmento:</span>
			<span>{{ this.createChecklistService.currentChecklist.segment.name }}</span>

			<h4>Itens do checklist</h4>
			<p *ngIf="!createChecklistService.currentChecklist.id">Cadastre os grupos e os itens de cada grupo para o seu checklist</p>
			<p *ngIf="createChecklistService.currentChecklist.id">Altere os grupos e os itens de cada grupo para o seu checklist</p>
		</div>

		<div class="d-flex justify-content-center">
			<button class="btn btn-link" (click)="showHelper()">Saiba mais</button>
		</div>

		<div class="checklist-segment" *ngIf="showGroups">
			<ng-container *ngFor="let group of groups; index as i">
				<app-checklist-item-group class="mb-3"
				                          [checklist]="createChecklistService.currentChecklist"
				                          *ngIf="validateActiveGroup(group) || group.active" [disabled]="createChecklistService.formDisabled"
				                          [group]="group" [showRemove]="i > 0 || group.id" (onRemoveGroup)="onRemoveGroup(group)">
					<div slot="item-body">
						<ng-container *ngFor="let item of group.categories; index as j">
							<div class="checklist-item" *ngIf="item.active && !item.opened">
								<div class="item-row mb-0 justify-content-between">
									<div class="item-name">
										{{ item.name || 'Nome do item' }}
									</div>

									<div class="d-flex">
										<div style="width: 65px;"> Peso {{ item.peso || 0 }}</div>
										<div class="badge badge-warning mr-2" *ngIf="item.peso == 100">NCG</div>

										<div class="item-action">
											<i class="btn-toggle fas" [ngClass]="{'fa-chevron-up' : item.opened, 'fa-chevron-down' : !item.opened}" (click)="openCloseItem(item)"></i>
											<i class="btn-delete fas fa-trash" (click)="changeStatusItem(group, item)" ></i>
										</div>
									</div>
								</div>
							</div>
							<div class="checklist-item" *ngIf="item.active && item.opened" [ngClass]="{'first': j == 0, 'last': j == group.categories.length - 1}">
								<div class="item-row">
									<div class="item-name">
										<label>Nome do item<span class="text-muted"> - máx. 500 caracteres</span></label>
										<input type="text" #itemName (focus)="onItemFocus(item)"
										       placeholder="Digite o nome do item"
										       [maxlength]="500"
										       [ngClass]="{ 'is-invalid': !itemName.value }"
										       class="form-control"
											   [maxLength]="500"
										       [disabled]="createChecklistService.currentChecklist.checkListType == 'AUTOMATIC'"
										       [(ngModel)]="item.name"/>
									</div>

									<div class="item-peso">
										<label>Peso</label>
										<input class="form-control" mask="separator.2" separatorLimit="100" #peso
										       [(ngModel)]="item.peso" (focus)="onItemFocus(item)" [ngClass]="{'is-invalid': isPesoInvalid(peso.value) }"
										       [disabled]="item.peso == 100" (change)="itemNcg(item.peso)">
									</div>

									<div class="item-ncg">
										<label>
											NCG
											<i class="fas fa-info-circle ml-1" container="body" placement="right" ngbTooltip="Não conformidade grave"></i></label>
										<div class="d-flex align-items-center" style="height: 33.5px">
											<input type="checkbox" [checked]="itemNcg(item.peso)" (change)="resetNcgValue(item)">
										</div>
									</div>

									<div class="item-action">
										<i class="btn-toggle fas" [ngClass]="{'fa-chevron-up' : item.opened, 'fa-chevron-down' : !item.opened}" (click)="openCloseItem()"></i>
										<i class="btn-delete fas fa-trash" (click)="changeStatusItem(group, item)" ></i>
									</div>

								</div>

								<div class="item-row">
									<div class="item-descricao">
										<label>Descrição do item (opcional) <span class="text-muted"> - máx. 500 caracteres</span></label>
										<input type="text" #itemDescription
										       placeholder="Digite uma descrição do item"
										       [maxlength]="500"
										       class="form-control"
										       [(ngModel)]="item.descricao"/>
									</div>
								</div>
							</div>
						</ng-container>

						<!-- <ng-container *ngFor="let item of group.categories">
							<div class="checklist-item mb-2" *ngIf="item.active">
								<div class="name">
						<div class="checklist-item" *ngIf="item.active && item.opened" [ngClass]="{'first': j == 0, 'last': j == group.categories.length - 1}">
							<div class="item-row">
								<div class="item-name">
									<label>Nome do item<span class="text-muted"> - máx. 500 caracteres</span></label>
									<input type="text" #itemName (focus)="onItemFocus(item)"
									       placeholder="Digite o nome do item"
										   [maxlength]="500"
									       [ngClass]="{ 'is-invalid': !itemName.value }"
									       class="form-control"
									       [disabled]="createChecklistService.currentChecklist.checkListType == 'AUTOMATIC'"
									       [(ngModel)]="item.name"/>
								</div>
								<div class="weight">
									<input class="form-control" mask="separator.2" separatorLimit="100" #peso
									       [(ngModel)]="item.peso" (focus)="onItemFocus(item)" [ngClass]="{'is-invalid': isPesoInvalid(peso.value) }"
										   [disabled]="item.peso == 100" (change)="itemNcg(item.peso)">
								</div>
								<div class="ncg">
									<input type="checkbox" [checked]="itemNcg(item.peso)" (change)="resetNcgValue(item)">
								</div>
								<div class="action">
									<div class="btn-delete" (click)="changeStatusItem(group, item)" *ngIf="createChecklistService.currentChecklist.checkListType == 'MANUAL'">
										<i class="fas fa-trash"></i>
									</div>

								</div>
							</div>
						</ng-container> -->

						<div class="row mt-3">
							<div class="col-12">
								<button class="btn btn-sm btn-outline-primary" (click)="addItem(group)" [ngClass]="{'disabled' : !group.id}" [disabled]="!group.id" *ngIf="createChecklistService.currentChecklist.checkListType == 'MANUAL'">Adicionar novo item</button>
							</div>
						</div>

					</div>
				</app-checklist-item-group>
			</ng-container>
		</div>

		<div class="d-flex justify-content-center">
			<button class="btn-add large mt-4" (click)="addGroup()" *ngIf="createChecklistService.currentChecklist.checkListType == 'MANUAL'"> Adicionar novo grupo +</button>
		</div>
	</div>

	<evo-stepper-footer [isLoading]="isLoading" [isFormInvalid]="pesoTotal !== 100 || invalidForm" (onNext)="onNext()" (onPrev)="onPrev()">
		<div progress class="progress-wrapper">
			<p *ngIf="pesoTotal <= 100" class="text-muted">Você possui {{100 - pesoTotal | number: '1.0-2'}} pontos restantes <i class="fas fa-info-circle ml-1"></i></p>
			<p *ngIf="pesoTotal > 100" class="text-danger">Você excedeu a pontuação em {{pesoTotal - 100 | number: '1.0-2'}} pontos <i class="fas fa-info-circle ml-1"></i></p>
			<p class="progress-bar"><ngb-progressbar type="primary" [value]="pesoTotal <= 100 ? pesoTotal : 0"></ngb-progressbar></p>
		</div>
	</evo-stepper-footer>
</div>
