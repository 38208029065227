import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { Segment } from './segment';

export class State implements Deserializable {
	id: number;
	codIBGE: number;
	name: string;
	region: string;
	initials: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}