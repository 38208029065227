import { Component, Input, OnInit } from '@angular/core';
import { InteractionStatusColorEnum } from '../../_helpers/interaction-status.enum';
import { InteractionStatus } from '../../_models/interaction-status';

@Component({
  selector: 'app-legend-indicator',
  templateUrl: './legend-indicator.component.html',
  styleUrls: ['./legend-indicator.component.scss']
})
export class LegendIndicatorComponent implements OnInit {

  @Input() status;

  constructor() { }

  ngOnInit(): void {
  }

  getStatusColor(status:InteractionStatus) {
    if (!status.id) return;

    return InteractionStatusColorEnum[status.id]
  }
}
