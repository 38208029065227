import { ChecklistItem } from './checklist-item';
import { Contestation } from './contestation';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Interaction } from './interaction';
import { Justification } from './justification';
import { Operation } from './operation';
import { Segment } from './segment';
import { State } from './state';
import { User } from './user';

export class MonitorInteractionItem implements Deserializable {
    id: number;
	answer: any;
	explanation: string;
	justification: Justification = new Justification();
    interaction: Interaction;
    customer: Customer;
    itensChecklist: ChecklistItem;
    peso: any;
    status: any;
    registerDate: Date;
    alteredDate: Date;
    userMonitor: User;
    contested: boolean = false;
    contestationAcceptComment: string;

    contestations: Contestation[] = [];

    contestationAcceptJustification?:Justification = new Justification();

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}