import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import WaveSurfer from 'wavesurfer.js';
import Regions from 'wavesurfer.js/dist/plugins/regions.esm.js'
import { BucketService } from '@app/services';
import { FileInfo } from '@app/models';


@Component({
  selector: 'clp-audio-player',
  templateUrl: './clp-audio-player.component.html',
  styleUrls: ['./clp-audio-player.component.scss']
})
export class ClpAudioPlayerComponent implements OnInit, OnDestroy {
  public audioURL: string;
  @Input() fileInfoId: number;
  @Output() updateHighlightTimestamp: EventEmitter<number> = new EventEmitter<number>();
  wave: WaveSurfer;
  timeIntervalId: any;
  timeInterval = 0;
  totalAudioDuration = 0;
  volumePercentage = 100;
  openedVolumeSlider = false;
  playbackSpeed = 1;
  playbackSpeedOptions = [0.25, 0.5, 1, 1.5, 2];
  audioIsPlaying = false;
  playbackError = false;

  audioLoaded = false;
  isLoading = false;

  constructor(private cdr: ChangeDetectorRef, private bucketService: BucketService) { }

  ngOnInit(): void {
    this.generateWaveform();
    const volumeInput = document.querySelector('#volume');
    const onChangeVolume = e => {
      this.wave.setVolume(e.target.value);
      this.volumePercentage = e.target.value * 100;
    };
    volumeInput.addEventListener('input', onChangeVolume);
    volumeInput.addEventListener('change', onChangeVolume);

    if (this.audioURL) {
      this.onPreviewPressed();
      this.setTimeInterval();
    }
  }

  ngOnDestroy() {
    if (this.timeIntervalId) {
      clearInterval(this.timeIntervalId);
      this.wave.destroy();
    }
  }

  togglePlaybackSpeed() {
    const idx = this.playbackSpeedOptions.indexOf(this.playbackSpeed);
    this.playbackSpeed = this.playbackSpeedOptions[idx === 4 ? 0 : idx + 1];
    this.wave.setPlaybackRate(this.playbackSpeed);
  }

  onPreviewPressed(): void {
    if (!this.wave) {
      this.generateWaveform();
    }

    this.cdr.detectChanges();
    // Promise.resolve().then(() => this.wave.load(this.audioURL));
  }

  loadAudio(): void {
	  this.isLoading = true;

    this.bucketService.getAudioFileUrl(this.fileInfoId).subscribe(
        url => {
          this.audioURL = url;

          Promise.resolve().then(() => {
            this.wave.load(this.audioURL);
          });
        },
        error => {
          console.error(error);
        }
    );


  }

  onStopPressed(): void {
    this.setTimeInterval();
    this.wave.stop();
  }

  onPlayPressed(): void {
    this.setTimeInterval();
    this.wave.play();
  }

  onPausePressed(): void {
    this.wave.pause();
  }

  setAudioCursorTo(seconds: number): void {
    this.wave.setTime(seconds);
  }

  setTimeInterval(): void {
	  if(!this.wave) return;

    // clear
    if (this.timeIntervalId) {
      clearInterval(this.timeIntervalId);
    }
    // reset
    this.timeInterval = 0;
    this.timeIntervalId = setInterval(() => {
      this.timeInterval = this.wave.getCurrentTime();
      this.updateHighlightTimestamp.emit(this.timeInterval);
    }, 100);
  }

  generateWaveform(): void {
    Promise.resolve(null).then(() => {
      this.wave = WaveSurfer.create({
	      backend: 'MediaElement',
        height: 53,
        container: '#waveform',
        waveColor: '#c0c0c0',
        progressColor: '#0056b3',
        plugins: [
          Regions.create({
            dragSelection: {
              slop: 5
            }
          })
        ]
      });

      this.wave.on('ready', () => {
        this.totalAudioDuration = this.wave.getDuration();
	      this.audioLoaded = true;
	      this.isLoading = false;
      });
      this.wave.on('play', () => {
        this.audioIsPlaying = true;
      });
      this.wave.on('pause', () => {
        this.audioIsPlaying = false;
      });
      this.wave.on('error', () => {
		  this.isLoading = false;
        this.playbackError = true;
      });
    });
  }
}
