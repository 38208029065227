import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { State } from '@app/models';

@Injectable({ providedIn: 'root' })
export class StateService {
    basePath: string = `${environment.apiUrl}/states`;

  constructor(private http: HttpClient) { }

  findAll() {
    return this.http.get<State[]>(`${this.basePath}`);
  }
}
