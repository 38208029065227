import { Component, OnDestroy, OnInit } from '@angular/core';
import { CreateChecklistService } from '@app/services';
import { ChecklistItem } from '@app/models';
import { ChecklistItemService, ChecklistItemServiceV2 } from '@app/services';
import { ToastService } from '@app/services';
import { GroupService } from '@app/services';
import { FirstStepsService } from '@app/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChecklistService } from '@app/services';
import { ChecklistItemFilter } from '@app/models';
import { ConfirmDialogComponent } from '../../../../_components/confirm-dialog/confirm-dialog.component';
import { HelpDeskService } from '@app/services';
import { SegmentService } from '@app/services';

@Component({
	selector: 'app-checklist-manual-step-two',
	templateUrl: './checklist-manual-step-two.component.html',
	styleUrls: ['./checklist-manual-step-two.component.scss']
})
export class ChecklistManualStepTwoComponent implements OnInit, OnDestroy {
	public isLoading: boolean = false;
	public groups: any[] = [{
		id: null,
		name: null,
		categories: [],
		active: true
	}];

	public showGroups: boolean = false;

	public get pesoTotal(): number {
		let total = this.simpleArrayFromGroups.map(item => item).reduce((acc, category) => {
			if (category.active && category.peso && category.peso != 100) {
				let pesoInt = Math.round(parseFloat(category.peso) * 10);
				return acc + pesoInt;
			} else {
				return acc;
			}
		}, 0) / 10;

		return this.roundToOneDecimalPlace(total);
	}

	private roundToOneDecimalPlace(value: number): number {
		return Math.round(value * 10) / 10;
	}


	get simpleArrayFromGroups() {
		return this.groups.reduce((acc, group) => (acc = [...acc, ...group.categories]), []);
	}

	private subs = new Subscription();

	constructor(public createChecklistService: CreateChecklistService,
	            public checklistItemService: ChecklistItemService,
	            public checklistService: ChecklistService,
	            public groupService: GroupService,
				private segmentService: SegmentService,
	            private modalService: NgbModal,
	            private toastService: ToastService,
	            private activeModal: NgbActiveModal,
				private helpDeskService: HelpDeskService,
	            public firstStepsService: FirstStepsService,
				public ChecklistItemServiceV2: ChecklistItemServiceV2) {
	}

	public ngOnInit() {

		if(!this.createChecklistService.currentChecklist?.id) {
			this.showGroups = true;
			return;
		}

		this.checklistItemService.filterWithChecklist({} as ChecklistItemFilter, this.createChecklistService.currentChecklist.id).subscribe(
			data => {
				this.groups = [];

				data.content.forEach(item => {
					let group = this.groups.find(group => group.id == item.group.id);

					if(!group) {
						group = item.group;
						this.groups = [ ...this.groups, item.group ];
					}

					if(!group.categories) {
						group.categories = [];
					}

					let newItem = {
						id: item.id,
						descricao: item.descricao,
						name: item.name,
						peso: item.peso,
						isSaved: false,
						active: item.active,
						opened: false,
						group: {
							id: group.id,
							name: group.name,
							active: true
						},
						checklist: {
							id: this.createChecklistService.currentChecklist.id,
							type: this.createChecklistService.currentChecklist.checkListType
						}
					}

					group.categories = [ ...group.categories, newItem ];
					this.createChecklistService.addCategory(newItem);
				});

				this.showGroups = true;
			}
		)
	}

	public showHelper(): void {
		this.helpDeskService.helperContent = `
			<h5>O que são os grupos?</h5>

			<p>Os grupos são um conjunto de itens com propósitos semelhantes.</p>

			<ul><li>Um grupo de “Qualidade” possui itens que garantem a qualidade do processo, ex: Confirmação de cadastro, Verificação de dúvidas.</li>
			<li>Um grupo de “Negócio” possui itens como Argumentação, Sondagem que ajudam no resultado do negócio.</li></ul>

			<p>Caso não queria separar seu checklist em grupos, crie apenas um grupo com um nome genérico da sua escolha.</p>

			<p>Após a criação do grupo, deve-se adicionar os itens do checklist. Caso tenha mais de 1 grupo, adicione novos grupos para adicionar mais itens.</p>

			<h5>O que são os pesos?</h5>

			<p>Os pesos são os valores de penalizações que serão considerados em caso de não realização de cada item pelo operador.</p>

			<ul><li>Toda avaliação começa com um total de 100 pontos.</li>
			<li>O operador que não cumprir um item durante a interação, terá o valor do item descontado dos pontos.</li>
			<li>A somatória dos pesos para todos os itens deve ser 100 (exceto NCGs).</li></ul>

			<p>Caso entenda que algum item não cumprido deva zerar a interação, ou seja, uma não conformidade grave (NCG), selecione a opção NCG.</p>
		`;

		this.helpDeskService.showHelpSidebar = true;
	}

	public addGroup(): void {
		this.showGroups = true;
		this.groups = [ ...this.groups, { id: null, name: null, categories: [], active: true }];
	}

	public addItem(group): void {
		this.openCloseItem();

		group.invalid = false;

		if(!group.id) {
			group.invalid = true;
			return;
		}
		group.categories = [ ...group.categories, {
			name: null,
			peso: 0,
			active: true,
			opened: true,
			group: { id: group.id, name: group.name },
			checklist: {
				id: this.createChecklistService.currentChecklist.id,
				type: this.createChecklistService.currentChecklist.checkListType
			}
		}]
	}

	public onRemoveGroup(group): void {
		group.categories.forEach(item => {
			item.active = false;

			if(!item.id) {
				this.removeGroupItem(group, item);
			}
		});

		if(group.categories.length > 0) {
			group.active = false;
			return;
		}

		this.removeGroupItem(group);
	}

	public changeStatusItem(group, item): void {
		const modalRef = this.modalService.open(ConfirmDialogComponent, {});
		modalRef.componentInstance.text = 'Deseja remover o item: ' + item.name + '?';
		modalRef.componentInstance.title = 'Remover item';

		modalRef.closed.subscribe(
			result => {
				if(result) {
					item.active = false;

					if(!item.id) {
						this.removeGroupItem(group, item);
					}
				}
			}
		)
	}

	private removeGroupItem(group, item = null): void {
		let elements = this.groups;
		let target = group;

		if(item) {
			elements = group.categories;
			target = item;
		}

		elements.indexOf(target) > -1 && elements.splice(elements.indexOf(target), 1);
	}

	public saveItem(group, category) {
		const item = new ChecklistItem().deserialize({
			descricao: category.descricao,
			name: category.name,
			peso: category.peso,
			active: category.active,
			checklist: {
				id: this.createChecklistService.currentChecklist.id,
				type: this.createChecklistService.currentChecklist.checkListType
			},
			group
		});

		if(category.id) {
			item.id = category.id;
		}

		this.createChecklistService.addCategory(item);
		category.isSaved = true;
		this.createChecklistService.currentChecklist.itensChecklist.push(item);
	}

	public get invalidForm(): boolean {
		let invalid = false;

		this.groups.forEach(group => {
			group.categories.some(category => {
				if(category.peso === '' || category.peso < 0 || category.peso == null || !category.name) {
					invalid = true;
					return true;
				}
				return false;
			})
		});

		return invalid;
	}

	public onNext() {
		this.isLoading = true;

		let allItems = [];

		this.groups.forEach(group => {
			group.categories.forEach(category => {
				if (category.id == null) {
					this.saveItem(group, category);
				}
			});
		});

		this.groups.forEach(group => {
			allItems = [ ...allItems, ...group.categories];
		});

		if(this.createChecklistService.currentChecklist.id) {

			allItems.forEach(item => {
				item.checklist = {
					id: this.createChecklistService.currentChecklist.id
				};
			})

			this.createChecklistService.currentChecklist.itensChecklist = [];

			this.checklistService.put(this.createChecklistService.currentChecklist, this.createChecklistService.currentChecklist.id).subscribe(
				data => {

					this.ChecklistItemServiceV2.updateItemsChecklist(allItems).subscribe(
						data => {
							if(this.createChecklistService.isFirstSteps) {
								this.finishAsFirstStep();
							} else {
								this.toastService.showSuccess('Checklist alterado com sucesso!');
								this.activeModal.close();
							}
						}, error => {
							this.toastService.showDanger('Erro ao salvar os itens do checklist.');
							console.error(error);
						}).add(() => this.isLoading = false);
				}, error => {
					this.isLoading = false;
				})

		} else {

			this.checklistService.post(this.createChecklistService.currentChecklist).subscribe(
				data => {
					this.toastService.showSuccess('Checklist criado com sucesso!');

					if(this.createChecklistService.isFirstSteps) {
						this.finishAsFirstStep();
					}

					this.activeModal.close();
				}, error => {
					console.error(error);
				}).add(() => this.isLoading = false);

		}
	}

	private finishAsFirstStep(): void {
		this.segmentService.getChecklistAvailable().subscribe(
			data => {
				if(data.length == 0) {
					this.firstStepsService.setStepAsDone(2);
					this.firstStepsService.setStepAsEnabled(3);
					this.firstStepsService.setCurrentStep(3);
					this.firstStepsService.onboardingStatus.checklist = true;
					this.activeModal.close();
					return;
				} else {
					this.createChecklistService.openNewChecklistModal().subscribe(
						result => {
							if (result === 'IMPORT') {
								this.activeModal.close();
							}
						});
					return;
				}
			});
	}

	public onPrev() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.createChecklistService.prevStep();
		}));
	}

	public isPesoInvalid(value: string): boolean {
		return (value == '' || parseFloat(value) < 0 || parseFloat(value) > 100);
	}

	public onItemFocus(item) {
		item.isSaved = false;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	public itemNcg(peso: number) : boolean {
		return peso == 100;
	}

	public resetNcgValue(item) : void {
		if(item.peso != null && item.peso == 100) {
			item.peso = 0;
		} else {
			item.peso = 100;
		}
	}

	public openCloseItem(item: any = null): void {
		this.groups.forEach(group => {
			group.categories.forEach(category => {
				category.opened = false;
			});
		});

		if(item) {
			item.opened = true;
		}
	}

	public validateActiveGroup(group): boolean {
		let active = false;

		group.categories.some(item => {
			active = item.active;
			return item.active;
		});

		return active;
	}
}
