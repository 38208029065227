export class CreatePbiReportRequest {
	public groupId: string;
	public reportId: string;
	public groupName: string;
	public reportName: string;
	public imageUrl: string;
	public description: string;
	public customerId: number;
	public permissionCode: string;
}
