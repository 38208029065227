import { Deserializable } from './deserializable';

export class ReportStatus implements Deserializable {
	id: string;
	status: string;
	name: string;

	bgClass: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export enum ReportStatusEnum {
    PENDING = <any>"Pendente",
    IN_PROGRESS = <any>"Em andamento",
    FAILED = <any>"Falha",
    FINISHED = <any>"Finalizado",
    CANCELED = <any>"Cancelado"
}

export enum StatusModalEnum {
	MANUAL_MONITOR = "Monitoramento Manual",
	REVISION = "Revisão",
	PENDING = "Pendente",
	EVALUATION = "Avaliado",
	FEEDBACK_ACCEPTED = "Feedback Aceito",
	CONTESTED = "Contestado",
	REPLICA = "Réplica",
	REPLICA_SENDED = "Réplica Enviada",
	REPLICA_ACCEPTED = "Réplica Aceita",
	REPLICA_NOT_ACCEPTED = "Réplica Não Aceita",
	FEEDBACK = "Feedback",
	FINISHED = "Finalizado",
	DISCARDED = "Descartado",
	IN_PROGRESS = "Em Progresso",
	NOT_EFFECTIVE = "Não Eficaz",
	REPROCESS_FAILED = "Reprocessamento Falhou",
	IN_ANALYSIS = "Em Análise",
	IN_TRANSCRIPTION = "Em Transcrição",
	IN_RELEASE = "Em Liberação"
  }



export enum ReportTypeEnum {
	INTERACTION = <any>"Interação",
	CONTESTATION = <any>"Contestação",
	INTERACTION_ITEM = <any>"Itens",
	INTERACTION_ITEM_COLUMN = <any>"Colunas de Itens"
}

export enum ReportStatusColorEnum {
	PENDING = "badge",
	IN_PROGRESS = "badge badge-soft-info",
	FAILED = "badge badge-soft-danger",
	FINISHED = "badge badge-success",
	CANCELED = "bg-warning"
}
