import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './baseService.service';

@Injectable({
  providedIn: 'root'
})
export class BucketService extends BaseServiceClass<any> {

  constructor(protected httpService: HttpClient) {
    super(httpService, '/buckets');
  }

  getUploadUrl(filename: string, isPublic: boolean = false): Observable<{file: string, url: string}> { //fileInfoId
	  let params = {
		  isPublic
	  };

    return this.httpService.get<{file: string, url: string}>(`${this.entity_url}/upload/url/${filename}`, { params } );
  }

  public getUploadUrlFileInfo(file: string): Observable<{ file: string, url: string, fileInfoId: number }> {
	  return this.httpService.get<{ file: string, url: string, fileInfoId: number }>(`${this.entity_url}/fileInfos/upload/url/${file}`);
  }

	getDownloadUrl(filename: string): Observable<string> {
		return this.httpService.get<string>(`${this.entity_url}/download/url/${filename}`);
	}

	getFileInfoUrl(id: number): Observable<string> {
		return this.httpService.get<string>(`${this.entity_url}/fileInfos/${id}`);
	}

	public getAudioFileUrl(id: number): Observable<string> {
	  return this.httpService.get<string>(`${this.entity_url}/fileInfos/audio/${id}`);
	}

  uploadMetadata(file: FormData) {
    return this.httpService.post(`${this.entity_url}/interactions/metadata`, file);
  }
}
