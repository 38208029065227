import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SystemMessages, ToastService } from '@app/services';
import { Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { Segment } from '@app/models';
import { Operation } from '@app/models';
import { ImportDataService, ImportTypes } from '@app/services';
import { SegmentService } from '@app/services';
import { FlowService } from '@app/services';
import { OperationService } from '@app/services';
import { PageResponse } from '@app/models';
import { Flow } from '@app/models';
import { OperationFilter } from '@app/models';

@Component({
	selector: 'app-import-data-step-one',
	templateUrl: './import-data-step-one.component.html',
	styleUrls: ['./import-data-step-one.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({opacity: 0}),
				animate('200ms 260ms ease-in-out', style({opacity: 1}))
			])
		])
	]
})
export class ImportDataStepOneComponent implements OnInit, OnDestroy {
	form: FormGroup;
	segments: Segment[] = [];
	operations: Operation[] = [];

	get segmentId() {
		return this.form.get('segmentId');
	}

	get operationId() {
		return this.form.get('operationId');
	}

	get checklistId() {
		return this.form.get('checklistId');
	}

	subs = new Subscription();
	isOperationsLoading = false;
	showOperationAlert: boolean = false;

	public segmentChecklists = [];

	get selectedSegment() {
		return this.segments.find(segment => segment.id === this.segmentId.value);
	}

	constructor(public importDataService: ImportDataService,
	            private formBuilder: FormBuilder,
	            private segmentService: SegmentService,
	            private flowService: FlowService,
	            private modalService: NgbModal,
	            private toastService: ToastService,
	            private router: Router,
	            private operationService: OperationService) {
	}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			segmentId: [this.importDataService.segmentId, Validators.required],
			operationId: [this.importDataService.operationId, Validators.required],
			checklistId: [{ value: this.importDataService.checklistId, disabled: true} , Validators.required]
		});

		this.filterSegments().pipe(
			filter(() => !!this.segmentId.value),
			switchMap(() => {
				return this.filterOperations();
			})).subscribe();

		this.subs.add(this.form.get('segmentId').valueChanges.pipe().subscribe(() => {
			this.segmentChecklists = this.selectedSegment.checklists.filter(item => item.active);
			this.form.get('checklistId').enable();
		}));

		this.subs.add(this.form.valueChanges.pipe(
			filter((form) => !!form.segmentId && !!form.operationId),
			switchMap((form) => {
				return this.flowService.filter({segmentId: +form.segmentId, operationId: +form.operationId});
			}),
			catchError(() => null)
		).subscribe((flow: PageResponse<Flow>) => {
			this.showOperationAlert = flow ? flow.content.length === 0 : false;
		}));
	}

	filterSegments() {
		return this.segmentService.findAll().pipe(tap(segments => {
			this.segments = segments.filter((segment) => {
					if (!segment.checklists || segment.checklists.length == 0 || !segment.active) return false;
					// if (this.importDataService.importType == ImportTypes.METADATA && segment.checklists[0].checkListType == 'AUTOMATIC') return false;

					return true;
				})
			}
		));
	}

	filterOperations() {
		this.operationId.setValue(null);

		this.form.get('operationId').disable();
		this.isOperationsLoading = true;

		let operationFilter: OperationFilter = {
			name: '',
			segmentIds: [this.segmentId.value]
		};
		return this.operationService.filter(operationFilter).pipe(tap((operations: PageResponse<Operation>) => {
			this.isOperationsLoading = false;
			this.form.get('operationId').enable();
			this.operations = operations.content;
			if (this.importDataService.operationId) this.operationId.setValue(this.importDataService.operationId);
		}, error => {
			this.isOperationsLoading = false;
			this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
			console.error(error);
		}));
	}

	navigateToNewFlow() {
		this.router.navigate(['/flow/new']);
		this.modalService.dismissAll();
	}

	onPrev() {
		this.importDataService.prevStep();
	}

	onNext() {
		this.importDataService.segmentId = this.segmentId.value;
		this.importDataService.operationId = this.operationId.value;
		this.importDataService.checklistId = this.checklistId.value;

		this.importDataService.nextStep();
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
