import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { Interaction, QualityPermissions, User } from "@app/models";
import { UserService, InteractionService, InteractionServiceV2, ToastService } from "@app/services";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmDialogComponent } from "app/_components/confirm-dialog/confirm-dialog.component";
import { saveAudioFile, saveFile } from "app/_helpers/file-helper";
import { InteractionStatusEnum } from "app/_helpers/interaction-status.enum";

@Component({
    selector: 'interaction-simplified',
    templateUrl: './interaction-simplified.component.html',
    styleUrls: ['./interaction-simplified.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class InteractionSimplifiedComponent implements OnInit {
	@Input()
	public interaction: Interaction;

	@Output()
	public close: EventEmitter<any> = new EventEmitter();

	@Output()
	public firstLoadingDone: EventEmitter<any> = new EventEmitter<any>();
    
    loading = false;
    currentUser: User;
    isAudio: boolean = false;
    qualityPermissions = QualityPermissions;
    
    public interactionAudioUrl: string;

    constructor(private userService: UserService,
        private interactionService: InteractionService,
        private interactionServiceV2: InteractionServiceV2,
        private toastService: ToastService,
        private http: HttpClient,
        private modalService: NgbModal) {

    }

    ngOnInit(): void {
        this.currentUser = this.userService.currentUser;

        if(this.interaction.fileInfo && this.interaction.fileInfo.originalFileName) {
		    let fileExtension = '.' + this.interaction.fileInfo.originalFileName.toLowerCase().split('.').pop();
		    const audioTypes = ['.wav', '.mp3', '.mp4', '.wmv', '.g'];
		    this.isAudio = audioTypes.includes(fileExtension);
	    }

        this.firstLoadingDone.emit();

        this.interactionAudioUrl = this.interaction.audioUrl;
    }

    downloadFile() {
        if(this.isAudio){
            this.http.get(this.interactionAudioUrl, {responseType: 'blob'}).subscribe(response => {
                saveAudioFile(response, this.interaction.originalFileName);
            });
        }else{
            this.interactionService.downloadFile(this.interaction.fileInfo.id).subscribe(
                (data) => saveFile(data, this.interaction.fileInfo.originalFileName, this.interaction.fileInfo.type));

        }
    }

    discardMonitor() {
        const modalRef = this.modalService.open(ConfirmDialogComponent, {});
        modalRef.componentInstance.text = `Gostaria de descartar a monitoria ${this.interaction.id}`;
        modalRef.componentInstance.title = "Descartar Monitoria";
        modalRef.result.then((data) => {
            if (data) {
                this.loading = true;
                this.interaction.status.id = InteractionStatusEnum.DISCARDED.toString();
				this.interaction.status.name = 'Descartada';
                this.interaction.deadLine = null;
				this.interaction.inAnalysis = false;
                this.interactionService.patch(this.interaction).subscribe(response => {
                    console.debug("success");
                    this.toastService.showSuccess("Monitoria descartada com sucesso");
					this.closeHandler();
                },
                    error => this.toastService.showDanger("Erro ao descartar monitoria")).add(() => this.loading = false);
            } 
        });
    }

	copyToClipboard(ev, interaction: Interaction) {
		ev.stopPropagation();
		navigator.clipboard.writeText(interaction.fileInfo?.originalFileName || interaction.fileInfo?.fileName)
		.then(() => this.toastService.showSuccess('Nome do Arquivo copiado para o seu clipboard.'))
		.catch(e => console.error(e));
	}

    public closeHandler(): void {
		this.close.emit(this.interaction);
	}

    public get interactionMetadata() {
		if(this.interaction.metadata) {
			return Object.entries(this.interaction.metadata);
		}

		return null;
	}
}