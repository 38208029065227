<button class="btn btn-info btn-sm" container="body" placement="bottom" ngbTooltip="Realizar feedback" (click)="openModal()">
    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>Feedback
</button>
<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Feedback</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
        <p>Por favor, preencha o feedback para o operador a respeito desta monitoria</p>
        <form [formGroup]="feedbackForm">
            <div class="row mt-2">
                <div class="form-group col">
                    <textarea type="text" formControlName="feedback" class="form-control" style="resize: none;" rows="5"
                        [ngClass]="{ 'is-invalid': (feedback.dirty || feedback.touched) && feedback.errors }"></textarea>
                    <div *ngIf="(feedback.dirty || feedback.touched) && feedback.errors" class="invalid-feedback">
                        <div *ngIf="feedback.errors.required">Feedback é obrigatório</div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading || !feedbackForm.valid" class="btn btn-primary" (click)="finishFeedback(); modal.close();">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Confirmar
        </button>
    </div>
</ng-template>
