import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import {
	ConfirmDangerDialogComponent
} from '../../../_components/confirm-danger-dialog/confirm-danger-dialog.component';

@Directive({
  selector: '[evolloConfirmDangerAction]'
})
export class ConfirmDangerActionDirective {
  @Input() title: string = 'default';
  @Input() text: string = 'default';

  @Output() confirm: EventEmitter<boolean> = new EventEmitter();

  constructor(private modalService: NgbModal) { }

  @HostListener('click', ['$event']) onClick() {
    const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
    modalRef.componentInstance.text = this.text;
    modalRef.componentInstance.title = this.title;

    modalRef.closed.pipe(filter(result => !!result)).subscribe(() => this.confirm.emit(true));
  }

}
