import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'evo-stepper',
  templateUrl: './evo-stepper.component.html',
  styleUrls: ['./evo-stepper.component.scss']
})
export class EvoStepperComponent implements OnInit {
  @Input() steps: {labels: string}[] = [];
  @Input() currentStep: number = 0;

  constructor() { }

  ngOnInit(): void {
  }

}
