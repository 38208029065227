import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CreateChecklistService } from '../../_services/create-checklist.service';
import { HelpDeskService } from '../../_services/help-desk.service';
import { ConfirmDangerDialogComponent } from '../confirm-danger-dialog/confirm-danger-dialog.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'evo-stepper-header',
  templateUrl: './stepper-header.component.html',
  styleUrls: ['./stepper-header.component.scss']
})
export class StepperHeaderComponent implements OnInit, OnDestroy {
  @Input() currentStep: number;
  @Input() steps: any;
  @Input() isLoading: boolean = false;

  @Input() confirmOnClose: boolean = true;
  @Input() closeConfirmationText: string = 'Deseja realmente fechar? Você perderá todas as alterações.';
  @Input() closeConfirmationTitle: string = 'Confirmar';
  @Input() ignoredStepsOnClose: number[] = [0];

  private subs = new Subscription();

  constructor(public activeModal: NgbActiveModal, private helpDeskService: HelpDeskService, private modalService: NgbModal,) { }

  ngOnInit(): void {
  }

  public checkCloseModal(): void {
	  if(!this.confirmOnClose || this.ignoredStepsOnClose.find(step => step == this.currentStep) != undefined) {
		  this.closeModal();
		  return;
	  }

	  const modal = this.showCloseConfirmation();
	  this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
		  this.modalService.dismissAll();
		  this.closeModal();
	  }));
  }

  private closeModal(): void {
	  this.activeModal.close();
	  this.helpDeskService.resetHelper();
  }

	private showCloseConfirmation(): NgbModalRef {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = this.closeConfirmationText;
		modalRef.componentInstance.title = this.closeConfirmationTitle;
		return modalRef;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
