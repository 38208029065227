import { Component, Input } from '@angular/core';
import { InteractionStatus } from '@app/models';

@Component({
  selector: 'evo-status-badge',
  templateUrl: './evo-status-badge.component.html',
  styleUrls: ['./evo-status-badge.component.scss']
})
export class StatusBadgeComponent {
  @Input() statusModel: InteractionStatus;
  @Input() inAnalysis: boolean = false;
  badgeClassName: string;

  constructor() {}

  getStatusClass(): string {
    if (this.statusModel?.name === 'Contestação Improcedente' && this.statusModel?.id === 'REPLICA') return 'badge-warning';
    switch (this.statusModel?.id) {
      // Danger
      case 'REVISION':
      case 'CONTESTED':
      case 'REPLICA':
      case 'FEEDBACK':
        return 'badge-danger';
      // Warning
      case 'EVALUATION':
      case 'FEEDBACK_ACCEPTED':
      case 'REPLICA_SENDED':
      case 'CONTESTATION_DECLINED':
      case 'RATED':
      case 'MANUAL_MONITOR':
        return 'badge-warning';
      // Success
      case 'FINISHED':
        return 'badge-success';
      // Light
      case 'PENDING':
      case 'DISCARDED':
      case 'REPLICA_NOT_ACCEPTED':
      case 'REPLICA_ACCEPTED':
      default:
        return 'badge-light';
    }
  }
}
