import { InteractionStatus } from "./interaction-status";
import { User } from "./user";

export class AnalysisHistory {
	id: number;
	startedAt?: any;
	finishedAt?: any;
	startedBy: User;
	interactionPreviousStatus: InteractionStatus;
	active: boolean;
	inactivatedBy?: any;
}
