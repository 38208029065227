import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-only-premium-dialog',
  templateUrl: './only-premium-dialog.component.html',
  styleUrls: ['./only-premium-dialog.component.scss']
})
export class OnlyPremiumDialogComponent implements OnInit {

  private pricingPage : string = "https://evollo.com.br/planos-e-precos/";

  constructor(public modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  redirectToPricing() {
    this.modal.close();
    window.open(this.pricingPage, "_blank");
  }

}
