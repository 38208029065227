import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { MonitorInteractionItem } from '@app/models';
import { ReportByFilter } from '@app/models';
import * as _ from 'lodash';
@Injectable({ providedIn: 'root' })
export class MonitorInteractionItemService {
  basePath: string = `${environment.apiUrl}/monitor-interactions`;

  constructor(private http: HttpClient) { }

  findByInteraction(interactionId:number){
    return this.http.get<MonitorInteractionItem[]>(`${this.basePath}/interactions/${interactionId}`);
  }

  saveBatch(items:MonitorInteractionItem[]) {
    return this.http.post<any>(`${this.basePath}/batch`, items);
  }
  patch(item:MonitorInteractionItem) {
    return this.http.put<any>(`${this.basePath}/${item.id}`, item);
  }

  getInteractionReport(reportByFilter: ReportByFilter) {
    // let options = { params: <any> _.omitBy(reportByFilter, _.isNil)};
    return this.http.post<any>(`${this.basePath}/report`, reportByFilter);
  }


  interactionItemReportDownload(reportByFilter: ReportByFilter, type: string) {
    let options = { responseType: 'blob' as 'json'};

    return this.http.post<any>(`${this.basePath}/report/download/${type}`, reportByFilter, options);
  }

  interactionItemReportDownloadExcel(reportByFilter: ReportByFilter) {
    let options = { responseType: 'blob' as 'json'};

    return this.http.post<any>(`${this.basePath}/report/download/XLSX`, reportByFilter, options);
  }

  interactionItemReportDownloadCSV(reportByFilter: ReportByFilter) {
    let options = { responseType: 'blob' as 'json'};

    return this.http.post<any>(`${this.basePath}/report/download/CSV`, reportByFilter, options);
  }

  distinctChecklistItems(reportByFilter: ReportByFilter) {
    let options = { params: <any> _.omitBy(reportByFilter, _.isNil)};
    return this.http.get<any>(`${this.basePath}/items`, options)
  }

  // contestation(itemId:number, contestation:string){
  //   return this.http.put<any>(`${this.basePath}/${itemId}/contestation`, contestation);
  // }

}
