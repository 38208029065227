import { EvoModel } from './evo-model';

export class Product extends EvoModel {

	public id: number;
	public name: string;
	public logo: string;
	public alias: string;

}
