<div class="sidenav-component" @fadeInLeft>
  <!-- Navbar -->
  <div class="clp-sidebar">
    <div class="brand-section">
      <img class="navbar-brand-logo" *ngIf="organizationService.currentOrganization && organizationService.currentOrganization.logoUrl"
           [src]="organizationService.currentOrganization.logoUrl">
	    <div [hidden]="barIsCollapsed" class="evo-product QUALITY" *ngIf="organizationService.currentOrganization && !organizationService.currentOrganization.logoUrl"></div>
      <span container="body" placement="top"
            [ngbTooltip]="organizationService.currentOrganization.name">
            {{ organizationService.currentOrganization.name }}
      </span>
    </div>
    <!--<div class="collapse-icon" (click)="toggleNavbar()">
      <i class="fas fa-angle-double-right"></i>
    </div>-->
    <div class="navigation-tabs">
      <!--@ Navigation section -->
      <nav class="nav flex-column mt-4">
        <div *ngFor="let navTabItem of navigationMenus">
          <hr *ngIf="navTabItem.id === 'administrative'">
          <sidebar-menu-component
                  [(activeNav)]="activeNavId"
                  [barIsCollapsed]="barIsCollapsed || barIsExpanding"
                  [sidebarMenu]="navTabItem">
          </sidebar-menu-component>
        </div>
      </nav>
    </div>
  </div>

	<div class="import-limit">

		<!-- <div class="d-flex align-items-top plan-title mb-2">
			<img class="mr-2" src="assets/icons/wallet.svg" width="16" height="16"/>
			<span class="" style="white-space: normal">{{ organizationService.currentOrganization.plan.name }}</span>
		</div>

		<div class="d-flex flex-column import-info mb-3" *ngIf="totalMonth !== undefined && organizationService.currentOrganization.plan.name !== 'Enterprise'">
			<div>CHAMADAS IMPORTADAS</div>
			<div progress class="progress-wrapper">
				<p class="progress-bar mb-0" style="border-radius: 8px; height: 4px"><ngb-progressbar type="primary" [value]="totalMonth / organizationService.currentOrganization.plan.interactionsPerMonth"></ngb-progressbar></p>
			</div>
			<div class="d-flex justify-content-between">
				<div class="">{{ totalMonth }}/{{organizationService.currentOrganization.plan.interactionsPerMonth}}</div>
				<div class="">MENSAL</div>
			</div>
		</div>

		<a class="btn btn-outline-primary w-100" href="https://evollo.com.br/planos-e-precos/" *ngIf="organizationService.currentOrganization.plan.name !== 'Enterprise'" target="_blank">Upgrade</a> -->

	</div>

	<div class="quality-version" *ngIf="config">
		<span>Quality</span>

		<span>v{{ config.version }}</span>
	</div>
</div>
