import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BucketService, HelpDeskService, OrganizationService, SystemMessages } from '@app/services';
import { HelpDeskMessage, OnboardingStepStatus } from '@app/models';
import { ToastService } from '@app/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, switchMap, tap } from "rxjs/operators";
import { EMPTY, of, Subject, Subscription } from "rxjs";
import { HttpClient, HttpProgressEvent } from "@angular/common/http";

@Component({
	selector: 'help-dialog',
	templateUrl: './help-dialog.component.html',
	styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit, OnDestroy {

	public type: 'help' | 'training' = 'help';

	public helpDeskMessage: HelpDeskMessage = new HelpDeskMessage();
	public isLoading: boolean;

	public messageForm: FormGroup;

	public file: File[] = [];

	private toggleUpload$ = new Subject<boolean>();

	uploadProgress: number = 0;

	subs = new Subscription();

	public acceptTypes: string = 'text/plain text/csv image/jpeg image/png application/vnd.ms-excel application/pdf application/vnd.openxmlformats-officedocument.spreadsheetml.sheet application/msword application/vnd.openxmlformats-officedocument.wordprocessingml.document';

	public get violatedRulesFileUpload() {
		return this.file.length > 0 && this.file[0].size > 102400 * 100 ? ['Arquivo excede o limite permitido. Envie arquivos com até 100MB.'] : [];
	}

	constructor(public modal: NgbActiveModal,
	            public helpDeskService: HelpDeskService,
				private formBuilder: FormBuilder,
				public organizationService: OrganizationService,
	            private toastService: ToastService,
	            private bucketService: BucketService,
	            private httpClient: HttpClient) {
	}

	public ngOnInit(): void {
		this.messageForm = this.formBuilder.group({
			subject: [
				{
					value: this.type == 'training' ? this.organizationService.currentOrganization.name + ' | Importação Treinamento' : '',
					disabled: this.type == 'training'
				},
				[ Validators.required, Validators.min(5) ]
			],
			message: ['', [Validators.required, Validators.min(5)]],
		});

		if(this.type == 'training') {
			this.initiateSendTrainingMessage();
		}
	}

	public postMessage(): void {
		this.helpDeskMessage.subject = this.messageForm.get('subject').value;
		this.helpDeskMessage.message = this.messageForm.get('message').value;

		this.isLoading = true;

		if(this.type == 'help') {
			this.postHelpMessage();
			return;
		}

		// Start Training message
		this.toggleUpload$.next(true);
	}

	private postHelpMessage(): void {
		this.helpDeskService.sendMessage(this.helpDeskMessage).subscribe(
			data => {
				this.successResult();
			}, error => {
				this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
				console.error(error);
			}).add(() => this.isLoading = false);
	}

	private initiateSendTrainingMessage(): void {
		this.subs.add(this.toggleUpload$.pipe(
			switchMap((toggleUpload) => {
				return toggleUpload ? this.bucketService.getUploadUrl(this.file[0].name) : of(null);
			}),
			switchMap(uploadInfo => {
				if (!uploadInfo) return EMPTY;
				this.helpDeskMessage.attachment = uploadInfo.file;
				return this.httpClient.put(uploadInfo.url, (this.file[0] as File), {
					reportProgress: true,
					observe: 'events'
				});
			}),
			tap((event) => {
				if (event.type === 1) this.uploadProgress = ((event as HttpProgressEvent)?.loaded / (event as HttpProgressEvent)?.total) * 100;
			}),
			filter((event) => event.type === 4),
			switchMap( () => {
				return this.bucketService.getDownloadUrl(this.helpDeskMessage.attachment);
			}),
			switchMap(downloadInfo => {
				this.helpDeskMessage.attachment = downloadInfo;
				return this.helpDeskService.sendTrainingMessage(this.helpDeskMessage);
			})
		).subscribe(data => {
			this.successResult();
		}, (error) => {
			this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
			console.error(error);
		}).add(() => {
			this.isLoading = false;
		}));
	}

	private successResult(): void {
		this.toastService.showSuccess('Mensagem enviada, entraremos em contato em breve.');
		this.modal.close();
		this.helpDeskMessage = new HelpDeskMessage();
	}

	public cancelUpload() {
		this.toggleUpload$.next(false);
		this.uploadProgress = 0;
		this.isLoading = false;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
