import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	ChecklistService,
	CreateChecklistService,
	FirstStepsService,
	SegmentService,
	ToastService
} from '@app/services';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ConfirmDialogComponent } from '../../../../_components/confirm-dialog/confirm-dialog.component';
import { InteractionMetadataService } from '../../../../_services/interaction-metadata.service';

@Component({
	selector: 'app-checklist-manual-step-two',
	templateUrl: './checklist-metadata.component.html',
	styleUrls: ['./checklist-metadata.component.scss']
})
export class ChecklistMetadataComponent implements OnInit, OnDestroy {
	public isLoading: boolean = false;

	private subs = new Subscription();

	public metadataForm: FormGroup;
	public metadataOptions: { [key: string]: any[] } = {};

	public metadataOptionsKey = [];

	public readonly Object = Object;

	public get blocks(): FormArray {
		return this.metadataForm.get('blocks') as FormArray;
	}

	constructor(public createChecklistService: CreateChecklistService,
	            public checklistService: ChecklistService,
	            private segmentService: SegmentService,
	            private modalService: NgbModal,
	            private toastService: ToastService,
	            private activeModal: NgbActiveModal,
	            public firstStepsService: FirstStepsService,
				public interactionMetadataService: InteractionMetadataService,
	            private fb: FormBuilder) {

		this.metadataForm = this.fb.group({
			'blocks': this.fb.array([])
		})
	}

	public ngOnInit() {
		if(this.createChecklistService.currentChecklist.metadata) {
			this.populateForm(this.createChecklistService.currentChecklist.metadata);
		}

		this.getInteractionMetadata();
	}

	public getInteractionMetadata() {
		this.interactionMetadataService.getInteractionMetadata().subscribe(
			data => {
				this.metadataOptions = this.parseMetadata(data.map(item => item.metadata));
				this.metadataOptionsKey = Object.keys(this.metadataOptions);
			});
	}


	private populateForm(metadata: [ { [key: string]: any }]) {
		this.blocks.setValue([]);

		for (const block of metadata) {
			const blockArray = this.fb.array([]);

			for (const [key, value] of Object.entries(block)) {
				const fieldGroup = this.fb.group({
					property: [key, Validators.required],
					value: [value, Validators.required]
				});

				fieldGroup.get('property').valueChanges.subscribe(() => {
					fieldGroup.get('value').reset();
					this.blocks.updateValueAndValidity();
				});
				fieldGroup.get('value').valueChanges.subscribe(() => {
					this.blocks.updateValueAndValidity();
				});

				blockArray.push(fieldGroup);
			}

			this.blocks.push(blockArray);
		}
	}

	public addBlock(): void {
		const blockArray = this.fb.array([], this.checkForDuplicateMetadata);
		this.blocks.push(blockArray);

		this.addField(this.blocks.length - 1);
	}

	public removeBlock(index: number): void {
		if(this.blocks.at(index).value.length > 0) {
			let modalRef = this.modalService.open(ConfirmDialogComponent);
			modalRef.componentInstance.title = 'Remover tag';
			modalRef.componentInstance.text = 'Essa tag não está vazia, tem certeza que deseja removê-la?';
			modalRef.result.then(result => {
				if(result) {
					this.blocks.removeAt(index);
				}
			});

			return;
		}

		this.blocks.removeAt(index);
	}

	public addField(blockIndex: number): void {
		const fieldGroup = this.fb.group({
			property: [null, [Validators.required, Validators.maxLength(100)]],
			value: [null, [Validators.required, , Validators.maxLength(100)]]
		});

		(this.blocks.at(blockIndex) as FormArray).push(fieldGroup);
	}

	public removeField(blockIndex: number, fieldIndex: number) {
		(this.blocks.at(blockIndex) as FormArray).removeAt(fieldIndex);
	}

	public addMetadataPropertyOption(event: any, value: any) {
	}

	public addMetadataValueOption(event: any, value: any) {
	}

	public onNext() {
		const formValue = this.metadataForm.value.blocks;
		let transformedData: any[] = [];

		for (const block of formValue) {
			const transformedBlock = {};
			for (const field of block) {
				transformedBlock[field.property] = field.value;
			}
			transformedData.push(transformedBlock);
		}

		if(transformedData.length === 0) {
			transformedData = null;
		}

		this.createChecklistService.currentChecklist.metadata = transformedData;
		this.createChecklistService.nextStep();
	}

	public onPrev() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.createChecklistService.prevStep();
		}));
	}

	private checkForDuplicateMetadata(formArray: FormArray): ValidationErrors | null {
		const combinations = new Set<string>();
		let hasDuplicate = false;

		formArray.controls.forEach((group: AbstractControl) => {
			const property = group.get('property')?.value;
			const value = group.get('value')?.value;
			const combination = `${property}-${value}`;

			if (combinations.has(combination)) {
				hasDuplicate = true;
			} else {
				combinations.add(combination);
			}
		});

		return hasDuplicate ? { duplicateCombination: true } : null;
	}

	private parseMetadata(metadata: Array<{ [key: string]: any }>) {
		const parsedMetadata: { [key: string]: any[] } = {};

		for (const block of metadata) {
			for (const [key, value] of Object.entries(block)) {
				if (!parsedMetadata[key]) {
					parsedMetadata[key] = [];
				}
				if (!parsedMetadata[key].includes(value) && value !== null) {
					parsedMetadata[key].push(value);
				}
			}
		}

		return parsedMetadata;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	public preventSpaces(event: KeyboardEvent) {
		if (event.code === 'Space' || event.key === ' ') {
			event.preventDefault();
		}
	}
}
