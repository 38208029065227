import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChecklistItemService, OrganizationService } from '@app/services';
import { ChecklistItem, Segment } from '@app/models';

@Component({
  selector: 'evo-report-filters',
  templateUrl: './report-filters.component.html',
  styleUrls: ['./report-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportFiltersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() filters: FormGroup;
  @Input() downloadPermissions: string[];
  @Output() filter = new EventEmitter();
  @Output() downloadFile = new EventEmitter();
  @Input() downloadEnabled: boolean = true;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() showFilterBtn: boolean = true;
  @Input() showItemsFilter: boolean = false;
  @Input() showInteractionType: boolean = false;
	@Input() showDownloadBtn: boolean = true;
	@Input() showMonitorCycles: boolean = true;

  @Output() clear = new EventEmitter();

  public checklistItems: ChecklistItem[] = [];

  sub = new Subscription();

  lastMonitorDate: any;
  lastContactDate: any;

  @Input()
  public limitDate: boolean = false;

  constructor(public organizationService: OrganizationService, private checklistItemService: ChecklistItemService) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.filters.get('contactDate').setValidators([Validators.required]);
    this.filters.get('monitorDate').setValidators([Validators.required]);

    this.sub.add(this.filters.get('segments').valueChanges.subscribe(segments => {
      this.filterChecklistItems(segments);
    }));

    this.sub.add(this.filters.get('contactDate').valueChanges.pipe(filter(date => date != this.lastContactDate)).subscribe((date) => {
      this.filters.get('monitorDate').setValidators(!!date ? [] : [Validators.required]);
      this.filters.get('monitorDate').updateValueAndValidity();
      this.lastContactDate = date;
    }));
    this.sub.add(this.filters.get('monitorDate').valueChanges.pipe(filter(date => date != this.lastMonitorDate)).subscribe((date) => {
      this.filters.get('contactDate').setValidators(!!date ? [] : [Validators.required]);
      this.filters.get('contactDate').updateValueAndValidity();
      this.lastMonitorDate = date;
    }));
  }

  onDownloadFile(type) {
    this.downloadFile.emit({type});
  }

  checkFiltersAreEmpty() {
    let isEmpty = Object.values(Object.entries(this.filters.value).filter(entry => entry[0] !== 'grade').map((entry) => entry[1])).reduce((acc: boolean, filter: any) => {
        return acc = acc ? !filter || Array.isArray(filter) && (!filter[0] && !filter[1] || filter.length === 0) : acc;
    }, true) as boolean;
    if (isEmpty) {
      isEmpty = this.filters.get('grade').value[0] === 0 && this.filters.get('grade').value[1] === 100;
    }
    return isEmpty;
  }

  onFilter() {
    this.filter.emit();
  }

	public clearFilters() {
	  if(!this.checkFiltersAreEmpty()) {
		  this.filters.reset();
		  this.clear.emit();

		  if(this.filters.get('monitorDate').invalid || this.filters.get('contactDate').invalid) {
			  return;
		  }

		  this.onFilter();
	  }
	}

  private filterChecklistItems(segments: Segment[] = []): void {
    this.checklistItemService.findBySegments(segments).subscribe(response => {
      this.checklistItems = response;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
