import {
	Directive,
	Host,
	Optional,
	Renderer2,
	Self,
	ViewContainerRef,
	Input,
	Output,
	EventEmitter,
	OnChanges, SimpleChanges, OnDestroy
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import * as _ from 'lodash';
import {InteractionListComponent} from "../../../_views/interactions/interaction-list.component";
import {Subscription} from "rxjs";
import {InteractionServiceV2} from "@app/services";

@Directive({
	selector: '[style-paginator]',
})
export class StylePaginatorDirective implements OnChanges, OnDestroy {
	private _currentPage = 0;
	private _rangeStart;
	private _rangeEnd;
	private _buttons = [];

	@Output()
	public numberButton: EventEmitter<any> = new EventEmitter<any>();

	private _showTotalPages = 2;

	@Input()
	get showTotalPages(): number {
		return this._showTotalPages;
	}

	set showTotalPages(value: number) {
		this._showTotalPages = value % 2 == 0 ? value + 1 : value;
	}

	@Input()
	get totalResults(): number {
		return this.matPag ? this.matPag.length : 0;
	}

	set totalResults(value: number) {
		if(this.matPag) {
			this.matPag.length = value;
			this.matPag.pageIndex = 0;
			this._currentPage = 0;

			this.initPageRange();
		}
	}

	private onPageResetSubscription: Subscription;

	constructor(
		@Host() @Self() @Optional() private readonly matPag: MatPaginator,
		private vr: ViewContainerRef,
		private ren: Renderer2,
		private interactionService: InteractionServiceV2
	) {
		this.matPag.page.subscribe((v) => {
			this.switchPage(v.pageIndex, false);
		});

		this.onPageResetSubscription = this.interactionService.onPageReset.subscribe(() => {
			this.switchPage(0, false); // Reset to page 0
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['pageIndex'] && !changes['pageIndex'].isFirstChange()) {
			this.switchPage(this.matPag.pageIndex, false);
		}
	}

	private buildPageNumbers() {
		const actionContainer = this.vr.element.nativeElement.querySelector(
			'div.mat-paginator-range-actions'
		);
		const nextPageNode = this.vr.element.nativeElement.querySelector(
			'button.mat-paginator-navigation-next'
		);

		if (this._buttons.length > 0) {
			this._buttons.forEach((button) => {
				this.ren.removeChild(actionContainer, button);
			});
			this._buttons.length = 0;
		}

		for (let i = 0; i < this.matPag.getNumberOfPages(); i++) {
			if ((i < this._showTotalPages && this._currentPage < this._showTotalPages && i > this._rangeStart) || (i >= this._rangeStart && i <= this._rangeEnd)) {
				this.ren.insertBefore(
					actionContainer,
					this.createButton(i, this.matPag.pageIndex),
					nextPageNode
				);
			}
		}
	}

	private createButton(i: any, pageIndex: number): any {
		const linkBtn = this.ren.createElement('div');
		this.ren.addClass(linkBtn, 'custom-button');

		const pagingTxt = isNaN(i) ? '' : +(i + 1);
		const text = this.ren.createText(pagingTxt + '');

		switch (i) {
			case pageIndex:
				this.ren.addClass(linkBtn, 'disabled');
				this.ren.setAttribute(linkBtn, 'disabled', 'disabled');
				break;
			default:
				this.ren.listen(linkBtn, 'click', () => {
					this.switchPage(i);
				});
				break;
		}

		this.ren.appendChild(linkBtn, text);
		this._buttons.push(linkBtn);
		return linkBtn;
	}

	private initPageRange(): void {
		this._rangeStart = this._currentPage - 2;
		this._rangeEnd = this._currentPage + 2;

		this.buildPageNumbers();
	}

	private switchPage(i: number, emitEvent: boolean = true): void {
		let prevPage = _.clone(this._currentPage);

		this._currentPage = i;
		this.matPag.pageIndex = i;

		if(emitEvent) {
			this.numberButton.emit({previousPageIndex: prevPage, pageIndex: i, pageSize: this.matPag.pageSize, length: this.matPag.length});
		}

		this.buildPageNumbers();
		this.initPageRange();
	}

	public ngAfterViewInit() {
		this._currentPage = 0;
		this.matPag.pageIndex = 0;
		this.initPageRange();
	}

	public ngOnDestroy() {
		if (this.onPageResetSubscription) {
			this.onPageResetSubscription.unsubscribe();
		}
	}
}

