import { PageFilter } from '../page-filter';

export interface ImportJobFilter extends PageFilter {
	page?: number;
	linesPerPage?: number;
	orderBy?: string;
	direction?: string;

	id?: number;
	operationIds?: number[];
	segmentIds?: number[];
	status?: string;
	startDate?: Date;
	endDate?: Date;

	finishedStartDate?: Date;
	finishedEndDate?: Date;
}
