import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../_services/toast.service';
import { environment } from '../../../environments/environment';
import { UserService } from '../../_services/user.service';

@Component({
	selector: 'training-error-dialog',
	templateUrl: './training-error-dialog.component.html',
	styleUrls: ['./training-error-dialog.component.scss']
})
export class TrainingErrorDialogComponent implements OnInit {

	public trainningErros: string[] = [];

	constructor(public modal: NgbActiveModal,
				public userService: UserService,
	            private toastService: ToastService) {
	}

	public ngOnInit(): void {
		let lastName = this.userService.currentUser.name.split(' ')[1];

		if(!lastName) {
			this.trainningErros = [ ...this.trainningErros, 'Sobrenome não definido' ];
		}
	}

	public get idUrl(): string {
		return environment.apps['id'];
	}

}
