import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { BehaviorSubject, EMPTY, of, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportDataService } from '@app/services';
import { environment } from '../../../../environments/environment';
import { InteractionModalComponent } from '../../../_components/integration-modal/interaction-modal.component';
import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { BucketService } from '@app/services';
import { JobService } from '@app/services';
import { Checklist, OnboardingStepStatus } from '@app/models';
import { OnboardingStepService } from '@app/services';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '@app/models';
import { SegmentService } from '@app/services';
import { OperationService } from '@app/services';
import { UserService } from '@app/services';
import { InteractionServiceV2 } from '@app/services';
import { ToastService } from '@app/services';
import { SegmentFilter } from '@app/models';
import { OperationFilter } from '@app/models';
import { PageResponse } from '@app/models';
import { Operation } from '@app/models';
import { UserFilter } from '@app/models';

@Component({
	selector: 'app-interaction-add',
	templateUrl: './interaction-add.component.html',
	styleUrls: ['./interaction-add.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0}),
				animate('200ms 260ms ease-in-out', style({ opacity: 1}))
			])
		])
	]
})
export class InteractionAddComponent implements OnInit, OnDestroy {

	isLoading = false;
	operations: any[] = [];
	segments: any[] = [];
	currentUser: User;
	interactionForm: FormGroup;
	users: User[] = [];
	fileName = '';
	modalRef: BsModalRef;

	get violatedRulesFileUpload() {
		return this.file.length > 0 && this.file[0].size > 102400 * 1000 ? ['Arquivo excede o limite permitido. Envie arquivos com até 1GB.'] : [];
	}

	search$: BehaviorSubject<string> = new BehaviorSubject(null);

	subs = new Subscription();

	curAgentOffset = 0;
	isAgentLastPage = true;

	public isChecklistAutomatic: boolean = false;

	public pSegmentId: number;
	public pOperationId: number;

	public segmentChecklists: Checklist[] = []

	file: File[] = [];

	uploadProgress: number = 0;
	toggleUpload$ = new Subject<boolean>();

	public automaticMimetypes: string = 'audio/mp3, audio/mpeg, audio/x-gsm, audio/gsm, audio/wav';
	public manualMimetypes: string = 'audio/mp3, audio/mpeg, audio/x-gsm, audio/gsm, audio/wav, application/pdf, image/jpeg, image/png';

	existentFile: boolean = true;

	constructor(private segmentService: SegmentService,
	            private operationService: OperationService,
	            private userService: UserService,
	            private formBuilder: FormBuilder,
	            private interactionService: InteractionServiceV2,
	            private bucketService: BucketService,
				public jobService: JobService,
	            private router: Router,
	            private modalService: BsModalService,
				public importDataService: ImportDataService,
	            public location: Location,
				private ngbModal: NgbModal,
	            private activeModal: NgbActiveModal,
	            private toastService: ToastService,
	            private httpClient: HttpClient,
	            private onboardingStepService: OnboardingStepService) {
		this.currentUser = this.userService.currentUser;
	}

	ngOnInit(): void {
		this.interactionForm = this.formBuilder.group({
			operationId: [null, [Validators.required, Validators.min(1)]],
			segmentId: [null, [Validators.required, Validators.min(1)]],
			checklistId: [null, [Validators.required, Validators.min(1)]],
			agentName: [null, [Validators.required]],
			contactedAt: [0, [Validators.required]]
		});
		this.setSegmentOptions();

		this.onSegmentSelect();
		this.onOperationSelect();
		this.onAgentSearch();
		this.initiateImport();
	}

	get agentName() {
		return this.interactionForm.get('agentName');
	}
	get customerId() {
		return this.interactionForm.get('customerId');
	}
	get checklistId() {
		return this.interactionForm.get('checklistId');
	}
	get operationId() {
		return this.interactionForm.get('operationId');
	}
	get segmentId() {
		return this.interactionForm.get('segmentId');
	}
	get contactedAt() {
		return this.interactionForm.get('contactedAt');
	}

	private initiateImport(): void {
		this.subs.add(this.toggleUpload$.pipe(
			switchMap((toggleUpload) => {
				return toggleUpload ? this.bucketService.getUploadUrl(this.file[0].name) : of(null);
			}),
			switchMap(uploadInfo => {
				if (!uploadInfo) return EMPTY;
				this.importDataService.zip = uploadInfo.file;
				return this.httpClient.put(uploadInfo.url, (this.file[0] as File), {reportProgress: true, observe: 'events'});
			}),
			tap((event) => {
				if (event.type === 1) this.uploadProgress = ((event as HttpProgressEvent)?.loaded / (event as HttpProgressEvent)?.total) * 100;
			}),
			filter((event) => event.type === 4),
			switchMap(() => {
				return this.saveInteraction();
			})
		).subscribe(data => {
			if(this.onboardingStepService.currentOnboardingStep.status !== OnboardingStepStatus.COMPLETED) {
				this.onboardingStepService.currentOnboardingStep.status = OnboardingStepStatus.COMPLETED;
				this.onboardingStepService.put(this.onboardingStepService.currentOnboardingStep).subscribe(
					data => {
					}, error =>{
						console.error(error);
					}
				);
			}

			if(data) {
				this.toastService.showSuccess("Nova Monitoria cadastrada com sucesso.");
				this.interactionModal(data);
				this.activeModal.close();
			} else {
				this.toastService.showSuccess("Sua chamada está sendo importada, aguarde.");
				this.activeModal.close(true);
				this.router.navigate(['/imports']);
			}
		}, (err) => {
			console.error(err);
			this.toastService.showDanger(err.message || 'Ocorreu um problema no upload do seu arquivo.')
		}).add(() => {
			this.isLoading = false;
			this.importDataService.isLoading = false
		}));
	}

	onNext() {
		this.isLoading = true;
		this.importDataService.isLoading = false;
		this.toggleUpload$.next(true);
	}

	private saveInteraction() {
		const formData = new FormData();
		const dateFromForm = this.interactionForm.get('contactedAt').value;
		const contactedAt = new Date(dateFromForm.year, dateFromForm.month - 1, dateFromForm.day);

		contactedAt.setHours(12);
		var isoDateTime = new Date(contactedAt.getTime() - (contactedAt.getTimezoneOffset() * 60000)).toISOString();

		let obj = {
			agentName: this.interactionForm.get('agentName').value,
			operationId: this.interactionForm.get('operationId').value,
			segmentId: this.interactionForm.get('segmentId').value,
			checklistId: this.interactionForm.get('checklistId').value,
			contactedAt: isoDateTime,
			file: this.importDataService.zip
		}

		return this.interactionService.newInteraction(obj);
	}

	private interactionModal(interaction: any): void {
		const modalRef = this.ngbModal.open(InteractionModalComponent, { windowClass: 'interaction-modal', backdrop: 'static', keyboard: false });
		modalRef.componentInstance.interactionId = interaction.id;
		modalRef.componentInstance.isMonitor = true;
	}

	onPrev() {
		this.importDataService.prevStep()
	}

	setSegmentOptions() {
		const segmentFilter: SegmentFilter = {
			name: ''
		};
		this.segmentService.filter(segmentFilter).subscribe(segments => {
			this.segments = [];

			segments.content.forEach(segment => {
				if(segment.checklists?.length > 0) {
					this.segments = [ ...this.segments, segment ];
				}
			});

			// this.segments = segments.content;
			this.interactionForm.get('operationId').disable();
			this.interactionForm.get('checklistId').disable();
		});
	}

	segmentSelected(event: any) {
		// this.isChecklistAutomatic = event.checklist.checkListType == 'AUTOMATIC';
		this.interactionForm.get('checklistId').enable();
		this.segmentChecklists = event.checklists.filter(item => item.active);
		this.file = [];
	}

	onSegmentSelect() {
		this.subs.add(this.interactionForm.get('segmentId').valueChanges.pipe(
			tap((segmentId) => {
				this.interactionForm.get('operationId').setValue(null);
				this.agentName.setValue(null);

				!!segmentId ? this.interactionForm.get('operationId').enable() : this.interactionForm.get('operationId').disable();
			}),
			filter((segmentId) => !!segmentId),
			switchMap((segmentId: number) => {
				this.pSegmentId = segmentId;
				let operationFilter: OperationFilter = {
					name: '',
					segmentIds: segmentId ? [segmentId] : null
				};
				return this.operationService.filter(operationFilter);
			})
		).subscribe((operations: PageResponse<Operation>) => {
			this.operations = operations.content;
		}));
	}

	onOperationSelect() {
		this.interactionForm.get('operationId').valueChanges.subscribe((operationId) => {
			this.pOperationId = operationId;

			let userFilter: UserFilter = {
				name: '',
				email: '',
				segmentIds: this.pSegmentId ? [this.pSegmentId] : null,
				operationIds: this.pOperationId ? [this.pOperationId] : null,
				active: true,
				linesPerPage: 10,
				page: 0,
				orderBy: 'name',
				direction: 'ASC'
			};
			this.userService.filter(userFilter).subscribe(
				data => {
					this.users = data.content;
					this.curAgentOffset = 1;
					this.isAgentLastPage = data.last;
			});
		})
	}

	onAgentSearch() {
		this.subs.add(this.search$.asObservable().pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			switchMap((searchTerm: string) => {
				let userFilter: UserFilter = {
					name: searchTerm,
					email: searchTerm,
					segmentIds: this.pSegmentId ? [this.pSegmentId] : null,
					operationIds: this.pOperationId ? [this.pOperationId] : null,
					active: true,
					linesPerPage: 10,
					page: 0,
					orderBy: 'name',
					direction: 'ASC'
				};
				return this.userService.filter(userFilter)
			})
		).subscribe((users) => {
			this.users = users.content;
			this.curAgentOffset = 1;
			this.isAgentLastPage = users.last;
		}));
	}

	nextAgentBatch() {
		this.userService.filter({
			page: this.curAgentOffset,
			name: this.search$.value,
			email: this.search$.value,
			segmentIds: [this.pSegmentId],
			operationsIds: [this.pOperationId],
			active: true,
			linesPerPage: 10,
			orderBy: 'name',
			direction: 'ASC'
		}).subscribe((users) => {
			this.users = [...this.users, ...users.content];
			this.curAgentOffset = this.curAgentOffset + 1;
			this.isAgentLastPage = users.last;
		});
	}

	resetUser() {
		this.search$.next(null);
		this.users = [];
	}

	filterOperation() {
		let operationFilter: OperationFilter = {
			name: '',
			segmentIds: this.segmentId.value
		};
		console.debug("filtering operations", operationFilter)
		this.operationService.filter(operationFilter).subscribe(response => {
			console.debug(response);
			this.operations = response.content;
		});
	}

	onDateSelect(event) {
		let year = event.year;
		let month = event.month <= 9 ? '0' + event.month : event.month;
		let day = event.day <= 9 ? '0' + event.day : event.day;
		this.contactedAt.setValue(day + "-" + month + "-" + year);
	}

	public get adminUrl(): string {
		return environment.apps.admin;
	}

	cancelUpload() {
		this.toggleUpload$.next(false);
		this.resetUploadFile();
	}

	private resetUploadFile() {
		this.uploadProgress = 0;
		this.importDataService.isLoading = false;
		this.isLoading = false;
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

	isFileExistent($event) {
		this.existentFile = $event;
	}
}
