import { FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';


@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnDestroy {
  @Input() rows: any = [];
  @Input() dtOptions: DataTables.Settings;
  @Input() headerColumns: Column[];
  @Input() fieldNames: string[];
  @Input() dtTrigger: Subject<any>;
  @Input() actionBar: boolean = false;
  @Input() actions: DataTableAction[] = [];
  @Input() customBody: boolean;
  @Input() customHeader: boolean;
  @Input() enableCustomPagination: any;
  @Output() editAction = new EventEmitter<any>();
  @ViewChild(DataTableDirective, {static: false}) datatableElement: DataTableDirective;
  isEditEnabled: boolean = false;

  constructor() {}

  ngOnInit() {
    this.dtOptions = this._getOptions();
    this.isEditEnabled = this.editAction.observers.length > 0;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
  }

  editEmitAction(rowElement: any) {
    this.editAction.emit(rowElement);
  }

  emitActionInteraction(rowElement: any) {
    this.editAction.emit(rowElement);
  }

  emitAction(rowElement: any, action: DataTableAction) {
    action.emitAction(rowElement, action.self);
  }

  getField(row: any, field: any) {
    let fieldNames;
    if (typeof field == 'string') {
      fieldNames = field.split('.');
    } else {
      fieldNames = field.field.split('.');
    }
    if (fieldNames.length == 3) {
      return row[fieldNames[0]][fieldNames[1]][fieldNames[2]];
    }
    if (fieldNames.length == 2) {
      return row[fieldNames[0]][fieldNames[1]];
    } else {
      return row[fieldNames[0]];
    }
  }

  _getOptions() {
    const options = { ...defaultOptions, ...this.dtOptions };
    if (this.enableCustomPagination) {
      return {
        pageLength: 10,
        serverSide: true,
        processing: true,
        columns: this.headerColumns,
        ...options
      };
    }
    return options;
  }
}

export interface ListTable {
  searchForm?: FormGroup;
  loading?: boolean;
  submitted?: boolean;
  headerColumns: Column[];
  fieldNames?: any[];
}

export interface Column {
  name?: string;
  field?: string;
  type?: any;
  customClass?: string;
  format?: string;
  notOrderable?: boolean;
}

export interface DataTableAction {
  icon: string;
  emitAction: any;
  self: any;
  description?: string;
  permissions?: string[];
}

export const defaultOptions = {
  orderCellsTop: true,
  lengthChange: false,
  responsive: true,
  ordering: true,
  pagingType: 'simple_numbers',
  lengthMenu: [[10, 35, 50, -1], [10, 35, 50, 'Todos']],
  language: {
    search: '',
    processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Carregando...</span> ',
    searchPlaceholder: 'Pesquisar..',
    zeroRecords: 'Nenhum registro encontrado',
    info: '_START_ até _END_ de _TOTAL_',
    lengthMenu: 'Mostrar _MENU_',
    infoEmpty: 'Nenhum registro encontrado',
    infoFiltered: '',
    paginate: {
      first: '',
      last: '',
      next: 'Próxima →',
      previous: '← Anterior'
    }
  }
};
