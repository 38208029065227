import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'search-input',
    templateUrl: './search-input.component.html',
    styleUrls: [ 'search-input.component.scss' ]
})
export class SearchInputComponent implements OnInit {

    @Input() searchFilter: string;
    @Input() placeholder = 'Pesquisar';
    @Output() searchFilterChange = new EventEmitter<string>();
    @Output() submit?: EventEmitter<any> = new EventEmitter<any>();
    @Output() keyup = new EventEmitter<string>();

	@Input() searchOptions = [];

	selectedOption;

    constructor(){ }

	ngOnInit() {
        if (this.searchOptions.length > 0) {
            this.selectedOption = this.searchOptions[0].value;
        }
	}

	onChange() {
        this.searchFilterChange.emit(this.searchFilter)
    }

    onKeyup($event:any){
        this.keyup.emit(this.searchFilter);
    }

    onSubmit(){
        this.submit.emit([this.searchFilter, this.selectedOption]);
    }

}
