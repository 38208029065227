export class JobValidate{
    message: string;

	constructor(message:string){
		this.message = message;
	}
}
export enum ImportJobError {
	GENERIC_ERROR = 'Ocorreu um erro inesperado',
	FORMAT_FILE = 'Extensão inválida',
	FORMAT_DATE = 'Formato da data inválido',
	FORMAT_TIME = 'Formato da hora inválido',
	READ_FILE = 'Erro ao tentar ler o arquivo',
	ERROR_AGENT = 'Nome do operador inválido',
	LIMIT_PLAN = 'Limite de chamadas excedidas para o plano.'
}
