<button (click)="openChangeAnswerModal()" class="btn btn-outline-primary btn-sm">
    <i class="fas fa-vote-yea mr-1" placement="bottom" container="body" ngbTooltip="Modificar Conformidade"></i>Editar conformidade
</button>

<ng-template #buttonModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Edição de Conformidade</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.close(false)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="padding-top: 0px;">
        <div class="row mt-3">
            <div class="col-md-12 col-lg-5">
                <label for="description">Resposta</label>
                <evo-select>
                    <ng-select  placeholder="Resposta"
                                [(ngModel)]="currentItem.answer"
                                [items]="answerOptions"
                                bindLabel="name">
                    </ng-select>
                </evo-select>
            </div>
            <div class="col-md-12 col-lg-7" *ngIf="justifications && justifications.length > 0">
                <label for="description">Justificativa</label>
                <evo-select>
                    <ng-select  placeholder="Selecione uma justificativa"
                                [(ngModel)]="currentItem.justification.id"
                                [items]="justifications"
                                bindLabel="text"
                                bindValue="id">
                    </ng-select>
                </evo-select>
            </div>
        </div>
        <div class="row mt-3">
            <div class="form-group col">
                <label for="description">Explicação</label>
                <input type="text" class="form-control" [(ngModel)]="currentItem.explanation" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Fechar
        </button>
        <button type="button" [disabled]="isLoading" class="btn btn-primary"
            (click)="saveAnswer()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
            Salvar
        </button>
    </div>
</ng-template>
