<table class="evo-table dataTable table-hover" [ngClass]="{'custom-pagination': enableCustomPagination}"
    [dtTrigger]="dtTrigger" [dtOptions]="dtOptions" datatable>
    <thead *ngIf="customHeader" class="thead-light">
        <ng-content select="[tableHeader]"></ng-content>
    </thead>
    <thead class="thead-light" *ngIf="!customHeader">
        <tr>
            <th *ngFor="let column of headerColumns">
                {{column.name}}
                <i class="asc_icon fas fa-long-arrow-alt-down" [hidden]="column.notOrderable"></i>
                <i class="desc_icon fas fa-long-arrow-alt-up" [hidden]="column.notOrderable"></i>
            </th>
        </tr>
    </thead>

    <tbody *ngIf="!customBody">
        <tr *ngFor="let row of rows">
            <td *ngFor="let field of fieldNames">
                <span class="{{field.customClass}}">
                    {{getField(row, field) | checkIFDate}}
                </span>
            </td>
            <td *ngIf="actionBar" class="edit-btn-cell">
                <button *ngIf="isEditEnabled" class="btn" (click)="editEmitAction(row)">
                    <i class="fas fa-pencil-alt"></i> Editar
                </button>
	            <ng-container *ngFor="let btn of actions">
	                <button class="btn" (click)="emitAction(row, btn)" container="body"
	                    placement="bottom" ngbTooltip="{{btn.description}}" *ngxPermissionsOnly="btn.permissions">
	                    <i [class]="btn.icon"></i> {{btn.description}}
	                </button>
	            </ng-container>
            </td>
        </tr>
    </tbody>
    <tbody *ngIf="customBody">
        <ng-content select="[tableBody]"></ng-content>
    </tbody>
</table>
