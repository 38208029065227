import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { Operation } from '@app/models';
import { OperationService } from '@app/services';
import { Segment } from '@app/models';
import { SegmentService } from '@app/services';
import { ToastService } from '@app/services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { BusinessException } from '../../_helpers/business-exception.enum';
import * as _ from "lodash";
import {lowerCase} from "lodash";
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
	selector: 'operation-add-dialog',
	templateUrl: './operation-add-dialog.component.html',
	styleUrls: ['./operation-add-dialog.component.scss']
})
export class OperationAddDialogComponent implements OnInit {
	@Input()
	confirmText: string = 'Confirmar';
	@Input()
	text: string;

	@Input() selectedOperations: any[] = [];
	@Input() segment: Segment;

	private segmentOperations: any[] = [];
	private newOperations: any[] = [];
	private removedOperations: any[] = [];

	public operations: Operation[] = [];
	public filteredOperations: Operation[] = [];
	public searchTerm: string = '';

	public newOperationName: string = '';

	public isLoading: boolean = false;

	constructor(public modal: NgbActiveModal,
				public modalService: NgbModal,
	            public operationService: OperationService,
	            public segmentService: SegmentService,
	            private toastService: ToastService,
				private fb: FormBuilder) {
	}

	ngOnInit() {
		this.getOperations();
	}

	public getOperations(): void {
		this.operationService.findAll().subscribe(
			data => {
				this.removeSelectedOperationsFromList(data);
				this.operations = data;
				this.filteredOperations = _.cloneDeep(this.operations);
			}
		)
	}

	public filterOperationsBySearchTerm(): void {
		this.filteredOperations = this.operations.filter( item => {
			return lowerCase(item.name).indexOf(lowerCase(this.searchTerm)) !== -1;
		})
	}

	private removeSelectedOperationsFromList(data) : void {
		this.selectedOperations.forEach(so => {
			data.splice(data.findIndex(o => o.id == so.id), 1);
		})
	}


	public addOperation(operation) : void {
		let operationsIds = [];

		this.selectedOperations.forEach(o => {
			operationsIds = [...operationsIds, { operationId: o.id}]
		});

		operationsIds = [...operationsIds, { operationId: operation.id}];

		this.isLoading = true;

		this.segmentService.putSegmentOperation(this.segment.id, operationsIds).subscribe(
			data => {
				this.toastService.showSuccess('Operação vinculada com sucesso.');
				this.selectedOperations = [ ...this.selectedOperations, operation ];
				this.operations.splice(this.operations.indexOf(operation), 1);
				this.filteredOperations.splice(this.filteredOperations.indexOf(operation), 1);
			}, error => {
				if(error.error.error == 'CANT_REMOVE_SEGMENT_OPERATION') {
					this.toastService.showDanger(BusinessException.CANT_REMOVE_SEGMENT_OPERATION);
					return;
				}

				this.toastService.showDanger('Ocorreu um erro ao tentar vincular a operação.');
			}
		).add(() => this.isLoading = false);
	}

	public removeOperation(operation): void {
		let operationsIds = [];

		this.selectedOperations.forEach(o => {
			if (o.id == operation.id) return;
			operationsIds = [...operationsIds, { operationId: o.id}]
		});

		this.isLoading = true;

		this.segmentService.putSegmentOperation(this.segment.id, operationsIds).subscribe(
			data => {
				this.selectedOperations.splice(this.selectedOperations.findIndex(o => o.id == operation.id),1);
				this.toastService.showSuccess('Operação desvinculada com sucesso.');
			}, error => {
				if(error.error.error == 'CANT_REMOVE_SEGMENT_OPERATION') {
					this.toastService.showWarning(BusinessException.CANT_REMOVE_SEGMENT_OPERATION);
					return;
				}

				this.toastService.showDanger('Ocorreu um erro ao tentar vincular a operação.');
			}
		).add(() => {
			this.isLoading = false;
			this.ngOnInit();
		});
	}

	public postOperation(): void {
		let operation = new Operation();
		operation.name = this.newOperationName;
		operation.description = this.newOperationName;

		this.isLoading = true;

		this.operationService.post(operation).subscribe(
			data => {
				this.newOperationName = '';

				this.addOperation(data);
			}, error => {
				console.error(error);
				this.toastService.showDanger(error.message || 'Ocorreu um erro inesperado.');
			}
		);

	}

	getItemLabelByValue(value) {
		const item = this.operations.find(item => item.id == value.id);
		return item ? item.name : '';
	}

	public confirmClose(): void {
		this.modal.close(false);
	}

	compareFn(item, selected) {
		return item.id === selected.id;
	}

	blockSpecialCharacter(event):boolean{
		return /^[A-zÀ-ÿ0-9 ]*$/.test(event.key);
	}

	blockOnPaste(event) {
		event.preventDefault();
	}

	getInitials(operation) {
		const splittedName = operation?.name?.split(' ');
		return splittedName?.reduce((initials, currName) => (initials = `${initials}${currName[0]}`), '');
	}
}
