import { Agent } from "app/_models/agent";
import { BaseServiceClass } from "./baseService.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AgentService extends BaseServiceClass<Agent> {
    constructor(protected http: HttpClient) {
        super(http, '/agents');
    }

    public filter(filters: any): Observable<any> {
		return this.http.get(`${this.entity_url}/filter`, { params: filters });
	}
}