import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { BucketService, InteractionService } from '@app/services';
import { Justification } from '@app/models';
import { ToastService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-contestation-accepted-button',
  templateUrl: './contestation-accepted-button.component.html',
  styleUrls: ['./contestation-accepted-button.component.scss']
})
export class ContestationAcceptedButtonComponent extends InteractionContestationButton implements OnInit {
  @Input() justifications: Justification[];

  constructor(modalService: NgbModal, protected activeModal: NgbActiveModal, protected toastService: ToastService, formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
    this.contestationForm = this.formBuilder.group({
      contestation: [null, Validators.required],
      minute: [null],
      contestationAcceptComment: ["Contestação procedente.", Validators.required]
    });
  }

  get contestationAccepComment() {
    return this.contestationForm.get('contestationAcceptComment');
  }

  openContestationAcceptModal() {
    this.item.contestationAcceptJustification = new Justification();
    this.openModal();
  }

  acceptContestation() {
    this.item.contestationAcceptJustification = this.contestation.value;
    this.item.contestationAcceptComment = this.contestationAccepComment.value;
    this.item.status = {id: "CONTESTATION_ACCEPTED", name: "Contestação Procedente"};
    this.item.answer = {id: "CONFORMING", name: "Conforme"};

    // this.contestation.patchValue("Contestação aceita.");
	  this.contestation.patchValue(this.contestationAccepComment.value);
    this.saveContestation('CONTESTATION_ACCEPTED', 'Contestação aceita com sucesso.');
  }

}
