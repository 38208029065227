import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-simple-checkable-card',
	templateUrl: './simple-checkable-card.component.html',
	styleUrls: ['./simple-checkable-card.component.scss']
})
export class SimpleCheckableCardComponent implements OnInit {
	@Input() title: string = '';
	@Input() description: string = '';
	@Input() iconClass: string = '';
	@Input() isBlocked: boolean = false;
	@Input() isPremium: boolean = false;
	@Input() isSelected: boolean = false;

	isDisabled: boolean = false;
	showPremiumTag: boolean = false;

	constructor() {
	}

	ngOnInit(): void {
		this.showPremiumTag = this.isPremium;
	}
}
