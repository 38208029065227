<!-- Single Tabs -->
<div *ngIf="!sidebarMenu.submenu">
		<a class="nav-link" *ngxPermissionsOnly="sidebarMenu.permissions"
		   [routerLink]="sidebarMenu.path"
		   routerLinkActive="active"
		   [routerLinkActiveOptions]="{exact: true}">
			<i [class]="sidebarMenu.icon" [title]="sidebarMenu.description"></i>
			<span [hidden]="barIsCollapsed">
      {{sidebarMenu.description}}
    </span>
		</a>
</div>
<!-- Nested Tabs -->
<div *ngIf="permissionsLoaded">
	<div>
		<div class="nested-tab-wrapper" *ngIf="sidebarMenu.submenu">
			<section>
				<!-- Menu-handler -->
					<div class="nav-link nest-header" *ngxPermissionsOnly="sidebarMenu.permissions"
					     [ngClass]="{'active': highlightParent, 'opened': activeNav === sidebarMenu.path, 'text-center': barIsCollapsed}"
					     (click)="toggleNavStatusActiveNav()">
						<i [class]="sidebarMenu.icon" [title]="sidebarMenu.description"></i>
						<span [hidden]="barIsCollapsed">
              {{sidebarMenu.description}}
            </span>
						<i class="toggle-icon fas fa-angle-down"
						   [hidden]="barIsCollapsed"
						   [ngClass]="{'opened': activeNav === sidebarMenu.path}">
						</i>
					</div>
				<!-- Sub-menu -->
				<ul [ngbCollapse]="activeNav !== sidebarMenu.path">
					<li *ngFor="let tab of sidebarMenu.submenu">
						<ng-container *ngIf="tab.path != null">
							<a class="nav-link sub-menu" *ngxPermissionsOnly="tab.permissions"
							   [routerLink]="tab.path"
							   routerLinkActive="active"
							   [routerLinkActiveOptions]="{exact: true}">
								{{tab.description}}
							</a>
						</ng-container>
						<ng-container *ngIf="tab.action">
							<a class="nav-link sub-menu" *ngxPermissionsOnly="tab.permissions" (click)="menuAction(tab.action)">
								{{tab.description}}
							</a>
						</ng-container>
					</li>
				</ul>
			</section>
			<!-- Collapsed-bar-dropdown -->
			<section class="collapsed-bar-dropdown" [hidden]="!barIsCollapsed">
				<div class="dropdown-wrapper">
					<div *ngFor="let tab of sidebarMenu.submenu">
						<button class="btn" *ngxPermissionsOnly="sidebarMenu.permissions">
							<a class="nav-link"
							   [routerLink]="tab.path"
							   routerLinkActive="active"
							   [routerLinkActiveOptions]="{exact: true}">
								{{tab.description}}
							</a>
						</button>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>
