
<div>
    <div class="header d-flex justify-content-between align-items-end">
        <span></span>
        <button (click)="modal.close()" class="btn"><i class="fas fa-times"></i></button>
    </div>
	<div id="body" class="body">
        <div style="text-align: center;">
            <h4>Adicionar treinamentos</h4>
            <p style="font-weight: 600; font-size: 16px; color: #5E5E5E;">Preencha o formulário abaixo com informações sobre o treinamento.</p>
            <iframe width="560" height="580" src="https://app.pipefy.com/public/form/aOktEKTd?embedded=true" frameborder="0"></iframe>
            <p style="font-weight: 600; font-size: 12px; color: #5E5E5E;">O treinamento será disponibilizado na plataforma em até 2 dias úteis.</p>
        </div>
	</div>
</div>

