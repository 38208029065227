<div class="card" id="card">

	<div class="d-flex align-items-center justify-content-center flex-column my-3" *ngIf="!interaction || !isReady">
		<h6>Carregando informações...</h6>
		<span class="spinner-border spinner-border-sm mr-1"></span>
	</div>

	<ng-container *ngIf="interaction && !isInTranscription">
		<app-new-interaction-contestation [interaction]="interaction" (close)="closeModal($event)" (resetScroll)="resetScroll()" *ngIf="interaction.lastStatusHistory.id != interactionStatusEnum.MANUAL_MONITOR" (firstLoadingDone)="updateIsReady()"></app-new-interaction-contestation>

		<monitor-interaction [interaction]="interaction" (close)="closeModal($event)" *ngIf="interaction.lastStatusHistory.id == interactionStatusEnum.MANUAL_MONITOR" (firstLoadingDone)="updateIsReady()"></monitor-interaction>
	</ng-container>

	<ng-container *ngIf="interaction && isInTranscription">
		<interaction-simplified [interaction]="interaction" (close)="closeModal($event)" (firstLoadingDone)="updateIsReady()"></interaction-simplified>
	</ng-container>

</div>
