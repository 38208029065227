import { HttpClient, HttpProgressEvent } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, of, Subject, Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { JobService } from '../../../../_services/job.service';
import { ImportDataService } from '../../../../_services/import-data.service';
import { ToastService } from '../../../../_services/toast.service';
import { BucketService } from '../../../../_services/bucket.service';
import {
	ConfirmDangerDialogComponent
} from '../../../../_components/confirm-danger-dialog/confirm-danger-dialog.component';

@Component({
  selector: 'app-import-data-step-two',
  templateUrl: './import-data-step-two.component.html',
  styleUrls: ['./import-data-step-two.component.scss']
})
export class ImportDataStepTwoComponent implements OnInit, OnDestroy {
  file: File[] = [];

  private invalidType: boolean;

  get violatedRulesFileUpload() {
	  let errors = [];

	  if(this.file.length > 0 && this.file[0].size > 500000) {
		  errors = [ ...errors, 'Arquivo excede o limite permitido. Envie arquivos com até 500kb.' ];
	  }

	  if(this.invalidType) {
		  errors = [ ...errors, 'Formato de arquivo inválido.' ];
	  }

	  return errors;
  }

  subs = new Subscription();
  uploadSub: Subscription = new Subscription();

  uploadProgress: number = 0;

  toggleUpload$ = new Subject<boolean>();

  constructor(public importDataService: ImportDataService,
              private modalService: NgbModal,
              private httpClient: HttpClient,
              private toastService: ToastService,
              private bucketService: BucketService,
              public jobService: JobService) { }

  ngOnInit(): void {
	this.importDataService.isLoading = false;
    this.subs.add(this.toggleUpload$.pipe(
      switchMap((toggleUpload: boolean) => {
        return toggleUpload ? this.bucketService.getUploadUrl(this.file[0].name) : of(null);
      }),
      switchMap(uploadInfo => {
		    if (!uploadInfo) return EMPTY;
        this.importDataService.metadata = uploadInfo.file;
        return this.httpClient.put(uploadInfo.url, (this.file[0] as File), {reportProgress: true, observe: 'events'});
      }),
      tap((event) => {
        if (event.type === 1) this.uploadProgress = ((event as HttpProgressEvent)?.loaded / (event as HttpProgressEvent)?.total) * 100;
      }),
      filter((event) => event.type === 4)
    ).subscribe(() => {
		this.importDataService.file = this.file[0];
		this.importDataService.nextStep();
    }, (err) => {
		this.toastService.showDanger(err?.message || 'Ocorreu um problema no upload do seu arquivo.');
		this.resetUploadFile();
	}).add(() => this.importDataService.isLoading = false));
  }

  cancelUpload() {
    this.toggleUpload$.next(false);
	this.resetUploadFile();
  }

	isInvalidType(event) {
	  this.invalidType = event;
	}

  private resetUploadFile() {
	this.uploadProgress = 0;
	this.importDataService.isLoading = false;
  }

  onPrev() {
		if (this.importDataService.isLoading) {
			const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
			modalRef.componentInstance.text = "Ao voltar, os dados da importação serão perdidas. Tem certeza que deseja voltar?";
			modalRef.componentInstance.title = "Importação da chamada";

			this.subs.add(modalRef.closed.pipe(filter((isConfirmed: boolean) => !isConfirmed)).subscribe(() => this.importDataService.prevStep()));
		} else {
			this.importDataService.prevStep();
		}
	}

  onNext() {
	  this.jobService.validateErrors = undefined;
    this.importDataService.isLoading = true;
	this.toggleUpload$.next(true);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}

