import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SpeechEnvironments } from '@app/models';

@Injectable({ providedIn: 'root' })
export class SpeechEnvironmentService {
    basePath: string = `${environment.apiUrl}/speech/environments`;

    constructor(private http: HttpClient) { }

    findByCustomer(customerId: number) {
        return this.http.get<SpeechEnvironments[]>(`${this.basePath}/customers/${customerId}`);
    }

    findBySpeechClientIdExternal(speechClientId: number) {
        return this.http.get<SpeechEnvironments[]>(`${environment.apiUrl}/external/speech/environments/client/${speechClientId}`);
    }
}
