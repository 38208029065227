<div class="card card-custom">
    <evo-stepper-header [steps]="steps" [currentStep]="currentStep$ | async"></evo-stepper-header>
    <div>
        <ng-template currentComponentContainer></ng-template>
    </div>
    <evo-stepper-footer
        [isLoading]="isLoading$ | async"
        [isFormInvalid]="isFormInvalid$ | async"
        (onNext)="onNext()" (onPrev)="onPrev()">
    </evo-stepper-footer>
</div>
