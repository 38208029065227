import { trigger, animate, transition, style } from '@angular/animations';

export const fadeIn =
	trigger('fadeIn', [
		transition(':enter', [
			style({ opacity: 0 }),
			animate('500ms ease-in-out', style({ opacity: 1 })),
		]),
		transition(':leave', [
			style({ opacity: 1 }),
			animate('500ms ease-in-out', style({ opacity: 0 })),
		])
	])

export const fadeInLeft =
	trigger('fadeInLeft', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateX(-400px)' }),
			animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateX(0px)' })),
		]),
	]);

export const fadeInRight =
	trigger('fadeInLeft', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateX(400px)' }),
			animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateX(0px)' })),
		]),
	]);

export const fadeInTop =
	trigger('fadeInTop', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateY(-120px)' }),
			animate('300ms ease-in-out', style({ opacity: 1, transform: 'translateX(0px)' })),
		]),
	]);
