export enum InteractionStatusEnum {
    MANUAL_MONITOR = "MANUAL_MONITOR",
	REVISION = "REVISION",
	EVALUATION = "EVALUATION",
	REPLICA_SENDED = "REPLICA_SENDED",
    REPLICA_DECLINED = 'REPLICA_DECLINED',
	FEEDBACK = "FEEDBACK",
	FINISHED = "FINISHED",
	FEEDBACK_ACCEPTED = "FEEDBACK_ACCEPTED",
	CONTESTED = "CONTESTED",
	REPLICA = "REPLICA",
    REPLICA_ACCEPTED = 'REPLICA_ACCEPTED',
	REPLICA_NOT_ACCEPTED = "REPLICA_NOT_ACCEPTED",
	DISCARDED = "DISCARDED",
	CONTESTATION_ACCEPTED = 'CONTESTATION_ACCEPTED',
    CONTESTATION_DECLINED = 'CONTESTATION_DECLINED',
	IN_PROGRESS = 'IN_PROGRESS',
	IN_ANALYSIS = 'IN_ANALYSIS',
    PENDING = 'PENDING',
	IN_TRANSCRIPTION = 'IN_TRANSCRIPTION',
	NOT_EFFECTIVE = 'NOT_EFFECTIVE',
	IN_RELEASE = 'IN_RELEASE'
}

export enum InteractionStatusColorEnum {
    MANUAL_MONITOR = "#e3a802",
    EVALUATION = "#c16627",
    REPLICA_SENDED = "#ec9a3c",
    FEEDBACK = "#132144",
    FINISHED = "#00c851",
    FEEDBACK_ACCEPTED = "#003d7b",
    CONTESTED = "#df0808",
    REPLICA = "#ffafd7",
    REPLICA_NOT_ACCEPTED = "#610030",
    DISCARDED = "#969797"
}

export enum ContestationStatus {
    RATED = "Avaliado",
	CONTESTED = "Contestado",
	CONTESTATION_ACCEPTED = "Contestação Procedente",
	CONTESTATION_DECLINED = "Contestação Improcedente",
	REPLICA = "Réplica",
	REPLICA_ACCEPTED = "Réplica Procedente",
	REPLICA_DECLINED = "Réplica Improcedente",
	REPLICA_ACCEPTED_DISAGREE = "Discorda Réplica Procedente",
	REPLICA_DECLINED_DISAGREE = "Discorda Réplica Improcedente",
	NOT_RATED = "Não Avaliado",
    CONFORMING_EDITED = "Item Editado"
}

export enum InteractionSentiment {
	POSITIVE = <any>'/assets/icons/positive.svg',
	NEGATIVE = <any>'/assets/icons/negative.svg',
	NEUTRAL = <any>'/assets/icons/neutral.svg',
	NORMAL = <any>'',
	CRITICAL = <any>'/assets/icons/critical.svg',
}

export enum InteractionSentimentLabels {
	POSITIVE = <any>'Positivo',
	NEGATIVE = <any>'Negativo',
	NEUTRAL = <any>'Neutro',
	NORMAL = <any>'Normal',
	CRITICAL = <any>'Negativo crítico',
}
