import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { toInteger } from 'lodash';

@Injectable()
export class NgbDateParserHelper extends NgbDateParserFormatter {
	parse(value: string): NgbDateStruct {
		if (value) {
			const dateParts = value.trim().split('-');
			if (dateParts.length === 1 && dateParts[0]) {
				return {day: toInteger(dateParts[0]), month: null, year: null};
			} else if (dateParts.length === 2 && dateParts[0] && dateParts[1]) {
				return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null};
			} else if (dateParts.length === 3 && dateParts[0] && dateParts[1] && dateParts[2]) {
				return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: toInteger(dateParts[2])};
			}
		}
		return null;
	}

	format(date: NgbDateStruct): string {
		if(!date) return '';
		
		let dayFix = date.day <= 9 ? '0' : '';
		let monthFix = date.month <= 9 ? '0' : '';

		return date ?
			`${date.day ? dayFix + date.day : ''}-${date.month ? monthFix + date.month : ''}-${date.year}` :
			'';
	}
}
