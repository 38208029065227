import { City } from './city';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { Segment } from './segment';

export class SpeechCategory implements Deserializable {
    id: number;
	name:string;
	external_id:string;
	customer: Customer;
	
	speechSegment:any;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}