<div class="row indicators">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <div class="card-subtitle">
                    <h6>TMA</h6>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <span class="indicator">
                            <span *ngIf="indicatorsLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span *ngIf="!indicatorsLoading">{{indicators.averageServiceTime}}</span>
                        </span>
                    </div>
                    <div class="col text-right">
                        <span class="fas"><i class="fas fa-headset"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body">
                <div class="card-subtitle">
                    <h6>OPERADORES</h6>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <span class="indicator">
                            <span *ngIf="indicatorsLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span *ngIf="!indicatorsLoading">{{indicators.operators}}</span>
                        </span>
                    </div>
                    <div class="col text-right">
                        <span class="fas"><i class="fas fa-users"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body">
                <div class="card-subtitle">
                    <h6>% DE SILÊNCIO</h6>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <span class="indicator">
                            <span *ngIf="indicatorsLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span *ngIf="!indicatorsLoading">{{indicators.averagePercentualSilence ? indicators.averagePercentualSilence : 0}}</span>
                        </span>
                    </div>
                    <div class="col text-right">
                        <span class="fas"><i class="fas fa-volume-mute"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body">
                <div class="card-subtitle">
                    <h6>NOTA</h6>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <span class="indicator">
                            <span *ngIf="indicatorsLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span *ngIf="!indicatorsLoading">{{indicators.averageGrade ? indicators.averageGrade : 0}}</span>
                        </span>
                    </div>
                    <div class="col text-right">
                        <span class="fas"><i class="fas fa-star"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col">
        <div class="card">
            <div class="card-body">
                <div class="card-subtitle">
                    <h6>INCIDÊNCIA</h6>
                </div>
                <div class="row align-items-center">
                    <div class="col">
                        <span class="indicator">
                            <span *ngIf="indicatorsLoading" class="spinner-border spinner-border-sm mr-1"></span>
                            <span *ngIf="!indicatorsLoading">{{indicators.averageNonConformingItens}}</span>
                        </span>
                    </div>
                    <div class="col text-right">
                        <span class="fas"><i class="fas fa-exclamation-triangle"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
