<div class="d-flex justify-content-center align-items-center flex-column" style="height: 100%; width: 100%">

	<img src="/assets/images/robot.svg" width="236" height="266"/>

	<h2 class="mb-2">Oops...</h2>

	<h5>Você não tem permissão para acessar este conteúdo.</h5>
	<h5 class="mb-3">Contate o administrador da sua organização.</h5>

	<button class="btn btn-primary" routerLink="/">Voltar para página inicial</button>

</div>
