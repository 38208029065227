export class SpeechInteractionIntegration {
	id: string;

	audioName: string;
	originalFileName: string;
	environment: string;
	segment: string;
	contactDate: any;
	qualityInteractionStatus: QualityInteractionStatus;
	qualityMonitoredAt: any;

	interactionType?: string;

	selected: boolean = false;

	logEntries: any;
}

export class QualityInteractionStatus {
	id: string;
	name: string;
}

export enum SpeechInteractionOrderBy {
	ENVIRONMENTS = 'ENVIRONMENTS',
	SEGMENTS = 'SEGMENTS',
	CONTACTED_AT = 'CONTACTED_AT',
	FILENAME = 'FILENAME',
	ID = 'ID'
}
