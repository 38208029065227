import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {BaseServiceClass} from "../baseService.service";
import {InteractionFilter, ReportByFilter, ReportStatusEnum} from '@app/models';
import {UserFilter, ReportResponse} from '@app/models';
import * as _ from "lodash";
import { id } from "@swimlane/ngx-charts";

@Injectable({providedIn: 'root'})
export class ReportServiceV2 extends BaseServiceClass<any> {


	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v2/reports');
	}

	public generateInteractionReport(filter: ReportByFilter | InteractionFilter, type: String) {
		return this.httpService.post(`${this.entity_url}/interactions/${type}`, filter);
	}

	public generateContestationReport(filter: ReportByFilter, type: String) {
		return this.httpService.post(`${this.entity_url}/contestations/${type}`, filter);
	}

	public generateMonitorInteractionsReport(filter: ReportByFilter, type: String) {
		return this.httpService.post(`${this.entity_url}/monitor-interactions/${type}`, filter);
	}

	public generateUsersReport(filter: UserFilter) {
		return this.httpService.post(`${this.entity_url}/users`, filter);
	}

	public getMyReports(filters: any): Observable<any> {
		let options = {params: <any>_.omitBy(filters, _.isNil)};
		return this.httpService.get<any>(`${this.entity_url}/my-reports`, options);
	}

	public cancelReport(id: number): Observable<any> {
		return this.httpService.post(`${this.entity_url}/cancel/${id}`, {});
	}
}

