export interface InteractionCustomer {
	id: number;
	personType:string;
    phoneNumber:string;
    state:string;
    birthDate:string;
    taxId:string;
    name:string;
    email:string;
}
