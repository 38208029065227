import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'no-access',
	templateUrl: './no-access.component.html',
	styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit {

	constructor(public modalService: NgbModal) {
	}

	ngOnInit(): void {
	}
}
