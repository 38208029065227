import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EmptyPage, JustificationFilter, PageResponse } from '@app/models';
import { JustificationService } from '@app/services';
import { ToastService } from '@app/services';
import { Justification } from '@app/models';

@Component({
	selector: 'confirm-justification-type.component',
	templateUrl: './confirm-justification-type.component.html',
	styleUrls: ['./confirm-justification-type.component.scss']
})

export class ConfirmJustificationTypeComponent implements OnInit {
	public justificationForm: FormGroup;
	public justifications: PageResponse<Justification> = new EmptyPage();
	public loading = false;
	@Input()
	public justificationFilter: JustificationFilter;


	public selectedJustificationType: { label: string, value: string };

	constructor(public modal: NgbActiveModal,
		private formBuilder: FormBuilder,
		public location: Location,
		private justificationService: JustificationService,
		private toastService: ToastService) { }


	ngOnInit() {
		this.justificationForm = this.formBuilder.group({
			id: [null, Validators.required]
		});
		this.getJustifications();
	}

	getJustifications() {
		this.justificationService.filter(this.justificationFilter).subscribe(
			data => {
				this.justifications = data;
			}, error => {
				this.toastService.showDanger('Não foi possível retornar as justificativas');
				console.error(error);
			}
		);
	}

	fromForm() {
		let justification = new Justification().deserialize(this.justificationForm.value);
		return justification;
	}

	public submit() {
		this.modal.close(this.fromForm);
	}
}
