import { City } from './city';
import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { Segment } from './segment';
import { State } from './state';

export class Address implements Deserializable {
    streetName: string;
	streetNumber: string;
    addressLine: string;
	city: City;
	neighborhood: string;
	postalCode: string;
    state: State;


    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}