import { Deserializable } from "./deserializable";

export class AvailableFlowConfigLink implements Deserializable {
    segmentId: number;
    operationId: number;
    segmentName: string;
    operationName: string;
    flowName: string;
    available: boolean;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}