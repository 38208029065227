import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Operation } from './operation';

export class SpeechSegment implements Deserializable {
    externalId: string;
    id: number;
    name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}