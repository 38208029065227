import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpeechEnvironmentService } from '../../_services/speech-environment-controller.service';
import { SegmentService } from '../../_services/segment.service';
import { UserService } from '../../_services/user.service';

@Component({
	selector: 'evo-card-expand',
	templateUrl: './evo-card-expand.component.html',
	styleUrls: ['./evo-card-expand.component.scss']
})
export class EvoCardExpandComponent implements OnInit {

	@Input() public isLoading: boolean = false;

	@Input() public showBody: boolean = true;
	@Input() public isDisabled: boolean = false;

	@Input() public order = 1;
	@Input() public title: string;
	@Input() public description: string;

	@Input() public isDone: boolean = false;
	@Input() public showActions: boolean = true;

	@Output() public onSave: EventEmitter<any> = new EventEmitter<any>();
	@Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

	constructor() {
	}

	ngOnInit(): void {
	}

	public save(): void {
		this.onSave.emit();
	}

	public cancel(): void {
		this.onCancel.emit();
	}

}
