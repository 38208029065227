import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'evo-stepper-title',
  templateUrl: './stepper-title.component.html',
  styleUrls: ['./stepper-title.component.scss']
})
export class StepperTitleComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;

  constructor() { }

  ngOnInit(): void {
  }

}
