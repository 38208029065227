import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionConfigService } from '../../_services/session-config.service';
import { OrganizationService } from '../../_services/organization.service';
import { fadeInLeft } from '../../_helpers/animations/fade.animations';
import { InteractionServiceV2 } from '@app/services';

import * as config from '../../../assets/config.json';
import { User } from '@app/models';
import { Organization } from '@app/models';
import { UserService } from '@app/services';
import { MENU } from '../../_helpers/menu.helper';

@Component({
    selector: 'evo-navigation-bar',
    templateUrl: './evo-navigation-bar.component.html',
    styleUrls: ['./evo-navigation-bar.component.scss'],
	animations: [ fadeInLeft ]
})
export class EvoNavigationBarComponent implements OnInit {
    @Input() currentUser: User;
    @Input() currentOrganization: Organization;
    barIsCollapsed = false;
    barIsExpanding = false;
    activeNavId: string;
    navigationMenus: any[];

	public totalMonth: number;

	public config = config;

    constructor(
        public userService: UserService,
        private router: Router,
        public organizationService: OrganizationService,
        public interactionService: InteractionServiceV2
    ) {}

    ngOnInit(): void {
        this.navigationMenus = MENU;
        this.barIsCollapsed = SessionConfigService.getSidebarStatus();

		this.getInteractionMonth();
    }

	public getInteractionMonth(): void {
		/*this.interactionService.getTotalMonth().subscribe(
			data => {
				this.totalMonth = data;
			}, error => {
				this.totalMonth = 0;
				console.error(error);
			}
		)*/
	}

    logout() {
        this.userService.logout();
        this.router.navigate(['/login']);
    }

    toggleNavbar(forceValue?: boolean) {
        const newCollapsedValue = (forceValue !== undefined) ? forceValue : !this.barIsCollapsed;

        // Create delay for a smooth animation
        if (this.barIsCollapsed && !newCollapsedValue) {
            this.barIsExpanding = true;
            setTimeout(() => { this.barIsExpanding = false; }, 100);
        }
        // Remove active status
        if (newCollapsedValue) {
            this.activeNavId = null;
        }

        this.barIsCollapsed = newCollapsedValue;
        SessionConfigService.setSidebarStatus(this.barIsCollapsed);
    }
}
