import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Checklist } from '@app/models';

import { BaseServiceClass } from './baseService.service';

@Injectable({providedIn: 'root'})
export class ChecklistService extends BaseServiceClass<Checklist> {

	constructor(protected httpService: HttpClient) {
		super(httpService, '/checklists');
	}

	findBySegment(segmentId: number) {
		return this.httpService.get<Checklist>(`${this.entity_url}/segments/${segmentId}`);
	}

	public updateActive(id: number, value: boolean) {
		return this.httpService.patch(`${this.entity_url}/${id}/active/${value}`, null);
	}
}
