import { Directive, HostListener } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { QualityPermissions } from '@app/models';

@Directive({
  selector: '[appBlockCopy]'
})
export class BlockCopyDirective {
  constructor(private ngxRolesService:NgxPermissionsService) { }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    let copyPermission = this.ngxRolesService.getPermission(QualityPermissions.COPY_TRANSCRIPTION);
    let adminPermission = this.ngxRolesService.getPermission(QualityPermissions.ALL_PERMISSIONS);
    if(!copyPermission && !adminPermission)
        e.preventDefault();
  }

}
