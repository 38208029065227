import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { SpeechCategory } from '@app/models';

@Injectable({ providedIn: 'root' })
export class SpeechCategoryService {
  basePath: string = `${environment.apiUrl}/speech/categories`;

  constructor(private http: HttpClient) { }

  findByCustomer(customerId:number) {
    return this.http.get<SpeechCategory[]>(`${this.basePath}/customers/${customerId}`);
  }

  findAvailable(customerId:number, checklistId:number) {
    return this.http.get<SpeechCategory[]>(`${this.basePath}/customers/${customerId}/available?checklist=${checklistId}`);
  }
  findByCustomerAndEnvinroment(customerId:number, speechEnvironmentId:string) {
    return this.http.get<SpeechCategory[]>(`${this.basePath}/customers/${customerId}/speechEnvironment/${speechEnvironmentId}`);
  }
}
