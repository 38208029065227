import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {filter} from 'rxjs/operators';
import {ConfirmDialogComponent} from '../../../_components/confirm-dialog/confirm-dialog.component';

@Directive({
	selector: '[evolloConfirmAction]'
})
export class ConfirmActionDirective {
	@Input() title: string = 'default';
	@Input() text: string = 'default';

	@Output() confirm: EventEmitter<boolean> = new EventEmitter();

	constructor(private modalService: NgbModal) {
	}

	@HostListener('click', ['$event']) onClick(event: MouseEvent) {
		event.preventDefault();
		const modalRef = this.modalService.open(ConfirmDialogComponent, {});
		modalRef.componentInstance.text = this.text;
		modalRef.componentInstance.title = this.title;

		modalRef.closed.pipe(filter(result => !!result)).subscribe(() => this.confirm.emit(true));
	}

}
