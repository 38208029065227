import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Operation } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class OperationService extends BaseServiceClass<Operation> {
	constructor(protected httpService: HttpClient) {
		super(httpService, '/operations');
	}

	getCompleteById(id: number) {
		return this.httpService.get<Operation>(`${this.entity_url}/${id}/complete`);
	}

	public patch(operation, id): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${id}`, operation);
	}

}
