import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDangerDialogComponent } from '../_components/confirm-danger-dialog/confirm-danger-dialog.component';
import { InteractionFilter, MassAnswerChecklistItem } from "@app/models";
import {
	InteractionMassAnswerChooseTypeComponent
} from "../_views/interactions/interaction-management/interaction-mass-answer";

@Injectable({
	providedIn: 'root'
})
export class InteractionMassAnswerService {
	public currentComponent$: Observable<any>;
	public steps: any[];

	public _currentStep: BehaviorSubject<number> = new BehaviorSubject(0);
	public interactionFilter: InteractionFilter;

	public groupedCheckListItems: any[] = [];
	public massAnswerCheckListItems: MassAnswerChecklistItem[] = [];

	public currentStatus: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	public segmentInteractionIds: { segmentId: number, segmentName: string, interactionIds: number[] }[] = [];

	public get currentStep() { return this._currentStep.value; }
	public set currentStep(step: number) { this._currentStep.next(step); }

	public isLoading: boolean = false;

	constructor(private modalService: NgbModal) {
	}

	nextStep() {
		this.currentStep = this.currentStep + 1;
	}

	prevStep() {
		this.currentStep = this.currentStep - 1;
	}

	showAlertOnPrev(): NgbModalRef {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = "Ao voltar, as informações preenchidas serão perdidas. Tem certeza que deseja voltar?";
		modalRef.componentInstance.title = "Respostas em masssa";
		return modalRef;
	}
}
