import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
	CreateChecklistService,
	ExternalSpeechEnvironmentsService,
	FirstStepsService, SystemMessages, ToastService
} from '@app/services';
import { Checklist } from "@app/models";

@Component({
	selector: 'app-checklist-automatic-effective-rules',
	templateUrl: './checklist-automatic-effective-rules.component.html',
	styleUrls: ['./checklist-automatic-effective-rules.component.scss']
})
export class ChecklistAutomaticEffectiveRulesComponent implements OnInit {

	public checklist: Checklist;
	public rules: any[] = [];

	public form: FormGroup;

	constructor(public createChecklistService: CreateChecklistService,
				private firstStepsService: FirstStepsService,
	            private formBuilder: FormBuilder,
				private toastService: ToastService,
				public speechEnviromentsService: ExternalSpeechEnvironmentsService) {
	}

	ngOnInit(): void {
		this.checklist = this.createChecklistService.currentChecklist;

		this.form = this.formBuilder.group({
			effective: [this.createChecklistService.currentChecklist.speechCategoryEffectiveIntegration ? this.createChecklistService.currentChecklist.speechCategoryEffectiveIntegration : null],
			nonEffective: [this.createChecklistService.currentChecklist.speechCategoryNonEffectiveIntegration ? this.createChecklistService.currentChecklist.speechCategoryNonEffectiveIntegration : null]
		});

		this.speechEnviromentsService.getEnvironmentRules(this.checklist.segment.speechEnvironmentId, 'ANALYSIS').subscribe(
			data => {
				this.rules = data;
			}, error => {
				console.error(error);
				this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
			}
		)
	}

	public prevStep(): void {
		this.createChecklistService.prevStep();
	}

	public nextStep(): void {
		this.createChecklistService.currentChecklist.speechCategoryEffectiveIntegration = this.form.get('effective')?.value;
		this.createChecklistService.currentChecklist.speechCategoryNonEffectiveIntegration = this.form.get('nonEffective')?.value;

		this.createChecklistService.nextStep();
	}

	public getItemLabelByValue(items, value) {
		const item = items.find(item => item._id == value._id);
		return item ? item.name : '';
	}
}
