import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class ChartsService extends BaseServiceClass<any> {

	constructor(protected http: HttpClient) {
		super(http, '/api/v1/graphs');
	}

	getParetoChecklistItems(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }) {
		return this.http.get(`${this.entity_url}/pareto/checklist/item`, {params: filters});
	}

	getAverageEvaluationGrade(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }) {
		return this.http.get(`${this.entity_url}/average/evaluation/grade`, {params: filters});
	}

	getIncidenceItems(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }) {
		return this.http.get(`${this.entity_url}/incidence/item`, {params: filters});
	}

	getAverageInteractionGrade(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }) {
		return this.http.get(`${this.entity_url}/average/interaction/grade`, {params: filters});
	}

	getAverageIncidenceGrade(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }) {
		return this.http.get(`${this.entity_url}/average/incidence/item`, {params: filters});
	}

	// 1
	public getPercentSentiment(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params:  filters };

		return this.http.get(`${this.entity_url}/percent/sentiment`, options)
	}

	// 2
	public getPercentPeriodSentiment(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params:  filters };

		return this.http.get(`${this.entity_url}/percent/period/sentiment`, options)
	}

	// 3
	public getPercentPeriodDissatisfaction(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params:  filters };

		return this.http.get(`${this.entity_url}/percent/period/dissatisfaction`, options)
	}

	// 4
	public getPercentDissatisfaction(filters: { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params:  filters };

		return this.http.get(`${this.entity_url}/percent/dissatisfaction`, options)
	}

	public getAgentInteractionDuration(filters: { operations: any[], segments: any[], operators: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get(`${this.entity_url}/average/agent/interaction/duration`, options);
	}

	public getAgentInteractionSilencePercent(filters: { operations: any[], segments: any[], operators: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get(`${this.entity_url}/average/agent/interaction/silence-percent`, options);
	}

	public getSummaryInteractionDurationSilence(filters: { operations: any[], segments: any[], operators: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get(`${this.entity_url}/average/summary/interaction/duration-silence-percent`, options);
	}

	public getAveragePeriodInteractionDuration(filters: { operations: any[], segments: any[], operators: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get(`${this.entity_url}/average/period/interaction/duration`, options);
	}

	public getAveragePeriodInteractionSilencePercent(filters: { operations: any[], segments: any[], operators: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get(`${this.entity_url}/average/period/interaction/silence-percent`, options);
	}

	public speechWordCloud(filters : { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters }

		return this.http.get( `${API_URL}/external/speech/word-cloud`, options);
	}

	public getAverageSummaryInteractionGrades(params) {
		return this.http.get(`${this.entity_url}/average/summary/interaction/grade`, {params});
	}

	public getAverageInteractionGrades(params) {
		return this.http.get(`${this.entity_url}/average/interaction/grade`, {params});
	}

	public getPercentIncidenceItem(params) {
		return this.http.get(`${this.entity_url}/percent/incidence/item`, {params});
	}

	public getAverageOperatorsGrade(params): Observable<any> {
		return this.http.get(`${this.entity_url}/average/evaluation/grade`, {params});
	}

	// Monitor Cycle Status
	public getSummaryEvaluationInteraction(params): Observable<any> {
		return this.http.get(`${this.entity_url}/summary/evaluation/interaction`, { params })
	}

	public getPercentCallDropCallback(params): Observable<any> {
		return this.http.get(`${this.entity_url}/percent/calldrop-callback`, {params});
	}

	public getPercentCallDropPeriod(params): Observable<any> {
		return this.http.get(`${this.entity_url}/percent/period/call-drop`, {params});
	}

	public getPercentCallbackPeriod(params): Observable<any> {
		return this.http.get(`${this.entity_url}/percent/period/callback`, {params});
	}

	public getCallbackWorldCloud(filters : { operations: any[], segments: any[], contactedAtStart: string, contactedAtEnd: string }): Observable<any> {
		let options = { params: filters };
		return this.http.get( `${API_URL}/external/speech/word-cloud/callback`, options);
	}

	public getPercentContestationByOperator(params, status?): Observable<any> {
		if(status) {
			params.itemStatus = status;
		}

		return this.http.get(`${this.entity_url}/percent/agent/contestation`, { params });
	}

	public getSummaryStatusItem(params, itemStatus?): Observable<any> {
		if(params.status) {
			delete params.status;
		}

		if(itemStatus) {
			params.itemStatus = itemStatus;
		}

		return this.http.get(`${this.entity_url}/percent/status/item`, { params })
	}

	// feedback
	public getPercentAgentFeedbackInteractions(params): Observable<any> {
		if(params.itemStatus) {
			delete params.itemStatus;
		}

		return this.http.get(`${this.entity_url}/percent/agent/interaction/feedback`, { params })
	}

	// finished
	public getSummaryAgentInteractionStatus(params, status?): Observable<any> {
		if(params.itemStatus) {
			delete params.itemStatus;
		}

		if(status) {
			params.status = status;
		}

		return this.http.get(`${this.entity_url}/summary/agent/interaction/status`, { params })
	}

}

export enum ChartLabels {
	POSITIVE = 'Positivo',
	NEUTRAL = 'Neutro',
	NEGATIVE = 'Negativo',
	NORMAL = 'Insatisfação',
	CRITICAL = 'Crítico',
	CALL_DROP = 'Quedas',
	CALLBACK = 'Rechamadas'
}

export enum ChartType {
	POSITIVE = 'success',
	NEUTRAL = 'neutral',
	NEGATIVE = 'danger'
}
