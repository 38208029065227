import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { MonthlyCycle } from '@app/models';
import { BaseServiceClass } from './baseService.service';

const API_URL = environment.apiUrl;
@Injectable({ providedIn: 'root' })
export class MonthlyCycleService extends BaseServiceClass<MonthlyCycle> {
    constructor(protected httpService: HttpClient) {
        super(httpService, '/api/v1/monthlyCycle');
    }

    findCyclesByOperationId(operationId: any[]): Observable<MonthlyCycle[]> {
        return this.httpService.get<MonthlyCycle[]>(this.entity_url, {params: {operationId}})
    }

    findCyclesByCustomerId(customerId: number): Observable<MonthlyCycle[]> {
        return this.httpService.get<MonthlyCycle[]>(`${this.entity_url}/${customerId}`);
    }
}
