import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router} from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { delay } from 'rxjs/operators';
import { OrganizationService } from '../../_services/organization.service';
import { HelpDialogComponent } from '../../_components/help-dialog/help-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


class NestedTab {
  description: string;
  path: string;
  icon: string;
  permissions: string[];
  action?: string;
}
class CustomSidebar extends NestedTab {
  id: string;
  submenu?: NestedTab[];

  constructor() { super(); }
}

@Component({
  selector: 'sidebar-menu-component',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})

export class SidebarMenuComponent implements OnInit, AfterViewInit {
  @Input() sidebarMenu: CustomSidebar;
  @Input() activeNav: any;
  @Input() barIsCollapsed: any;
  @Output() activeNavChange = new EventEmitter<string>();

  routerService: Router;
  availableTabs: string[] = [];
  highlightParent: boolean;

	public permissionsLoaded: boolean = false;

  constructor(private router: Router,
              private ngxPermissionService: NgxPermissionsService,
              public organizationService: OrganizationService,
              public modalService: NgbModal,) {
    this.routerService = router;
  }

  ngOnInit(): void {
	  this.listenToPermissions();
  }

  ngAfterViewInit() {
	  // If submenus are included
	  if (this.sidebarMenu.submenu) {
		  // Creating array of available tabs
		  this.availableTabs = this.sidebarMenu.submenu.map(tab => tab.path);
		  this.routerService.events.subscribe((nav) => {
			  if (nav instanceof NavigationEnd) {
				  this.updateNavigationSettings(nav.url);
			  }
		  });
		  setTimeout(() => this.updateNavigationSettings(this.routerService.url));
	  }
  }

	private updateNavigationSettings(pathUrl) {
    this.activeNav = '';
    // Setting the parent-tab status as active
    this.highlightParent = (this.availableTabs.indexOf(pathUrl) !== -1);
    // Opening side-bar nav if parent is active and it's not collapsed
    if (this.highlightParent && !this.barIsCollapsed) {
      this.activeNav = this.sidebarMenu.path;
      this.activeNavChange.emit(this.activeNav); // Updating nav status on parent component
    }
  }

  toggleNavStatusActiveNav() {
    if (!this.barIsCollapsed) {
      // Setting active tab as parent's tab value
      this.activeNav = (this.activeNav === this.sidebarMenu.path) ? '' : this.sidebarMenu.path;
      this.activeNavChange.emit(this.activeNav); // Updating nav status on parent component
    }
  }

	private listenToPermissions(): void {
		this.organizationService.permissionsLoaded.pipe(delay(0)).subscribe(
			isLoaded => {
				this.permissionsLoaded = isLoaded;
			});
	}

	public menuAction(action: string): void {
	  if(action == 'trainingAction') {
		  this.openTrainingDialog();
	  }
	}

	public openTrainingDialog(): void {
		const modalRef = this.modalService.open(HelpDialogComponent, {});
		modalRef.componentInstance.type = 'training';

		modalRef.result.then( data => {
			if(data) {
			}
		});
	}

}
