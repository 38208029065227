import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { ConfirmDangerDialogComponent } from '../_components/confirm-danger-dialog/confirm-danger-dialog.component';
import { Checklist, Group } from '@app/models';
import { NewChecklistModalComponent } from '../_views/first-steps/create-checklist/new-checklist-modal/new-checklist-modal.component';
import { ChecklistItemService } from './checklist-item.service';
import { ChecklistService } from './checklist.service';
import { FirstStepsService } from './first-steps.service';
import { ToastService } from './toast.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { HelpDeskService } from './help-desk.service';

@Injectable({
	providedIn: 'root'
})
export class CreateChecklistService {
	_currentStep: BehaviorSubject<number> = new BehaviorSubject(0);
	get currentStep() { return this._currentStep.value; }
	set currentStep(step: number) { this._currentStep.next(step); }

	currentChecklist: Checklist;
	isFirstSteps: boolean = true;

	currentCategories: any[] = [];
	automaticCategories: any[] = [];
	savedCategories: SavedCategory[] = [];

	changeCreationMode$ = new Subject<"AUTOMATIC" | "MANUAL">();

	public formDisabled = true;

	constructor(private modalService: NgbModal,
				private checklistService: ChecklistService,
				private message: ToastService,
				private firstStepsService: FirstStepsService,
				private ngxPermissionService: NgxPermissionsService,
				private helpDeskService: HelpDeskService,
				private checklistItemService: ChecklistItemService) { }

	nextStep() {
		this.currentStep = this.currentStep + 1;
		this.helpDeskService.resetHelper();
	}

	prevStep() {
		this.currentStep = this.currentStep - 1;
		this.helpDeskService.resetHelper();
	}

	showAlertOnPrev(): NgbModalRef {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = "Ao voltar, as informações preenchidas do checklist serão perdidas. Tem certeza que deseja voltar?";
		modalRef.componentInstance.title = "Detalhes do checklist";
		return modalRef;
	}

	addCategory(category) {
		this.currentCategories = [...this.currentCategories, category];
	}

	updateCategory(category) {
		this.currentCategories = this.currentCategories.map(c => {
			if (c.descricao === category.descricao) c = category;
      		return c;
		});
	}

	createChecklist(): Observable<any[]> {
		return this.checklistService[!this.currentChecklist.id ? 'post' : 'put'](this.currentChecklist, this.currentChecklist.id).pipe(
			tap(checklist => this.currentChecklist = checklist),
			switchMap(checklist => {
				return forkJoin(this.currentCategories.map(category => {
					category.checklist.id = checklist.id;

					if(category.id) {
						return this.checklistItemService.put(category, category.id).pipe(catchError(() => {
							this.message.showDanger('Ocorreu um erro ao editar a categoria ' + category.descricao);
							return of(null);
						}));
					}

					return this.checklistItemService.post(category).pipe(catchError(() => {
						this.message.showDanger('Ocorreu um erro ao cadastrar a categoria ' + category.descricao);
						return of(null);
					}));
				}));
			}),
			finalize(() => this.currentCategories = [])
		);
	}

	create(checklist: Checklist){
		return this.checklistService[!checklist.id ? 'post' : 'put'](this.currentChecklist, this.currentChecklist.id);
	}

	openNewChecklistModal(reopen: boolean = false): Observable<any> {
		const modal = this.modalService.open(NewChecklistModalComponent, {backdrop: 'static'});

		if(reopen) {
			modal.componentInstance.title = 'Etapa concluída';
			modal.componentInstance.subtitle = 'Você já criou um Checklist e completou essa etapa.';
		}

		return modal.closed.pipe(
			tap((action) => {
				if (action === 'NEW_CHECKLIST') {
					this.currentChecklist = new Checklist();
					this.currentCategories = [];
					this.currentStep = 0;
				} else if (action === 'IMPORT') {
					this.firstStepsService.setStepAsDone(2);
					this.firstStepsService.setStepAsEnabled(3);
					this.firstStepsService.setCurrentStep(3);
				} else {
					this.message.showDanger('Ocorreu um erro inesperado.');
				}
			})
		);
	}

}

export class SavedCategory {
	id?: number;
	name: string;
	active?: boolean;
	checklist: Checklist;
	checklist_id?: number;
	defaultAnswer?: { id: number, name: string };
	descricao: string;
	group: Group;
	justificationTouched?: boolean;
	peso: number;
	speechCategoryId: string;
	customer: any;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}
}
