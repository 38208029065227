import { Deserializable } from './deserializable';

export class ImportJobStatus implements Deserializable {
    id: string;
    name: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}

export enum ImportJobStatusEnum {
	PENDING = <any>'Aguardando',
	IN_PROGRESS = <any>'Em andamento',
	COMPLETED = <any>'Finalizada',
	FAILED = <any>'Falha'
}
