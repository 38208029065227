import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Segment } from './segment';
import { SpeechSegment } from './speechSegment';

export class Operation implements Deserializable {
    id: number;
    name: string;
    description: string;
    customerId: number;
    segmentId: number;
    customer: Customer;
    speechSegment?: SpeechSegment[] = [];
    speechEnvironment?: any;
    segmentIds?:SegmentIdWithSpeechSegmentId[];
    segments?:Segment[];
    
    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}
export interface SegmentIdWithSpeechSegmentId{
    segmentId:number;
    speechSegmentId:string;
}