import { Component, Input, OnInit } from '@angular/core';
import { TimelineService } from '@app/services';
import { FileInfo, MonitorInteractionItem } from '@app/models';
import {
	InteractionAttachmentDialogComponent
} from '../interaction-attachment-dialog/interaction-attachment-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


export interface TimelineItem {
	title: string;
	date: Date;
	content: string;
	status?: string;
	minuteSelected?: string;
	fileInfo?: FileInfo;
	file?: any;
}

@Component({
	selector: 'evo-timeline-list',
	templateUrl: './timeline-list.component.html',
	styleUrls: ['./timeline-list.component.scss']
})
export class TimelineListComponent implements OnInit {
	@Input() monitorInteraction: MonitorInteractionItem;
	@Input() timelineItems: TimelineItem[];

	constructor(private timelineService: TimelineService, private modalService: NgbModal) {
	}

	ngOnInit(): void {
		if (!this.timelineItems) {
			this.timelineItems = this.timelineService.getTimelineItemFromMonitorInteraction(this.monitorInteraction);
		}
	}

	public openInteractionAttachmentModal(file: any): void {
		let modalRef = this.modalService.open(InteractionAttachmentDialogComponent, {size: 'lg'});
		modalRef.componentInstance.attachment = file;
	}
}
