<div class="speed-test-card">

	<div class="speed-test-item mb-3">
		<div class="d-flex justify-content-between">
			<span>Status</span>

			<span class="text-success" *ngIf="networkStatus">Conectado</span>
			<span class="text-danger" *ngIf="!networkStatus">Desconectado</span>
		</div>
	</div>

	<div class="speed-test-item">
		<div class="d-flex justify-content-between mb-1">
			<span class="label">Ping <i class="fas fa-info-circle" tooltip="Tempo de resposta do servidor"></i></span>
			<span class="value" *ngIf="!pingLoading">{{ finalPing | number: '1.0-0' }}ms</span>
			<span class="value" *ngIf="pingLoading">Verificando...</span>
		</div>

		<div progress class="progress-wrapper">
			<p class="progress-bar mb-0" style="border-radius: 8px; height: 6px">
				<ngb-progressbar type="{{ getPingColor() }}" [value]="finalPing < maxPing ? finalPing * 100 / maxPing : 100"></ngb-progressbar>
			</p>
		</div>
	</div>

	<div class="speed-test-item">
		<div class="d-flex justify-content-between mb-1">
			<span class="label">Download <i class="fas fa-info-circle" tooltip="Sua velocidade de download."></i></span>
			<span class="value" *ngIf="!downloadLoading">{{ finalDownload }}mb/s</span>
			<span class="value" *ngIf="downloadLoading">Verificando...</span>
		</div>

		<div progress class="progress-wrapper">
			<p class="progress-bar mb-0" style="border-radius: 8px; height: 6px">
				<ngb-progressbar type="{{ getDownloadColor() }}" [value]="finalDownload < maxDownload ? finalDownload * 100 / maxDownload : 100"></ngb-progressbar>
			</p>
		</div>
	</div>

	<div class="speed-test-item">
		<div class="d-flex justify-content-between mb-1">
			<span class="label">Upload <i class="fas fa-info-circle" tooltip="Sua velocidade de upload."></i></span>
			<span class="value" *ngIf="!uploadLoading">{{ finalUpload | number: '1.0-0' }}mb/s</span>
			<span class="value" *ngIf="uploadLoading">Verificando...</span>
		</div>

		<div progress class="progress-wrapper">
			<p class="progress-bar mb-0" style="border-radius: 8px; height: 6px">
				<ngb-progressbar type="{{ getUploadColor() }}" [value]="finalUpload < maxUpload ? finalUpload * 100 / maxUpload : 100"></ngb-progressbar>
			</p>
		</div>
	</div>

	<div class="mt-3 text-center" *ngIf="lastTestLabel !== ''">
		<span class="status">
			Verificação feita {{ lastTestLabel }}
		</span>
	</div>

</div>
