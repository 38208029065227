import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-checkable-card',
  templateUrl: './checkable-card.component.html',
  styleUrls: ['./checkable-card.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckableCardComponent),
    multi: true
  }]
})
export class CheckableCardComponent implements OnInit, ControlValueAccessor{
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() iconClass: string = '';
  @Input() isBlocked: boolean = false;
  @Input() isPremium: boolean = false;

  onChange: any = () => {}
  onTouch: any = () => {}

  isDisabled: boolean = false;
  showPremiumTag: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.showPremiumTag = this.isPremium;
  }

  _value = new FormControl(false);
  set value(val: any) {
    if (!this.isDisabled) {
      this._value.setValue(val);
      this.onChange(val);
      this.onTouch(val);
    }
  }

  get value() {
    return this._value;
  }

  get isEnabled() {
    return this._value.value;
  }

  changeCheckState() {
    this.value = !this.value.value;
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
