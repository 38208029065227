import { stringify } from '@angular/compiler/src/util';
import { Pipe, PipeTransform } from '@angular/core';
import { format, subHours } from 'date-fns';

@Pipe({
    name: 'checkIFDateHour'
})
export class CheckIFDateHour implements PipeTransform {

    transform(value: any, subtractHours: number = 3): any {

        let newValue = Date.parse(value);
        if (isNaN((newValue))) {
            return value;
        }

		return this.dateFsFormat(newValue, subtractHours);
    }

	dateFsFormat(value: Date | string | number, subtractHours: number = 3): string {
		const date = new Date(value);
		const adjustedDate = subHours(date, subtractHours);
		return format(adjustedDate, "dd/MM/yyyy 'às' HH:mm:ss");
	}

    dataAtualFormatada(date, value) {
		const dateMaxLimite = new Date(2029, 1, 1);
        const dateMinLimite = new Date(2018, 1, 1);
        if (new Date(date) > dateMaxLimite) { return value; }
        if (dateMinLimite > new Date(date)) { return value; }

        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear(),
			horas = subHours(data, 3).getHours().toString(),
	        horasF = (horas.length == 1) ? '0' + horas : horas,
	        minutos = data.getMinutes().toString(),
	        minutosF = (minutos.length == 1) ? '0' + minutos : minutos,
	        segundos = data.getSeconds().toString(),
	        segundosF = (segundos.length == 1) ? '0' + segundos : segundos;
        return diaF + "/" + mesF + "/" + anoF + ' às ' + horasF + ':' + minutosF + ':' + segundosF;
    }

}
