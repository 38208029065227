<div class="dropzone" evoDnd (onFileDropped)="fileDropped($event)" (onFileHover)="isHover = true" (onFileLeave)="isHover = false" (click)="fileInput.click()">

	<input [disabled]="isDisabled || isLoading" (click)="resetValueOnClick($event)" (change)="writeValue($event.target.files)" type="file" [accept]="accept" hidden [multiple]="isMultiple" #fileInput/>

	<img src="/assets/icons/upload.svg"/>

	<div class="details" *ngIf="!isHover">
		<span class="main"><span class="text-primary">Clique para {{ actionText }}</span> ou arraste e solte</span>
		<span class="legend">{{ fileLegend }}</span>
	</div>

	<div class="details" *ngIf="isHover">
		<span class="main">Solte aqui os arquivos</span>
	</div>

</div>

<div class="drop-errors" *ngIf="dropError">
	<span>{{ dropError }}</span>
	<i class="fas fa-times" (click)="dropError = ''"></i>
</div>

<div class="files-list">

	<div class="single-file" *ngFor="let file of files; index as i">
		<div class="name">
			<span>
				<i class="fas fa-times btn-remove" (click)="onCancelUpload(file, i)"></i>
				{{ file.name }}
			</span>
			<i class="fas fa-check-circle" *ngIf="uploadProgress[i] == 100"></i>
		</div>
		<div class="progress-div" *ngIf="uploadProgress[i] == 0 && showItemLegend">
			<span style="opacity: 0.4">{{ itemLegend }}</span>
			<span>{{ byteToMb(file.size) }}</span>
		</div>
		<div class="progress-div" *ngIf="uploadProgress[i] > 0">

			<div class="evo-progress">
				<div class="background"></div>
				<div class="progress" [ngStyle]="{ 'width': uploadProgress[i] + '%' }"></div>
			</div>

			<div class="progress-value">
				<span>{{uploadProgress[i] | number : '1.0-0'}}%</span>
			</div>

		</div>
	</div>

</div>
