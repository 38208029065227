import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseServiceClass } from "../baseService.service";

@Injectable({providedIn: 'root'})
export class SegmentServiceV2 extends BaseServiceClass<any> {

	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v2/segments');
	}

}
