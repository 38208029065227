<div class="input-group">
	<input class="form-control py-2 border-right-0 border" [placeholder]="placeholder" id="search-input"
	       [(ngModel)]="searchFilter" (keydown.enter)="onSubmit()" (ngModelChange)="onChange()"
	       (keyup)="onKeyup($event)">
	<div class="input-group-append" *ngIf="searchOptions && searchOptions.length > 0">
		<evo-select>
			<ng-select class="ng-select-search" [(ngModel)]="selectedOption"
		           [items]="searchOptions"
		           [clearable]="false"
					bindLabel="name"
					bindValue="value">
			</ng-select>
		</evo-select>
	</div>
	<span class="input-group-append">
      <button class="btn btn-primary border-left-0 border" type="button" (click)="onSubmit()">
        <i class="fas fa-search"></i>
      </button>
    </span>
</div>
