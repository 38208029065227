<card>
    <div header class="mb-0 d-flex justify-content-between">
        <h5>{{title}}</h5>
        <div class="d-flex" style="height: min-content;" *ngIf="results.length > 0">
            <span class="mr-1">Mostrando</span>
            <ng-select [items]="[10, 20, 30]" [(ngModel)]="perPage" (change)="onFilterChange()" [clearable]="false"></ng-select>
            <span class="ml-1 mr-1">de {{results.length}} em ordem</span>
            <ng-select [(ngModel)]="orderBy" (change)="onFilterChange()" [clearable]="false">
                <ng-option [value]="'asc'">Crescente</ng-option>
                <ng-option [value]="'desc'">Decrescente</ng-option>
            </ng-select>
        </div>
    </div>
    <div body>
        <div class="w-100" [ngStyle]="{height: (!xAxis && perPage <= 20) ? '40vh' : '25vh'}" *ngIf="results.length > 0">
            <ngx-charts-bar-vertical
                class="w-100 h-100"
                [results]="resultsSlice"
                (select)="onSelect($event)"
                [yAxis]="yAxis"
                [xAxis]="perPage <= 20"
                [scheme]="colorScheme"
                [roundEdges]="roundEdges"
                [yScaleMax]="yScaleMax"
                [noBarWhenZero]="noBarWhenZero"
                [barPadding]="barPadding"
                [legend]="legend">
                <ng-template #tooltipTemplate let-model="model">
                    <div class="d-flex flex-column">
                        <span>{{model.name}}</span>
                        <span style="font-size: 1.2em">Nota <strong>{{model.value}}</strong> • <strong>{{model.extra.code}}</strong> interações</span>
                    </div>
                </ng-template>
            </ngx-charts-bar-vertical>
        </div>
        <ngb-pagination
            *ngIf="results.length > 0"
            class="d-flex justify-content-center pt-4"
            size="sm"
            [disabled]="isPaginating"
            [(page)]="currentPage"
            [maxSize]="5"
            [pageSize]="perPage"
            [boundaryLinks]="true"
            [collectionSize]="results?.length"
            (pageChange)="onPageChanged($event)">
        </ngb-pagination>
        <div class="w-100" style="padding-left: 5em" *ngIf="results.length === 0">
            <span class="text-muted">Nenhum dado disponível.</span>
        </div>
    </div>
  </card>