<div>
    <span style="font-size: 14px; font-weight: 600;">Dias Úteis</span>
    <p style="margin-bottom: 0.3rem;">Selecione os dias úteis para a contagem de relatórios e status das monitorias.</p>
    <div>
        <div>
            <form>
                <div class="custom-control custom-checkbox mb-1" *ngFor="let day of weekDays; index as idx">
                    <input [formControl]="_days.controls[idx]" class="custom-control-input" (change)="onDayChanged($event)" type="checkbox" [id]="day.formControl">
                    <label class="custom-control-label" [for]="day.formControl">
                    {{day.label}}
                    </label>
                </div>
            </form>
            <input ngbDatepicker
                #datepicker="ngbDatepicker"
                [autoClose]="true"
                (dateSelect)="onDateSelection($event)"
                [(ngModel)]="currDateSelected"
                [ngModelOptions]="{standalone: true}"
                [displayMonths]="2"
                outsideDays="hidden"
                positionTarget=".dias-nao-uteis"
                [markDisabled]="isDisabled"
                [hidden]="true"
                tabindex="-1"
                readonly>
            <a type="button" class="dias-nao-uteis" (click)="datepicker.toggle()">Dias não úteis +</a>
            <div class="d-flex flex-wrap mt-1">
                <span *ngFor="let day of holidays.value; index as idx" class="badge badge-pill mr-1">
                    <span>{{day.day | number: '2.0-0'}}/{{day.month | number: '2.0-0'}}/{{day.year}}</span>
                    <button (click)="removeDiaNaoUtil(idx)" type="button" class="btn btn-sm pr-0 pt-0 pb-0"><i class="fas fa-times-circle"></i></button>
                </span>
            </div>
        </div>
    </div>
</div>