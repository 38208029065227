import { FormGroup } from '@angular/forms';
import { Checklist } from './checklist';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { Group } from './group';
import { Justification } from './justification';
import { SpeechCategory } from './speechCategory';

export class ChecklistItem implements Deserializable {
	id?: number;
	name: string;
	descricao: string;
	peso: number;
	customer: Customer;

	group: Group;
	justifications?: Justification[];
	checklist: any;
	checklist_id?: number;

	answer?: string;
	answerTouched?: boolean = false;
	justification?: Justification;
	justificationTouched?: boolean = false;

	explanation?: string;

	evaluationGrade?: number;

	speechCategory?: SpeechCategory;

	defaultAnswer?: any;
	itemAnswer?: any;

	active?: boolean;

	isSaved?: boolean;
	speechCategoryId: string;


	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

}
