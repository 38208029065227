import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FirstStepsService } from '../../_services/first-steps.service';
import { fadeInTop } from '../../_helpers/animations/fade.animations';

@Component({
    selector: 'evo-onboard-overlay',
    templateUrl: './onboard-overlay.component.html',
    styleUrls: [ 'onboard-overlay.component.scss' ],
	animations: [ fadeInTop ]
})
export class OnboardOverlayComponent implements OnInit {

	public isOpen: boolean = false;

	public stepCompleted: number = 0;

    constructor(private router: Router, public firstStepsService: FirstStepsService){ }

	ngOnInit() {
		if(this.firstStepsService.missingSegment || this.firstStepsService.missingOperation) {
			this.stepCompleted = 0;
		}

		if(!this.firstStepsService.missingOperation && this.firstStepsService.missingChecklist) {
			this.stepCompleted = 1;
		}

		if(!this.firstStepsService.missingChecklist && this.firstStepsService.missingImportJob) {
			this.stepCompleted = 2;
		}
	}
}
