import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { BaseServiceClass } from './baseService.service';
import { OnboardingStep } from '@app/models';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class OnboardingStepService extends BaseServiceClass<any> {

	public currentOnboardingStep: OnboardingStep;

	constructor(private http: HttpClient, private router: Router, private userService: UserService) {
		super(http, '');

		this.entity_url = environment.accountUrl + '/api/v1/organizations/steps';
	}

	public put(onboardingStep: OnboardingStep): Observable<any> {
		return this.http.put(`${this.entity_url}`, onboardingStep);
	}

	public putNextOnboardingStep(currentStep: number, totalSteps: number): Observable<any> {
		this.currentOnboardingStep.step = currentStep;

		return this.put(this.currentOnboardingStep);
	}

	public getStatus(): Observable<any> {
		let url = environment.apiUrl + '/api/v2/onboarding/state';
		return this.http.get(url);
	}
}
