import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDangerDialogComponent } from '../_components/confirm-danger-dialog/confirm-danger-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class ImportDataService {
	public currentComponent$: Observable<any>;
	public steps: any[] = [];

	_currentStep: BehaviorSubject<number> = new BehaviorSubject(0);
	get currentStep() { return this._currentStep.value; }
	set currentStep(step: number) { this._currentStep.next(step); }

	segmentId: number = null;
	operationId: number = null;
	checklistId: number = null;
	metadata: string = null;
	zip: string = null;
	file: File = null;
	columnIndexes: any = null;

	uniqueOperators: any[];
	operatorsMap: any;

	isLoading: boolean = false;

	private _importType: string;

	public get importType(): string {
		return this._importType;
	}

	public set importType(value: string) {
		this._importType = value;

		if(this.importType == ImportTypes.MULTI) this.steps = this.multiImportSteps;
		if(this.importType == ImportTypes.SINGLE) this.steps = this.singleImportSteps;
		if(this.importType == ImportTypes.METADATA) this.steps = this.metadataImportSteps;
	}

	private readonly multiImportSteps = [];
	private readonly singleImportSteps = [];
	private readonly metadataImportSteps = [];

	constructor(private modalService: NgbModal) {
		this.multiImportSteps = [
			{label: 'Detalhes da chamada', componentPromise: import('../_views/first-steps/import-data/import-data-step-one/import-data-step-one.component')},
			{label: 'Importar metadados', componentPromise: import('../_views/first-steps/import-data/import-data-step-two/import-data-step-two.component')},
			{label: 'Relacionar campos', componentPromise: import('../_views/first-steps/import-data/import-data-step-three/import-data-step-three.component')},
			{label: 'Importar áudio', componentPromise: import('../_views/first-steps/import-data/import-data-step-four/import-data-step-four.component')}
		];

		this.singleImportSteps = [
			{label: 'Importar interação', componentPromise: import('../_views/interactions/interaction-add/interaction-add.component')}
		];

		this.metadataImportSteps = [
			{label: 'Detalhes da interação', componentPromise: import('../_views/first-steps/import-data/import-data-step-one/import-data-step-one.component')},
			{label: 'Importar metadados', componentPromise: import('../_views/first-steps/import-data/import-data-step-two/import-data-step-two.component')},
			{label: 'Relacionar campos', componentPromise: import('../_views/first-steps/import-data/import-data-step-three/import-data-step-three.component')}
		];
	}

	nextStep() {
		this.currentStep = this.currentStep + 1;
	}

	prevStep() {
		this.currentStep = this.currentStep - 1;
	}

	showAlertOnPrev(): NgbModalRef {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = "Ao voltar, as informações preenchidas da importação serão perdidas. Tem certeza que deseja voltar?";
		modalRef.componentInstance.title = "Detalhes das chamadas";
		return modalRef;
	}

	public resetForm(): void {
		this.segmentId = null;
		this.operationId = null;
		this.checklistId = null;
		this.metadata = null;
		this.zip = null;
		this.file = null;
		this.columnIndexes = null;
	}
}

export enum ImportTypes {
	SINGLE = 'SINGLE',
	MULTI = 'IMPORT',
	METADATA = 'IMPORT_METADATA'
}
