import { Injectable } from '@angular/core';


@Injectable()
export class SessionConfigService {

  static setSidebarStatus(isCollapsed: boolean) {
    localStorage.setItem('keep_collapsed', JSON.stringify(isCollapsed));
  }

  static getSidebarStatus(): boolean {
    return JSON.parse(localStorage.getItem('keep_collapsed')) || false;
  }

}
