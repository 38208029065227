<ul class="timeline-list">
	<li class="timeline-item" *ngFor="let item of timelineItems">
		<div class="item-block-icon">
			<i class="far fa-clock"></i>
		</div>
		<div class="time-block-title">
			{{ item.status }}
		</div>
		<div class="time-block-sub-title">
			<div class="d-flex">
				<div class="mr-3">
					<i class="fas fa-user"></i> {{ item.title }}
				</div>
				<div>
					<i class="far fa-clock"></i> {{ item.date | date:'dd/MM/yyyy HH:mm' }}
				</div>
			</div>

			<div class="d-flex">
				<button class="btn btn-sm btn-link" *ngIf="item.fileInfo" (click)="openInteractionAttachmentModal(item.fileInfo)">
					<i class="fas fa-paperclip"></i>
					{{ item.fileInfo.fileName }}
				</button>
				<button class="btn btn-sm btn-link" *ngIf="item.file" [disabled]="true">
					<i class="fas fa-paperclip"></i>
					Anexo: {{ item.file.name }}
				</button>
			</div>
		</div>
		<section class="time-block-content" *ngIf="item.content">
			<div>{{ item.minuteSelected }}</div>
			<div>{{ item.content }}</div>
		</section>
	</li>
</ul>
