import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input, OnInit } from '@angular/core';
import { BucketService } from '@app/services';
import { HttpClient } from '@angular/common/http';
import { FileInfo, InteractionAttachment } from '@app/models';

@Component({
	selector: 'interaction-attachment-dialog',
	templateUrl: './interaction-attachment-dialog.component.html',
	styleUrls: ['./interaction-attachment-dialog.component.scss']
})
export class InteractionAttachmentDialogComponent implements OnInit {
	@Input() attachment: InteractionAttachment | FileInfo;
	@Input() downloadEnabled: boolean = true;

	public get isAudio(): boolean {
		const audioTypes = ['wav', 'mp3', 'mp4', 'wmv', 'g'];
		return audioTypes.includes(this.attachment.type);
	}

	public get isImage(): boolean {
		const imageTypes = ['jpeg', 'jpg', 'png'];
		return imageTypes.includes(this.attachment.type);
	}

	constructor(public modal: NgbActiveModal,
	            private bucketService: BucketService,
	            private http: HttpClient) {
	}

	public ngOnInit() {
		if(this.attachment.url) return;

		this.bucketService.getFileInfoUrl(this.attachment.id).subscribe(url => {
			this.attachment.url = url;
		}, error => {
			console.error(error);
		});
	}

	public downloadFile(attachment: InteractionAttachment | FileInfo): void {
		this.bucketService.getFileInfoUrl(attachment.id).subscribe(
			url => {
				this.attachment.url = url;
				window.open(url, '_blank');
			},
			error => {
				console.error(error);
			}
		);
	}

	public dismissModal(): void {
		this.modal.dismiss();
	}
}
