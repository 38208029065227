<div class="modal-header">
    <h4 class="modal-title" *ngIf="type == 'help'">Como podemos ajudar?</h4>
	<h4 class="modal-title" *ngIf="type == 'training'">Acesso ao treinamento</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;">
	<form [formGroup]="messageForm">
		<div class="form-group">
			<label>Assunto</label>
			<input class="form-control" placeholder="" formControlName="subject">
		</div>

		<div class="form-group">
			<label>Mensagem</label>
			<textarea rows="4" class="form-control" placeholder="Informe sua dúvida ou sugestão..." formControlName="message"></textarea>
		</div>
	</form>

	<div class="row">
		<div class="col-12 form-group" *ngIf="type == 'training'">
			<label for="file">Arquivo*
				<i class="fas fa-question" container="body" placement="bottom" ngbTooltip="Tamanho máximo permitido é de 100mb"></i>
			</label>
			<evo-upload-file-input name="file" style="width: 100%"
								   [(ngModel)]="file"
								   [disabled]="file.length > 0"
								   [accept]="acceptTypes"
								   [violatedRules]="violatedRulesFileUpload"
								   [isProgressBarEnable]="true"
			                       [actionText]="'escolher'"
								   [uploadProgress]="[uploadProgress]"
								   fileLegend="Tamanho máximo permitido é de 100mb"
								   [isLoading]="isLoading"
								   [isMultiple]="false"
								   (cancelUpload)="cancelUpload()">
			</evo-upload-file-input>
		</div>
	</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Fechar</button>
    <button type="button" class="btn btn-primary" (click)="postMessage()" [disabled]="isLoading || messageForm.invalid">
	    <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
	    Enviar
    </button>
</div>
