import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, forkJoin, of, Subscription } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { ConfirmDangerDialogComponent } from '../confirm-danger-dialog/confirm-danger-dialog.component';
import { User } from '@app/models';
import { EmptyPage, PageResponse } from '@app/models';
import { SegmentService } from '@app/services';
import { OrganizationService } from '@app/services';
import { UserService } from '@app/services';
import { ToastService } from '@app/services';

interface SimpleFilter {
	name: string,
	email: string
}

@Component({
	selector: 'app-user-search',
	templateUrl: './user-search.component.html',
	styleUrls: ['./user-search.component.scss']
})
export class UserSearchDialogComponent implements OnInit, OnDestroy {
	@Input() segmentId: number;
	@Input() operationId: number;

	@Input() segment: any;
	@Input() operation: any;

	public users: User[] = [];
	public currentPage: PageResponse<User> = new EmptyPage();
	public alreadySelectedUsers: Set<number> = new Set();
	public checkedUsers: {isSelected: boolean, id: number}[] = [];

	public searchFilter: string;

	@Output() public action = new EventEmitter<any>();

	public isLoading = false;
	private subs = new Subscription();

	private searchTerm$ = new BehaviorSubject<string>('');
	public get searchTerm(): string { return this.searchTerm$.value; }
	public set searchTerm(term: string) { this.searchTerm$.next(term); }

	public pageChange$ = new BehaviorSubject<number>(0);

	public soUsers: any = [];

	constructor(public segmentService: SegmentService,
				public organizationService: OrganizationService,
				public userService: UserService,
				private toastService: ToastService,
				private modalService: NgbModal,
	            public modal: NgbActiveModal) {
	}

	ngOnInit(): void {
		this.getSegmentOperationsUsers();

		this.subs.add(combineLatest([
			this.pageChange$.asObservable(),
			this.searchTerm$.asObservable().pipe(debounceTime(400), map(searchTerm => searchTerm.trim() || ''))
		]).pipe(switchMap(([page, searchTerm]) => this.filter({page, name: searchTerm, email: searchTerm}))).subscribe(
			data => {
				this.users = [];
				let found = false;

				data.content.forEach(user => {
					found = false;

					user.segmentOperations.some(so => {
						if(so.segment.id == this.segmentId && so.operation.id == this.operation.id) {
							found = true;
							return true;
						}
						return false;
					});

					if(!found) {
						this.users = [ ...this.users, user ];
					}
				});
			}
		));
	}

	getInitials(user) {
		if (!user.name) return user?.email[0];
		const splittedName = user?.name?.split(' ');
		return splittedName?.reduce((initials, currName) => (initials = `${initials}${currName[0]}`), '');
	}

	get usersToSave() {
		return this.checkedUsers.filter(user => user.isSelected && !this.alreadySelectedUsers.has(user.id)).map(user => user.id);
	}

	get usersToDelete() {
		return this.checkedUsers.filter(user => !user.isSelected && this.alreadySelectedUsers.has(user.id)).map(user => user.id);
	}

	public getSegmentOperationsUsers(): void {
		this.segmentService.getUsersBySegmentOperation(this.segmentId, this.operationId).subscribe(
			data => {
				this.soUsers = data;
			}, error => {
				this.toastService.showDanger('Não foi possível encontrar os usuários vinculados, tente novamente mais tarde.');
				console.error(error);
			})
	}

	public filter({page, name = this.searchTerm, email = this.searchTerm}) {
		return this.segmentService.getUsers(this.segmentId, { linesPerPage: 20, page, name, email, hideUserType: ['AGENT']});

		/* return this.segmentService.getUsers(this.segmentId, {linesPerPage: 20, page, name}).pipe(
			tap((users) => {
				this.currentPage = users;
				this.users = page === 0 ? users.content : _.uniqBy([...this.users, ...users.content], (user) => user.id);
				this.checkedUsers = this.users.map(user => {
					const _user = {
						isSelected: !!user.segmentOperations.find(segOp => segOp.operation.id === this.operationId && this.segmentId == segOp.segment.id) || !!this.checkedUsers.filter(user => user.isSelected).find(u => u.id === user.id),
						id: user.id
					}
					return _user as {isSelected: boolean, id: number};
				});
			}),
			take(1),
			tap(() => {
				this.alreadySelectedUsers = new Set(this.users.filter(user => !!user.segmentOperations.find(segOp => segOp.operation.id === this.operationId && segOp.segmentId == this.segmentId)).map(user => user.id));
			})
		); */
	}

	public confRemoveSoUser(user): void {
		const modalRef = this.modalService.open(ConfirmDangerDialogComponent, {});
		modalRef.componentInstance.text = 'Deseja remover vínculo do usuário <b>' + user.name + '</b>? <br/><br/> Segmento: <b>' + this.segment.name + '</b> <br/> Operação: <b>' + this.operation.name +'</b>';
		modalRef.componentInstance.title = 'Desvincular usuário';

		modalRef.closed.subscribe(
			result => {
				if(result) {
					this.removeSoUser(user);
				}
			}
		)
	}

	private removeSoUser(user): void {
		this.segmentService.removeUser(this.segmentId, this.operationId, user.id).subscribe(
			data => {
				this.toastService.showSuccess('Usuário desvinculado com sucesso.');
				this.soUsers.splice(this.soUsers.indexOf(user), 1);
			}, error => {
				console.error(error);
				this.toastService.showDanger('Ocorreu um erro ao tentar desvincular o usuário.');
			}
		)
	}

	public saveUser(user): void {
		this.isLoading = true;

		this.segmentService.setUsers(this.segmentId, this.operationId, [user.id]).subscribe(
			data => {
				this.getSegmentOperationsUsers();
				this.toastService.showSuccess('Usuário vinculado com sucesso.');

				this.users.splice(this.users.indexOf(user), 1);

			}, error => {
				console.error(error);
				this.toastService.showDanger('Não foi possível vincular o usuário.');
			}
		).add( () => this.isLoading = false );
	}

	public saveUsers() {
		let usersToChange$ = this.usersToDelete.reduce((acc, userId) => {
			return acc = [...acc, this.segmentService.removeUser(this.segmentId, this.operationId, userId)]
		}, []);

		if (this.usersToSave.length > 0) usersToChange$ = [...usersToChange$, this.segmentService.setUsers(this.segmentId, this.operationId, this.usersToSave).pipe(catchError((err) => of(null)))];

		this.isLoading = true;
		forkJoin(usersToChange$).subscribe((users) => {
			this.modal.close();
			this.toastService.showSuccess('Usuários salvos com sucesso!');
		}, (err) => {
			if(err.status == 404) {
				return;
			}
			this.toastService.showDanger(err?.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.');
		}).add(() => this.isLoading = false);
	}

	public emitAction() {
		this.isLoading = true;
		// this.action.emit(this.selectedUser);
		// this.action.emit(this.selectedUsers);
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
