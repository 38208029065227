import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { QualityPermissions } from '@app/models';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { OnboardingStepService } from '@app/services';
import { OnboadingStepNames, OnboardingStep, OnboardingStepStatus } from '@app/models';
import { Products } from '@app/services';
import { FirstStepsService } from '@app/services';
import { Organization } from '@app/models';
import { User } from '@app/models';
import { OrganizationService } from '@app/services';
import { UserService } from '@app/services';
import { CustomerService } from '@app/services';
import { PermissionService } from '@app/services';
import { SystemMessages, ToastService } from '@app/services';
import {CacheInterceptorService} from "../../_services/cache-interceptor.service";

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  public organizations: Organization[] = [];
  public currentUser: User;

  public switchOrganization: string;

  public showOrganizationList: boolean = false;

  constructor(private organizationService: OrganizationService,
              private userService: UserService,
			  private customerService: CustomerService,
			  private permissionService: PermissionService,
			  private toastService: ToastService,
			  private permissionsService: NgxPermissionsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
			  private onboardingStepService: OnboardingStepService,
			  private firstStepsService: FirstStepsService,
			  private onboardingService: OnboardingStepService,
			  private cacheInterceptorService: CacheInterceptorService,
              @Inject(DOCUMENT) private document: Document) {
  }

  	ngOnInit(): void {
		this.currentUser = this.userService.currentUser;
		console.log(this.userService.currentUser);

		if(localStorage.getItem('organizationId') && !localStorage.getItem('switchOrganization')) {
			this.organizationService.currentOrganization = new Organization();
			this.organizationService.currentOrganization.id = localStorage.getItem('organizationId');
			this.organizationService.currentOrganization.alias = localStorage.getItem('organizationAlias');

			this.getOrganization(localStorage.getItem('organizationId') as string);
			return null;
		}
    	this.getOrganizations();
  	}

  	public getOrganization(id: string): void {
		this.organizationService.find(id).subscribe(
			data => {
				this.selectOrganization(data);
			}, error => {
				this.toastService.showDanger('Não foi possível buscar informações da Organização.');
		});
	}

 	 public getOrganizations(): void {
		this.organizationService.findAll().subscribe(
			data => {
				if(localStorage.getItem('switchOrganization')) {
					this.selectOrganization(data.find(item => item.alias == localStorage.getItem('switchOrganization')));
					localStorage.removeItem('switchOrganization');

					return;
				}

				if(data.length == 1 && !this.organizationService.forceOrganizationSelection) {
					this.selectOrganization(data[0]);
					return;
				}

				if(data.length == 0) {
					this.document.location.href = environment.apps['admin'];
					return;
				}

				this.organizationService.forceOrganizationSelection = false;
				this.showOrganizationList = true;
				this.organizations = data;
			}, error => {
				console.error(error);
		});
	}

  	public selectOrganization(organization: Organization): void {
		this.organizationService.currentOrganization = organization;

		if(!this.organizationService.currentOrganization.plan) {
			this.organizationService.currentOrganization.plan = {
				id: '',
				name: '',
				type: 'FREE',
				status: '',
				automaticChecklist: false,
				availableDashboards: [],
				interactionsPerMonth: 0,
				training: false
			};
		}

		localStorage.setItem('organizationId', organization.id);
		localStorage.setItem('organizationAlias', organization.alias);
		localStorage.setItem('organizationName', organization.name);

	    if(this.userService.returnUrl == '/organizations') {
			this.userService.returnUrl = '/';
		}

	    this.getCurrentUserPermissions();
	}

	private getCurrentUserPermissions(): void {
		this.userService.getCurrentUserPermissions().subscribe(
			permissionsApps => {
				let allPermissions = [];

				permissionsApps.forEach( profile => {
					allPermissions = [...allPermissions, ...profile.permissions.filter(item => item.product == 'QUALITY' || item.product == 'TRAINING' || item.product == 'GEN_AI').map(item => item.name)];
				});

				this.permissionsService.loadPermissions(allPermissions);
				this.setAdminPermission();
				this.setInternalPermission();

				this.organizationService.permissionsLoaded.next(true);

				this.onboardingStepService.currentOnboardingStep = this.organizationService.currentOrganization.steps?.find((step: OnboardingStep) => step.product == 'QUALITY');

				this.onboardingService.getStatus().subscribe(data => {
					this.firstStepsService.onboardingStatus = data;

					if(this.firstStepsService.onboardingStatus.importJob &&
					   this.firstStepsService.onboardingStatus.checklist &&
					   this.firstStepsService.onboardingStatus.segment &&
					   this.firstStepsService.onboardingStatus.operation) {
						this.router.navigate([this.userService.returnUrl]);
					} else {
						if(!this.onboardingStepService.currentOnboardingStep) {
							this.startOnboardingStep();
							return;
						}

						if(this.onboardingStepService.currentOnboardingStep.status != OnboardingStepStatus.COMPLETED) {
							this.firstStepsService.goToFirstSteps();
							return;
						}

						if(this.userService.returnUrl == '/first-steps') {
							this.userService.returnUrl = environment.features.homepage;
						}

						this.router.navigate([this.userService.returnUrl]);
					}
				}, error => {
					this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
				});
			}, error => {
				console.error(error);
			}
		)
	}

	setAdminPermission() {
		if (this.currentUser.organizations.find(org => org.id === this.organizationService.currentOrganization.id)?.admin) {
			this.permissionsService.addPermission(QualityPermissions.ALL_PERMISSIONS);
		}
	}

	setInternalPermission() {
		if (this.userService.currentUser.type == 'INTERNAL') {
			this.permissionsService.addPermission(QualityPermissions.INTERNAL);
			// this.permissionsService.addPermission(QualityPermissions.ALL_PERMISSIONS);
		}
	}

	private startOnboardingStep(): void {
		let onboardingStep = new OnboardingStep();
		onboardingStep.step = 0;
		onboardingStep.name = OnboadingStepNames.FIRST_STEPS;
		onboardingStep.product = Products[Products.QUALITY];
		onboardingStep.status = OnboardingStepStatus.IN_PROGRESS;

		this.onboardingStepService.currentOnboardingStep = onboardingStep;

		this.onboardingStepService.post(onboardingStep).subscribe(
			data => {
				this.firstStepsService.goToFirstSteps();
			}, error => {
				console.error(error);
			});
	}

	private onboadFix(): void {
	  this.onboardingStepService.currentOnboardingStep.step = 0;
	  this.onboardingStepService.currentOnboardingStep.status = OnboardingStepStatus.IN_PROGRESS;

	  this.onboardingStepService.put(this.onboardingStepService.currentOnboardingStep).subscribe(
		  data => {
		  }, error => {
			  console.error(error);
		  }
	  )
	}
}
