import { EvoModel } from './evo-model';

export class Organization extends EvoModel {

	public id: string;
	public code: number
	public name: string;
	public logoUrl: any;
	public alias: string;
	public status: string;
	public createdAt: any;
	public environments: {id: string, name: string}[];

	public products: any[];

	public profiles: any[];
	public admin?: boolean;

	public steps: any[];

	public plan: {
		id: string;
		name: string;
		type: string;
		status: string;
		training: boolean;
		automaticChecklist: boolean;
		availableDashboards: string[];
		interactionsPerMonth: number;
		interactionConfiguration?: { hasMonthlyCycle?: boolean, monthlyCycleType?: InteractionMonthlyCycleType };
	}
}

export enum DashboardsRoutes {
	PERFORMANCE = <any>'/dashboards/service-performance',
	QUALITY = <any>'/dashboards/attendance-quality',
	SENTIMENT = <any>'/dashboards/customer-satisfaction',
	MONITOR_FLOW = <any>'/dashboards/monitor-cycle-status',
	CALLDROP_CALLBACK = <any>'/dashboards/calldrop-callback'
}

export enum InteractionMonthlyCycleType {
	CONTACTED_AT = <any>'CONTACTED_AT',
	MONITORED_AT = <any>'MONITORED_AT',
}
