import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { InteractionService } from '../../../../_services/interaction.service';
import { ToastService } from '../../../../_services/toast.service';
import { BucketService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-replica-declined-button',
  templateUrl: './replica-declined-button.component.html',
  styleUrls: ['./replica-declined-button.component.scss']
})
export class ReplicaDeclinedButtonComponent extends InteractionContestationButton implements OnInit {

  constructor(modalService: NgbModal, protected activeModal: NgbActiveModal, toastService: ToastService, formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {}

}
