import { Component, HostListener, OnInit } from '@angular/core';
import { read, utils } from 'xlsx';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { format } from 'date-fns';
import { lastDayOfMonth } from 'date-fns';
import { Router } from '@angular/router';
import { ImportDataService, ImportTypes } from '@app/services';
import { OrganizationService } from '@app/services';
import { ToastService } from '@app/services';
import { JobService } from '@app/services';
import { ImportJobError, JobValidate } from '@app/models';

@Component({
	selector: 'app-import-data-step-three',
	templateUrl: './import-data-step-three.component.html',
	styleUrls: ['./import-data-step-three.component.scss']
})
export class ImportDataStepThreeComponent implements OnInit {
	@HostListener('window:beforeunload', ['$event']) beforeunloadHandler(event) {
		event.preventDefault();
		return false;
	}

	fields: { label: string, value: string, formatMessage?: string, dangerFormatAlert?: string }[] = [
		{ label: 'Data de contato', value: 'DATE', formatMessage: 'Formato da data em DD/MM/YYYY ou DDMMYYYY.', dangerFormatAlert: 'Você possui datas não formatadas. Utilize o formato de data DD/MM/YYYY.' },
		{ label: 'Hora de contato', value: 'TIME' },
		{ label: 'Nome do operador', value: 'AGENT_NAME' },
		{ label: 'Nome do arquivo', value: 'FILE_NAME', formatMessage: 'Os formatos de áudo aceitos são .mp3, .gsm e .wav.'},
	];

	availableFields: { label: string, value: string, formatMessage?: string, dangerFormatAlert?: string }[] = [];

	columns: { header: string, preview: string, fieldSelected?: string }[] = [];

	isLoading: boolean = false;

	invalidFields: string[] = [];

	public importTypes = ImportTypes;

	public fileType: string;
	public currentFile;

	constructor(public importDataService: ImportDataService,
	            private datePipe: DatePipe,
	            private organizationService: OrganizationService,
	            private toastService: ToastService,
	            private jobService: JobService,
	            private activeModal: NgbActiveModal,
	            private router: Router) { }

	ngOnInit(): void {
		this.availableFields = this.fields;

		if(this.importDataService.importType == ImportTypes.METADATA) {
			this.availableFields.push({ label: 'Identificador', value: 'METADATA_ID', formatMessage: 'É necessário que todas as linhas tenham identificadores únicos.'});
		}

		const file = this.importDataService.file;
		if (this.getFileExtension(file.name).includes(".csv")) {
			this.fileType = 'csv';

			const reader = new FileReader();
			reader.onload = (e) => {
				const text = e.target.result;
				this.currentFile = e.target.result;
				this.columns = csvToColumns(text);
			}
			reader.readAsText(file);
		} else if (this.getFileExtension(file.name).includes('.xls')) {
			this.fileType = 'xls';

			const reader = new FileReader();
			reader.onload = (e) => {
				const file = e.target.result;
				this.currentFile = e.target.result;
				this.columns = xlsxToColumn(file);
			}
			reader.readAsArrayBuffer(file);
		} else {
			this.toastService.showDanger('Formato não reconhecido');
			this.importDataService.prevStep();
		}
	}

	isFilenameValid(preview: string) {
		// const validFormats = new Set(['wav', 'gsm', 'mp3']);
		// const isValid = validFormats.has(preview.slice(preview.length - 3, preview.length).toLowerCase());
		this.setInvalidField(true, 'FILE_NAME');
		return true;
	}

	isUniqueValid(value: any): boolean {
		if(!value.header) {
			return true;
		}
		const isValid = validateUniqueColumn(this.currentFile, value.header, this.fileType);
		this.setInvalidField(isValid, 'METADATA_ID');
		return isValid;
	}

	isContactDateValid(preview) {
		const hasSlash = new Set(preview).has('/');
		let isValid = false;

		if(preview.length != 7) {
			try {
				isValid = (hasSlash && preview === this.datePipe.transform(this.getDateFromDateString(preview), 'dd/MM/yyyy')) || (!hasSlash && preview.length === 8 && preview == this.datePipe.transform(this.getDateFromPlainString(preview), 'ddMMyyyy')) || (hasSlash && preview.length == 6 && this.getDateFromDateString(preview) != 'Invalid Date');
			} catch (err) {
				isValid = false;
			}
		}

		if(preview.length === 7) {
			try {
				let tempDate = new Date(preview);
				let tempDateStr = this.datePipe.transform(preview, 'M/dd/yyyy');

				isValid = true;

				if(preview.match(/.*[a-zA-Z].*/)) {
					isValid = false;
				}

			} catch(err) {
				isValid = false;
			}
		}

		this.setInvalidField(isValid, 'DATE');
		return isValid;
	}

	isContactHourValid(preview) {
		try {
			if(preview.indexOf('AM') != -1 || preview.indexOf('PM') != -1) {
				preview = preview.slice(0, preview.length == 10 ? 7 : 8);
			}

			if(preview.indexOf(':') != -1) {
				preview = preview.replace(/:/g, '');
			}

			const isValid = (preview.length === 4 || preview.length === 3 || preview.length === 5 || preview.length === 6) &&
				((preview === this.datePipe.transform(this.getDateFromTimeString(preview), 'Hmm') ||
					preview == this.datePipe.transform(this.getDateFromTimeString(preview), 'HHmm') ||
					preview == this.datePipe.transform(this.getDateFromTimeString(preview), 'HHmmss') ||
					preview == this.datePipe.transform(this.getDateFromTimeString(preview), 'Hmmss')
				));
			this.setInvalidField(isValid, 'TIME');
			return isValid;
		} catch (err) {
			this.setInvalidField(false, 'TIME');
			return false;
		}
	}

	private getDateFromPlainString(date: string) {
		try {
			return new Date(Number(date.slice(4,8)), Number(date.slice(2,4)) - 1, Number(date.slice(0,2)));
		} catch (err) {
			return null;
		}
	}

	private getDateFromDateString(date: string): Date | string {
		try {
			if(date.length == 6) {
				return new Date(date);
			}

			if(date.length != 7) {
				return new Date(Number(date.slice(6,10)), Number(date.slice(3,5)) - 1, Number(date.slice(0,2)));
			}

			return null;
		} catch (err) {
			return null;
		}
	}

	private getDateFromTimeString(time: string) {
		try {
			const date = new Date();
			if (time.length === 4) {
				date.setHours(Number(time.slice(0,2)), Number(time.slice(2,4)));
			} else if(time.length === 3) {
				date.setHours(Number(time.slice(0, 1)), Number(time.slice(1, 3)));
			} else if(time.length === 5) {
				date.setHours(Number(time.slice(0, 1)), Number(time.slice(1, 3)), Number(time.slice(3, 5)));
			} else if(time.length == 6) {
				date.setHours(Number(time.slice(0,2)), Number(time.slice(2, 4)), Number(time.slice(4, 6)));
			}
			return date;
		} catch (err) {
			return null;
		}


	}

	private setInvalidField(isValid: boolean, field: string) {
		if(isValid) {
			this.invalidFields = this.invalidFields.filter(item => item != field);
		} else {
			if(!this.invalidFields.find(item => item == field)) {
				this.invalidFields = [...this.invalidFields, field];
			}
		}
	}

	private getFileExtension(filename: string): string {
		return filename.slice(filename.length - 5, filename.length).toLowerCase();
	}

	updateAvailableFields() {
		const selectedFields = new Set(this.columns.filter(column => !!column.fieldSelected).map(column => column.fieldSelected));
		this.availableFields = this.fields.filter(field => !selectedFields.has(field.value));
	}

	getFieldByValue(value: string) {
		return this.fields.find(field => field.value == value);
	}

	findColumnIndex(columnName: string): number {
		let index = this.columns.map((column, index) => ({name: column.fieldSelected, index})).find(column => column.name === columnName)?.index;

		if(index == undefined || index == -1) {
			return -1;
		}

		return index;
	}

	validateAvailableFields(): boolean {
		if(this.availableFields.length == 0) return true;

		if(this.availableFields.length == 1) {
			if(this.importDataService.importType == this.importTypes.METADATA && this.availableFields[0].value == 'FILE_NAME') return true;
			if(this.importDataService.importType == this.importTypes.MULTI && this.availableFields[0].value == 'METADATA_ID') return true;
		}

		return false;
	}

	onPrev() {
		this.importDataService.prevStep();
	}

	onNext() {
		this.isLoading = true;

		this.importDataService.columnIndexes = {
			"DATE": this.findColumnIndex('DATE'),
			"TIME": this.findColumnIndex('TIME'),
			"AGENT_NAME": this.findColumnIndex('AGENT_NAME')
		}

		let metadataId = this.findColumnIndex('METADATA_ID');
		let fileName = this.findColumnIndex('FILE_NAME');

		if(metadataId != -1) this.importDataService.columnIndexes.METADATA_ID = metadataId;
		if(fileName != -1) this.importDataService.columnIndexes.FILE_NAME = fileName;

		const body = {
			segmentId: this.importDataService.segmentId,
			operationId: this.importDataService.operationId,
			metadata: this.importDataService.metadata,
			columns: this.importDataService.columnIndexes,
			type: this.importDataService.importType,
			checklistId: this.importDataService.checklistId
		};

		this.jobService.validarMetadado(body).subscribe((response: any) => {
			if(response.status == 'COMPLETED') {
				this.isLoading = false
				this.jobService.importJobId = response.importJobId;
				// this.importDataService.uniqueOperators = this.getUniqueOperators();

				if(this.importDataService.importType !== this.importTypes.METADATA) {
					this.importDataService.nextStep();
					return;
				} else {
					const body = {
						segmentId: this.importDataService.segmentId,
						operationId: this.importDataService.operationId,
						columns: this.importDataService.columnIndexes,
						metadata: this.importDataService.metadata,
						metadataId: this.jobService.importJobId,
						type: 'IMPORT_METADATA',
						checklistId: this.importDataService.checklistId
					}

					this.postImportJob(body);
					return;
				}
			} else {
				this.jobService.validateErrors = [];

				response.errors?.forEach(error => {
					let validateError = this.jobService.validateErrors.find(item => item.message == ImportJobError[error?.error]);

					if(!validateError) {
						this.jobService.validateErrors = [ ...this.jobService.validateErrors, new JobValidate(ImportJobError[error?.error])];
					}
				});

				if(this.jobService.validateErrors.length == 0) {
					this.jobService.validateErrors =  [ new JobValidate(ImportJobError.GENERIC_ERROR) ];
				}

				this.onPrev();
			}
		}, (error) => {
			this.isLoading = false
			if(error?.error?.error == 'LIMIT_PLAN'){
				let message = ImportJobError[error?.error?.error] +
					" Você possui " + error.error.data  +
					" chamadas disponiveis até o dia " + format(lastDayOfMonth(new Date()), 'dd/MM/yyyy')
				this.jobService.validateErrors = [new JobValidate(message)];
				this.onPrev();
			}else{
				this.toastService.showDanger(error.message || 'Ocorreu um erro ao enviar a relação de metadados.');
			}
		});
	}

	private postImportJob(body): void {
		this.jobService.post(body).subscribe(
			data => {
				this.toastService.showSuccess('Chamadas importadas com sucesso.');
				this.activeModal.close(true);
				this.router.navigate(['/imports']);
			}, error => {
				console.error(error);
				this.toastService.showDanger('Não foi possível fazer a importação das chamadas, entre em contato.');
			});
	}

	public formatDateString(value): string {
		try {
			return format(new Date(value), 'dd/MM/yyyy');
		} catch (err) {
			return value;
		}
	}

	public validateDateValue(value: any): string {
		const hasSlash = new Set(value).has('/');
		let isValid = false;

		if(value.length != 7) {
			try {
				isValid = hasSlash && value === this.datePipe.transform(this.getDateFromDateString(value), 'dd/MM/yyyy') || !hasSlash && value.length === 8 && value == this.datePipe.transform(this.getDateFromPlainString(value), 'ddMMyyyy');
			} catch (err) {
				isValid = false;
			}
		}

		if(value.length === 7) {
			try {
				let tempDate = new Date(value);
				let tempDateStr = this.datePipe.transform(value, 'M/dd/yyyy');

				isValid = true;

				if(value.match(/.*[a-zA-Z].*/)) {
					isValid = false;
				}

			} catch(err) {
				isValid = false;
			}
		}

		if(isValid) {
			if(value.length == 10) {
				return value;
			}

			return this.formatDateString(value);
		}

		return value;
	}

	private getUniqueOperators() {
		const fileDetails = this.fileType == 'csv' ? findCsvRows(this.currentFile) : findXlsRows(this.currentFile);
		const operatorsIndex = this.findColumnIndex('AGENT_NAME');

		let allOperators = [];

		fileDetails[1].forEach(lines => {
			if(typeof lines == 'string') {
				const delimiter = detectDelimiter(lines);
				lines = lines.split(delimiter);
			}

			if(lines[operatorsIndex] !== undefined) {
				allOperators.push(lines[operatorsIndex]);
			}
		});

		return [...new Set(allOperators)];
	}
}

function csvToColumns(str) {
	const delimiter = detectDelimiter(str);
	const fileDetails = findCsvRows(str);
	return fileDetails[0].reduce((arr, header, idx) => (arr = [...arr, { header: header.trim(), preview: fileDetails[1][0].split(delimiter)[idx].trim() }]), []);
}

function xlsxToColumn(file) {
	const fileDetails = findXlsRows(file);
	return (fileDetails[0] as any[]).reduce((acc, header, idx) => (acc = [...acc, {header: header.trim(), preview: fileDetails[1][0][idx].trim()}]), []);
}

function validateUniqueColumn(file, column, type): boolean {
	const fileDetails = type == 'csv' ? findCsvRows(file) : findXlsRows(file);

	let selectedIndex = fileDetails[0].indexOf(column);
	let allLines = [];

	fileDetails[1].forEach(lines => {
		if(lines[selectedIndex] !== undefined) {
			allLines.push(lines[selectedIndex]);
		}
	});
	return !allLines.some((item, index) => index !== allLines.indexOf(item)) // true = duplicate;
}

function findXlsRows(file) {
	const data = new Uint8Array(file);
	const workbook = read(data, { type: 'buffer', cellText: true });
	const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
	const arr = utils.sheet_to_json(firstSheet, { header: 1, raw: false });
	return [arr.shift(), arr];
}

function findCsvRows(str) {
	const delimiter = detectDelimiter(str);
	const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
	return [headers, str.slice(str.indexOf("\n") + 1).split("\n")];
}

function detectDelimiter(input: string): string {
	const separators = [',', ';', '|', '\t'];
	const idx = separators
	.map((separator) => input.indexOf(separator))
	.reduce((prev, cur) =>
		prev === -1 || (cur !== -1 && cur < prev) ? cur : prev
	);
	return (input[idx] || ',');
};
