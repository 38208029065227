import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageResponse } from '@app/models';
import { Segment } from '@app/models';
import { User } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';
import {UsersSegmentsOperationsRequest} from "../_models/users-segments-operations.request";

@Injectable({providedIn: 'root'})
export class SegmentService extends BaseServiceClass<Segment> {

	public currentSegment: Segment;

	constructor(protected httpService: HttpClient) {
		super(httpService, '/segments');
	}

	getChecklistAvailable() {
		return this.httpService.get<any>(`${this.entity_url}/checklists/available`);
	}

	getUsers(segmentId: number, params?: any) {
		return this.httpService.get<PageResponse<User>>(`${this.entity_url}/${segmentId}/users`, {params});
	}

	public getUsersBySegmentOperation(segmentId: number, operationId: number): Observable<any> {
		return this.httpService.get(`${this.entity_url}/${segmentId}/operations/${operationId}/users`);
	}

	setUsers(segmentId, operationId, usersId) {
		return this.httpService.post(`${this.entity_url}/${segmentId}/operations/${operationId}/users`, {usersId});
	}

	removeUser(segmentId, operationId, userId) {
		return this.httpService.delete(`${this.entity_url}/${segmentId}/operations/${operationId}/user/${userId}`);
	}

	removeOperationSegment(segmentId, operationId): Observable<any> {
		return this.httpService.delete(`${this.entity_url}/${segmentId}/operations/${operationId}`);
	}

	public putSegmentOperation(segmentId, operationsIds: any[]): Observable<any> {
		return this.httpService.put(`${this.entity_url}/${segmentId}/operations`, operationsIds);
	}


	public activeSpeechIntegration(segmentsIds: any[], active: boolean): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/speechIntegrationActive/${active}/batch`, segmentsIds);
	}

	public activeSegments(segmentId: any, active): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/${segmentId}/active/${active}`, {})
	}

	public getSegments(): Observable<any> {
		return this.httpService.get(`${this.entity_url}`);
	}

	public getSegmentsIds(filters: any): Observable<any> {
		let options = {params: <any> _.omitBy(filters, _.isNil)};
		return this.httpService.get(environment.apiUrl + '/api/v2/segments/ids', options)
	}

	public addUserToSegmentsOperations(usersSegmentsOperations: UsersSegmentsOperationsRequest): Observable<any> {
		return this.httpService.post( environment.apiUrl +  '/api/v2/segments/users', usersSegmentsOperations);
	}
}
