import { Deserializable } from './deserializable';

export class FileInfo implements Deserializable {
    id:number;
    path:string;
    fileNamepath:string;
    fileName:string;
	originalFileName: string;
    keypath:string;
    bucketpath:string;
    typepath:string;
    type:string;

    url?: string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
