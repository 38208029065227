export interface PageResponse <T>{
    content: T[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    last: boolean;
    number: number;
    size: number;
    sort: Sortable;
    numberOfElements: number;
    first: boolean;
    indicator?: any;
}
export interface Pageable{
    offset: number;
    pageSize: number;
    pageNumber: number;
    unpaged: boolean;
    paged: boolean;
    sort: Sortable;
}
export interface Sortable{
    sorted: boolean;
    unsorted: boolean;
}

export class EmptyPage<T> implements PageResponse<T> {
    content: T[];
    pageable: Pageable;
    totalElements: number;
    totalPages: number;
    last: boolean;
    number: number;
    size: number;
    sort: Sortable;
    numberOfElements: number;
    first: boolean;
    indicator?: any;

    constructor() {
        this.content = [];
        this.pageable = {
            offset: 0,
            pageSize: 10,
            pageNumber: 0,
            unpaged: false,
            paged: true,
            sort: {sorted: false, unsorted: true}
        };
        this.totalElements = 0;
        this.totalPages = 0;
        this.last = true;
        this.number = 0;
        this.size = 0;
        this.sort = {sorted: false, unsorted: true};
        this.numberOfElements = 0;
        this.first = true;
    }
}