import { stringify } from '@angular/compiler/src/util';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checkIFDate'
})
export class CheckIFDate implements PipeTransform {

    transform(value: any): any {

        let newValue = Date.parse(value);
        if (isNaN((newValue))) {
            return value;
        }
        const date = this.dataAtualFormatada(newValue, value);
        return date;
    }

    dataAtualFormatada(date, value) {
        const dateMaxLimite = new Date(2029, 1, 1);
        const dateMinLimite = new Date(2011, 1, 1);
        if (new Date(date) > dateMaxLimite) { return value; }
        if (dateMinLimite > new Date(date)) { return value; }

        var data = new Date(date),
            dia = data.getDate().toString(),
            diaF = (dia.length == 1) ? '0' + dia : dia,
            mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
            mesF = (mes.length == 1) ? '0' + mes : mes,
            anoF = data.getFullYear();
        return diaF + "/" + mesF + "/" + anoF;
    }

}