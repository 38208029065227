import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseServiceClass } from './baseService.service';
import { Organization } from '@app/models';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;
const ACCOUNT_URL = environment.accountUrl;

@Injectable({
	providedIn: 'root'
})
export class OrganizationService extends BaseServiceClass<any> {

	public currentOrganization: Organization = new Organization();
	public forceOrganizationSelection: boolean = false;

	public permissionsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient) {
		super(http, '/api/v1/organizations');

        this.entity_url = environment.accountUrl + '/api/v1/organizations';
	}

	public find(id: any): Observable<any> {
		return this.http.get(`${this.entity_url}/${id}`);
	}

	public findAll(): Observable<any> {
		return this.http.get(`${this.entity_url}?product=${this.app_name}`);
	}

	public post(organization: Organization): Observable<any> {
		return this.http.post(`${this.entity_url}`, organization);
	}

	public put(organization: Organization, id: any): Observable<any> {
		return this.http.put(`${this.entity_url}/${id}`, organization);
	}

	public getPlans(): Observable<any> {
		return this.http.get(`${ACCOUNT_URL}/api/v1/plans`);
	}
}

export enum OrganizationsErrors {
	ALIAS_NOT_AVAILABLE = 'Alias da Organização não está disponível.',
	API_FIELDS_INVALID = 'Campos obrigatórios inválidos.'
}
