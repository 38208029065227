import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { InteractionService } from '../../../../_services/interaction.service';
import { ToastService } from '../../../../_services/toast.service';
import { BucketService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-replica-button',
  templateUrl: './replica-button.component.html',
  styleUrls: ['./replica-button.component.scss']
})
export class ReplicaButtonComponent extends InteractionContestationButton implements OnInit {

  constructor(protected modalService: NgbModal, activeModal: NgbActiveModal, protected toastService: ToastService, protected formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {}

}
