import { Directive, ViewContainerRef, Component, ViewChild, Type, ComponentRef, InjectionToken, Inject, Optional, ElementRef, Self, SkipSelf, TemplateRef, ContentChild, AfterContentChecked, AfterContentInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { tap, startWith, map } from 'rxjs/operators';

export interface StepComponent {
  isLoading$: Subject<boolean>;
  form: FormGroup;
  doesRequestFailed$: BehaviorSubject<boolean>;

  onNext: (currentStep: BehaviorSubject<number>) => Subscription | void;
  onPrev: () => Subscription | void;
}

@Directive({
  selector: '[currentComponentContainer]',
})
export class CurrentStepDirective {
  constructor(public viewContainerRef: ViewContainerRef) {
  }
}

export class Step {
  constructor(public component: Type<any>, public label: string) {}
}

@Component({
  selector: 'app-stepper-modal',
  templateUrl: './stepper-modal.component.html',
  styleUrls: ['./stepper-modal.component.scss']
})
export class StepperModalComponent {
  @Input() steps: Step[];

  public currentStep: BehaviorSubject<number> = new BehaviorSubject(0);
  public currentStep$: Observable<number> = this.currentStep.asObservable().pipe(
    tap((currentStep) => {
      const viewContainerRef = this.currentComponentContainer.viewContainerRef;
      viewContainerRef.clear();
      this.currentComponent = viewContainerRef?.createComponent<StepComponent>(this.steps[currentStep]?.component);
      this.isLoading$ = this.currentComponent.instance.isLoading$;
      this.isFormInvalid$ = this.currentComponent.instance.form?.valueChanges.pipe(startWith(this.currentComponent.instance.form.invalid), map(() => this.currentComponent.instance.form.invalid));
      this.doesRequestFailed$ = this.currentComponent.instance.doesRequestFailed$;
    })
  );

  public isFormInvalid$: Observable<boolean>;
  public isLoading$: Subject<boolean>;
  public doesRequestFailed$: BehaviorSubject<boolean>;

  @ViewChild(CurrentStepDirective, {static: true}) currentComponentContainer!: CurrentStepDirective;
  public currentComponent?: ComponentRef<StepComponent>;

  constructor() { }

  onNext() {
    this.currentComponent.instance.onNext(this.currentStep);
  }

  onPrev() {
    const onPrev = this.currentComponent.instance.onPrev();
    if (onPrev instanceof Subscription) {
      onPrev.add(() => this.currentStep.next(this.currentStep.value - 1));
    } else if(this.currentStep.value > 0) {
      this.currentStep.next(this.currentStep.value - 1)
    }
  }

}
