<div @fadeInOut>
    <div id="body" class="body" style="position: relative;">
        <evo-stepper-title title="Detalhes das chamadas" subtitle="Adicione informações sobre as chamadas a serem importadas"></evo-stepper-title>
        <form [formGroup]="form">
            <div class="form-group mb-0">
                <label class="required">Segmento</label>
                <ng-select placeholder="Selecione um segmento"
                           [items]="segments"
                           formControlName="segmentId"
                           bindValue="id"
                           bindLabel="name"
                           (change)="filterOperations().subscribe()">
                </ng-select>
            </div>
            <div *ngIf="!!selectedSegment && selectedSegment?.checklists.checkListType === 'AUTOMATIC'" class="alert alert-primary" role="alert">
                <div class="d-flex flex-start">
                    <i class="fas fa-exclamation-triangle font-primary mr-2 mt-2"></i>
                    <div>
                        <p class="m-0">
                            Este segmento possui checklists automáticos, o prazo de importação da chamada pode levar até 2 dias.
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group mb-0">
                <label class="required">Operação</label>
                <ng-select placeholder="Selecione uma operação"
                           [items]="operations"
                           formControlName="operationId"
                           bindValue="id"
                           bindLabel="name">
                </ng-select>
            </div>
	        <div class="form-group mb-0">
		        <label class="required">Checklist</label>
		        <ng-select placeholder="Selecione um checklist"
		                   [items]="segmentChecklists || []"
		                   formControlName="checklistId"
		                   bindValue="id"
		                   bindLabel="name">
			        <ng-template ng-option-tmp ng-label-tmp let-item="item" let-index="index">
				        <span>{{ item.name }}</span>
				        <span class="ml-2 badge" [ngClass]="item.checkListType == 'AUTOMATIC' ? 'badge-automatic' : 'badge-manual'">
							{{ item.checkListType == 'AUTOMATIC' ? 'Automático' : 'Manual' }}
						</span>
			        </ng-template>
		        </ng-select>
	        </div>
            <!-- <div *ngIf="showOperationAlert" class="alert alert-warning" role="alert">
                <div class="d-flex flex-start">
                    <i class="fas fa-exclamation-triangle font-warning mr-2 mt-2"></i>
                    <div>
                        <p class="m-0">Crie um fluxo de trabalho para importar interações para esta operação.</p>
                    </div>
                </div>
                <div class="d-flex align-items-end flex-column">
                    <button class="btn text-link" (click)="navigateToNewFlow()">Criar fluxo de trabalho</button>
                </div>
            </div> -->
        </form>
    </div>
    <evo-stepper-footer [isFormInvalid]="form.invalid || showOperationAlert" (onPrev)="onPrev()" (onNext)="onNext()"></evo-stepper-footer>
</div>
