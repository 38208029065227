import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChecklistItem } from '@app/models';
import { BaseServiceClass } from '../baseService.service';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class ChecklistItemServiceV2 extends BaseServiceClass<ChecklistItem>{
	entity_url: any;

	constructor(protected httpService: HttpClient) {
		super(httpService, '/itenschecklists');
	}

	public updateItemsChecklist(params: any): Observable<any> {
		let arrayClone = params.map(item => ({ ...item }));
		let filteredArray = arrayClone.map(obj => _.omitBy(obj, _.isNil));
		return this.httpService.patch(`${this.entity_url}/batch`, filteredArray);
	}
}
