import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'evollo-bar-chart-with-pagination',
  templateUrl: './bar-chart-with-pagination.component.html',
  styleUrls: ['./bar-chart-with-pagination.component.scss']
})
export class BarChartWithPaginationComponent implements OnInit {

  @Input() roundEdges = true;
  @Input() noBarWhenZero = true;
  @Input() barPadding = 8;
  @Input() legend = true;
  @Input() yScaleMax;
  @Input() yAxis = false;
  @Input() xAxis = false;
  @Input() colorScheme: {domain: any[]} = {domain: []}

  @Input() results: any[] = [];
  @Input() title: string;

  @Output() pageChange: EventEmitter<any[]> = new EventEmitter();
  @Output() barSelect: EventEmitter<any> = new EventEmitter();

  resultsSlice: any[] = [];

  orderBy: 'asc' | 'desc' = 'asc';
  perPage: number = 20;
  currentPage = 1;
  isPaginating: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.setSlicedPage();
  }

  onPageChanged(page) {
    this.setSlicedPage(page);
  }

  onFilterChange() {
    this.setSlicedPage();
  }

  onSelect(currentBar) {
    this.barSelect.emit(currentBar);
  }

  setSlicedPage(currentPage = this.currentPage) {
    this.isPaginating = true;
    this.resultsSlice = this.results.sort((a, b) => this.orderBy === 'asc' ? a.value - b.value : b.value - a.value).slice((currentPage - 1) * this.perPage, currentPage * this.perPage);
    this.pageChange.emit(this.resultsSlice);
    this.isPaginating = false;
  }

}
