import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BaseServiceClass } from './baseService.service';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IntegrationResponse } from '@app/models';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class IntegrationsService extends BaseServiceClass<any> {

	public selectedIntegration: IntegrationResponse;

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '');

		this.entity_url = environment.integrationUrl + '/api/v1/integrations';
	}

	public getIntegrations(active: boolean = true, product: string[] = [ 'QUALITY' ]): Observable<any> {
		return this.httpService.get(this.entity_url + '?active=' + active + '&product=' + product.join(','));
	}

	public getOrganizationIntegrations(): Observable<any> {
		return this.httpService.get(environment.integrationUrl + '/api/v1/organizations/integrations');
	}

	public getSpeechIntegrations(filters: any): Observable<any> {
		let options = {params: <any> _.omitBy(filters, _.isNil)};
		return this.httpService.get(environment.apiUrl + '/external/speech/interactions/filter', options);
	}

	public getSpeechIntegrationsIds(filters: any): Observable<any> {
		let options = {params: <any> _.omitBy(filters, _.isNil)};
		return this.httpService.get(environment.apiUrl + '/external/speech/interactions/ids', options);
	}

	public activateSpeechIntegration(active: boolean): Observable<any> {
		return this.httpService.put(environment.apiUrl + '/customers/segments/speechIntegrationActive/' + active, {});
	}

	public integrationReprocess(interactions: string[]): Observable<any> {
		return this.httpService.post(environment.apiUrl + '/api/v2/interactions/reprocess', { interactions });
	}
}
