import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { of, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChecklistItemFilter } from '@app/models';
import { ChecklistItemService } from '@app/services';
import { HelpDeskService } from '@app/services';
import { Checklist } from '@app/models';
import { CreateChecklistService } from '@app/services';
import { ExternalSpeechEnvironmentsService } from '@app/services';

@Component({
	selector: 'app-checklist-automatic-step-two',
	templateUrl: './checklist-automatic-step-two.component.html',
	styleUrls: ['./checklist-automatic-step-two.component.scss']
})
export class ChecklistAutomaticStepTwoComponent implements OnInit, OnDestroy {
	checklist: Checklist;

	categoriesGrouped: {
		group: string,
		categories: any[]
	}[] = [];

	categoriesSelected: {
		group: string,
		categories: any[]
	}[] = [];

	categoriesForm: FormArray;

	subs = new Subscription();

	groupSelected: boolean[] = [];

	get categoriesSelectedLength() {
		return this.categoriesSelected.reduce((acc, group) => {
			return acc = acc + group.categories.length;
		}, 0);
	}

	constructor(private createChecklistService: CreateChecklistService,
	            private checklistItemService: ChecklistItemService,
		private formBuilder: FormBuilder,
		private helpDeskService: HelpDeskService,
		private speechEnviromentsService: ExternalSpeechEnvironmentsService) { }

	ngOnInit(): void {
		this.checklist = this.createChecklistService.currentChecklist;

		this.speechEnviromentsService.getEnvironmentRules(this.checklist.segment.speechEnvironmentId).subscribe((categories: any[]) => {
			this.categoriesGrouped = _(categories).groupBy('group').map((group, groupName) => ({ group: groupName, categories: group })).value();
			this.groupSelected = this.categoriesGrouped.map(() => false);
			this.setForm();
			this.setFormValueChanges();
			this.setAllGroupSelectedDetection();

			// this.getFormControl(0, 3).setValue(true);

			if(this.createChecklistService.currentChecklist.id) {
				this.setEditForm();
			}

		});
	}

	public showHelper(): void {
		this.helpDeskService.helperContent = `
			<h5>O que são os itens?</h5>

			<p>O checklist digital é dividido em 3 grupos. Cada grupo possui itens semelhantes que podem ser selecionados para compor o checklist final.</p>

			<p>Atualmente possuímos 3 grupos com itens cujo foco é a realização de Negócios, garantir a Qualidade do atendimento e garantir a Experiência positiva do cliente na interação.</p>
		`;

		this.helpDeskService.helpDialogText = 'Selecione os itens que mais de adequem à avaliação de sua operação. Não identificou um item que precisa, nos envie um e-mail de sugestões';
		this.helpDeskService.showHelpDialog = true;

		this.helpDeskService.showHelpSidebar = true;
	}

	setForm() {
		this.categoriesForm = this.formBuilder.array(this.categoriesGrouped.map(group => {
			return this.formBuilder.array(group.categories.map(category => {
				this.formBuilder.control(null);
			}));
		}));
	}

	setEditForm(): void {
		this.checklistItemService.filterWithChecklist({} as ChecklistItemFilter, this.createChecklistService.currentChecklist.id).subscribe(
			data => {
				this.createChecklistService.savedCategories = data.content;

				let i = 0;
				let j = 0;

				this.categoriesGrouped.forEach(group => {
					j = 0;

					group.categories.forEach(category => {
						data.content.forEach(savedCategory => {
							if(savedCategory.speechCategoryId == category._id) {
								this.getFormControl(i, j).setValue(savedCategory.active);
							}
						});

						j++;
					});

					i++;
				})
			});
	}

	getFormControl(i, j) {
		return (this.categoriesForm.controls[i] as FormArray).controls[j];
	}

	setFormValueChanges() {
		this.subs.add(this.categoriesForm.valueChanges.subscribe((groups) => {
			this.categoriesSelected = this.categoriesGrouped.map((group, i) => {
				const _group = { ...group };
				_group.categories = group.categories.filter((category, j) => groups[i][j]);
				return _group;
			});
		}));
	}

	setAllGroupSelectedDetection() {
		this.subs.add(this.categoriesForm.valueChanges.subscribe((groups) => {
			groups.forEach((group, idx) => {
				this.groupSelected[idx] = group.reduce((acc, category) => (acc = acc ? category : false), true);
			})
		}));
	}

	changeAllGroup(groupIndex: number, select: boolean) {
		(this.categoriesForm.controls[groupIndex] as FormArray).controls.forEach((control: FormControl) => control.patchValue(select));
	}

	isGroupAllSelected(groupIndex: number) {
		return (this.categoriesForm.controls[groupIndex] as FormArray).value.reduce((acc, category) => {
			return acc = acc ? category : false;
		}, true);
	}

	prevStep() {
		const modal = this.createChecklistService.showAlertOnPrev();
		this.subs.add(modal.closed.pipe(filter((isConfirmed: boolean) => !!isConfirmed)).subscribe(() => {
			this.createChecklistService.prevStep();
		}));
	}

	nextStep() {
		this.createChecklistService.automaticCategories = this.categoriesSelected;
		this.createChecklistService.nextStep();
	}

	ngOnDestroy() {
		this.subs.unsubscribe();
	}

}
