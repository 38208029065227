import { FormGroup } from '@angular/forms';
import { Customer } from './customer';
import { Deserializable } from './deserializable';

export class Justification implements Deserializable {
    id: number;
    text: string;
	type: string;
    customer: Customer;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }

}
