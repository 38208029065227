<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Reprocessar interações</h4>
	<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
	        (click)="modal.close(false)">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body" style="padding-top: 0;" *ngIf="allInteractionSelected || reprocessDashboard">
	<p *ngIf="allInteractionSelected && reprocessDashboard || reprocessDashboard && this.selectedIds.length > 1">{{ 'Deseja reprocessar as informações de dashboard das '+(allInteractionSelected ? totalResults : this.selectedIds.length)+' interações?' }}</p>
	<p *ngIf="reprocessDashboard && this.selectedIds.length == 1">Deseja reprocessar as informações de dashboard dessa interação?</p>
	<p *ngIf="!reprocessDashboard">Deseja reprocessar também as regras das interações selecionadas?</p>

	<div class="d-flex align-items-center" *ngIf="!reprocessDashboard">
		<input id="processRule" type="checkbox" [(ngModel)]="processRules">
		<label class="m-0 ml-2" for="processRule">Reprocessas regras</label>
	</div>
</div>
<div class="modal-body" style="padding-top: 0;" *ngIf="!allInteractionSelected && !reprocessDashboard">
	<p>Selecione as interações para também reprocessar as regras:</p>

	<div class="div-table">
		<mat-table [dataSource]="reprocessInteractions">

			<ng-container matColumnDef="id">
				<mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
				<mat-cell *matCellDef="let interaction">{{interaction.id}}</mat-cell>
			</ng-container>

			<ng-container matColumnDef="applyRules">
				<mat-header-cell *matHeaderCellDef>Selecionar</mat-header-cell>
				<mat-cell *matCellDef="let interaction">
					<input type="checkbox" [(ngModel)]="interaction.applyRules">
				</mat-cell>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row *matRowDef="let interaction; columns: displayedColumns;"></mat-row>

		</mat-table>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Cancelar</button>
	<button type="button" class="btn btn-primary" (click)="sendReprocessResult()">Confirmar</button>
</div>
