import { ConfirmJustificationTypeComponent } from '../interaction-management/confirm-justification-type.component/confirm-justification-type.component';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { countDownTimerConfigModel, CountupTimerService, timerTexts } from 'ngx-timer';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { DecimalPipe } from '@angular/common';
import { saveAudioFile, saveFile } from '../../../_helpers/file-helper';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ConfirmDialogComponent } from '../../../_components/confirm-dialog/confirm-dialog.component';
import { InteractionSentimentLabels, InteractionStatusEnum } from '../../../_helpers/interaction-status.enum';
import { TimelineItem } from '../../../_components/timeline-list/timeline-list.component';
import {
	checkIfGradeShouldCount,
	getSentimentImage,
	getSentimentLabel
} from '../../../_helpers/interaction-status.helper';
import {
	ChecklistItem,
	Contestation, EvoAIModels, FileInfo,
	Flow,
	Interaction,
	InteractionStatus,
	Justification,
	JustificationFilter,
	MonitorInteractionItem,
	PageResponse,
	QualityPermissions,
	User
} from '@app/models';
import {
	BucketService,
	FlowService,
	InteractionService,
	InteractionServiceV2,
	JustificationService,
	MonitorInteractionItemService,
	SystemMessages,
	TimelineService,
	ToastService,
	UserService
} from '@app/services';
import { BusinessException } from '../../../_helpers/business-exception.enum';
import { NgxPermissionsService } from 'ngx-permissions';
import {
	InteractionAttachmentDialogComponent
} from '../../../_components/interaction-attachment-dialog/interaction-attachment-dialog.component';
import { InteractionAttachment } from '../../../_models/interaction-attachment';
import { ExternalSpeechService } from "../../../_services/external-speech.service";
import { GenAiFlag } from "../../../_models/gen-ai-flag";
import { environment } from "../../../../environments/environment";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
	selector: 'app-new-interaction-contestation',
	templateUrl: './interaction-contestation.component.html',
	styleUrls: ['./interaction-contestation.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class InteractionContestationComponent implements OnInit {

	public getSentimentImage = getSentimentImage;
	public getSentimentLabel = getSentimentLabel;
	public interactionSentimentLabels: InteractionSentimentLabels;

	public aiInsightsEnabled = environment.features.aiInsightsEnabled;
	public aiInteractionEnabled = environment.features.aiInteractionEnabled;

	@Input()
	public interaction: Interaction;
	itemsTimeline: TimelineItem[];

	monitorItems: MonitorInteractionItem[] = [];
	itemsGrouped: MonitorInteractionItem[][];

	contestations: Contestation[] = [];
	justifications: Justification[] = [];
	itensToUpdate: MonitorInteractionItem[] = [];

	currentUser: User;
	currentModalItem: MonitorInteractionItem;

	timer: countDownTimerConfigModel;

	isLoading: boolean = false;
	isModalLoading: boolean = false;

	flow: Flow;

	// atendimento, qualidade, admin

	isAudio: boolean = false;

	activeIds: Set<string>[] = [];

	@ViewChild('view', { read: ElementRef }) public view: ElementRef<any>;

	qualityPermissions = QualityPermissions;

	public interactionMetadata: any;

	public uploadProgress: number = 0;

	public transcriptionSegments: { text: string, type: string, start: number, end: number}[] = [];
	public genAiFlags: GenAiFlag[];

	public speechInteractionId: any;
	public aiFilters: FormGroup;

	public get hasNoPermissions(): boolean {
		return _.isEmpty(this.ngxPermissionsService.getPermissions());
	}

	public lockablePermissions = [
		{ interactionStatus: InteractionStatusEnum.CONTESTED, permission: this.qualityPermissions.CONTEST_EVALUATION },
		{ interactionStatus: InteractionStatusEnum.REVISION, permission: this.qualityPermissions.REVIEW_INTERACTIONS },
		{ interactionStatus: InteractionStatusEnum.REPLICA_SENDED, permission: this.qualityPermissions.REPLY_REPLICA },
		{ interactionStatus: InteractionStatusEnum.MANUAL_MONITOR, permission: this.qualityPermissions.EVALUATE_INTERACTIONS },
		{ interactionStatus: InteractionStatusEnum.FEEDBACK, permission: this.qualityPermissions.APPLY_FEEDBACK }
	];

	@Input()
	public interactionId: number;

	@Output()
	public close: EventEmitter<any> = new EventEmitter();

	@Output()
	public resetScroll: EventEmitter<any> = new EventEmitter();

	@Output()
	public firstLoadingDone: EventEmitter<any> = new EventEmitter<any>();

	public get analysisInProgress(): boolean {
		return this.interaction.inAnalysis;
	}

	public get currentStatus(): InteractionStatus {
		return this.interaction.status;
	}

	public get isReady(): boolean {
		return !!this.interaction && !!this.flow;
	}

	public interactionInAnalysis: boolean;

	public isAdmin: boolean;
	public isInternal: boolean;

	public lockablePermissionsByStatus(status: InteractionStatus): any {
		return this.lockablePermissions.find(item =>
			item.interactionStatus == this.interaction.lastStatusHistory.id)
			.permission;
	}

	sameUserAnalysis: boolean = false;

	constructor(private activatedRoute: ActivatedRoute,
		private userService: UserService,
		public interactionService: InteractionService,
		public interactionServiceV2: InteractionServiceV2,
		private externalSpeechService: ExternalSpeechService,
		private countupTimerService: CountupTimerService,
		private monitorInteractionItemService: MonitorInteractionItemService,
		private justificationService: JustificationService,
		private timelineService: TimelineService,
		private toastService: ToastService,
		private decimalPipe: DecimalPipe,
		private flowService: FlowService,
		private modalService: NgbModal,
		private router: Router,
		private http: HttpClient,
		private ngxPermissionsService: NgxPermissionsService,
	    private bucketService: BucketService,
        private fb: FormBuilder) {

		this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
			data => {
				this.isInternal = data;
			});

		this.ngxPermissionsService.hasPermission(QualityPermissions.ALL_PERMISSIONS).then(
			data => {
				this.isAdmin = data;
			});
	}

	ngOnInit(): void {
		if(this.aiInteractionEnabled) {
			this.aiFilters = this.fb.group({
				aiModelCode: [EvoAIModels.AWS_CLAUDE_SONNET_V3_5],
				temperature: [0.5],
				topP: [1]
			});
		}

		this.externalSpeechService.getSpeechInteractionById(this.interaction.id).subscribe(
			data => {
				this.speechInteractionId = data._id;
				this.transcriptionSegments = data.transcriptionSegments;
				this.genAiFlags = data.genAiFlags.filter(item => item.genAiQuestion != null && item.genAiResponse != null && item.genAiQuestion.active);

				this.genAiFlags.forEach(item => {
					item.genAiResponse = this.transformString(item.genAiResponse);
				});

			}, error => {
				console.log('sem id?');
				// console.error(error);
			}
		);

		this.currentUser = this.userService.currentUser;

		if (this.interaction.analysisHistories.length > 0) {
			this.sameUserAnalysis = this.interaction.analysisHistories.find(item => item.active == true)?.startedBy.email == this.currentUser.email;
		}

		if (this.interaction.metadata) {
			this.interactionMetadata = Object.entries(this.interaction.metadata);
		}

		forkJoin({
			// interaction: this.interactionService.find(interactionId).pipe(catchError(() => of(null))),
			interactionItems: this.findInteractionItems(this.interaction.id),
			flow: this.flowService.findByInteractionId(this.interaction.id).pipe(catchError((error) => {
				if ((error?.error?.error != null || error?.error?.error != undefined) && BusinessException[error?.error?.error] != null) {
					this.toastService.showWarning(BusinessException[error?.error?.error]);
				} else {
					this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
				}

				return of(error);
			})),
			justifications: this.justificationService.filter({ text: '', type: ['MONITOR'] }).pipe(catchError(() => of([]))),
		}).pipe(
			tap(({ interactionItems, flow, justifications }) => {
				this.monitorItems = interactionItems.map(item => new MonitorInteractionItem().deserialize(item));;
				this.justifications = (justifications as PageResponse<Justification>).content;
				this.setTimerConfig(this.interaction);
				this.itemsGrouped = this.getItemsByGroup();
				this.flow = flow;

				// check if string has .wav or .mp3
				if (this.interaction.fileInfo && this.interaction.fileInfo.originalFileName) {
					let fileExtension = '.' + this.interaction.fileInfo.originalFileName.toLowerCase().split('.').pop();
					const audioTypes = ['.wav', '.mp3', '.mp4', '.wmv', '.g'];
					this.isAudio = audioTypes.includes(fileExtension);
				}

				// this.isAudio = this.interaction.fileInfo && (this.interaction.fileInfo.type === null) || this.interaction.fileInfo.type.includes('audio');
				this.createFeedbackItems();

				if (!this.interaction.inAnalysis && (this.interaction.status.id == InteractionStatusEnum.CONTESTED ||
					this.interaction.status.id == InteractionStatusEnum.REVISION || this.interaction.status.id == InteractionStatusEnum.REPLICA_SENDED ||
					this.interaction.status.id == InteractionStatusEnum.MANUAL_MONITOR || this.interaction.status.id == InteractionStatusEnum.FEEDBACK)) {

					this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
					isInternal => {
						if (isInternal) {
							this.ngxPermissionsService.hasPermission(this.lockablePermissions.find(item => item.interactionStatus == this.interaction.lastStatusHistory.id).permission)
							.then(hasPermission => {
								if (hasPermission) {
									this.turnOnInAnalysis();
								} else {
									this.ngxPermissionsService.hasPermission(QualityPermissions.ALL_PERMISSIONS)
									.then(hasAdminPermission => {
										if (hasAdminPermission) {
											this.turnOnInAnalysis();
										}
									});
								}
							});
						}
					});
          		}

				this.firstLoadingDone.emit();
			}),
			take(1)
		).subscribe(() => {
			this.activeIds = new Array(this.itemsGrouped.length).fill(new Set());
		});
	}

	turnOnInAnalysis() {
		this.interactionServiceV2.interactionInAnalysis(this.interaction.id, true).subscribe(
			data => {
			  this.interaction = data;

			  this.sameUserAnalysis = true;
			  this.toastService.showSuccess('Análise iniciada, outros usuários não podem fazer alterações nessa interação.');

			  this.ngxPermissionsService.hasPermission(QualityPermissions.INTERNAL).then(
				hasPermission => {
				  this.interactionInAnalysis = hasPermission && this.interaction.inAnalysis;
				});
			}, error => {
			  console.error(error);
			});
	}

	public unblockInteraction(): void {
		this.isLoading = true;

		this.interactionServiceV2.interactionInAnalysis(this.interaction.id, false).subscribe(
			data => {
				this.interaction = data;
				this.toastService.showSuccess('Interação foi liberada para outros usuários.');
				this.interaction.inAnalysis = false;
				this.closeHandler();
			}, error => {
				this.toastService.showDanger(SystemMessages.INTERACTION_UNBLOCK_ERROR);
				console.error(error);
			}
		).add(() => {
			this.isLoading = false;
		});
	}

	private createFeedbackItems(): void {
		this.itemsTimeline = [];

		if (this.interaction.dateFeedback) {
			let timelineItemFeedback: TimelineItem = {
				title: this.interaction.userFeedback.name,
				status: 'FEEDBACK',
				date: this.interaction.dateFeedback,
				content: this.interaction.feedback,
			};

			this.itemsTimeline = [...this.itemsTimeline, timelineItemFeedback];
		}

		if (this.interaction.dateFeedbackAccepted) {
			let timelineItemFeedbackAccepted: TimelineItem = {
				title: this.interaction.userFeedbackAccepted.name,
				status: 'FEEDBACK ACEITO',
				date: this.interaction.dateFeedback,
				content: '',
			};

			this.itemsTimeline = [...this.itemsTimeline, timelineItemFeedbackAccepted];
		}
	}

	findInteractionItems(interactionId): Observable<MonitorInteractionItem[]> {
		return this.monitorInteractionItemService.findByInteraction(interactionId).pipe(
			catchError(() => of([])),
			map((items: MonitorInteractionItem[]) => {
				return _.sortBy(items, (item) => {
					return item.itensChecklist.group ? item.itensChecklist.group.id : item.itensChecklist.name;
				}).map(item => new MonitorInteractionItem().deserialize(item));
			})
		);

	}

	setTimerConfig(interaction): void {
		if (interaction.customer.simpleInteractionFlow && interaction.status.id === 'EVALUATION') return null;

		this.timer = new countDownTimerConfigModel();
		this.timer.timerTexts = new timerTexts();
		this.timer.timerTexts.hourText = "h"; //default - hh
		this.timer.timerTexts.minuteText = "m"; //default - mm
		this.timer.timerTexts.secondsText = "s"; //default - ss

		this.countupTimerService.startTimer(new Date());
	}

	updateArray(arrayName: string, entry: any) {
		this[arrayName] = [...this[arrayName], entry];
	}

	updateItem(item: MonitorInteractionItem, id: string, groupIdx: number) {
		const index = this.monitorItems.indexOf(item);
		this.monitorItems[index] = item;
		this.itemsGrouped = this.getItemsByGroup();
		this.updateActiveIds(groupIdx, id, false);
	}

	updateActiveIds(groupIdx, itemId, isToggle = true) {
		this.activeIds[groupIdx].has(itemId) && isToggle ? this.activeIds[groupIdx].delete(itemId) : this.activeIds[groupIdx].add(itemId);
	}

	getActiveIdsGroup(groupIdx) {
		return Array.from(this.activeIds[groupIdx]);
	}

	getGroupGrade(item: ChecklistItem): string {
		const negativeStatus = ["CONTESTATION_DECLINED", "CONFORMING_EDITED", "CONTESTED", "RATED", "REPLICA_ACCEPTED_DISAGREE", "REPLICA_DECLINED"];
		let totalGrade = 0;
		const grade = this.monitorItems
			.filter(_item => _item.itensChecklist.group && _item.itensChecklist.group.id == item.group.id && _item.itensChecklist.peso != 100)
			.filter(_item => {
				totalGrade += _item.itensChecklist.peso;
				return _item.answer != null && _item.answer.id == "NONCONFORMING" && negativeStatus.indexOf(_item.status.id) > -1;
			})
			.reduce((acc, _item) => (acc -= _item.itensChecklist.peso), totalGrade);

		return this.decimalPipe.transform(grade, '1.1-1') + "/" + this.decimalPipe.transform(totalGrade, '1.1-1');
	}

	getGroupElementsIds() {
		return this.itemsGrouped.map((group, idx) => 'group' + idx).join(',');
	}

	getItemsByGroup() {
		const groups = Array.from(new Set(this.monitorItems.map(item => item.itensChecklist.group.name)));
		return this.monitorItems.reduce((acc, item) => {
			const index = groups.indexOf(item.itensChecklist.group.name);
			acc[index] = [...acc[index], item];
			return acc;
		}, new Array(groups.length).fill([]));
	}

	getItemIndex(item: MonitorInteractionItem) {
		return this.monitorItems.indexOf(item) + 1;
	}

	getInteractionWithStatus(status: string) {
		const interactionStatus = new InteractionStatus();
		interactionStatus.id = status;
		const monitorInteraction = cloneDeep(this.interaction);
		monitorInteraction.status = interactionStatus;
		return monitorInteraction;
	}

	checkGroupChanged(item: MonitorInteractionItem, index: number): boolean {
		return index > -1 && this.monitorItems[index] ? (this.monitorItems[index].itensChecklist.group) && this.monitorItems[index].itensChecklist.group.name != item.itensChecklist.group.name : true;
	}

	checkHasStatus(status: string): boolean {
		return Boolean(this.monitorItems.find(item => item.status.id === status));
	}

	hasContestedAndConformingEditedStatus(): boolean {
		return this.checkHasStatus('CONTESTED') && this.checkHasStatus('CONFORMING_EDITED');
	}

	getContestationTitle(): string {
		return this.hasContestedAndConformingEditedStatus() ? 'Enviar Contestação & Item editado' : 'Enviar Contestação';
	}

	getContestationText(): string {
		return this.hasContestedAndConformingEditedStatus() ? 'Deseja enviar a sua contestação e item editado?' : 'Deseja enviar a sua contestação?';
	}

	checkGradeShouldCount(item: MonitorInteractionItem): boolean {
		return checkIfGradeShouldCount(item);
	}

	checkAnyItemHasCustomerEvaluation() {
		let hasCustomerEvaluation = false;
		this.monitorItems.forEach(item => {
			const timeline = this.timelineService.getTimelineItemFromMonitorInteraction(item);
			if (timeline.length > 0 && (timeline[timeline.length - 1]?.status === 'CONTESTATION_ACCEPTED' ||
				timeline[timeline.length - 1]?.status === 'CONTESTATION_DECLINED' && timeline[timeline.length - 2]?.status === 'CONTESTATION_DECLINED')) {
				hasCustomerEvaluation = true;
				return;
			}
		});
		return hasCustomerEvaluation;
	}

	checkEvaluationDeclinedIsConfirmed(item: MonitorInteractionItem) {
		const length = item.contestations.length;
		return item.contestations[length - 1].status.id === 'CONTESTATION_DECLINED' && item.contestations[length - 2].status.id === 'CONTESTATION_DECLINED';
	}

	finishInteraction() {
		const monitorInteraction = this.getInteractionWithStatus(this.interaction.customer.hasFeedback && this.interaction.status.id != 'FEEDBACK_ACCEPTED' && this.interaction.status.id != 'FEEDBACK' ? 'FEEDBACK' : 'FINISHED');

		this.saveInteraction(monitorInteraction, "Monitoria finalizada com sucesso.");
	}

	finishEvaluation() {
		let status = "FINISHED"
		if (this.flow.feedback) status = "FEEDBACK";
		const monitorInteraction = this.getInteractionWithStatus(status);

		this.saveInteraction(monitorInteraction, 'Contestação enviada com sucesso.');
	}

	finishContestation() {
		if (!this.checkHasStatus('CONTESTED') && !this.checkHasStatus('CONFORMING_EDITED')) return null;
		const monitorInteraction = this.getInteractionWithStatus('CONTESTED');
		this.saveInteraction(monitorInteraction, 'Contestação enviada com sucesso.');
	}

	finishContestationResponses() {
		if (!this.checkHasStatus('CONTESTATION_ACCEPTED') && !this.checkHasStatus('CONTESTATION_DECLINED')) return null;

		let status = "FINISHED";
		if (this.flow.replica && this.checkHasStatus('CONTESTATION_DECLINED')) status = "REPLICA";
		if ((!this.flow.replica || !this.checkHasStatus('CONTESTATION_DECLINED')) && this.flow.feedback) status = "FEEDBACK";

		const monitorInteraction = this.getInteractionWithStatus(status);

		this.saveInteraction(monitorInteraction, "Respostas enviadas com sucesso.");
	}

	finishContestationReplicas() {
		if (!this.checkHasStatus('REPLICA')) return null;

		const monitorInteraction = this.getInteractionWithStatus('REPLICA_SENDED');
		this.saveInteraction(monitorInteraction, "Réplicas enviadas com sucesso.");
	}

	finishContestationReplicasResponses() {
		if (!this.checkHasStatus('REPLICA_DECLINED') && !this.checkHasStatus('REPLICA_ACCEPTED')) return null;

		let status = "FINISHED";
		if (this.flow.feedback) status = "FEEDBACK";

		const monitorInteraction = this.getInteractionWithStatus(status);
		this.saveInteraction(monitorInteraction, "Respostas enviadas com sucesso.");
	}

	finishFeedback(feedback: string) {
		const monitorInteraction = this.getInteractionWithStatus('FEEDBACK_ACCEPTED');
		monitorInteraction.feedback = feedback;

		if (this.interaction.customer.simpleInteractionFlow) {
			this.countupTimerService.pauseTimer();
			let timer = this.countupTimerService.timerValue;
			monitorInteraction.monitorDuration = timer.hours + ":" + timer.mins + ":" + timer.seconds;
		}

		this.saveInteraction(monitorInteraction, "Feedback enviado com sucesso");
	}

	finishRevision() {
		const monitorInteraction = this.getInteractionWithStatus('PENDING');
		this.saveInteraction(monitorInteraction, "Revisão finalizada com sucesso.");
	}

	actionItemUpdate({ contestation, item }, i, j) {
		this.updateArray('contestations', contestation);
		this.updateArray('itensToUpdate', item);
		this.updateItem(item, 'group' + i + 'item' + j, i);
		//this.saveItem(item, "Contestação aceita com sucesso.", "CONTESTATION_ACCEPTED");
	}

	saveInteraction(interaction, successText) {
		this.isLoading = true;

		const uploadTasks = this.contestations
		.filter(contestation => !!contestation.file)
		.map(contestation =>
			this.bucketService.getUploadUrlFileInfo(contestation.file.name).pipe(
				switchMap(data => {
					const fileInfo = new FileInfo();
					fileInfo.id = data.fileInfoId;
					contestation.fileInfo = fileInfo;

					return this.http.put(data.url, contestation.file as File, {
						reportProgress: true,
						observe: 'events'
					}).pipe(
						tap(event => {
							if (event.type === HttpEventType.UploadProgress) {
								this.uploadProgress = event.loaded / event.total * 100;
							}
						}),
						filter(event => event.type === HttpEventType.Response),
						catchError(error => throwError(error))
					);
				})
			)
		);

		const uploadsComplete = uploadTasks.length > 0 ? forkJoin(uploadTasks) : of(null);

		uploadsComplete.pipe(
			switchMap(() => {
				interaction.contestations = this.contestations;
				interaction.itensToUpdate = this.itensToUpdate;
				interaction.inAnalysis = false;

				return this.interactionService.contestationPatch(interaction).pipe(
					switchMap(() => this.interactionService.find(interaction.id))
				);
			}),
			catchError(error => {
				this.toastService.showDanger(BusinessException[error?.error?.message] || "Erro ao finalizar interação");
				return of(null);
			}),
			tap({
				complete: () => this.isLoading = false
			})
		).subscribe({
			next: (_interaction) => {
				if (_interaction) {
					this.toastService.showSuccess(successText);
					this.interaction = _interaction;
					this.closeHandler();
					this.contestations = [];
					this.itensToUpdate = [];
					this.resetScroll.emit();
				}
			},
			error: () => {
			}
		});
	}

	saveItem(item: MonitorInteractionItem, successText: string, status: string) {
		this.isLoading = true;
		const monitorInteractionItem = new MonitorInteractionItem();
		monitorInteractionItem.id = item.id;
		monitorInteractionItem.status = { id: status };

		if (item.contestationAcceptJustification) monitorInteractionItem.contestationAcceptJustification = item.contestationAcceptJustification;

		this.monitorInteractionItemService.patch(monitorInteractionItem).subscribe(response => {
			this.toastService.showSuccess(successText);
			this.modalService.dismissAll();
			item.status = response.status;
			item.answer = response.answer;
			this.monitorItems[this.getItemIndex(item) - 1] = item;
			this.itemsGrouped = this.getItemsByGroup();
		}, error => {
			this.toastService.showDanger(BusinessException[error?.error?.message] || "Erro ao realizar ação");
			if (BusinessException[error?.error?.message] !== null && BusinessException[error?.error?.message] !== undefined) this.ngOnInit();
		}).add(() => this.isLoading = false);
	}

	copyToClipboard(ev, interaction: Interaction) {
		ev.stopPropagation();
		navigator.clipboard.writeText(interaction.fileInfo?.originalFileName || interaction.fileInfo?.fileName)
			.then(() => this.toastService.showSuccess('Nome do Arquivo copiado para o seu clipboard.'))
			.catch(e => console.error(e));
	}

	downloadFile() {


		if (this.isAudio) {
			this.http.get(this.interaction.audioUrl, { responseType: 'blob' }).subscribe(response => {
				saveAudioFile(response, this.interaction.originalFileName);
			});
		} else {
			this.interactionService.downloadFile(this.interaction.fileInfo.id).subscribe(
				(data) => saveFile(data, this.interaction.fileInfo.originalFileName, this.interaction.fileInfo.type));

		}
	}

	discardMonitor($event) {
		$event.stopPropagation();

		const modalRef = this.modalService.open(ConfirmJustificationTypeComponent, {});
		modalRef.componentInstance.justificationFilter = { text: '', type: ['DISCARD'] } as JustificationFilter;
		modalRef.result.then((data) => {
			if (data) {
				this.isLoading = true;
				this.interaction.status.id = InteractionStatusEnum.DISCARDED.toString();
				this.interaction.status.name = 'Descartada';
				this.interaction.deadLine = null;
				this.interaction.inAnalysis = false;
				let justification = new Justification();
				justification = data;
				this.interaction.justification = justification;
				this.interactionService.patch(this.interaction).subscribe(response => {
					this.toastService.showSuccess('Monitoria descartada com sucesso');
					this.ngOnInit();
				},
					error => this.toastService.showDanger('Erro ao descartar monitoria')).add(() => this.isLoading = false);
			}
		});
	}

	public openInteractionAttachmentModal(file: any): void {
		let modalRef = this.modalService.open(InteractionAttachmentDialogComponent, { size: 'lg' });
		modalRef.componentInstance.attachment = file;
	}

	attachmentType(file: InteractionAttachment): string {
		const audioTypes = ['wav', 'mp3', 'mp4', 'wmv', 'g'];
		if (audioTypes.includes(file.type)) {
			return 'fa-file-audio';
		}

		const imageTypes = ['jpg', 'png'];
		if (imageTypes.includes(file.type)) {
			return 'fa-file-image';
		}

		return 'fa-file';
	}

	public closeHandler(): void {
		this.close.emit(this.interaction);
	}

	public transformString(input: string): string {
		const regex = /<([^>]+)>(.*?)<\/\1>/gs;
		let result = '';
		let hasTags = false;
		let match;

		while ((match = regex.exec(input)) !== null) {
			hasTags = true;
			const tagName = '<h6>' + this.toTitleCase(match[1]) + '</h6>';
			const tagContent = '<p>' + match[2] + '</p>';

			result += `${tagName}\n`;
			result += `${tagContent.replace(/\|/g, '')}\n\n`;
		}

		if (!hasTags) {
			return '<p>' + input + '</p>';
		}

		return result.trim();
	}

	private toTitleCase(str: string): string {
		return str.replace(/\w\S*/g, (txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}
}
