import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
	selector: 'evo-inline-edit',
	templateUrl: './inline-edit-input.component.html',
	styleUrls: ['./inline-edit-input.component.scss']
})
export class InlineEditInputComponent implements OnInit {

	@Output() public changeDone: EventEmitter<any> = new EventEmitter<any>();
	@Input() public value: any;

	@Input() public permissions: string[] | string;
	@Input() public isCenter: boolean = false;

	@Input() public type: string = '';

	@Input() min: number;
	@Input() max: number;

	@Input() blockSpecialCharacters: boolean = false;
	@Input() blockPaste: boolean = false;

	public showEditIcon: boolean = true;

	public tempValue: string;
	public showInput: boolean = false;

	public lengthError = '';

	public get inlineEditTypes(): typeof InlineEditTypes {
		return InlineEditTypes;
	}

	constructor(public ngxPermissionsService: NgxPermissionsService) {
	}

	public ngOnInit() {
		this.ngxPermissionsService.hasPermission(this.permissions).then(
			data => {
				this.showEditIcon = data;
			});
	}

	public onShowInput(): void {
		this.ngxPermissionsService.hasPermission(this.permissions).then(
			data => {
				if(data) {
					this.showInput = true;
					this.tempValue = this.value;
				}
			});
	}

	public onChangeCancel(): void {
		this.lengthError = '';
		this.showInput = false;
	}

	public onChangeDone(): void {
		this.lengthError = '';

		if(this.min && this.tempValue.length < this.min) {
			this.lengthError = 'É preciso informar pelo menos 5 caracteres.';
			return;
		}

		if(this.max && this.tempValue.length > this.max) {
			this.lengthError = 'Não é possível informar mais de 120 caracteres.';
			return;
		}

		this.changeDone.emit(this.tempValue);
		this.showInput = false;
	}

	blockSpecialCharacter(event):boolean{
		if (this.blockSpecialCharacters) {
			return /^[A-zÀ-ÿ0-9 ]*$/.test(event.key);
		} else {
			return true;
		}
	}
	
	blockOnPaste(event) {
		if (this.blockPaste) {
			event.preventDefault();
		}
	}
}

export enum InlineEditTypes {
	TITLE = 'TITLE',
	TABLE = 'TABLE'
}
