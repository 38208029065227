import { EvoModel } from './evo-model';

export class Permission extends EvoModel {

	public id: string;
	public code: string;
	public name: string;
	public description: string;
	public product: any;
	public group: PermissionGroup | string;
	public createdAt: any;
	public updatedAt: any;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}


export enum QualityPermissions {
	ALL_PERMISSIONS = 'ALL_PERMISSIONS',
	CREATE_SEGMENTS = 'CREATE_SEGMENTS',
	EDIT_SEGMENTS = 'EDIT_SEGMENTS',
	VIEW_SEGMENTS = 'VIEW_SEGMENTS',
	ACTIVATE_SEGMENTS = 'ACTIVATE_SEGMENTS',
	CREATE_OPERATIONS = 'CREATE_OPERATIONS',
	EDIT_OPERATIONS = 'EDIT_OPERATIONS',
	VIEW_OPERATIONS = 'VIEW_OPERATIONS',
	CREATE_CHECKLISTS = 'CREATE_CHECKLISTS',
	EDIT_CHECKLISTS = 'EDIT_CHECKLISTS',
	VIEW_CHECKLISTS = 'VIEW_CHECKLISTS',
	DOWNLOAD_CONTESTATION_REPORTS = 'DOWNLOAD_CONTESTATION_REPORTS',
	VIEW_CONTESTATION_REPORTS = 'VIEW_CONTESTATION_REPORTS',
	DOWNLOAD_INTERACTION_REPORTS = 'DOWNLOAD_INTERACTION_REPORTS',
	VIEW_INTERACTION_REPORTS = 'VIEW_INTERACTION_REPORTS',
	DOWNLOAD_ITEM_REPORTS = 'DOWNLOAD_ITEM_REPORTS',
	VIEW_ITEM_REPORTS = 'VIEW_ITEM_REPORTS',
	CREATE_INTERACTIONS = 'CREATE_INTERACTIONS',
	VIEW_ALL_INTERACTIONS = 'VIEW_ALL_INTERACTIONS',
	VIEW_OWNED_INTERACTION = 'VIEW_OWNED_INTERACTION',
	VIEW_INTERACTIONS_DETAILS = 'VIEW_INTERACTIONS_DETAILS',
	VIEW_INTERACTIONS_CUSTOMER_DETAILS = 'VIEW_INTERACTIONS_CUSTOMER_DETAILS',
	REVIEW_INTERACTIONS = 'REVIEW_INTERACTIONS',
	EVALUATE_INTERACTIONS = 'EVALUATE_INTERACTIONS',
	RELEASE_INTERACTIONS = 'RELEASE_INTERACTIONS',
	IMPORT_INTERACTIONS = 'IMPORT_INTERACTIONS',
	DISCARD_INTERACTION = 'DISCARD_INTERACTION',
	ACCEPT_EVALUATION = 'ACCEPT_EVALUATION',
	CONTEST_EVALUATION = 'CONTEST_EVALUATION',
	CONTEST_EVALUATION_ATTACHMENT = 'CONTEST_EVALUATION_ATTACHMENT',
	REPLY_CONTESTATION = 'REPLY_CONTESTATION',
	REPLY_CONTESTATION_ATTACHMENT = 'REPLY_CONTESTATION_ATTACHMENT',
	REPLICA_REPLY_CONTESTATION = 'REPLICA_REPLY_CONTESTATION',
	REPLY_REPLICA = 'REPLY_REPLICA',
	INTERNAL = 'INTERNAL',
	ACCEPT_FEEDBACK = 'ACCEPT_FEEDBACK',
	APPLY_FEEDBACK = 'APPLY_FEEDBACK',
	VIEW_INTERACTION_INSIGHT = 'VIEW_INTERACTION_INSIGHT',
	COPY_TRANSCRIPTION = 'COPY_TRANSCRIPTION',
	AUDIO_DOWNLOAD = 'AUDIO_DOWNLOAD',
	VIEW_FLOWS = 'VIEW_FLOWS',
	CREATE_FLOWS = 'CREATE_FLOWS',
	EDIT_FLOWS = 'EDIT_FLOWS',
	VIEW_JUSTIFICATIONS = 'VIEW_JUSTIFICATIONS',
	CREATE_JUSTIFICATIONS = 'CREATE_JUSTIFICATIONS',
	EDIT_JUSTIFICATIONS = 'EDIT_JUSTIFICATIONS',
	DASHBOARD_QUALITY = 'DASHBOARD_QUALITY',
	DASHBOARD_SENTIMENT = 'DASHBOARD_SENTIMENT',
	DASHBOARD_PBI_QUALITY = 'DASHBOARD_PBI_QUALITY',
	DASHBOARD_PBI_VOLUME = 'DASHBOARD_PBI_VOLUME',
	DASHBOARD_PBI_CONTROL = 'DASHBOARD_PBI_CONTROL',
	DASHBOARD_PBI_PERFORMANCE = 'DASHBOARD_PBI_PERFORMANCE',
	DASHBOARD_PBI_SENTIMENT = 'DASHBOARD_PBI_SENTIMENT',
	DASHBOARD_PBI_CALLBACK = 'DASHBOARD_PBI_CALLBACK',
	DASHBOARD_PBI_BUSINESS = 'DASHBOARD_PBI_BUSINESS',
	DASHBOARD_PBI_SCORE = 'DASHBOARD_PBI_SCORE',
	DASHBOARD_PBI_OUTCOME = 'DASHBOARD_PBI_OUTCOME',
	DASHBOARD_TRAINING = 'DASHBOARD_TRAINING',
	DASHBOARD_PERFORMANCE = 'DASHBOARD_PERFORMANCE',
	DASHBOARD_CALLDROP_CALLBACK = "DASHBOARD_CALLDROP_CALLBACK",
	TRAINING_ACCESS = 'TRAINING_ACCESS',
	DASHBOARD_MONITOR_FLOW = 'DASHBOARD_MONITOR_FLOW',
	CREATE_TRAINING = 'CREATE_TRAINING',
	VIEW_TRANSCRIPTION = 'VIEW_TRANSCRIPTION',

	VIEW_REPORTS = 'VIEW_REPORTS',
	VIEW_FRAGMENTS = "VIEW_FRAGMENTS",
	EDIT_CONFORMING_ANSWER = "EDIT_CONFORMING_ANSWER",
	GEN_AI_CHAT = "GEN_AI_CHAT",
	GEN_AI_INTERACTION_CHAT = "GEN_AI_INTERACTION_CHAT",
	GEN_AI_CHAT_CONFIGURATION = "GEN_AI_CHAT_CONFIGURATION"
}

export enum PermissionGroup {
	USERS = 'Usuários',
	SEGMENTS = 'Segmentos',
	OPERATIONS = 'Operações',
	CHECKLISTS = 'Check List',
	REPORTS = 'Relatórios',
	INTERACTIONS = 'Interações',
	ORGANIZATIONS = 'Organizações',
	ROLES = 'Perfis',
	FLOWS = 'Fluxos',
	JUSTIFICATIONS = 'Justificativas',
	DASHBOARDS = 'Dashboards',
	TRAINING_PLATAFORM = 'Training',
	TRAINING = 'Treinamentos',
	GEN_AI = 'IA Generativa'
}
