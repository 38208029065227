import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceClass } from './baseService.service';
import { Observable } from 'rxjs';
import { HelpDeskMessage } from '@app/models';


@Injectable({providedIn: 'root'})
export class HelpDeskService extends BaseServiceClass<HelpDeskMessage> {

	public showHelpSidebar: boolean = false;
	public helperContent = '';

	public showHelpDialog: boolean = false;
	public helpDialogText: string = '';

	constructor(protected httpService: HttpClient) {
		super(httpService, '/notifications');
	}

	public resetHelper(): void {
		this.showHelpSidebar = false;
		this.showHelpDialog = false;
		this.helperContent = '';
		this.helpDialogText = '';
	}

	public sendMessage(helpMessage: HelpDeskMessage): Observable<any> {
		return this.httpService.post(`${this.entity_url}/helpdesk`, helpMessage);
	}

	public sendTrainingMessage(helpMessage: HelpDeskMessage): Observable<any> {
		return this.httpService.post(`${this.entity_url}/training`, helpMessage);
	}
}
