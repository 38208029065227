import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { addDays, differenceInDays, subDays } from 'date-fns';


@Component({
  selector: 'datepicker-range-popup',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RangeDatepickerComponent),
    multi: true
  }]
})
export class RangeDatepickerComponent implements ControlValueAccessor {
  @Input() toDate: NgbDate | null;
  @Input() fromDate: NgbDate | null;
  @Input() placeholder = 'Selecione um período';
  @Input() maxDate: any = null;

  hoveredDate: NgbDate | null = null;
  @Input() shouldLimitDate: boolean = false;

  @Input() limitDate: boolean = false;

  @Input() set forceChange(dates: Date[]) {
    if (dates) {
      this.fromDate = new NgbDate(dates[0].getFullYear(), dates[0].getMonth() + 1, dates[0].getDate());
      this.toDate = new NgbDate(dates[1].getFullYear(), dates[1].getMonth() + 1, dates[1].getDate());
      this.items[this.items.length - 1] = {value: null, label: `${new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toLocaleDateString('pt-BR')} - ${new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).toLocaleDateString('pt-BR')}`}
      this.select.select(this.items[this.items.length - 1]);

      this._value.setValue(dates);
      this.onChange(dates);
      this.onTouch(dates);
    } else {
      this._value.setValue(null);
      this.select.writeValue(undefined);
      this.onChange(null);
      this.onTouch(null);
    }
  }

  @ViewChild('select', {static: true}) select: NgSelectComponent;
  @ViewChild('datepicker', {static: true}) datepicker: any;

  @Output() onDateClear = new EventEmitter();

  @Input() items: any[] = [
    {value: 0, label: 'Hoje'},
    {value: 7, label: 'Últimos 7 dias'},
    {value: 15, label: 'Últimos 15 dias'},
	  {value: 30, label: 'Últimos 30 dias'},
    {value: null, label: 'Período Personalizado'}
  ];

  isDisabled = (date: NgbDate, current: {month: number}) => {
    if(this.shouldLimitDate){
      if(this.fromDate && this.toDate) return false;
      const disable = this.disabledDate ? date.after(this.disabledDate) || date.equals(this.disabledDate) : false;
      return  disable;
    }
    return false;
  }

  disabledDate: NgbDate;

  onChange: any = () => {}
  onTouch: any = () => {}

  constructor(private calendar: NgbCalendar,
              public formatter: NgbDateParserFormatter) {}

  ngOnInit(): void {}

  _value = new FormControl([null, null]);
  set value(val: any) {
    if(val && val.length > 0 && val[0] instanceof Date && this.isRangeSelected(val[0], val[1])) {
      this.onRangeSelection(this.items.find(item => item.value === differenceInDays(val[1], val[0])));
    } else if (val && val.length > 0 && val[0] instanceof Date && !this.isRangeSelected(val[0], val[1])) {
      this.onDateSelection(new NgbDate(val[0].getFullYear(), val[0].getMonth() + 1, val[0].getDate()));
      this.onDateSelection(new NgbDate(val[1].getFullYear(), val[1].getMonth() + 1, val[1].getDate()));
    }
    if(val && val.length === 2 && !!val[0] && !!val[1]) {
      const formatedValue = [new Date(val[0].year, val[0].month - 1, val[0].day), new Date(val[1].year, val[1].month - 1, val[1].day)];
      this._value.setValue(formatedValue);
      this.onChange(formatedValue);
      this.onTouch(formatedValue);
    } else if(val === null) {
      this.fromDate = null;
      this.toDate = null;
      this._value.setValue(null);
      this.select.writeValue(null);
      this.onChange(null);
      this.onTouch(null);
    }
  }

  get value() {
    return this._value;
  }

  writeValue(value) {
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  dateDisable() {
    this.disabledDate = new NgbDate(2099,12,31)
  }

  isRangeSelected(fromDate, toDate) {
	  const result = differenceInDays(toDate, new Date()) === 0 && (differenceInDays(toDate, fromDate) === 1 || differenceInDays(toDate, fromDate) === 7 || differenceInDays(toDate, fromDate) === 15 || differenceInDays(toDate, fromDate) === 30);

    return result;
  }

  onRangeSelection(range) {
    if (!range || range.value === null || range.value === undefined) return null;

	const today = new Date();

	if(typeof range.value == 'number') {
		const subtractedDay = subDays(today, range.value);
		this.toDate = new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
		this.fromDate = new NgbDate(subtractedDay.getFullYear(), subtractedDay.getMonth() + 1, subtractedDay.getDate());
	}

	if(typeof range.value == 'string' && range.value == 'year') {
		this.fromDate = new NgbDate(today.getFullYear(), 1, 1);
		this.toDate = new NgbDate(today.getFullYear(), 12, 31);
	}

    this.value = [this.fromDate, this.toDate];
    this.select.select(range);
    this.select.close();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      if(this.limitDate) {
        let limitDate = addDays(new Date(date.year, date.month, date.day), 45);
        if(!this.maxDate){
          this.maxDate = {year: limitDate.getFullYear(), month: limitDate.getMonth() + 1, day: limitDate.getDate()};
        }
      }
    } else if (this.fromDate && !this.toDate && date && (date.after(this.fromDate) || date.equals(this.fromDate))) {
	    this.toDate = date;
		let dateLabel = '';

		if(date.equals(this.fromDate)) {
			dateLabel = `${new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toLocaleDateString('pt-BR')}`
		} else {
			dateLabel = `${new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).toLocaleDateString('pt-BR')} - ${new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).toLocaleDateString('pt-BR')}`
		}

	    this.items[this.items.length - 1] = {
		    value: null,
		    label: dateLabel
	    }
	    this.select.select(this.items[this.items.length - 1]);
	    this.datepicker.close();

		this.maxDate = null;
    } else {
      this.toDate = null;
      this.fromDate = date;

	  if(this.limitDate) {
		  let limitDate = addDays(new Date(date.year, date.month, date.day), 45);
		  this.maxDate = { year: limitDate.getFullYear(), month: limitDate.getMonth() + 1, day: limitDate.getDate() };
	  }
    }
    const dateBlock = new Date(this.fromDate.year, this.fromDate.month, this.fromDate.day);
    dateBlock.setDate(dateBlock.getDate() + 30);
    this.disabledDate = new NgbDate(dateBlock.getFullYear(), dateBlock.getMonth(), dateBlock.getDate());
    this.value = [this.fromDate, this.toDate];
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  format(fromDate: any, toDate: any) {
    if (fromDate === null || toDate === null) {
      return 'Escolher nova data'
    }
    if (fromDate === undefined || toDate === undefined) {
      const fromDateArr = this.formatter.format(fromDate).split('-');
      return fromDateArr[2] + '/' + fromDateArr[1] + '/' + fromDateArr[0] + " - ";
    }
    return ((fromDate.day < 10 ? 0 + fromDate.day.toString() : fromDate.day) + '/' + (fromDate.month < 10 ? 0 + fromDate.month.toString() : fromDate.month) + '/' + fromDate.year) + ' - ' + ((toDate.day < 10 ? 0 + toDate.day.toString() : toDate.day) + '/' + (toDate.month < 10 ? 0 + toDate.month.toString() : toDate.month) + '/' + toDate.year);
  }

}
