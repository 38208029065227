<div>
    <div class="body">
        <evo-stepper-title title="Importar arquivos de áudio" subtitle="Importe os arquivos de áudio das chamadas"></evo-stepper-title>
        <div class="alert alert-primary" role="alert">
            <div class="d-flex flex-start">
                <i class="fas fa-exclamation-triangle font-primary mr-2 mt-2"></i>
                <div>
                    <p class="m-0">
                        Antes de realizar o upload, verifique se os nomes dos arquivos de áudio são iguais as colunas referenciadas no arquivo de metadados.
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label for="file" class="required">Upload de arquivos de chamada</label>
            <p class="text-muted">2GB é o tamanho limite por arquivo. Somente arquivos .zip são permitidos.</p>
            <div class="d-flex">
                <evo-upload-file-input name="file" style="width: 100%"
                    [(ngModel)]="file"
                    accept="application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
                    [violatedRules]="violatedRulesFileUpload"
                    [isProgressBarEnable]="true"
                    [uploadProgress]="[uploadProgress]"
                    fileLegend="Somente arquivos .ZIP. (max. 2GB por arquivo)"
                    [showItemLegend]="true"
                    itemLegend="Clique em Realizar upload para enviar os arquivos"
                    [isLoading]="importDataService.isLoading"
                    [isMultiple]="false"
                    (cancelUpload)="cancelUpload()">
                </evo-upload-file-input>
            </div>
        </div>
    </div>
    <evo-stepper-footer [nextButtonLabel]="importDataService.isLoading ? 'Fazendo upload' : 'Realizar upload'"
                        [isFormInvalid]="file.length === 0 || violatedRulesFileUpload?.length > 0"
                        [isLoading]="importDataService.isLoading"
                        (onPrev)="onPrev()"
                        (onNext)="onNext()">
    </evo-stepper-footer>
</div>
