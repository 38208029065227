import { Input, ViewChild, TemplateRef, Output, EventEmitter, OnInit, Directive } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BucketService, ToastService } from '@app/services';
import { Contestation, FileInfo, QualityPermissions } from '@app/models';
import { ContestationStatus } from '../../../_helpers/interaction-status.enum';
import { MonitorInteractionItem } from '@app/models';
import { User } from '@app/models';
import { Justification } from '@app/models';
import { Interaction } from '@app/models';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

@Directive({ selector: '[interactionContestationButton]' })
export class InteractionContestationButton {
	@Input() index: number;
	@Input() item: MonitorInteractionItem;
	@Input() currentUser: User;
	@Input() justifications: Justification[];
	@Input() interaction: Interaction;

	@ViewChild('buttonModal', { static: true }) protected modal: TemplateRef<any>;

	@Output() protected actionCompleted: EventEmitter<any> = new EventEmitter();

	public modalRef: any;

	public isLoading: boolean = false;
	public contestationForm: FormGroup;
    
	// File Upload
	public file: File[] = [];

	public uploadProgress: number = 0;

	public automaticMimetypes: string = '';

	public get violatedRulesFileUpload() {
		return this.file.length > 0 && this.file[0].size > 102400 * 500 ? ['Arquivo excede o limite permitido. Envie arquivos com até 500MB.'] : [];
	}

	public readonly qualityPermissions = QualityPermissions;

	constructor(protected modalService: NgbModal,
		protected activeModal: NgbActiveModal,
		protected toastService: ToastService,
		protected formBuilder: FormBuilder) {
		this.contestationForm = this.formBuilder.group({
			contestation: ['', [Validators.required, Validators.maxLength(2000)]],
			minute: [''],
		});
	}

	public openModal() {
		this.modalRef = this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title' });
	}

	public saveContestation(status: string, text: string,  fileInfoId?: number, conforming?: {  id: string, name: string }) {
		const contestation = new Contestation();
		contestation.explanation = this.contestation.value,
		contestation.registerDate = new Date(),
		contestation.status = { id: status, name: ContestationStatus[status] },
		contestation.minuteSelected = status == 'CONTESTED' ? this.minute.value : null,
		contestation.user = new User();
		contestation.user.name = this.currentUser.name;
		contestation.monitorInteractionItem = new MonitorInteractionItem();
		contestation.monitorInteractionItem.id = this.item.id;
		contestation.file = this.file[0];

		/*this.bucketService.getUploadUrlFileInfo(this.file[0].name).pipe(
			switchMap(data =>
				this.httpClient.put(data.url, this.file[0] as File, {reportProgress: true, observe: 'events'}).pipe(
					tap(event => {
						if (event.type === HttpEventType.UploadProgress) {
							this.uploadProgress = event.loaded / event.total * 100;
						}
					}),
					filter(event => event.type === HttpEventType.Response),
					catchError(error => {
						return throwError(error);
					})
				)
			)
		).subscribe(data => {
			if(data.fileInfoId) {
				let fileInfo = new FileInfo();
				fileInfo.id = fileInfoId;
				contestation.fileInfo = fileInfo;
			}

			this.save(contestation, text);
		}, error => {
			this.toastService.showDanger('Erro ao enviar arquivo.');
		});*/

		this.save(contestation, text, conforming);
	}

	protected save(contestation, text, conforming?: {  id: string, name: string }) {
		this.item.status = contestation.status;
		this.item.contestations = [...this.item.contestations, contestation];
		this.actionCompleted.emit({ contestation, item: this.item });
		if(conforming) {
			this.item.answer = conforming;
		}

		this.contestation.patchValue("");
		//this.modalService.dismissAll();
		// this.activeModal.dismiss();
		// this.toastService.showSuccess(text);

	}

	public get contestation() {
		return this.contestationForm.get('contestation');
	}

	public get minute() {
		return this.contestationForm.get('minute');
	}
}
