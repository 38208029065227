import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
	selector: '[evoDnd]'
})
export class EvoDndDirective {

	@Output() onFileHover = new EventEmitter<any>();
	@Output() onFileLeave = new EventEmitter<any>();

	@Output() onFileDropped = new EventEmitter<any>();
	@HostBinding('style.background-color') public background = '#fff';

	@HostBinding('class') public dropClass = '';

	@HostListener('dragover', ['$event']) onDragOver(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#FAFAFA';
		this.dropClass = 'drag-hover';

		this.onFileHover.emit();
	};

	@HostListener('dragleave', ['$event']) public onDragLeave(evt) {
		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#fff';

		this.onFileLeave.emit();
	}

	@HostListener('drop', ['$event']) public ondrop(evt) {
		if(evt.dataTransfer.files?.length == 0) {
			this.onDragLeave(evt);
			return;
		}

		evt.preventDefault();
		evt.stopPropagation();
		this.background = '#fff';
		this.dropClass = '';

		let files = evt.dataTransfer.files;
		if (files.length > 0) {
			this.onFileDropped.emit(files);
			this.onFileLeave.emit();
		}
	}

	constructor() {
	}

}
