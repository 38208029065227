import { Injectable } from "@angular/core";
import { BaseServiceClass } from "../baseService.service";
import { Operation } from "@app/models";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({providedIn: 'root'})
export class OperationServiceV2 extends BaseServiceClass<Operation> {
	constructor(protected httpService: HttpClient) {
		super(httpService, '/api/v2/operations');
	}

    public getOperations(segmentIds: number[]): Observable<any> {
        const options = { params: {segmentIds: segmentIds} };
        return this.httpService.get<any>(`${this.entity_url}`, options);
    }

}