<div class="modal-header">
	<h4 class="modal-title" id="modal-title">Deseja confirmar sua alteração?</h4>
	<button
		type="button"
		class="close"
		aria-label="Close button"
		aria-describedby="modal-title"
		(click)="modal.close(false)"
	>
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body" style="padding-top: 0px">
	<form [formGroup]="justificationForm">
		<label>Selecione o tipo de justificativa</label>
		<div class="col form-group">
			<label>Selecione o tipo de Justificativa:</label>
			<ng-select
				[items]="justifications.content"
				formControlName="id"
				[multiple]="false"
				bindLabel="text"
				placeholder="Selecione um tipo"
				bindValue="id"
			>
			</ng-select>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button
		type="button"
		class="btn btn-outline-primary"
		(click)="modal.close(false)"
	>
		Cancelar
	</button>
	<button
		type="button"
		class="btn btn-primary"
		(click)="submit()"
		[disabled]="justificationForm.invalid"
	>
		Confirmar
	</button>
</div>
