import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'evo-stepper-footer',
  templateUrl: './stepper-footer.component.html',
  styleUrls: ['./stepper-footer.component.scss']
})
export class StepperFooterComponent {
  @Input() isFormInvalid: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() nextButtonLabel: string = 'Avançar';

  @Input() prevButtonLabel: string = 'Voltar';

  @Output() onPrev = new EventEmitter();
  @Output() onNext = new EventEmitter();

  constructor() { }

}
