<div class="modal-header d-flex align-items-center">
    <i class="fas fa-exclamation-circle text-danger mr-2" style="font-size: 1.25em;"></i>
    <h5 class="modal-title" id="modal-title">{{title}}</h5>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;">
    <div [innerHTML]="text"></div>
</div>
<div class="modal-footer">
    <button type="button" class="btn text-primary" (click)="modal.close(false)">Cancelar</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">{{confirmText}}</button>
</div>
