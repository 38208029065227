import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    basePath: string = `${environment.apiUrl}/profiles`;

  constructor(private http: HttpClient) { }

  findAll() {
    return this.http.get<string[]>(`${this.basePath}`);
  }
}
