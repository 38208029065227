import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseServiceClass } from './baseService.service';
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Permission } from "@app/models";

@Injectable({
	providedIn: 'root'
})
export class PermissionService extends BaseServiceClass<any> {

	public findAll(): Observable<Permission[]> {
		return this.http.get<Permission[]>(environment.accountUrl + '/api/v1/permissions');
	}

	constructor(private http: HttpClient) {
		super(http, '');
	}
}
