// function that checks empty values and delete the property of the object
export function clearEmptyValues(value: any): any {
	for (const key in value) {
		if (value[key] === null) {
			delete value[key];
		}
	}

	if (Object.keys(value).length === 0) {
		return null;
	}

	return value;
}
