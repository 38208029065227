import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { User } from './user';

export class PbiReport implements Deserializable {
	id: number;
	groupId: string;
	reportId: string;
	groupName: string;
	reportName: string;
	description: string;
	embeddedAccessToken: string;
	permission: string;
	permissionCode: string;
	customer: Customer;
	createdAt: Date;
	updatedAt: Date;
	updatedBy: User;
	active: boolean;
	imageUrl: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

}
