import { Component, Input } from '@angular/core';
import { UserService } from '../../_services/user.service';

@Component({
    selector: 'avatar-initials',
    templateUrl: './avatar-initials.component.html',
    styleUrls: ['./avatar-initials.component.scss']
})
export class AvatarInitialsComponent {
    // @Input()
    // avatarSize: number = 2;
    // @Input()
    // initials: string;
    // circleSize: string;
    // topSize: string;
    // fontSize: string;
    constructor(private userService: UserService) {
    }

    // ngOnInit() {
    //     if (!this.initials) {
    //         let that = this;
    //         this.authenticationService.currentUser.subscribe({
    //             next(user) {
    //                 if (user != null) {
    //                     let nameSplit = user.name.split(" ");
    //                     that.initials = nameSplit.length > 1 ? nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0) : nameSplit[0].charAt(0) + nameSplit[0].charAt(1);
    //                 }
    //             },
    //             error(msg) {
    //                 console.log('Error Getting user: ', msg);
    //             }
    //         });
    //     }
    //     this.topSize = (this.avatarSize / 4) + "rem";
    //     this.fontSize = (this.avatarSize / 2) + "rem";
    //     this.circleSize = this.avatarSize + "rem";
    //     this.initials = this.initials.toUpperCase();
    // }
    @Input()
    public photoUrl: string;

    @Input()
    public name: string;
    @Input()
    public defaultColor: boolean = false;
    @Input() large: boolean = false;
    @Input()
    small: boolean = false;
    public showInitials = false;
    public initials: string;
    @Input() public circleColor: string;
    @Input() public initialsColor: string;

    private colors = [
        '#1839B4'
    ];

    ngOnInit() {
        if (!this.name) {
            let that = this;
            const user = this.userService.currentUser;
            if (user) {
                that.name = user.username;
                that.noPhotoUrlInit();
            }
        } else {
            if (!this.photoUrl) {
                this.noPhotoUrlInit();
            }
        }

    }

    noPhotoUrlInit() {
        this.showInitials = true;
        this.createInititals();
        if (this.circleColor) return null;

        if (this.defaultColor) {
            this.circleColor = this.colors[0];
        } else {
            const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
            this.circleColor = this.colors[randomIndex];
        }
    }

    private createInititals(): void {
        let initials = "";
        let upperCaseName = this.name.toUpperCase().split('');
        initials = upperCaseName[0];

        for (let i = 0; i < upperCaseName.length; i++) {
            const element = upperCaseName[i];
            if (element === ' ') {
                initials += upperCaseName[i + 1]
                if (initials.length == 2) {
                    break;
                }
            }
        }

        this.initials = initials;
    }
}
