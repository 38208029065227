import { EvoModel } from './evo-model';

export class OnboardingStep extends EvoModel {

	public name: string;
	public product: string;
	public status: string;
	public step: number;

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}

export enum OnboardingStepStatus {
	IN_PROGRESS = 'IN_PROGRESS',
	COMPLETED = 'COMPLETE'
}

export enum OnboadingStepNames {
	FIRST_STEPS = 'FIRST_STEPS'
}
