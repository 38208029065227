<div class="modal-header">
    <h4 class="modal-title" id="modal-title">Acesso ao Training</h4>
    <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
        (click)="modal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="padding-top: 0px;">
	<p class="font-weight-bold">
		Identificamos que você ainda não concluiu a configuração da sua conta, está faltando um ou mais campos serem preenchidos.
		Você precisa concluir a configuração antes de prosseguir.
	</p>

	<div class="my-2 text-danger" *ngFor="let error of trainningErros">
		<i class="fas fa-times-circle"></i>
		{{ error }}
	</div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="modal.close(false)">Cancelar</button>
    <a type="button" class="btn btn-primary text-white" href="{{ idUrl }}" target="_blank" (click)="modal.close();">Configurar conta</a>
</div>
