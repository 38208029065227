import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'evo-confirm-danger-dialog',
  templateUrl: './confirm-danger-dialog.component.html',
  styleUrls: ['./confirm-danger-dialog.component.scss']
})
export class ConfirmDangerDialogComponent {
  @Input() title: string = "Confirmar";
  @Input() confirmText: string = "Confirmar";
  @Input() text: string;

  constructor(public modal: NgbActiveModal) {
  }
}