import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { GenAiPromptRequest } from "../_models/gen-ai-prompt.request";
import { catchError, map } from "rxjs/operators";
import { Operation, Segment } from "@app/models";

@Injectable({ providedIn: 'root' })
export class GenAiChatService {

	public segments: Segment[];
	public operations: Operation[];

	constructor(private http: HttpClient) { }

	public askQuestion(genAiPromptRequest: GenAiPromptRequest): Observable<any> {
		let headers = new HttpHeaders({
			'Accept': 'application/json, text/plain'
		});

		return this.http.post(`${environment.genAiUrl}/api/v1/messages/completion`, genAiPromptRequest, { headers, observe: 'response', responseType: 'text'}).
			pipe(
				map(this.handleSuccess),
				catchError(this.handleError)
			);
	}

	public sendPromptToKnowledgeBase(genAiPromptRequest: GenAiPromptRequest): Observable<any> {
		let headers = new HttpHeaders({
			'Accept': 'application/json, text/plain'
		});

		return this.http.post(`${environment.genAiUrl}/api/v1/messages/knowledgeBase`, genAiPromptRequest, { headers, observe: 'response', responseType: 'text' })
			.pipe(
				map(this.handleSuccess),
				catchError(this.handleError)
			);
	}

	private handleSuccess(response: HttpResponse<string>) {
		const contentType = response.headers.get('Content-Type');
		if (response.status === 200) {
			if (contentType && contentType.includes('text/plain')) {
				return response.body;
			} else if (contentType && contentType.includes('application/json')) {
				try {
					return JSON.parse(response.body);
				} catch (e) {
					return response.body;
				}
			} else {
				throw new Error('Unexpected content type');
			}
		} else {
			throw new Error('Unexpected response status');
		}
	}

	private handleError(error: any) {
		let errorMessage: any;
		if (error instanceof HttpErrorResponse) {
			if (error.error instanceof ErrorEvent) {
				errorMessage = error.error.message;
			} else {
				try {
					errorMessage = JSON.parse(error.error);
				} catch (e) {
					errorMessage = error.error;
				}
			}
		} else if (typeof error === 'string') {
			errorMessage = error;
		} else {
			errorMessage = 'An unknown error occurred';
		}
		return throwError(errorMessage);
	}
}
