import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { InteractionService } from '../../../../_services/interaction.service';
import { ToastService } from '../../../../_services/toast.service';
import { BucketService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-contestation-declined-button',
  templateUrl: './contestation-declined-button.component.html',
  styleUrls: ['./contestation-declined-button.component.scss']
})
export class ContestationDeclinedButtonComponent extends InteractionContestationButton implements OnInit {

  constructor(ngbModal: NgbModal, protected activeModal: NgbActiveModal, toastService: ToastService, formBuilder: FormBuilder, public interactionService: InteractionService) {
    super(ngbModal, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
  }

}
