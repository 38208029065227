import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { BucketService, InteractionService, ToastService } from '@app/services';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
	selector: 'evo-edit-conforming-button',
	templateUrl: './edit-conforming-button.component.html',
	styleUrls: ['./edit-conforming-button.component.scss']
})
export class EditConformingButtonComponent extends InteractionContestationButton implements OnInit {

	@ViewChild('modal', {static: true}) protected modalItem: any;

	constructor(modalService: NgbModal,
	            activeModal: NgbActiveModal,
	            toastService: ToastService,
	            formBuilder: FormBuilder,
	            public interactionService: InteractionService) {
		super(modalService, activeModal, toastService, formBuilder);
	}

	ngOnInit(): void {
	}

	public confirmContestation(): void {
		this.saveContestation('CONFORMING_EDITED', 'Item contestado - Pendente envio.', null, { id: 'NONCONFORMING', name: 'Não Conforme'});
	}

	disableCtrlV($event): void {
		let charCode = String.fromCharCode($event.which).toLowerCase();

		if ($event.ctrlKey && charCode === 'v') {
			//$event.preventDefault();
		}
	}

	private resetUploadFile() {
		this.uploadProgress = 0;
		this.isLoading = false;
	}

}
