import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class AgentCacheService {
    agents: string[];
    agentOptions: any[] = [];

    hasAgents(): boolean {
        return this.agents && this.agents.length > 0;
    }

    addAgentOptions(options: any[]) {
        this.agentOptions = options;
    }

    checkAgentOptionsExist(id) {
        const options = new Set(this.agentOptions.map(option => option.id));
        return options.has(id);
    }
}
