<button class="btn btn-outline-success btn-sm" (click)="openModal()">Aceitar Réplica</button>

<ng-template #buttonModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Aceitar Réplica</h4>
		<button type="button" class="close" aria-label="Close button" aria-describedby="modal-title"
				(click)="modal.close(false)">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<form [formGroup]="contestationForm" class="modal-body" style="padding-top: 0px;">
		<div class="form-group">
			<label class="required">Comentário para a réplica procedente</label>
			<input class="form-control" formControlName="replicaAcceptComment" placeholder="Digite um comentário"/>
		</div>
	</form>
	<div class="modal-footer">
		<button type="button" [disabled]="isLoading" class="btn btn-outline-primary" (click)="modal.close(false)">
			<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
			Fechar
		</button>
		<button type="button" [disabled]="isLoading || contestationForm.invalid" class="btn btn-primary"
				(click)="acceptReplica(); modal.close();">
			<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
			Confirmar
		</button>
	</div>
</ng-template>
