import { EvoModel } from './evo-model';

export class JwtUser extends EvoModel {

	public id: number = 0;
	public username: string = '';
	public auth_time: number = 0;
	public client_id: string = '';
	public event_id: string = '';
	public exp: number = 0;
	public iat: number = 0;
	public iss: string = '';
	public jti: string = '';
	public origin_jti: string = '';
	public scope: string = '';
	public sub: string = '';
	public token_user: string = '';

	public deserialize(input: any): this {
		return super.deserialize(input);
	}
}
