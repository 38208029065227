import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InteractionContestationButton } from '../interaction-contestation-button';
import { InteractionService } from '../../../../_services/interaction.service';
import { Interaction } from '../../../../_models/interaction';
import { ToastService } from '../../../../_services/toast.service';
import { BucketService } from '@app/services';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'evo-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent extends InteractionContestationButton implements OnInit {

  @Input() interaction: Interaction;

  feedbackForm: FormGroup;

  constructor(modalService: NgbModal,
			  protected activeModal: NgbActiveModal,
              toastService: ToastService,
              protected formBuilder: FormBuilder,
              public interactionService: InteractionService) {
    super(modalService, activeModal, toastService, formBuilder);
  }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      feedback: ['', Validators.required]
    });
  }

  get feedback() {
    return this.feedbackForm.get('feedback');
  }

  finishFeedback(){
    if (this.feedbackForm.invalid) return null;
    this.actionCompleted.emit(this.feedbackForm.get('feedback').value);
  }

}
