export class ImportJobResponse {
	public importJobId: number;
	public errors: any[];
	public status: string;
	public type: string;

	public startDate: any;
	public endDate: any;
	public finishedStartDate: any;
	public finishedEndDate: any;
	public createdAt: any;

	public userName: string;
	public segmentName: string;
	public operationName: string;

	public totalReceived: number; // totalLines
	public totalImported: number; // totalCallbackReceived
}
