import { UserResponse } from "./user.response";
import { Customer } from "../customer";

export class PbiReportResponse {
	public id: number;
	public groupId: string;
	public reportId: string;
	public groupName: string;
	public reportName: string;
	public imageUrl: string;
	public description: string;
	public embeddedAccessToken: string;
	public customerDTO: Customer;
	public createdAt: string;
	public updatedAt: string;
	public updatedBy: UserResponse;
	public active: boolean;
	public permission: string;
}
