import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AvailableFlowConfigLink } from '@app/models';
import { Flow } from '@app/models';
import { PageResponse } from '@app/models';
import { BaseServiceClass } from './baseService.service';
import { environment } from '../../environments/environment';

const API_URL = environment.apiUrl;
@Injectable({ providedIn: 'root' })
export class FlowService extends BaseServiceClass<Flow> {
    constructor(protected httpService: HttpClient) {
        super(httpService, '/flowConfigurations');
    }

    createFlow(flow: Flow): Observable<Flow> {
        return this.httpService.post<Flow>(this.entity_url, flow);
    }

    listFlows(params): Observable<PageResponse<Flow>> {
        return this.httpService.get<PageResponse<Flow>>(this.entity_url, {params});
    }

    findByInteractionId(interactionId: number): Observable<Flow> {
        return this.httpService.get<Flow>(`${this.entity_url}/interaction/${interactionId}`);
    }

	findAvailablePair(segmentId: number, operationId: number): Observable<AvailableFlowConfigLink> {
		return this.httpService.get<AvailableFlowConfigLink>(`${this.entity_url}/available/segments/${segmentId}/operations/${operationId}`);
	}

	public filter(obj: any) {
		let newObj = {};
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const element = obj[key];
				if (element !== null) {
					newObj = { ...newObj, [key]: element }
				}
			}
		}
		let options = { params: <any>newObj };
		return this.httpService.get<PageResponse<Flow>>(`${this.entity_url}`, options);
	}

}
