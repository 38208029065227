import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';


class ToastModelOptions {
  className?: string;
  delayTime?: number = 5000;
  hideCloseButton?: boolean = false;
  allowUndoAction?: boolean = false;

  constructor(props) {
    if (props) { Object.assign(this, props); }
  }
}

class ToastModel extends ToastModelOptions {
  textOrTpl?: string | TemplateRef<any>;
  observer?: Subscriber<any>;

  constructor(props) {
    if (props) {
      super(props);
      Object.assign(this, props);
    }
  }
}

export enum SystemMessages {
	UNEXPECTED_ERROR = 'Ocorreu um erro. Por favor, tente novamente ou contate o administrador do seu sistema.',
	USER_LASTNAME_REQUIRED = 'USER_LASTNAME_REQUIRED',
	INTERACTION_UNBLOCK_ERROR = 'Não foi possível desbloquear a interação, por favor entre em contato.',
	INTERACTION_BLOCL_ERROR = 'Não foi possível bloquear a interação, por favor entre em contato.'
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastModel[] = [];

  // Callback method to remove Toast DOM element from view
  removeToast(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  cancelToastRequest(toast: any) {
    toast.observer.error();
    this.removeToast(toast);
  }

  // Push new Toasts to array with content and options
  showToast(textOrTpl: string | TemplateRef<any>, options: ToastModelOptions = {}): Observable<any> {
    const toastItem = new ToastModel({ textOrTpl, ...options });
    this.toasts.push(toastItem);

    if (toastItem.allowUndoAction) {
      return new Observable((observer) => {
        toastItem.observer = observer;
        setTimeout(() => observer.next(), toastItem.delayTime);
      });
    } else {
      return new Observable();
    }
  }

  showStandard(textOrTpl: string, options: ToastModelOptions = {}): Observable<any> {
    return this.showToast(textOrTpl, options);
  }

  showSuccess(textOrTpl: string, options: ToastModelOptions = {}): Observable<any> {
    return this.showToast(textOrTpl, {
      className: 'toast-success',
      ...options
    });
  }

  showWarning(textOrTpl: string, options: ToastModelOptions = {}): Observable<any> {
    return this.showToast(textOrTpl, {
      className: 'toast-warning',
      ...options
    });
  }

  showDanger(textOrTpl: string, options: ToastModelOptions = {}): Observable<any> {
    return this.showToast(textOrTpl, {
      className: 'toast-danger',
      ...options
    });
  }

  showLight(textOrTpl: string, options: ToastModelOptions = {}): Observable<any> {
    return this.showToast(textOrTpl, {
      className: 'toast-light',
      ...options
    });
  }
}
