import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { NgbAccordion, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { countDownTimerConfigModel, CountupTimerService, timerTexts } from 'ngx-timer';
import * as _ from 'underscore';
import { Interaction, InteractionStatus, Justification, JustificationFilter } from '@app/models';
import { User } from '@app/models';
import { MonitorInteractionItem } from '@app/models';
import { Checklist } from '@app/models';
import { Group } from '@app/models';
import { QualityPermissions } from '@app/models';
import { Flow } from '@app/models';
import { InteractionServiceV2, SystemMessages, UserService } from '@app/services';
import { InteractionService } from '@app/services';
import { ToastService } from '@app/services';
import { HttpClient } from '@angular/common/http';
import { ChecklistService } from '@app/services';
import { TimelineService } from '@app/services';
import { MonitorInteractionItemService } from '@app/services';
import { FlowService } from '@app/services';
import { saveAudioFile, saveFile } from '../../../_helpers/file-helper';
import { ChecklistItem } from '@app/models';
import { InteractionStatusEnum } from '../../../_helpers/interaction-status.enum';
import { ConfirmMonitorComponent } from '../../../_components/confirm-monitor/confirm-monitor.component';
import { ConfirmMonitor } from '@app/models';
import { MENU_PATHS } from '../../../_helpers/menu.helper';
import { ConfirmDialogComponent } from '../../../_components/confirm-dialog/confirm-dialog.component';
import { BusinessException } from 'app/_helpers/business-exception.enum';
import { NgxPermissionsService } from "ngx-permissions";
import { cloneDeep } from 'lodash';
import {
	InteractionAttachmentDialogComponent
} from '../../../_components/interaction-attachment-dialog/interaction-attachment-dialog.component';
import { InteractionAttachment } from '../../../_models/interaction-attachment';
import { ConfirmJustificationTypeComponent } from '../interaction-management/confirm-justification-type.component/confirm-justification-type.component';
import { ExternalSpeechService } from "../../../_services/external-speech.service";


@Component({
	selector: 'monitor-interaction',
	templateUrl: './monitor-interaction.component.html',
	styleUrls: ['./monitor-interaction.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class MonitorInteractionComponent implements OnInit {
	loading = false;
	currentUser: User;
	monitorItems: MonitorInteractionItem[] = [];
	checklist: Checklist;
	checklistGrouped: Checklist[];
	currentGroup: Group;
	timerValue: any;
	isAudio: boolean = false;
	formInvalid: boolean = false;
	flow: Flow;
	qualityPermissions = QualityPermissions;
	isLoading = false;
	sameUserAnalysis: boolean = false;
	audioLoaded: Promise<boolean>;

	public interactionAudioUrl: string;

	public lockablePermissions = [
		this.qualityPermissions.EVALUATE_INTERACTIONS,
		this.qualityPermissions.REVIEW_INTERACTIONS,
		this.qualityPermissions.CONTEST_EVALUATION,
		this.qualityPermissions.REPLY_REPLICA,
		this.qualityPermissions.APPLY_FEEDBACK,
		this.qualityPermissions.ALL_PERMISSIONS
	];

	@Input()
	public interaction: Interaction;

	@Output()
	public close: EventEmitter<any> = new EventEmitter();

	@Output()
	public firstLoadingDone: EventEmitter<any> = new EventEmitter<any>();

	public isAdmin: boolean;
	public isInternal: boolean;

	public get analysisInProgress(): boolean {
		return this.interaction.status.id == InteractionStatusEnum.IN_ANALYSIS;
	}

	public interactionMetadata: any;

	public get hasNoPermissions(): boolean {
		return _.isEmpty(this.ngxPermissionsService.getPermissions());
	}


	constructor(private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private interactionService: InteractionService,
		private interactionServiceV2: InteractionServiceV2,
		private externalSpeechService: ExternalSpeechService,
		private toastService: ToastService,
		private http: HttpClient,
		private countupTimerService: CountupTimerService,
		private checklistService: ChecklistService,
		private modalService: NgbModal,
		public timelineService: TimelineService,
		private monitorInteractionItemService: MonitorInteractionItemService,
		private router: Router,
		private flowService: FlowService,
		private ngxPermissionsService: NgxPermissionsService) {

		this.ngxPermissionsService.hasPermission([QualityPermissions.INTERNAL]).then(
			data => {
				this.isInternal = data;
			});

		this.ngxPermissionsService.hasPermission([QualityPermissions.ALL_PERMISSIONS]).then(
			data => {
				this.isAdmin = data;
			});
	}

	ngOnInit() {
		this.currentUser = this.userService.currentUser;

		if (this.interaction.analysisHistories.length > 0) {
			this.sameUserAnalysis = this.interaction.analysisHistories.find(item => item.active == true)?.startedBy.email == this.currentUser.email;
		}

		if (this.interaction.metadata) {
			this.interactionMetadata = Object.entries(this.interaction.metadata);
		}

		// check if string has .wav or .mp3
		if (this.interaction.fileInfo && this.interaction.fileInfo.originalFileName) {
			let fileExtension = '.' + this.interaction.fileInfo.originalFileName.toLowerCase().split('.').pop();
			const audioTypes = ['.wav', '.mp3', '.mp4', '.wmv', '.g'];
			this.isAudio = audioTypes.includes(fileExtension);
		}

		// this.isAudio = this.interaction.fileInfo && (this.interaction.fileInfo?.type === null) || this.interaction.fileInfo?.type.includes('audio');

		this.getMonitorItems(this.interaction.id);

		this.getAudioUrl();

		this.flowService.findByInteractionId(this.interaction.id).subscribe(response => {
			this.flow = response;
		}, error => {
			if ((error?.error?.error != null || error?.error?.error != undefined) && BusinessException[error?.error?.error] != null) {
				this.toastService.showWarning(BusinessException[error?.error?.error]);
			} else {
				this.toastService.showDanger(SystemMessages.UNEXPECTED_ERROR);
			}
		});

		this.timerValue = new countDownTimerConfigModel();
		this.timerValue.timerTexts = new timerTexts();
		this.timerValue.timerTexts.hourText = "h"; //default - hh
		this.timerValue.timerTexts.minuteText = "m"; //default - mm
		this.timerValue.timerTexts.secondsText = "s"; //default - ss

		this.countupTimerService.startTimer(new Date());
		this.firstLoadingDone.emit();

		this.interactionAudioUrl = this.interaction.audioUrl;

		console.log(this.interaction);

		// this.externalSpeechService.getSpeechInteractionById()
	}

	updateInteractionStatus(): void {

		if (this.interaction.lastStatusHistory.id == InteractionStatusEnum.CONTESTED || this.interaction.lastStatusHistory.id == InteractionStatusEnum.REVISION || this.interaction.lastStatusHistory.id == InteractionStatusEnum.REPLICA_SENDED || this.interaction.lastStatusHistory.id == InteractionStatusEnum.MANUAL_MONITOR || this.interaction.lastStatusHistory.id == InteractionStatusEnum.FEEDBACK) {

			this.ngxPermissionsService.hasPermission([QualityPermissions.INTERNAL]).then(
				result => {
					if (result) {
						this.interactionServiceV2.interactionInAnalysis(this.interaction.id, true).subscribe(
							data => {
								this.interaction = data;
								this.toastService.showSuccess('Análise iniciada, outros usuários não podem fazer alterações nessa interação.');

								this.interaction.analysisHistories = [...this.interaction.analysisHistories, {
									id: 0, interactionPreviousStatus: this.interaction.status, active: true, startedBy: this.currentUser
								}];

								this.sameUserAnalysis = true;
							}, error => {
								console.error(error);
							});
					}
				});
		}
	}

	downloadFile() {
		if (this.isAudio) {
			this.http.get(this.interactionAudioUrl, { responseType: 'blob' }).subscribe(response => {
				saveAudioFile(response, this.interaction.originalFileName);
			});
		} else {
			this.interactionService.downloadFile(this.interaction.fileInfo.id).subscribe(
				(data) => saveFile(data, this.interaction.fileInfo.originalFileName, this.interaction.fileInfo.type));

		}
	}

	getAudioUrl() {
		/*this.interactionService.findAudio(this.interaction.id).subscribe(response => {
			if(response.data){
				this.audioLoaded = Promise.resolve(true);
				this.interaction.audioUrl = response.data.replace("http://", "https://");
			}

		});*/
	}

	sumGroupGrade(item: ChecklistItem) {
		let totalGrade = 0;
		let actualGrade = 0;
		for (let monitorItem of this.monitorItems) {
			if (monitorItem.itensChecklist.group.id == item.group.id) {
				if (monitorItem.answer.id == 'CONFORMING') {
					actualGrade += monitorItem.peso;
				}
				totalGrade += monitorItem.peso;
			}
		}
		return actualGrade + "/" + totalGrade;
	}

	grabChecklist() {
		let response = this.interaction.checklist;
		response.itensChecklist = this.interaction.checklist.itensChecklist.filter(item => item.active);

		if (response && response.itensChecklist) {
			response.itensChecklist = response.itensChecklist.filter(item => {
				for (let monitorItem of this.monitorItems) {
					if (monitorItem.itensChecklist.id == item.id) {
						return false;
					}
				}
				return true;
			});
			this.checklist = new Checklist().deserialize(response);
			this.checklist.itensChecklist = this.checklist.itensChecklist.map(item => {
				if (!item.answer || item.answer == '') {
					item.answer = 'CONFORMING'
				}

				return new ChecklistItem().deserialize(item)
			});
			this.checklist.itensChecklist = _.sortBy(this.checklist.itensChecklist, (obj) => {
				if (obj.group) return obj.group.id;

				return obj.name;
			});
			this.checklistGrouped = this.getChecklistByGroup();
			this.updateInteractionStatus();
		} else {
			this.toastService.showWarning("Nenhuma checklist cadastrada para este segmento.");
		}
	}

	getMonitorItems(interactionId: number) {
		this.monitorInteractionItemService.findByInteraction(interactionId).subscribe(response => {
			response = _.sortBy(response, (obj) => {
				if (obj.itensChecklist.group) return obj.itensChecklist.group.id;

				return obj.itensChecklist.name;
			});
			console.debug("monitor items", response);
			this.monitorItems = response.map(item => new MonitorInteractionItem().deserialize(item));
			this.grabChecklist();
		});
	}

	getGroupName(group) {
		if (!this.currentGroup || this.currentGroup.id != group.id) {
			this.currentGroup = group;
			if (this.currentGroup) return this.currentGroup.name;
		}
		return "";

	}

	getChecklistByGroup() {
		const groups = Array.from(new Set(this.checklist.itensChecklist.map(item => item.group.name)));
		return this.checklist.itensChecklist.reduce((acc, item) => {
			const index = groups.indexOf(item.group.name);
			acc[index] = [...acc[index], item];
			return acc;
		}, new Array(groups.length).fill([]));
	}

	getGroupElementsIds() {
		return this.checklistGrouped.map((group, idx) => 'group' + idx).join(',');
	}

	onSubmit() {
		this.formInvalid = false;
		this.countupTimerService.pauseTimer();
		let grade: number = 100;

		let monitorItems: MonitorInteractionItem[] = [];

		this.checklist.itensChecklist.forEach(item => {
			item.answerTouched = true;
			if ((!item.answer || item.answer == '')) {
				this.formInvalid = true;
			}
			if (item.answer == 'NONCONFORMING') {
				item.evaluationGrade = item.peso;
				grade = grade - item.evaluationGrade;
			}

			if (grade < 0) grade = 0;

			let monitorItem = new MonitorInteractionItem();
			monitorItem.answer = item.answer;
			monitorItem.customer = this.checklist.customer;
			monitorItem.explanation = item.explanation;
			monitorItem.interaction = new Interaction();
			monitorItem.interaction.id = this.interaction.id;
			monitorItem.itensChecklist = item;
			if (item.justification) {
				monitorItem.justification = item.justification;
			}

			monitorItems.push(monitorItem);

		});

		let monitorInteraction = cloneDeep(this.interaction);

		let timer = this.countupTimerService.timerValue;
		monitorInteraction.monitorDuration = timer.hours + ":" + timer.mins + ":" + timer.seconds;
		monitorInteraction.monitorDate = new Date();
		monitorInteraction.grade = grade;
		monitorInteraction.status.id = InteractionStatusEnum.EVALUATION.toString();
		monitorInteraction.evaluation = monitorItems;

		if (!this.formInvalid) {
			this.ConfirmDiscardJustificationComponent(monitorInteraction, monitorItems);
		}
	}


	ConfirmDiscardJustificationComponent(monitorInteraction: Interaction, monitorItems: MonitorInteractionItem[]) {
		this.countupTimerService.pauseTimer();

		const modalRef = this.modalService.open(ConfirmMonitorComponent, {});
		let idMonitoria = this.interaction.id;
		let grade = monitorInteraction.grade;
		modalRef.componentInstance.text = `Seu tempo de monitoria foi de ${monitorInteraction.monitorDuration}.<br/> Gostaria de finalizar a monitoria #${idMonitoria} com a nota ${grade}?`;
		modalRef.componentInstance.title = "Finalizar Monitoria";
		modalRef.result.then((data: ConfirmMonitor) => {
			if (data.closeModal) {
				monitorInteraction.status.id = InteractionStatusEnum.EVALUATION.toString();
				monitorInteraction.inAnalysis = false;
				this.loading = true;
				this.interactionService.evaluation(monitorInteraction).subscribe(response => {
					this.interaction.status = { id: InteractionStatusEnum.EVALUATION, name: 'Avaliada' } as InteractionStatus;
					this.interaction.grade = monitorInteraction.grade;

					this.toastService.showSuccess("Monitoria realizada com sucesso");
					// this.router.navigate([MENU_PATHS.INTERACTIONS]);
					this.closeHandler();
				},
					error => this.toastService.showDanger("Erro ao finalizar monitoria")).add(() => this.loading = false);
			} else {
				this.countupTimerService.startTimer();
			}
		});
	}

	public unblockInteraction(): void {
		this.isLoading = true;

		this.interactionServiceV2.interactionInAnalysis(this.interaction.id, false).subscribe(
			data => {
				this.interaction = data;
				this.toastService.showSuccess('Interação foi liberada para outros usuários.');
				this.closeHandler()
			}, error => {
				this.toastService.showDanger(SystemMessages.INTERACTION_UNBLOCK_ERROR);
				console.error(error);
			}
		).add(() => {
			this.isLoading = false;
		});
	}

	discardMonitor() {
		const modalRef = this.modalService.open(ConfirmJustificationTypeComponent, {});
		modalRef.componentInstance.justificationFilter = { text: '', type: ['DISCARD'] } as JustificationFilter;
		modalRef.result.then((data) => {
			if (data) {
				this.loading = true;
				this.interaction.status.id = InteractionStatusEnum.DISCARDED.toString();
				this.interaction.status.name = 'Descartada';
				this.interaction.deadLine = null;
				this.interaction.inAnalysis = false;
				let justification = new Justification();
				justification = data;
				this.interaction.justification = justification;
				this.interactionService.patch(this.interaction).subscribe(response => {
					console.debug("success");
					this.toastService.showSuccess("Monitoria descartada com sucesso");
					//this.router.navigate([MENU_PATHS.INTERACTIONS]);
					this.closeHandler();
				},
					error => this.toastService.showDanger("Erro ao descartar monitoria")).add(() => this.loading = false);
			} else {
				this.countupTimerService.startTimer();
			}
		});
	}

	copyToClipboard(ev, interaction: Interaction) {
		ev.stopPropagation();
		navigator.clipboard.writeText(interaction.fileInfo?.originalFileName || interaction.fileInfo?.fileName)
			.then(() => this.toastService.showSuccess('Nome do Arquivo copiado para o seu clipboard.'))
			.catch(e => console.error(e));
	}

	public validateConforming(event, accordion: NgbAccordion, id): void {
		if (event.value == 'NONCONFORMING') {
			accordion.expand(id);
		} else {
			accordion.collapse(id);
		}
	}

	public isExplanationInvalid(): boolean {
		let isInvalid = false;

		this.checklist.itensChecklist.some(item => {
			if (item.explanation) {
				isInvalid = item.explanation.length > 2000;
				return isInvalid;
			}

			return false;
		});

		return isInvalid;
	}

	public openInteractionAttachmentModal(file: any): void {
		let modalRef = this.modalService.open(InteractionAttachmentDialogComponent, { size: 'lg' });
		modalRef.componentInstance.attachment = file;
	}

	attachmentType(file: InteractionAttachment): string {
		const audioTypes = ['wav', 'mp3', 'mp4', 'wmv', 'g'];
		if (audioTypes.includes(file.type)) {
			return 'fa-file-audio';
		}

		const imageTypes = ['jpg', 'png'];
		if (imageTypes.includes(file.type)) {
			return 'fa-file-image';
		}

		return 'fa-file';
	}

	public closeHandler(): void {
		this.close.emit(this.interaction);
	}
}
