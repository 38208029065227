export class IntegrationResponse {
	public id: string;
	public name: string;
	public label: string;
	public about: string;
	public iconUrl: string;
	public uriPath: string;
	public pageTitle: string;
	public pageDescription: string;
	public isPublic: boolean;
	public content: { cards: any[], faq: any[] };
	public product: string;
	public active: boolean;

	public code: string;
}

export enum IntegrationCode {
	SPEECH = 'SPEECH'
}
