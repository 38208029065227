import { Deserializable } from './deserializable';

export class Transcription implements Deserializable {
    id: number;
	agent:string;
	agentInverted:string;
	client:string;
	clientInverted:string;
	transcriptionAll:string;
	transcriptionAllInverted:string;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}