<div class="training-list-page">
    <div class="row align-items-center">
        <div class="col-sm">
            <div class="page-header-title">Treinamentos</div>
        </div>
        <div class="col-sm-auto">
            <!-- <ng-template
                [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.CREATE_TRAINING]">
                <div ngbDropdown>
                    <button [disabled]="loading"
                            class="btn btn-primary d-flex align-items-center"
                            (click)="openHelpDialog()">
                        <span>Suporte</span>
                    </button>
                </div>
            </ng-template> -->
        </div>
    </div>

    <div class="no-content">
		<div class="mt-2 d-flex flex-column align-items-center">
			<img src="../../../assets/images/box-no-content.png"/>
			<h5 class="mt-2">Parece que sua organização não adicionou nenhum treinamento.</h5>
			<p>Entre em contato e solicite um treinamento.</p>
			<button [disabled]="loading"
			        class="btn btn-primary d-flex align-items-center"
			        (click)="openHelpDialog()">
				<img class="mr-2" src="/assets/icons/helpdesk_white.svg" width="18" height="18"/>
				<span>Suporte</span>
			</button>

            <!-- <ng-template
            [ngxPermissionsOnly]="[qualityPermissions.ALL_PERMISSIONS, qualityPermissions.CREATE_TRAINING]">
            <div ngbDropdown>
                <button [disabled]="loading"
                        class="btn btn-primary dropdown-toggle d-flex align-items-center"
                        (click)="addTraining()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    <span>Adicionar treinamento</span>
                </button>
            </div>
        </ng-template> -->
		</div>
	</div>
</div>
