<div class="modal-header">
	<h5 class="modal-title">Adicionar Operação | <span class="text-muted" style="font-size: 75%">{{ segment.name }}</span></h5>
	<button type="button" class="close" aria-label="Close" aria-describedby="modal-title" (click)="confirmClose()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">

	<div class="d-flex">

		<div class="" style="flex: 1">
			<div class="search-div">
				<input class="search-input" [(ngModel)]="searchTerm" placeholder="Procurar" (ngModelChange)="filterOperationsBySearchTerm()"/>
				<i class="fa fa-search"></i>
			</div>

			<div class="operation-list">
				<div class="list">
					<div class="operation-item mb-2" *ngFor="let operation of filteredOperations; index as i">
						<div class="d-flex align-items-center">
							<div class="circle" [ngStyle]="{'background-color':  '#1839B4' }">
								<div class="initials initials-default-color">{{ getInitials(operation) | uppercase }}</div>
							</div>
							<div class="d-flex flex-column">
								<span class="operation-name">{{ operation.name }}</span>
							</div>

						</div>

						<div class="p-2" *ngIf="isLoading">
							<span class="spinner-border spinner-border-sm mr-1"></span>
						</div>

						<div class="btn btn-delete" (click)="addOperation(operation)" *ngIf="!isLoading">
							<i class="fas fa-arrow-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="ml-3 pl-3 border-left" style="flex: 1">

			<div class="mb-4 d-flex">
				<input class="form-control mr-3" [(ngModel)]="newOperationName" placeholder="Nova operação" style="height: 38px;"/>

				<button class="btn btn-primary" (click)="postOperation()" [disabled]="newOperationName.length < 1">Adicionar</button>
			</div>

			<div class="operation-list-status mb-3">
				Operações vinculadas
			</div>

			<div class="operation-list">
				<div class="list" style="height: 263px;">
					<div class="d-flex justify-content-between mb-2" *ngFor="let operation of selectedOperations; index as i">
						<div class="d-flex align-items-center">
							<div class="circle" [ngStyle]="{'background-color':  '#1839B4' }">
								<div class="initials initials-default-color">{{ getInitials(operation) | uppercase }}</div>

							</div>
							<div class="d-flex flex-column">
								<span class="operation-name">{{ operation?.name }}</span>
							</div>
						</div>

						<div class="btn btn-delete" (click)="removeOperation(operation)">
							<i class="fas fa-trash"></i>
						</div>
					</div>
				</div>
			</div>

		</div>

	</div>

</div>

<div class="modal-footer justify-content-end">
	<button type="button" class="btn btn-primary" [disabled]="isLoading" (click)="modal.close()">
		<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
		Fechar
	</button>
</div>
