export class Flow {
    name: string;
    contestation: boolean;
    contestationDeadline: number;
    replica: boolean;
    replicaDeadline: number;
    feedback: boolean;
    feedbackDeadline: number;
    businessDays: string[];
    segmentsOperationsFlow: {segmentId: number, operationId: number}[];
    holidays: {day: string, month: string, year: string}[];
    customerId: number;
    revision: boolean;
}