<div class="evo-switch">
	<div class="switch-header">
		<span>Alterar para</span>

		<button (click)="onSwitchOrganizations()" *ngIf="showOrganizationButton || isInternal">
			Organizações
		</button>
	</div>

	<div class="switch-products">
		<div class="switch-product" *ngFor="let product of products">
			<div class="switch-button" *ngIf="product.alias !== 'ADMIN'">
				<a class="d-flex align-items-center" style="width: 100%" [href]="product.accessUrl">
					<div class="evo-product" [ngClass]="product.alias"></div>
					<span>{{product.name}}</span>
				</a>

				<div class="product-more" style="min-width: 100px" (click)="product.isOpen = !product.isOpen; $event.stopPropagation()">
					<i class="fas ml-auto" [ngClass]="{'fa-chevron-up': product.isOpen, 'fa-chevron-down': !product.isOpen}"></i>
				</div>

			</div>

			<div class="product-organizations" [ngClass]="{'is-open': product.isOpen}" *ngIf="product.organizations">
				<a class="switch-button" *ngFor="let organization of product.organizations" [href]="product.accessUrl + '/organizations?organization=' + organization.alias">
					<div class="logo" *ngIf="organization.logoUrl" [ngStyle]="{'background-image' : 'url('+ organization.logoUrl +')'}"></div>
					<div class="logo no-logo" *ngIf="!organization.logoUrl">
						{{ organization.name[0] }}
					</div>
					<span>{{ organization.name }}</span>

					<!-- <i class="fas fa-cog"></i> -->
				</a>
			</div>
		</div>
		<div class="switch-product" *ngIf="showTraining">
			<div class="switch-button">
				<a class="d-flex align-items-center" style="width: 100%" [href]="trainingUrl" target="_blank">
					<div class="evo-product TRAINING"></div>
					<span>Training</span>
				</a>
			</div>
		</div>
	</div>

	<div class="switch-footer">

		<a class="switch-button" *ngIf="showAdmin || isInternal" [href]="getAdminUrl()">
			<i class="fas fa-cog"></i>
			<span class="">Administração da organização</span>
		</a>
	</div>
</div>
