import { Deserializable } from './deserializable';
import { Operation } from './operation';
import { Segment } from './segment';
import { State } from './state';

export class City implements Deserializable {
    id: number;
	codIBGE: number;
	name: string;
	state: State;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
}