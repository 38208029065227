import { FormGroup } from '@angular/forms';
import { Customer } from './customer';
import { Deserializable } from './deserializable';
import { MonitorInteractionItem } from './monitor-interaction-item';
import { User } from './user';
import { FileInfo } from './fileInfo';

export class Contestation implements Deserializable {
    id: number;
    explanation: string;
    registerDate: Date;
    status: any;
    minuteSelected: string;
    user: User;

    monitorInteractionItem?: MonitorInteractionItem;

    fileInfo: FileInfo;

    public file?: File;

    deserialize(input: any): this {
        Object.assign(this, input);
        return this;
    }
    
}
