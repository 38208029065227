<div class="evo-card" [ngClass]="{ 'is-done' : isDone }">

	<div class="evo-card-header">
		<div class="order">
			<span class="" *ngIf="!isDone">{{ order }}</span>
			<i class="fas fa-check" *ngIf="isDone"></i>
		</div>

		<div class="title">
			<span class="">{{ title }}</span>

			<div class="header-action" (click)="showBody = !showBody" *ngIf="!isDisabled">
				<i class="fas fa-chevron-up" [ngClass]="{'fa-chevron-up': showBody, 'fa-chevron-down' : !showBody}"></i>
			</div>
		</div>
	</div>

	<div class="evo-card-header-description">
		<span class="">{{ description }}</span>
	</div>

	<div class="evo-card-body" [ngClass]="{'hide' : !showBody}">
		<ng-content select="[slot=card-body]"></ng-content>
	</div>

	<div class="evo-card-action" *ngIf="showBody && showActions">
		<button class="btn btn-link" (click)="cancel()" [disabled]="isLoading || isDisabled">Cancelar</button>
		<button class="btn btn-outline-primary" (click)="save()" [disabled]="isLoading || isDisabled">
			<span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1 mt-auto mb-auto"></span>
			Salvar alterações
		</button>
	</div>

</div>
