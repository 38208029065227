import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BaseServiceClass } from './baseService.service';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ProductService extends BaseServiceClass<any> {

	constructor(private http: HttpClient, private router: Router, @Inject(DOCUMENT) private document: Document) {
		super(http, '/api/v1/products');

		this.entity_url = environment.accountUrl + '/api/v1/products';
	}
}

export enum Products {
	ADMIN = <any>'Administrador',
	QUALITY = <any>'Quality'
}
