import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CacheService {
    private cache: Map<string, { response: HttpResponse<any>; expiry: number }> = new Map<string, { response: HttpResponse<any>; expiry: number }>();

    get(url: string) {
        return this.cache.get(url);
    }

    set(url: string, response: HttpResponse<any>, expiry: number) {
        this.cache.set(url, { response, expiry });
    }

    clear(url?: string) {
        if (url) {
            this.cache.delete(url);
        } else {
            this.cache.clear();
        }
    }
}
