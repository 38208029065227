import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'custom-modal',
    templateUrl: './custom-modal.component.html',
    styleUrls: ['./custom-modal.component.scss']
})
export class ModalComponent {
    @Input()
    title: string = '';
    @Output()
    action = new EventEmitter<any>();

    constructor(public modal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    emitAction() {
        console.debug("emitting edit event");
        this.action.emit();
    }
}
