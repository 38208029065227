import { Injectable } from '@angular/core';
import { TimelineItem } from '../../_components/timeline-list/timeline-list.component';
import { MonitorInteractionItem } from '@app/models';

@Injectable({providedIn: 'root'})
export class TimelineService {

  getTimelineItemFromMonitorInteraction(monitorInteraction: MonitorInteractionItem): TimelineItem[] {
    const timelineItems: TimelineItem[] = [];

    // Explanation
    if (monitorInteraction.explanation) {
      timelineItems.push({
        date: monitorInteraction.registerDate,
        title: monitorInteraction.userMonitor?.name || monitorInteraction.interaction.userMonitor?.name || '',
        content: monitorInteraction.explanation
      });
    }
    // Contestations
    if (monitorInteraction.contestations && monitorInteraction.contestations.length > 0) {
      monitorInteraction.contestations.forEach(contestation => {
        timelineItems.push({
          date: contestation.registerDate,
          title: contestation.user.name,
          content: contestation.explanation,
          status: contestation.status.name,
          minuteSelected: this.maskMinuteSelected(contestation.minuteSelected),
          fileInfo: contestation.fileInfo,
          file: contestation.file
        });
      });
    }

    return timelineItems;
  }

  maskMinuteSelected(time): string {
    if (time) {
      return time.slice(0, 2) + ':' + time.slice(2, 4) + ':' + time.slice(4, 6);
    }
    return null;
  }

}
