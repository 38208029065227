import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EvoAIModels, Interaction, InteractionFilter, Operation, QualityPermissions, Segment } from "@app/models";
import { BehaviorSubject, of, Subscription } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { InteractionMetadataService } from "../../../_services/interaction-metadata.service";
import { InteractionService, InteractionServiceV2, SegmentService } from "@app/services";
import { OperationServiceV2 } from "../../../_services/v2/operation.service";
import { GenAiChatService } from "../../../_services/gen-ai-chat.service";
import { subDays } from "date-fns";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
	selector: 'app-ai-chat-filters',
	templateUrl: './ai-chat-filters.component.html',
	styleUrls: ['../ai-chat.component.scss', './ai-chat-filters.component.scss']
})
export class AiChatFiltersComponent implements OnInit, OnDestroy {

	@Output() public closeFilter: EventEmitter<any> = new EventEmitter<any>();
	@Input() filters: FormGroup;
	@Input() public showFilter: boolean = false;
	@Input() public interaction: Interaction;

	public get segments(): Segment[] {
		return this.genAiChatService.segments;
	}
	public get operations(): Operation[]  {
		return this.genAiChatService.operations;
	};

	selectMultipleMaxLabels = 2;
	sub = new Subscription();

	// Metadata
	public parsedMetadata: { [key: string]: any[] } = {};
	private fullMetadata: { [key: string]: any[] } = {};
	public searchMetadata$ = new BehaviorSubject('');
	public searchMetadataKey$ = new BehaviorSubject('');
	public currOffset = 0;
	public metadataLastPage: any[] = [];
	public canSearchMetadata = new BehaviorSubject(false);
	public canShowMetadata = new BehaviorSubject(false);

	public aiModels: { label: string, value: string }[] = [
		{ label: 'Claude Sonnet V3.5', value: EvoAIModels.AWS_CLAUDE_SONNET_V3_5 },
	];

	public floor: number = 0;
	public ceil: number = 1;

	public qualityPermissions = QualityPermissions;

	public get operationsFilter() {
		return this.filters.get('operations');
	}

	public get metadataFilter() {
		return this.filters.get('metadata');
	}

	constructor(private interactionMetadataService: InteractionMetadataService,
	            private segmentService: SegmentService,
				private operationServiceV2: OperationServiceV2,
				private genAiChatService: GenAiChatService,
				private interactionService: InteractionServiceV2,
	            private changeDetection: ChangeDetectorRef,
	            private fb: FormBuilder) {
	}

	ngOnInit(): void {
		if(this.hasFilter('segments')) {
			this.sub.add(this.filters.get('segments').valueChanges.subscribe(segments => {
				this.operationsFilter.setValue([]);
				this.filterOperations();
				this.getMetadataFilters({ segmentId: this.filters.get('segments').value, operationId: null });

				this.canSearchMetadata.next(true);
			}));
		}

		if(this.hasFilter('operations')) {
			this.sub.add(this.filters.get('operations').valueChanges.subscribe(operations => {
			}));
		}

		if(this.hasFilter('metadata')) {
			this.sub.add(this.canSearchMetadata.subscribe(item => {
				if(!item) {
					this.filters.get('metadata').reset();

					for (const key of Object.keys(this.parsedMetadata)) {
						const control = this.filters.get(`metadata.${key}`);
						if (control) {
							control.disable();
						}
					}

				}
			}));
		}

		if (this.hasFilter('segments') && this.hasFilter('operations')) {
			this.filterSegments().add(() => this.filterOperations());
		}

		// calculando data padrão
		/*if(this.hasFilter('contactDate')) {
			this.interactionService.getLatestContactedAt().subscribe(
				data => {
					let lastDate = new Date(data);
					const subtractedDay = subDays(lastDate, 30);

					let toDate = new NgbDate(lastDate.getFullYear(), lastDate.getMonth(), lastDate.getDate());
					let fromDate = new NgbDate(subtractedDay.getFullYear(), subtractedDay.getMonth(), subtractedDay.getDate());

					this.filters.get('contactDate').setValue([new Date(fromDate.year, fromDate.month, fromDate.day), new Date(toDate.year, toDate.month, toDate.day)]);
				}, error => {
					console.error('Não foi possível pegar as últimas datas de contato.');
				}
			);
		}*/

		this.searchMetadata$.asObservable().pipe(
			filter((searchTerm: string) => searchTerm && searchTerm.length > 2),
			debounceTime(350),
			tap(() => this.currOffset = 0),
			switchMap((searchTerm: string) => {
				let filter: any = {
					segmentId: this.filters.get('segments').value,
					operationId: this.filters.get('operations').value,
					linesPerPage: 30,
					page: 0,
					metadataKey: this.searchMetadataKey$.value,
					metadataValue: this.searchMetadata$.value
				}

				return this.interactionMetadataService.getInteractionMetadataValues(filter);
			})
		).subscribe((metadata) => {
			this.fullMetadata[this.searchMetadataKey$.value] = metadata.content;
			this.currOffset = this.currOffset + 1;
			this.metadataLastPage[this.searchMetadataKey$.value] = metadata.last;
		});

		if(!this.interaction) {
			this.getMetadataFilters();
		}
	}

	public close(): void {
		this.showFilter = false;
		this.closeFilter.emit();
	}

	private getMetadataFilters(filter?: { segmentId: number[], operationId: number[]}): void {
		this.interactionMetadataService.getInteractionMetadataInitialValues(filter).subscribe(
			data => {
				if(this.parsedMetadata && Object.keys(this.parsedMetadata).length == 0) {
					this.parsedMetadata = data;

					const metadataGroup = this.fb.group({});

					for (const key of Object.keys(this.parsedMetadata)) {
						metadataGroup.addControl(key, this.fb.control({ value: null, disabled: true }));
					}

					this.filters.setControl('metadata', metadataGroup);
					this.changeDetection.detectChanges();
					return;
				}

				for (const key of Object.keys(this.parsedMetadata)) {
					this.parsedMetadata[key] = data[key];
					this.metadataLastPage[key] = true;
					// Check if the control exists before attempting to update it
					const control = this.filters.get(`metadata.${key}`);

					if (control) {
						control.enable();
					}
				}
			});
	}

	public nextMetadataBatch(): void {
		this.interactionMetadataService.getInteractionMetadataValues({
			segmentId: this.filters.get('segments').value,
			operationId: this.filters.get('operations').value,
			linesPerPage: 30,
			page: this.currOffset,
			metadataKey: this.searchMetadataKey$.value,
			metadataValue: this.searchMetadata$.value
		}).subscribe(metadata => {
			this.fullMetadata[this.searchMetadataKey$.value] = metadata.content;
			this.currOffset = this.currOffset + 1;
			this.metadataLastPage[this.searchMetadataKey$.value] = metadata.last;
		});
	}

	public searchMetadata(innerTag: string, term: string): void {
		if(this.canSearchMetadata) {
			this.searchMetadataKey$.next(innerTag);
			this.searchMetadata$.next(term.length > 0 ? term : null);
		}
	}

	filterSegments() {
		return this.segmentService.filter({}).subscribe(response => {
			this.genAiChatService.segments = response.content.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
		});
	}

	filterOperations() {
		const segments = this.filters.value.segments;
		segments && segments.length > 0 ? this.operationServiceV2.getOperations(this.filters.value.segments).subscribe(response => {
			this.genAiChatService.operations = response.sort((a, b) => {
				return a.name.localeCompare(b.name);
			});
			this.operationsFilter.setValue(this.operations.map(o => o.id));
		}) : [];
	}

	onSelectSegmentsAll() {
		this.filters.get('segments').setValue(this.segments.map(seg => seg.id));
		// this.filterOperations();
	}

	onSelectOperationsAll() {
		this.operationsFilter.setValue(this.operations.map(op => op.id));
	}

	// Helpers
	public hasFilter(filterName: string): boolean {
		return !!this.filters.get(filterName);
	}

	getSegmentLabelByValue(value) {
		const item = this.segments.find(item => item.speechEnvironmentId == value.speechEnvironmentId);
		return item ? item.name : '';
	}

	getItemLabelByValue(items, value) {
		const item = items.find(item => item.id == value.id);
		return item ? item.name : '';
	}

	public getTags(): string[] {
		let tags = Object.keys(this.parsedMetadata);
		return tags;
	}

	ngOnDestroy() {
		this.sub.unsubscribe();
	}

}
